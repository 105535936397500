import { EInteractionDirection, Maybe } from '@austria-codex/types'
import Bidirectional from '../../../assets/images/interaction/interaction-direction-bi.svg?react'
import Undirected from '../../../assets/images/interaction/interaction-direction-un.svg?react'
import Unidirectional from '../../../assets/images/interaction/interaction-direction-uni.svg?react'
import { Box, Flex } from '../../../components/Layout/Box'
import { Text } from '../../../components/Layout/Typography'

// We need to rotate the uni-directional symbol so that the fat arrow
// is on top and directs from right to left. Right substance
// is influencing the left substance.
const DirectionIcon = {
  [EInteractionDirection.Unidirectional]: (
    <Unidirectional style={{ transform: 'rotate(180deg)' }} />
  ),
  [EInteractionDirection.Bidirectional]: <Bidirectional />,
  [EInteractionDirection.Undirected]: <Undirected />,
}

type InteractionPairProps = {
  left: string
  right: string
  direction: Maybe<EInteractionDirection>
}

export function InteractionPair({
  left,
  right,
  direction,
}: InteractionPairProps) {
  const d = direction ?? EInteractionDirection.Undirected

  return (
    <Flex
      py={2}
      px={2}
      alignItems="center"
      justifyContent="center"
      flexDirection={['column', 'row']}
    >
      <Flex
        flex="1"
        flexBasis={1 / 2}
        justifyContent="flex-end"
        flexDirection={['row-reverse', 'row']}
      >
        <Text display="block" textAlign="right">
          {left}
        </Text>
      </Flex>
      <Box mx={3} my={[2, 0]}>
        {DirectionIcon[d]}
      </Box>
      <Flex flex="1" flexBasis={1 / 2}>
        <Text display="block" textAlign="left">
          {right}
        </Text>
      </Flex>
    </Flex>
  )
}
