import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { AlertMessage } from '../types/alert'
import { RootState } from './index'

const entitiesAdapter = createEntityAdapter<AlertMessage>()

const alertSlice = createSlice({
  name: 'alert',
  initialState: entitiesAdapter.getInitialState(),
  reducers: {
    add: entitiesAdapter.addOne,
    remove: entitiesAdapter.removeOne,
  },
})

export default alertSlice.reducer

export const { remove: removeAlert, add: addAlert } = alertSlice.actions

export const { selectAll: alertSelector } =
  entitiesAdapter.getSelectors<RootState>((state) => state.alert)
