import { gql } from '@apollo/client'
import { ProductData, ProductVerlaufData } from '@austria-codex/types'

export type ProductsByIdsVariables = {
  ids: string[]
}

export type ProductsResponse = {
  products: ProductData[]
}

export const PRODUCTS_QUERY = gql`
  query FetchProducts($ids: [String!]!) {
    products(identifiers: $ids) {
      id
      bezeichnung
      suffix
      istVeterinaer
      zusammensetzung {
        eintraege {
          ... on ProductCompositionSubstanceEntryType {
            eintragsTyp
            laufNummer
            bezLaufnummer
            stoff {
              stoff {
                id
                bezeichnung
              }
              basisStoff {
                id
                bezeichnung
              }
            }
          }
        }
      }
      klassifikation {
        istMonopraeparat
      }
    }
  }
`

export type ProductByIdVariable = {
  id: string
}

export type ProductResponse = {
  product: ProductVerlaufData
}

export const PRODUCT_VERLAUF_DIALOG_QUERY = gql`
  query FetchProductVerlaufForDialog($id: String!) {
    product(id: $id) {
      ... on ProductVerlaufType {
        id
        bezeichnung
        zulassungsnummer
        zulassungsnummerAlternativ
        klassifikationVerlauf {
          istMonopraeparat
          arzneiform {
            arzneiform
            geltungsbereiche
          }
          applikationsarten {
            appReihung
            anwGruppe
            weg
            wegKurz
            geltungsbereiche
          }
          atcCodes {
            atcCode
            text
          }
          indikationsgruppen {
            text
          }
        }
        teilbarkeit {
          maxTeilbarkeit
        }
        zusammensetzung {
          verordnungshinweise {
            verordnungsTyp
            einheitsAngabe
            reihenFolge
          }
          bezug {
            mengenAngabe
            einheitsAngabe
            alternativeBezuege {
              mengenAngabe
              einheitsAngabe
              quellenAngabe
            }
          }
          eintraege {
            laufNummer
            eintragsTyp
            bezLaufnummer
            praefix
            stoff {
              bezeichnung
              minStaerke
              maxStaerke
              einheitsAngabe
              staerkeEinheit
            }
            bezeichnung
            suffix
          }
          eigenschaften {
            eigenschaftsTyp
            minMenge
            maxMenge
            einheitsAngabe
            bezugsMenge
            bezugsEinheitsAngabe
            entsprichtBezug
            reihenFolge
            Geltungsbereiche {
              geltungsbereich
            }
          }
        }
        dokumentation {
          fachinformation
          gebrauchsinformation
          schnellhilfe {
            abschnittsInhalt
            text
          }
        }
        intoleranzen {
          lactoseIntoleranz
          galactoseIntoleranz
          glucoseGalactoseMalabs
          saccharoseIntoleranz
          fructoseIntoleranz
          histaminIntoleranz
          glutenIntoleranz
        }
        anwWarnHinweise {
          vvAufloesen
          vvZubereiten
          einnahmeMahlzeit
          einnahmeUnversehrt
          hinwAlkohol
          hinwDiabetiker
          hinwSchwangerschaft
          hinwStillzeit
          hinwKinder
          hinwKadersportler
          hinwSonnenlicht
          hinwVerkehrstuechtigkeit
          zusUeberwachung
          hinwReligion
          hinwVegan
          hinwAlterstauglichkeit
          geltungsbereiche {
            text
            inhaltsTyp
            quellenAngabe
          }
          arzneimittelRisiken {
            mglAuswirkungen
            quellenAngabe
            beschreibung
            quellenkommentar
          }
        }
        lagerung {
          kennzeichen {
            zeichen
            kurzZeichen
          }
          haltbarkeit {
            hinweis
            dauerOp
            dauerWert
            dauerEinheitsAngabe
          }
          lagerungsbedingung {
            hinweis
            tempOp
            tempMin
            tempMax
            tempEinheitsAngabe
          }
        }
        kontakte {
          zulassungsinhaber {
            firmenname
            anzeigename
            strasse
            plz
            ort
            land {
              isoCode
              bezeichnung
            }
            telefon
            telefax
            email
          }
          vertriebsfirma {
            firmenname
            anzeigename
            strasse
            plz
            ort
            land {
              isoCode
              bezeichnung
            }
            telefon
            telefax
            email
          }
          herstellerfirmen {
            firmenname
            anzeigename
            strasse
            plz
            ort
            land {
              isoCode
              bezeichnung
            }
            telefon
            telefax
            email
          }
        }
      }
    }
  }
`

//export const PRODUCT_QUERY = gql`
//   query FetchProduct($id: String!) {
//     product(identifier: $id) {
//       ... on ProductVerlaufType {
//         id
//         bezeichnung
//         istVeterinaer
//       }
//       ... on ProductType {
//         id
//         bezeichnung
//         suffix
//         istVeterinaer
//         zusammensetzung {
//           eintraege {
//             ... on ProductCompositionSubstanceEntryType {
//               eintragsTyp
//               laufNummer
//               bezLaufnummer
//               stoff {
//                 stoff {
//                   id
//                   bezeichnung
//                 }
//                 basisStoff {
//                   id
//                   bezeichnung
//                 }
//               }
//             }
//           }
//         }
//         klassifikation {
//           istMonopraeparat
//         }
//       }
//     }
//   }
// `
