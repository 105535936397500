import {
  EntityIndicationGroupHitsData,
  FeatureEnum,
  ProductData,
  SubstanceData,
} from '@austria-codex/types'
import { Box } from '@mui/material'
import { useContext } from 'react'
import AtIcon from '../../../assets/images/countryFlagsSvg/at-sm.svg?react'
import { CodeBlock } from '../../../components/Codes/CodeBlock'
import { CodeGroup } from '../../../components/Codes/CodesGroup'
import { Section } from '../../../components/Section/Section'
import { EntityContext } from '../../../contexts/entity'
import { useAppSelector } from '../../../hooks/useStoreHooks'
import { FeatureProps } from '../../../types/bundle'
import { IndicationGroupProductsTable } from './InterationGroupProductsTable'

function Heading() {
  return (
    <>
      <AtIcon />
      <Box sx={{ ml: 0.75 }}>Indikationsgruppe Österreich</Box>
    </>
  )
}

const modeToHeading = {
  national: <>Indikationsgruppe</>,
  international: <Heading />,
}

export function IndicationGroupsSection({ features }: FeatureProps) {
  const mode = useAppSelector((state) => state.user.mode)
  const entity = useContext(EntityContext) as ProductData | SubstanceData
  const hasProducts = features.includes(FeatureEnum.IndicationGroupsProducts)

  const indiGroups = entity?.klassifikation?.indikationsgruppen ?? []
  const heading = modeToHeading[mode]

  return (
    <Section heading={heading} width="small">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
        {indiGroups.map((indicationGroups, index) => (
          <IndicationGroup
            key={index}
            indicationGroups={indicationGroups}
            entityId={entity.id}
            hasProducts={hasProducts}
          />
        ))}
      </Box>
    </Section>
  )
}

type TIndicationGroupProps = {
  indicationGroups: EntityIndicationGroupHitsData[]
  entityId: string
  hasProducts: boolean
}

function IndicationGroup({
  indicationGroups,
  entityId,
  hasProducts,
}: TIndicationGroupProps) {
  // Order by indication group id: most general group on top, eg: 18 -> 18C.
  // We can simply use the length of the id for sorting.
  const orderedIndicationGroups = [...indicationGroups].sort(
    (a, b) => a.indikationsgruppe.id.length - b.indikationsgruppe.id.length
  )

  return (
    <CodeGroup>
      {orderedIndicationGroups.map(
        (
          {
            indikationsgruppe: { id, bezeichnung },
            treffer,
            bezeichnung: caption,
          },
          index
        ) => (
          <Box my={3} key={id}>
            <CodeBlock
              code={id}
              title={bezeichnung}
              caption={caption}
              hits={treffer}
              section={entityId}
              group={`alternatives`}
              identifier={id}
              hasToggle={hasProducts}
            >
              {(onToggle: (collapse?: boolean) => void) =>
                hasProducts && (
                  <Box my={2}>
                    <IndicationGroupProductsTable
                      indicationGroup={id}
                      name={`alternatives:indicationGroup:${index}:${id}`}
                      heading={bezeichnung}
                      subheading={id}
                      onClose={onToggle}
                    />
                  </Box>
                )
              }
            </CodeBlock>
          </Box>
        )
      )}
    </CodeGroup>
  )
}
