import { Box } from '@mui/material'
import EarthIcon from '../assets/images/earth.svg?react'

export function IntLabel() {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      <EarthIcon />
      <Box component="span">International (INT)</Box>
    </Box>
  )
}
