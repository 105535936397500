export const atcCodeLevel = (code: string, level: number) => {
  const length = [1, 3, 4, 5, 7]
  let hasPrefix = false

  if (code.startsWith('Q')) {
    hasPrefix = true
    code = code.substr(1)
  }

  code = code.substr(0, length[level - 1])

  return hasPrefix ? `Q${code}` : code
}

export const indicationGroupLevel = (code: string, level: number) => {
  const length = [2, 3, 4]
  let hasPrefix = false

  if (code.startsWith('8')) {
    hasPrefix = true
  }

  return code.substr(0, length[hasPrefix ? level : level - 1])
}

export const isHumanAtcCode = (code: string): boolean => !code.startsWith('Q')
