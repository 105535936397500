import { EntityDataUnion } from '@austria-codex/types'
import { EntityId } from '@reduxjs/toolkit'

export const closestUnselectedIndex = (
  index: number,
  results: EntityDataUnion[],
  selected: EntityId[],
  reverse: boolean
): number => {
  const step = reverse ? -1 : 1

  for (let i = index + step; i >= 0 && i < results.length; i += step) {
    if (!selected.includes(results[i].id)) {
      return i
    }
  }

  return index
}
