import { FeatureEnum, UserType } from '@austria-codex/types'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { MouseEvent, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import NotificationsIcon from '../../assets/images/ic-notification.svg?react'
import SettingsIcon from '../../assets/images/ic-settings.svg?react'
import { logout } from '../../client/austriaCodex'
import { useBundleFeature } from '../../hooks/useBundleFeature'
import { useAppDispatch, useAppSelector } from '../../hooks/useStoreHooks'
import { setBackUrl } from '../../store/settings.store'
import { Paths } from '../../utilities/path.constants'
import { toFullPath } from '../../utilities/url'
import { Flex } from '../Layout/Box'
import { Profile } from '../Profile/Profile'
import { profileRedirect } from '../Profile/actions'

export function Options() {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const profile = useAppSelector((state) => state.user.profile)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const hasSettings = useBundleFeature(FeatureEnum.Settings)
  const hasNotifications = useBundleFeature(FeatureEnum.Notifications)

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  // Do not show "Profile" for IP/Machine users
  const showProfileBtn = profile.user.type !== UserType.MACHINE
  const open = Boolean(anchorEl)

  return (
    <Flex>
      <Flex display={['flex', 'none']}>
        <IconButton
          id="profile-button-mobile"
          color="primary"
          onClick={handleClick}
          aria-controls={open ? 'profile-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="profile-menu-mobile"
          anchorEl={anchorEl}
          keepMounted
          transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          onClose={handleClose}
          MenuListProps={{ 'aria-labelledby': 'profile-button-mobile' }}
        >
          {hasNotifications && <MenuItem>Benachrichtigungen</MenuItem>}
          {hasSettings && (
            <MenuItem onClick={() => navigate(Paths.Settings)}>
              Einstellungen
            </MenuItem>
          )}
          {showProfileBtn && (
            <MenuItem onClick={() => profileRedirect()}>Profil</MenuItem>
          )}
          <MenuItem onClick={() => logout()}>Abmelden</MenuItem>
        </Menu>
      </Flex>
      <Flex display={['none', 'flex']} alignItems="center">
        {hasNotifications && (
          <IconButton aria-label="Benachrichtigungen" color="primary">
            <NotificationsIcon />
          </IconButton>
        )}
        {hasSettings && (
          <Link
            to={Paths.Settings}
            onClick={() => dispatch(setBackUrl(toFullPath(location)))}
          >
            <IconButton aria-label="Einstellungen" color="primary">
              <SettingsIcon />
            </IconButton>
          </Link>
        )}
        <Profile />
      </Flex>
    </Flex>
  )
}
