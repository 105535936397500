import { isSubstanceIdentifier } from '@austria-codex/utilities'
import { Action, ThunkAction } from '@reduxjs/toolkit'
import { RootState } from '.'
import { filterInteractions } from '../utilities/interactions'
import { filterMultiSelect } from '../utilities/multiSelect'
import { removeEntity } from './entities'
import { fetchInteractions, setInteractions, setMultiSelect } from './safety'

export const removeEntityAndUpdateRelatedData = (
  removeIdentifier: string
): ThunkAction<void, RootState, unknown, Action<string>> => {
  return (dispatch, getState) => {
    const state = getState()
    const { interactions, multiSelect } = state.safety
    const identifiers = state.entities.ids
    const interactionsFilter = state.interactionsFilter
    const newEntityIdentifiers = identifiers.filter(
      (identifier) => identifier !== removeIdentifier
    )

    // After deletion only one substance remains and therefore new interactions have to be fetched
    const shouldFetchInteractions =
      newEntityIdentifiers.length === 1 &&
      isSubstanceIdentifier(newEntityIdentifiers[0])

    if (shouldFetchInteractions) {
      dispatch(fetchInteractions(newEntityIdentifiers))
    } else {
      dispatch(
        setInteractions(
          filterInteractions(
            interactions,
            newEntityIdentifiers,
            interactionsFilter
          )
        )
      )
    }

    dispatch(
      setMultiSelect(filterMultiSelect(multiSelect, newEntityIdentifiers))
    )

    dispatch(removeEntity(removeIdentifier))
  }
}
