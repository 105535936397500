export const ENTITY_QUERY_FRAGMENT = `
  fragment SubstanceRetailReimbursementBoxHitsFragment on SubstanceRetailReimbursementBoxHitsType {
    G
    Y
    R
    N
  }

  fragment SubstanceRetailDispensaryHitsFragment on SubstanceRetailDispensaryHitsType {
    RP {
      ...SubstanceRetailReimbursementBoxHitsFragment
    }
    SG {
      ...SubstanceRetailReimbursementBoxHitsFragment
    }
    OTC {
      ...SubstanceRetailReimbursementBoxHitsFragment
    }
  }

  fragment SubstanceRetailDomainHitsFragment on SubstanceRetailDomainHitsType {
    human {
      ...SubstanceRetailDispensaryHitsFragment
    }
    veterinaer {
      ...SubstanceRetailDispensaryHitsFragment
    }
  }

  fragment SubstanceRetailAvailabilityHitsFragment on SubstanceRetailPreparationHitsType {
    mono {
      ...SubstanceRetailDomainHitsFragment
    }
    kombination {
      ...SubstanceRetailDomainHitsFragment
    }
  }

  fragment SubstanceRetailDeDispensaryHitsFragment on SubstanceRetailDeDispensaryHitsType {
    RP
    SG
    OTC
  }

  fragment SubstanceRetailDeDomainHitsFragment on SubstanceRetailDeDomainHitsType {
    human {
      ...SubstanceRetailDeDispensaryHitsFragment
    }
    veterinaer {
      ...SubstanceRetailDeDispensaryHitsFragment
    }
  }

  fragment SubstanceRetailDePreparationHitsFragment on SubstanceRetailDePreparationHitsType {
    mono {
      ...SubstanceRetailDeDomainHitsFragment
    }
    kombination {
      ...SubstanceRetailDeDomainHitsFragment
    }
  }

  fragment SubstanceApplicationCountryHitsFragment on SubstanceApplicationCountryHitsType {
    isoCode
    mono {
      human {
        ...SubstanceApplicationRoutesOfAdministrationHitsTypeFragment
      }
      veterinaer {
        ...SubstanceApplicationRoutesOfAdministrationHitsTypeFragment
      }
    }
    kombination {
      human {
        ...SubstanceApplicationRoutesOfAdministrationHitsTypeFragment
      }
      veterinaer {
        ...SubstanceApplicationRoutesOfAdministrationHitsTypeFragment
      }
    }
  }

  fragment ContactTypeFragment on ContactType {
    id
    anzeigename
    email
    telefon
    telefax
    strasse
    plz
    ort
    land {
      isoCode
      bezeichnung
    }
  }

  fragment SubstanceApplicationRoutesOfAdministrationHitsTypeFragment on SubstanceApplicationRoutesOdAdministrationHitsType {
    A
    B
    C
    D
    E
    F
    G
  }

  fragment AllergyTypeFragment on AllergyWithCrossAllergiesType {
    id
    bezeichnung
    beschreibung
    kreuzallergien {
      id
      bezeichnung
      beschreibung
    }
  }
`

export const ENTITY_QUERY_PARTIAL = `
  entities(identifiers: $newEntityIdentifiers, searched: $searched) {
    ... on SubstanceType {
      ...SubstanceFragment
    }
    ... on SubstanceInternationalType {
      ...SubstanceInternationalFragment
    }
    ... on ProductType {
      ...ProductFragment
    }
    ... on ProductInternationalType {
      ...ProductInternationalFragment
    }
  }
`
