import { TCountryMode, UserType } from '@austria-codex/types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ProfileQueryResponse } from '../api/profile'
import { LocalStorage } from '../helpers/localstorage.helper'

export type TUserState = {
  mode: TCountryMode
  selectedCountries: string[]
  profile: ProfileQueryResponse['profile']
  licenses: string[]
}

const defaultCountries = ['at', 'de']

function getInitialState(): TUserState {
  const userStr = LocalStorage.get('user')

  return userStr
    ? JSON.parse(userStr)
    : {
        mode: 'national',
        // Selected countries are only used in international mode.
        // In international mode, 'at' and 'de' are always selected
        // and cannot be changed.
        selectedCountries: defaultCountries,
        profile: {
          image: null,
          gender: { id: '2' },
          user: {
            id: null,
            profile: null,
            customer: null,
            type: UserType.EXTERNAL,
          },
        },
        licenses: [],
      }
}

const userSlice = createSlice({
  name: 'user',
  initialState: getInitialState(),
  reducers: {
    setProfile(state, action: PayloadAction<ProfileQueryResponse>) {
      state.profile = action.payload.profile
    },
    setMode(state, action: PayloadAction<TUserState['mode']>) {
      state.mode = action.payload
    },
    // Session mode is basically the same as setMode, difference is,
    // that when dispatching session mode, the mode won't be updated
    // in the local storage middleware. (see ./middleware/localStorage.middleware.ts)
    setSessionMode(state, action: PayloadAction<TUserState['mode']>) {
      state.mode = action.payload
    },
    setLicenses(state, action: PayloadAction<string[]>) {
      state.licenses = action.payload
    },
    addCountry(state, action: PayloadAction<string>) {
      if (state.selectedCountries.includes(action.payload)) {
        return
      }

      state.selectedCountries.push(action.payload)
    },
    addCountries(state, action: PayloadAction<string[]>) {
      const countries = state.selectedCountries.concat(action.payload)
      const uniqueCountries = new Set(countries)
      state.selectedCountries = [...uniqueCountries]
    },
    removeCountry(state, action: PayloadAction<string>) {
      const currentCountries = state.selectedCountries
      state.selectedCountries = currentCountries.filter(
        (c) => c !== action.payload
      )
    },
    toggleCountry(state, action: PayloadAction<string>) {
      const country = action.payload
      if (state.selectedCountries.includes(country)) {
        const currentCountries = state.selectedCountries
        state.selectedCountries = currentCountries.filter((c) => c !== country)
      } else {
        state.selectedCountries.push(country)
      }
    },
    resetCountries(state) {
      state.selectedCountries = defaultCountries
    },
  },
})

export const {
  setProfile,
  setLicenses,
  setMode,
  setSessionMode,
  addCountry,
  addCountries,
  removeCountry,
  toggleCountry,
  resetCountries,
} = userSlice.actions
export default userSlice.reducer
