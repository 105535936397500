import { createCookieAdapter } from '../utilities/storage/cookie'

export type SettingsAdapterInterface = {
  set: (value: unknown) => Promise<boolean>
  get: () => Promise<unknown>
}

type SettingsType = BooleanConstructor | StringConstructor | NumberConstructor

type SettingsInterface = {
  [key: string]: {
    type: SettingsType
    adapter: SettingsAdapterInterface
  }
}

export const settings: SettingsInterface = {
  collapseOpenOnAdd: {
    type: Boolean,
    adapter: createCookieAdapter('collapseOpenOnAdd'),
  },
  includeVeterinary: {
    type: Boolean,
    adapter: createCookieAdapter('includeVeterinary'),
  },
  includeRevokedAuthorizations: {
    type: Boolean,
    adapter: createCookieAdapter('includeRevokedAuthorizations'),
  },
}

export function loadSettings(): Promise<{ [key: string]: unknown }> {
  return new Promise((resolve) => {
    const userSettings: Record<string, unknown> = {}
    const promises: Promise<void>[] = []

    for (const key in settings) {
      const setting = settings[key]

      promises.push(
        setting.adapter.get().then((value) => {
          if (value !== undefined) {
            userSettings[key] = convertValue(value, setting.type)
          }
        })
      )
    }

    Promise.all(promises).then(() => resolve(userSettings))
  })
}

function convertValue(value: unknown, type: SettingsType) {
  switch (type) {
    case Boolean: {
      if (typeof value === 'string') {
        return value === 'true'
      }
      return Boolean(value)
    }
    case Number: {
      return Number(value)
    }
    default:
      return value
  }
}
