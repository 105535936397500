import { EInteractionSeverity } from '@austria-codex/types'
import { createTheme } from '@mui/material/styles'
import { commonTheme } from './common'

declare module '@mui/material/styles/createPalette' {
  type PaletteLightColor = {
    main: string
    light: string
  }

  type PaletteDarkColor = {
    main: string
    dark: string
  }

  interface Palette {
    product: PaletteDarkColor
    substance: PaletteDarkColor
    personalCharacteristic: PaletteDarkColor
    highlight: PaletteLightColor
    reimbursementBox: {
      G: PaletteLightColor
      Y: PaletteLightColor
      R: PaletteLightColor
      N: PaletteLightColor
    }
    interactionSeverity: string[]
  }

  interface PaletteOptions {
    product: PaletteDarkColor
    substance: PaletteDarkColor
    personalCharacteristic: PaletteDarkColor
    highlight: PaletteLightColor
    reimbursementBox: {
      G: PaletteLightColor
      Y: PaletteLightColor
      R: PaletteLightColor
      N: PaletteLightColor
    }
    interactionSeverity: Record<EInteractionSeverity, string>
  }
}

export const materialTheme = createTheme({
  ...commonTheme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1980,
    },
  },
  typography: {
    fontFamily: ['PT Sans', 'sans-serif'].join(','),
    h4: {
      fontSize: '1.125rem',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: commonTheme.palette.grey[700],
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 12,
          fontSize: 12,
        },
        paddingCheckbox: {
          paddingLeft: 4,
          paddingRight: 4,
        },
        head: {
          color: commonTheme.palette.grey[600],
          textTransform: 'uppercase',
          fontSize: 12,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: 16,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
  },
})
