export enum AuthenticationErrorTypes {
  AUTH_NO_TOKEN = 'AUTH_NO_TOKEN',
  AUTH_INVALID_TOKEN = 'AUTH_INVALID_TOKEN',
  NO_TOKEN = 'NO_TOKEN',
  INVALID_USER = 'INVALID_USER',
  NO_LICENSE = 'NO_LICENSE',
  NO_MACHINE_LICENSE = 'NO_MACHINE_LICENSE',
}

export enum AuthorizationErrorTypes {
  FEATURE_NOT_LICENSED = 'FEATURE_NOT_LICENSED',
}
