import { EInteractionSeverity } from '@austria-codex/types'
import styled from '@emotion/styled'
import { InteractionIcon } from '../../../components/Icons/InteractionIcon'
import { Flex } from '../../../components/Layout/Box'
import { Text } from '../../../components/Layout/Typography'
import { interactionHeadings } from './constants'

type InteractionSeverityHeaderProps = {
  severity: EInteractionSeverity
}

type InteractionHeaderProps = {
  title?: string
}

export function InteractionHeader({
  severity,
  title,
}: InteractionSeverityHeaderProps & InteractionHeaderProps) {
  const showSubHeading = severity > 10 && title

  return (
    <InteractionHeaderBorder
      severity={severity}
      flexDirection="column"
      alignItems="center"
      p={2}
    >
      <InteractionIcon severity={severity} />
      <Text variant="h6" fontWeight="bold" display="block">
        {interactionHeadings[severity] ?? '---'}
      </Text>
      {showSubHeading && (
        <SubHeading severity={severity} textAlign="center">
          {title}
        </SubHeading>
      )}
    </InteractionHeaderBorder>
  )
}

const InteractionHeaderBorder = styled(Flex)<InteractionSeverityHeaderProps>`
  border-top: 6px solid
    ${(props) => props.theme.palette.interactionSeverity[props.severity]};
  border-bottom: 1px solid
    ${(props) => props.theme.palette.interactionSeverity[props.severity]};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`

const SubHeading = styled(Text)<InteractionSeverityHeaderProps>`
  color: ${(props) => props.theme.palette.interactionSeverity[props.severity]};
`
