import { css } from '@emotion/react'
import { Config } from '../../../config'
import { Flex } from '../../Layout/Box'
import { ParagraphChildType } from '../types'
import { TextElement } from './TextElement'

type IconProps = {
  children: ParagraphChildType[]
  id: string
}

export function Icon({ children, id }: IconProps) {
  return (
    <Flex>
      <img
        src={`${Config.userManager.api.file.path}${Config.userManager.api.file.endpoints.files}/${id}`}
        alt=""
        css={css`
          max-height: 24px;
          margin-right: 16px;
        `}
      />
      <div>
        {children.map((child, index) => (
          <TextElement key={index} child={child} variant="p" />
        ))}
      </div>
    </Flex>
  )
}
