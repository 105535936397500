import styled from '@emotion/styled'
import { ReactNode } from 'react'
import ApoLogo from '../../assets/images/apoverlag.svg?react'
import { Box, Flex } from '../Layout/Box'

type Props = {
  children: ReactNode
}

export function ErrorScreen({ children }: Props) {
  return (
    <Flex
      height="100%"
      justifyContent="center"
      alignItems="center"
      bg="primary.light"
    >
      <Flex
        alignItems="center"
        flexDirection="column"
        bg="white"
        p={4}
        borderRadius="5px"
      >
        <Box mb={3}>
          <Logo />
        </Box>
        <Flex flexDirection="column" alignItems="center">
          {children}
        </Flex>
      </Flex>
    </Flex>
  )
}

const Logo = styled(ApoLogo)`
  height: 48px;
  width: auto;
`
