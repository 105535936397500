import { FeatureEnum } from '@austria-codex/types'
import { isProduct, isSubstance } from '@austria-codex/utilities'
import { ModuleSections } from '../../components/ModuleSections/ModuleSections'
import { RetailMetaHeader } from '../../features/components/EntityHeader/RetailMetaHeader'
import { SubstanceMetaHeader } from '../../features/components/EntityHeader/SubstanceMetaHeader'
import { useShouldRenderEntityType } from '../../hooks/useShouldRenderEntityType'

export function RetailPage() {
  const shouldRenderEntityType = useShouldRenderEntityType(
    FeatureEnum.ProductRetailHeader,
    FeatureEnum.SubstanceRetailHeader
  )

  return (
    <ModuleSections
      module="Retail"
      metaRenderer={(entity) =>
        isProduct(entity) ? (
          <RetailMetaHeader product={entity} />
        ) : isSubstance(entity) ? (
          <SubstanceMetaHeader substance={entity} />
        ) : null
      }
      shouldRenderEntityType={shouldRenderEntityType}
    />
  )
}
