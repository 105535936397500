import { ContactData } from '@austria-codex/types'
import Typography from '@mui/material/Typography'
import { Flag } from '../Icons/Flag'
import { Anchor } from '../Layout/Anchor'
import { Box, Flex } from '../Layout/Box'

type ContactProps = {
  contact: ContactData
}

export function Contact({ contact }: ContactProps) {
  return (
    <Box>
      <Typography variant="h6">{contact.anzeigename}</Typography>
      <Flex width="100%" flexDirection={['column', 'row']}>
        <Flex alignItems="center" flex="1 1 50%">
          <Box>
            <div>{contact.strasse}</div>
            <div>
              {contact.plz} {contact.ort}
            </div>
            <div>
              <Box as="span" mr={1}>
                {contact.land?.bezeichnung}
              </Box>
              {contact.land?.isoCode && <Flag code={contact.land.isoCode} />}
            </div>
          </Box>
        </Flex>
        <Flex flexDirection="column" flex="1 1 50%" justifyContent="end">
          {contact.telefon && (
            <div>
              Tel.:{' '}
              <Anchor href={`tel:${contact.telefon}`}>{contact.telefon}</Anchor>
            </div>
          )}
          {contact.telefax && (
            <div>
              Fax:{' '}
              <Anchor href={`tel:${contact.telefax}`}>{contact.telefax}</Anchor>
            </div>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
