import { useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import Tooltip from '@mui/material/Tooltip'
import { CharacteristicData } from '@austria-codex/types'
import { Pill, PillAddOn, PillButton } from '../../../../components/Pill/Pill'

type CharacteristicPillProps = {
  characteristic: CharacteristicData
  onClick: (allergy: CharacteristicData) => void
}

export function CharacteristicPill({
  characteristic,
  onClick,
}: CharacteristicPillProps) {
  const [hover, setHover] = useState(false)

  return (
    <Pill type="personalCharacteristic" highlight={hover}>
      <PillAddOn>
        <Tooltip title="Merkmal entfernen">
          <PillButton
            onClick={() => onClick(characteristic)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <ClearIcon fontSize="small" color="inherit" />
          </PillButton>
        </Tooltip>
      </PillAddOn>
      <span dangerouslySetInnerHTML={{ __html: characteristic.bezeichnung }} />
    </Pill>
  )
}
