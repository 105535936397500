import React, { ReactNode } from 'react'
import { Box, Flex } from '../Layout/Box'

type IconTextBlockProps = {
  children: ReactNode
  Icon: ReactNode
}

export function IconTextBlock({ children, Icon }: IconTextBlockProps) {
  return (
    <Flex mb={1} alignItems="center">
      <Flex>{Icon}</Flex>
      <Box ml={2}>{children}</Box>
    </Flex>
  )
}
