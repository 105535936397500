import BlueIcon from '../../assets/images/interaction/ic-interaction-blue.svg?react'
import GreyIcon from '../../assets/images/interaction/ic-interaction-grey.svg?react'
import OrangeIcon from '../../assets/images/interaction/ic-interaction-orange.svg?react'
import ProduktSpezifischIcon from '../../assets/images/interaction/ic-interaction-produktspezifisch.svg?react'
import KontraindiziertIcon from '../../assets/images/interaction/ic-interaction-red-kontraindiziert.svg?react'
import SchwerwiegendIcon from '../../assets/images/interaction/ic-interaction-red-schwerwiegend.svg?react'
import YellowIcon from '../../assets/images/interaction/ic-interaction-yellow.svg?react'

type InteractionIconProps = {
  severity: number
}

export function InteractionIcon({ severity }: InteractionIconProps) {
  switch (severity) {
    case 0:
      return <GreyIcon />
    case 10:
      return <BlueIcon />
    case 20:
      return <ProduktSpezifischIcon />
    case 30:
      return <YellowIcon />
    case 40:
      return <OrangeIcon />
    case 50:
      return <SchwerwiegendIcon />
    case 60:
      return <KontraindiziertIcon />
    default:
      return null
  }
}
