import { Anchor } from '../../components/Layout/Anchor'
import { Section } from '../../components/Section/Section'
import { UpSellingRibbon } from '../../components/UpSellingRibbon/UpSellingRibbon'
import { Config } from '../../config'

export function SubstanceAlternativesTeaserSection() {
  return (
    <Section>
      <UpSellingRibbon>
        <>
          Weitere Inhalte wie alternative Arzneispezialitäten nach ATC-Codes,
          Indikationsgruppen, Wirkstoffen oder ausgewählten pharmakologischen
          Eigenschaften sowie Angaben zu Parallelimporten und Biosimilars finden
          Sie im Austria-Codex Online auf{' '}
          <Anchor href={Config.upselling.path} target="_blank">
            meinAPOVERLAG
          </Anchor>
          .
        </>
      </UpSellingRibbon>
    </Section>
  )
}
