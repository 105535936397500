import { ProductUnion } from '@austria-codex/types'
import { isProduct } from '@austria-codex/utilities'
import { EntityProductIcon } from '../../components/EntityIcon/ProductIcon'
import { Text } from '../../components/Layout/Typography'
import { SeparatedAgentList } from '../../components/Utility/SeparatedActiveIngredientList'
import { getAlpha3ByAlpha2 } from '../../helpers/country.helper'
import {
  getActiveBaseSubstancesInt,
  getSubstancesInt,
} from '../../helpers/product-international.helper'
import {
  getActiveSubstances,
  getSubstances,
} from '../../helpers/product.helper'

export const productIconCell = {
  key: 'icon',
  hideTitle: true,
  width: 48,
  render: (_: unknown, product: ProductUnion) => {
    return (
      <Text color="primary.main">
        <EntityProductIcon product={product} />
      </Text>
    )
  },
}

export const agentsCell = {
  key: 'agents',
  title: 'Wirkstoff',
  render: (_: unknown, product: ProductUnion) => {
    const agents = isProduct(product)
      ? getSubstances(product)
      : getSubstancesInt(product)

    return <SeparatedAgentList agents={agents} limit={3} />
  },
  width: 230,
}

export const mainAgentsCell = {
  key: 'main-agents',
  title: 'Wirkstoff',
  render: (_: unknown, product: ProductUnion) => {
    const agents = isProduct(product)
      ? getActiveSubstances(product)
      : getActiveBaseSubstancesInt(product)

    return <SeparatedAgentList agents={agents} limit={3} />
  },
  width: 230,
}

export const descriptionCell = {
  key: 'description',
  title: 'Beschreibung',
  render: (
    _: unknown,
    product: ProductUnion,
    meta?: Record<string, unknown>
  ) => {
    const isProd = isProduct(product)
    const suffix = isProd ? product.suffix : null
    const bezeichnung = isProd ? product.bezeichnung : product.acoSearchTerm

    const showProviderIds =
      meta && meta['showProviderIds']
        ? (meta['showProviderIds'] as string[])
        : []

    // Show the provider name on those products, which are duplicates.
    if (showProviderIds.length > 0) {
      const provider = isProd
        ? product.kontakte?.vertriebsfirma?.anzeigename
        : product.kontakteInt.anbieter.kurzname

      return (
        <>
          {bezeichnung} {suffix && <Text fontStyle="italic">{suffix}</Text>}
          {provider && <Text color="grey.600">({provider})</Text>}
        </>
      )
    }

    return (
      <span>
        {bezeichnung} {suffix && <Text fontStyle="italic">{suffix}</Text>}
      </span>
    )
  },
  width: '100%',
}

export const countryCell = {
  key: 'isoCode',
  title: 'Land',
  render: (_data: unknown, product: ProductUnion) => {
    const alpha3 = getAlpha3ByAlpha2(product.isoCode)
    return <>{alpha3.toUpperCase()}</>
  },
  width: '120px',
}
