import { Box } from '@mui/material'
import OutOfStockIcon from '../../assets/images/nicht-im-handel.svg?react'
import { List, ListItem } from '../../components/Layout/List'
import { Text } from '../../components/Layout/Typography'
import { Section } from '../../components/Section/Section'
import { useProductData } from '../../contexts/entity'
import { isType2Or3Product } from '../../helpers/product.helper'

const typeToText = new Map<number, string>()
typeToText.set(2, 'Zugelassene Arzneispezialität, noch nicht im Handel.')
typeToText.set(3, 'Zugelassene Arzneispezialität, nicht mehr im Handel.')

export function DispensarySection() {
  const product = useProductData()

  const ausVerlauf = product.ausVerlauf
  const nichtImHandel = isType2Or3Product(ausVerlauf)

  if (nichtImHandel) {
    const text = typeToText.get(ausVerlauf)

    return (
      <Section width="small" heading="Verfügbarkeit">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
          <OutOfStockIcon />
          <Text>{text}</Text>
        </Box>
      </Section>
    )
  }

  return product.abgabe?.kennzeichen &&
    product.abgabe.kennzeichen.length > 0 ? (
    <Section width="small" heading="Verschreibung und Abgabe">
      <List>
        {product.abgabe.kennzeichen.map((indicator) => (
          <ListItem key={indicator.bezeichnung}>
            {(indicator.kategorie === 1 || indicator.kategorie === 3) && (
              <Text mr={2} fontWeight="bold">
                {indicator.kategorie === 1
                  ? indicator.synonym
                  : indicator.bezeichnung}
                :
              </Text>
            )}
            {indicator.beschreibungKurz || indicator.beschreibung}
          </ListItem>
        ))}
      </List>
    </Section>
  ) : null
}
