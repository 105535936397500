import { useLazyQuery } from '@apollo/client'
import type {
  CharacteristicData,
  SearchResultInterface,
} from '@austria-codex/types'
import styled from '@emotion/styled'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import {
  useEffect,
  useState,
  type ChangeEvent,
  type KeyboardEvent,
} from 'react'
import { useDebounce } from 'use-debounce'
import { CHARACTERISTICS_SEARCH_QUERY } from '../../../../api/characteristicsSearch'
import { Box } from '../../../../components/Layout/Box'
import { Heading } from '../../../../components/Layout/Typography'
import { useSearchField } from '../../../../hooks/useSearchField'
import { useAppDispatch, useAppSelector } from '../../../../hooks/useStoreHooks'
import {
  addCharacteristic,
  characteristicsSelector,
  removeCharacteristic,
} from '../../../../store/characteristics'
import { CharacteristicPillsList } from './CharacteristicPillsList'

type TSearchQueryVariables = { query: string }
type TSearchQueryResult = {
  characteristics: SearchResultInterface<CharacteristicData[]>
}

const queryTreshold = 2

export function CharacteristicSelect() {
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(false)
  const [debouncing, setDebouncing] = useState(false)
  const [debouncedQuery] = useDebounce(query, 300)
  const dispatch = useAppDispatch()
  const characteristics = useAppSelector(characteristicsSelector)

  const [getCharacteristics, { loading, data }] = useLazyQuery<
    TSearchQueryResult,
    TSearchQueryVariables
  >(CHARACTERISTICS_SEARCH_QUERY)

  const characteristicsHits = data?.characteristics?.hits

  const { keyHandler, itemProps, reset, selected } =
    useSearchField<CharacteristicData>(
      characteristicsHits ?? [],
      characteristics
    )

  useEffect(() => {
    if (debouncedQuery.length > queryTreshold) {
      setDebouncing(false)
      getCharacteristics({
        variables: {
          query,
        },
      })
    }
  }, [debouncedQuery, getCharacteristics, query])

  useEffect(() => {
    setDebouncing(query.length > queryTreshold)
  }, [query])

  useEffect(() => {
    reset()
  }, [data, characteristics, reset])

  function onAdd(characteristic: CharacteristicData) {
    dispatch(addCharacteristic(characteristic))
    setQuery('')
    reset()
  }

  function onRemove(characteristic: CharacteristicData) {
    dispatch(removeCharacteristic(characteristic.id))
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value.trimStart())
  }

  function onKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' && selected) {
      onAdd(selected)
    }
  }

  const progress = loading || debouncing

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Box mb={2}>
          <Heading level={3}>Allergien</Heading>
        </Box>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Container {...keyHandler}>
            <TextField
              label="Allergie suchen"
              fullWidth
              value={query}
              onFocus={() => setOpen(true)}
              onChange={onChange}
              inputProps={{ onKeyDown }}
            />
            {open && query.length > queryTreshold && (
              <Dropdown>
                {progress ? (
                  <Item disabled>Lädt...</Item>
                ) : data?.characteristics?.hits?.length === 0 ? (
                  <Item disabled>Keine Treffer</Item>
                ) : (
                  characteristicsHits?.map((characteristic, index) => (
                    <Item
                      {...itemProps(characteristic, index)}
                      key={characteristic.id}
                      onClick={() => onAdd(characteristic)}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: characteristic.bezeichnung,
                        }}
                      />
                    </Item>
                  ))
                )}
              </Dropdown>
            )}
          </Container>
        </ClickAwayListener>
      </Grid>
      {characteristics.length > 0 && (
        <Grid item xs={12}>
          <Box mb={3}>
            <CharacteristicPillsList
              characteristics={characteristics}
              onRemove={onRemove}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

type ItemProps = {
  selected?: boolean
  disabled?: boolean
}

const Item = styled(Box)<ItemProps>`
  background: ${(props) =>
    props.selected ? props.theme.palette.action.hover : 'inherit'};
  color: ${(props) =>
    props.disabled ? props.theme.palette.action.disabled : 'inherit'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

Item.defaultProps = {
  p: 2,
}

const Container = styled.div`
  position: relative;
  z-index: 900;
`

const Dropdown = styled(Paper)`
  margin-top: 3px;
  padding: 4px 0;
  position: absolute;
  left: 0;
  right: 0;
`

Dropdown.defaultProps = {
  elevation: 5,
}
