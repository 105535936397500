import { FilterMatchTypesEnum } from '@austria-codex/types'
import { PRODUCTS_BY_AGENT_TABLE_CONFIG } from '../../../../common/tableConfig/products-international'
import { IntProductsDataTable } from '../../../../components/DataTable/IntProductsDataTable'
import { useProductIntData } from '../../../../contexts/entity'

type TProps = {
  substanceId: string
  heading?: string
  subheading?: string
  onClose: () => void
}

export function IntCompositionProductsTable({
  substanceId,
  heading,
  subheading,
  onClose,
}: TProps) {
  const product = useProductIntData()

  return (
    <IntProductsDataTable
      config={PRODUCTS_BY_AGENT_TABLE_CONFIG}
      filter={{
        agents: {
          value: [substanceId],
          match: product.klassifikationInt?.istMonopraeparat
            ? FilterMatchTypesEnum.EXACT
            : FilterMatchTypesEnum.PARTIAL,
        },
      }}
      heading={heading}
      subheading={subheading}
      onClose={onClose}
    />
  )
}
