import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/useStoreHooks'
import {
  addPersonalCharacteristic,
  personalCharacteristicsSelector,
  removePersonalCharacteristic,
  togglePersonalCharacteristic,
} from '../../../store/personalCharacteristics'
import { PersonalCharacteristicsEnum } from '../../../types/highlight'

const GENDER_OPTIONS = [
  {
    label: 'Keine Angabe',
    value: '',
  },
  {
    label: 'Männlich',
    value: PersonalCharacteristicsEnum.MALE,
  },
  {
    label: 'Weiblich',
    value: PersonalCharacteristicsEnum.FEMALE,
  },
]

const AGE_OPTIONS = [
  {
    label: 'Erwachsene',
    value: '',
  },
  {
    label: 'Kinder',
    value: PersonalCharacteristicsEnum.CHILD,
  },
]

export function FeaturesOptions() {
  const dispatch = useAppDispatch()
  const selectedPersonalCharacteristics = useAppSelector(
    personalCharacteristicsSelector
  )

  function onToggle(personalCharacteristic: PersonalCharacteristicsEnum) {
    dispatch(togglePersonalCharacteristic(personalCharacteristic))
  }

  const onChange = useCallback(
    (selected: string | null, deselected: string | null) => {
      if (selected && selected !== '') {
        dispatch(
          addPersonalCharacteristic(selected as PersonalCharacteristicsEnum)
        )
      }
      if (deselected && deselected !== '') {
        dispatch(
          removePersonalCharacteristic(
            deselected as PersonalCharacteristicsEnum
          )
        )
      }

      if (
        deselected === PersonalCharacteristicsEnum.FEMALE ||
        selected === PersonalCharacteristicsEnum.CHILD
      ) {
        dispatch(
          removePersonalCharacteristic(PersonalCharacteristicsEnum.PREGNANT)
        )
        dispatch(
          removePersonalCharacteristic(
            PersonalCharacteristicsEnum.BREAST_FEEDING
          )
        )
      }
    },
    [dispatch]
  )

  const canSelectPregnancyOptions =
    selectedPersonalCharacteristics.includes(
      PersonalCharacteristicsEnum.FEMALE
    ) &&
    !selectedPersonalCharacteristics.includes(PersonalCharacteristicsEnum.CHILD)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <FormControl variant="standard" component="fieldset">
          <FormLabel component="legend">Geschlecht</FormLabel>
          <RadioOptions
            options={GENDER_OPTIONS}
            highlightOptions={selectedPersonalCharacteristics}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FormControl variant="standard" component="fieldset">
          <FormLabel component="legend">Altersgruppe</FormLabel>
          <RadioOptions
            options={AGE_OPTIONS}
            highlightOptions={selectedPersonalCharacteristics}
            onChange={onChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FormControl variant="standard" component="fieldset">
          <FormLabel component="legend">Personengruppen</FormLabel>
          <CheckboxOption
            value={PersonalCharacteristicsEnum.PREGNANT}
            highlightOptions={selectedPersonalCharacteristics}
            onChange={onToggle}
            label="Schwanger"
            disabled={!canSelectPregnancyOptions}
          />
          <CheckboxOption
            value={PersonalCharacteristicsEnum.BREAST_FEEDING}
            highlightOptions={selectedPersonalCharacteristics}
            onChange={onToggle}
            label="Stillend"
            disabled={!canSelectPregnancyOptions}
          />
          <CheckboxOption
            value={PersonalCharacteristicsEnum.SQUAD_SPORT}
            highlightOptions={selectedPersonalCharacteristics}
            onChange={onToggle}
            label="Kadersportler"
          />
          <CheckboxOption
            value={PersonalCharacteristicsEnum.DIABETES}
            highlightOptions={selectedPersonalCharacteristics}
            onChange={onToggle}
            label="Diabetiker"
          />
          <CheckboxOption
            value={PersonalCharacteristicsEnum.ALCOHOL}
            highlightOptions={selectedPersonalCharacteristics}
            onChange={onToggle}
            label="Alkoholabstinente"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FormControl variant="standard" component="fieldset">
          <FormLabel component="legend">Intoleranzen</FormLabel>
          <CheckboxOption
            value={PersonalCharacteristicsEnum.HISTAMINE_INTOLERANCE}
            highlightOptions={selectedPersonalCharacteristics}
            onChange={onToggle}
            label="Histamin"
          />
          <CheckboxOption
            value={PersonalCharacteristicsEnum.SUGAR_INTOLERANCE}
            highlightOptions={selectedPersonalCharacteristics}
            onChange={onToggle}
            label="Zuckerintoleranz"
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

type Option = {
  value: string | null
  label: string
}

type RadioSProps = {
  options: Option[]
  highlightOptions: string[]
  onChange: (value: string | null, oldValue: string | null) => void
}

function RadioOptions({ options, onChange, highlightOptions }: RadioSProps) {
  const value =
    options.find(
      (option) => option.value && highlightOptions.includes(option.value)
    )?.value ?? ''

  return (
    <RadioGroup
      name="gender"
      value={value}
      onChange={(event, nextValue) => onChange(nextValue, value)}
    >
      {options.map(({ value, label }) => (
        <FormControlLabel
          control={<Radio />}
          label={label}
          value={value}
          key={value}
        />
      ))}
    </RadioGroup>
  )
}

type CheckboxOptionProps = {
  value: string
  label: string
  highlightOptions: string[]
  onChange: (value: PersonalCharacteristicsEnum) => void
  disabled?: boolean
}

function CheckboxOption({
  value,
  label,
  onChange,
  highlightOptions,
  disabled,
}: CheckboxOptionProps) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          checked={highlightOptions.includes(value)}
          onChange={() => onChange(value as PersonalCharacteristicsEnum)}
          name={value}
          color="primary"
        />
      }
      label={label}
    />
  )
}
