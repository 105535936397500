import {
  EEvaluationDescription,
  EInteractionSeverity,
} from '@austria-codex/types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { LocalStorage } from '../helpers/localstorage.helper'

export type TInteractionsFilterState = {
  // Schweregrade
  selectedSeverities: EInteractionSeverity[]
  // Datenlage
  selectedEvaluations: EEvaluationDescription[]
}

// Default selected values
const severities = [
  EInteractionSeverity.NoStatementPossible,
  EInteractionSeverity.NoInteraction,
  EInteractionSeverity.ProductSpecificWarning,
  EInteractionSeverity.Minor,
  EInteractionSeverity.Moderate,
  EInteractionSeverity.Severe,
  EInteractionSeverity.Contraindicated,
]
const evaluations = [
  EEvaluationDescription.Unknown,
  EEvaluationDescription.NotSufficient,
  EEvaluationDescription.Weak,
  EEvaluationDescription.Enough,
  EEvaluationDescription.Good,
]

function getInitialState(): TInteractionsFilterState {
  const stateStr = LocalStorage.get('interactionsFilter')

  return stateStr
    ? JSON.parse(stateStr)
    : {
        selectedSeverities: [...severities],
        selectedEvaluations: [...evaluations],
      }
}

const interactionsFilterSlice = createSlice({
  name: 'interactionsFilter',
  initialState: getInitialState(),
  reducers: {
    toggleSeverity(state, action: PayloadAction<EInteractionSeverity>) {
      const severity = action.payload
      if (state.selectedSeverities.includes(severity)) {
        const currentSeverities = state.selectedSeverities
        state.selectedSeverities = currentSeverities.filter(
          (s) => s !== severity
        )
      } else {
        state.selectedSeverities.push(severity)
      }
    },
    toggleEvaluation(state, action: PayloadAction<EEvaluationDescription>) {
      const evaluation = action.payload
      if (state.selectedEvaluations.includes(evaluation)) {
        const currentEvaluations = state.selectedEvaluations
        state.selectedEvaluations = currentEvaluations.filter(
          (e) => e !== evaluation
        )
      } else {
        state.selectedEvaluations.push(evaluation)
      }
    },
  },
})

export const { toggleSeverity, toggleEvaluation } =
  interactionsFilterSlice.actions
export default interactionsFilterSlice.reducer
