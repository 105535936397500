import { Html } from '../../components/Html/Html'
import { DocumentationSection } from '../../components/Section/DocumentationSection'
import { useProductData } from '../../contexts/entity'

export function HabituationEffectSection() {
  const product = useProductData()

  return product.dokumentation?.schnellhilfe?.gewoehnungseffekte ? (
    <DocumentationSection
      inherit={product.dokumentation?.schnellhilfe?.gewoehnungseffekte.vererbt}
      width="small"
      heading="Gewöhnungseffekte"
    >
      <Html
        content={product.dokumentation?.schnellhilfe.gewoehnungseffekte.text}
      />
    </DocumentationSection>
  ) : null
}
