import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  query: string
  hitAdded: boolean
}

const initialState: State = {
  query: '',
  hitAdded: false,
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setQuery(state, { payload }: PayloadAction<State>) {
      state.query = payload.query.replace(/\s+/g, ' ').replace(/^\s+/, '')
      state.hitAdded = payload.hitAdded
    },
  },
})

export default searchSlice.reducer
export const { setQuery } = searchSlice.actions
