import { FilterMatchTypesEnum, type ProductsFilter } from '@austria-codex/types'
import {
  isProduct,
  isProductInternational,
  isSubstance,
  isSubstanceInternational,
} from '@austria-codex/utilities'
import Typography from '@mui/material/Typography'
import { useMemo, type ReactNode } from 'react'
import { AGENTS_QUERY } from '../../api/agents'
import {
  AGENTS_TABLE_CONFIG,
  AGENTS_TABLE_CONFIG_INT,
} from '../../common/tableConfig/agents'
import { useEntityDataUnion } from '../../contexts/entity'
import { getActiveBaseSubstancesInt } from '../../helpers/product-international.helper'
import { getRootAgentIdentifiers } from '../../helpers/product.helper'
import { useAppSelector } from '../../hooks/useStoreHooks'
import { IntProductsDataTable } from '../DataTable/IntProductsDataTable'
import { Html } from '../Html/Html'
import { Box } from '../Layout/Box'
import { ProductsDataSourceTable } from '../PaginatedDataTable/ProductsDataSourceTable'
import { Section } from '../Section/Section'
import {
  ProductSeparatedAgentList,
  SeparatedAgentList,
} from '../Utility/SeparatedActiveIngredientList'

type ProductsTableProps = {
  name: string
  match: FilterMatchTypesEnum
  heading: (agents: number) => string
}

export function ProductsTable({ name, match, heading }: ProductsTableProps) {
  const entity = useEntityDataUnion()
  const mode = useAppSelector((state) => state.user.mode)

  const tableConf =
    mode === 'national' ? AGENTS_TABLE_CONFIG : AGENTS_TABLE_CONFIG_INT

  const [filter, agents, description] = useMemo<
    [ProductsFilter, number, ReactNode]
  >(() => {
    let description: ReactNode
    let agentIds: Set<string> | null = null

    if (isProduct(entity)) {
      agentIds = new Set(getRootAgentIdentifiers(entity))
      description = (
        <ProductSeparatedAgentList product={entity} separator=" + " />
      )
    }

    if (isProductInternational(entity)) {
      const agents = getActiveBaseSubstancesInt(entity)
      // We take the oeavStoffId, which is the id of a KHIX substance.
      // If no oeavStoffId exists (so no KHIX substance), we use the
      // normal id, which is the id of an international substance.
      agentIds = new Set(agents.map((a) => a.oeavStoffId ?? a.id))
      description = <SeparatedAgentList agents={agents} />
    }

    if (isSubstance(entity) || isSubstanceInternational(entity)) {
      agentIds = new Set([entity.id])
      description = <Html content={entity.bezeichnung} />
    }

    const ids = agentIds ? [...agentIds] : []

    const filter = {
      agents: {
        value: ids,
        match: match,
      },
    }

    return [filter, ids.length, description]
  }, [entity, match])

  const h = heading(agents)

  return (
    <Section width="small" heading={h}>
      <Box mb={3}>
        <Typography variant="h5">{description}</Typography>
      </Box>
      {mode === 'international' ? (
        <IntProductsDataTable
          config={tableConf}
          filter={filter}
          heading={h}
          subheading={description}
        />
      ) : (
        <ProductsDataSourceTable
          query={AGENTS_QUERY}
          config={tableConf}
          filter={filter}
          name={name}
          heading={h}
          subheading={description}
        />
      )}
    </Section>
  )
}
