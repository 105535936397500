import {
  type InteractionData,
  type InteractionDescriptionData,
} from '@austria-codex/types'
import { interactionDescriptions } from '../features/components/Interactions/constants'

type Section = {
  title: string
  content: string
}

export function getSections(descriptions: InteractionDescriptionData[]) {
  const sections: Section[] = []

  interactionDescriptions.forEach(({ type, title }) => {
    const section = getDescriptionForType(descriptions, type)
    if (section) {
      sections.push({
        title,
        content: section.beschreibung,
      })
    }
  })

  return sections
}

export function getDescriptionForType(
  descriptions: InteractionDescriptionData[],
  type: number
): InteractionDescriptionData | undefined {
  return descriptions?.find((description) => description.typ === type)
}

export function getDescriptionTitleForType(
  descriptions: InteractionDescriptionData[],
  type: number
): string | undefined {
  return getDescriptionForType(descriptions, type)?.beschreibung
}

export function hasInteractionGroups(interaction: InteractionData) {
  return (
    interaction.gruppeLinks.produkte.length *
      interaction.gruppeRechts.produkte.length +
      interaction.gruppeLinks.produkte.length *
        interaction.gruppeRechts.stoffe.length +
      interaction.gruppeLinks.stoffe.length *
        interaction.gruppeRechts.produkte.length >
    0
  )
}
