export const Config = {
  app: {
    version: 51,
    userAgent: window && window.navigator ? window.navigator.userAgent : null,
  },
  api: {
    url: import.meta.env.VITE_API_URL ?? 'http://localhost:3030',
    endpoints: {
      graphql: '/api',
      api: '/api',
      login: '/api/auth/login',
      logout: '/api/auth/logout',
      document: '/api/document',
      documentExists: '/api/document/exists',
    },
  },
  debug: {
    redux: import.meta.env.MODE === 'development',
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENV,
  },
  upselling: {
    path:
      import.meta.env.VITE_UPSELLING_URL ??
      'https://meinapoverlag-portal-stage.dgns.wtf',
  },
  tracking: { gtag: import.meta.env.VITE_TRACKING_GTAG ?? null },
  telemetrie: {
    url:
      import.meta.env.VITE_TELEMETRIE_URL ?? 'http://localhost:8070/api/events',
  },
  userManager: {
    portal: {
      path:
        import.meta.env.VITE_USER_MANAGER_PORTAL_PATH ??
        'https://portal.dgns.wtf',
      endpoints: {
        login:
          import.meta.env.VITE_USER_MANAGER_PORTAL_PATH_ENDPOINT_LOGIN ??
          '/anmeldung?product=austria_codex&client=local',
        profile:
          import.meta.env.VITE_USER_MANAGER_PORTAL_PATH_ENDPOINT_PROFILE ??
          '/verwaltung/profil',
        products: '/verwaltung/produkte',
      },
    },
    api: {
      auth: {
        path:
          import.meta.env.VITE_USER_MANAGER_AUTH_PATH ??
          'https://portal.dgns.wtf/api/auth',
        endpoints: {
          logout:
            import.meta.env.VITE_USER_MANAGER_AUTH_ENDPOINT_LOGOUT ??
            '/auth/logout',
        },
      },
      backend: {
        path:
          import.meta.env.VITE_USER_MANAGER_BACKEND_PATH ??
          'https://portal.dgns.wtf/api/backend/graphql',
      },
      file: {
        path:
          import.meta.env.VITE_USER_MANAGER_FILE_PATH ??
          'https://portal.dgns.wtf/api/file',
        endpoints: {
          files:
            import.meta.env.VITE_USER_MANAGER_FILE_ENDPOINT_FILES ?? '/files',
        },
      },
    },
  },
}
