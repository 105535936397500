import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import NoMultiSelectIcon from '../../../assets/images/empty/ic-no-multi.svg?react'
import { EmptyMessage } from '../../../components/Empty/EmptyMessage'
import { Box } from '../../../components/Layout/Box'
import { List, ListItem } from '../../../components/Layout/List'
import { Text } from '../../../components/Layout/Typography'
import { Section } from '../../../components/Section/Section'
import { filteredMultiSelectSelector } from '../../../store/safety'

export const MultiSelectSection = () => {
  const multiSelect = useSelector(filteredMultiSelectSelector)
  const hasMultiSelect =
    multiSelect &&
    (multiSelect.agentBased?.length > 0 || multiSelect.atcCodeBased?.length > 0)

  return (
    <Section width="medium">
      {hasMultiSelect ? (
        <Box my={4}>
          <Box my={3}>
            {multiSelect.agentBased?.length > 0 &&
              multiSelect.agentBased.map(({ stoff, produkte }) => (
                <Fragment key={stoff.id}>
                  <Box my={3}>
                    <Text>
                      Folgende Arzneispezialitäten beinhalten den Wirkstoff{' '}
                      <Text fontWeight="bold">{stoff.bezeichnung}:</Text>
                    </Text>
                  </Box>
                  <List>
                    {produkte.map(({ id, bezeichnung }) => (
                      <ListItem key={id}>{bezeichnung}</ListItem>
                    ))}
                  </List>
                </Fragment>
              ))}
          </Box>
          <Box my={3}>
            {multiSelect.atcCodeBased?.length > 0 &&
              multiSelect.atcCodeBased.map(({ atcCode, produkte }) => (
                <Fragment key={atcCode.id}>
                  <Box my={3}>
                    <Text>
                      Folgende Arzneispezialitäten sind in der selben
                      pharmakotherapeutischen Gruppe{' '}
                      <Text fontWeight="bold">{atcCode.bezeichnung}:</Text>
                    </Text>
                  </Box>
                  <List>
                    {produkte.map(({ id, bezeichnung }) => (
                      <ListItem key={id}>{bezeichnung}</ListItem>
                    ))}
                  </List>
                </Fragment>
              ))}
          </Box>
        </Box>
      ) : (
        <EmptyMessage Icon={<NoMultiSelectIcon />}>
          Keine Mehrfachauswahl für diese Auswahl vorhanden.
        </EmptyMessage>
      )}
    </Section>
  )
}
