export type Maybe<T> = T | undefined | null

export enum ReimbursementBoxTypesEnum {
  G = 'G',
  Y = 'Y',
  R = 'R',
  N = 'N',
}

export type ReimbursementBoxTypes = keyof typeof ReimbursementBoxTypesEnum

export enum DispensaryTypesEnum {
  SG = 'SG',
  OTC = 'OTC',
  RP = 'RP',
}

export type DispensaryTypes = keyof typeof DispensaryTypesEnum

export enum RoutesOfAdministrationTypesEnum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export type RoutesOfAdministrationTypes =
  keyof typeof RoutesOfAdministrationTypesEnum

export enum CompositionTypesEnum {
  Beschreibung = 0,
  Hilfsstoff = 1,
  Wirkstoff = 2,
}

export enum EntityType {
  Unknown = 0,
  Product = 1,
  Substance = 2,
  Interaction = 4,
  Allergy = 8,
  Characteristic = 16,
  Article = 32,
  ProductInternational = 64,
  SubstanceInternational = 128,
  ProductVerlauf = 96,
}

export enum DatabaseIndex {
  Articles = 'articles',
  Characteristics = 'characteristics',
  Interactions = 'interactions',
  Products = 'products',
  Release = 'release',
  ReleaseExtended = 'release-extended',
  Substances = 'substances',
  ATCCodeHits = 'atc-code-hits',
}

// International

export enum RoutesOfAdministrationInt {
  Peroral = 'peroral',
}
export type RoutesOfAdministrationIntType =
  keyof typeof RoutesOfAdministrationInt

export enum CompositionIntType {
  ErklaerenderText = 0,
  Wirkstoff = 1,
  Hilfsstoff = 2,
}

export enum DispensaryInt {
  OTC = 0, // Nicht verschreibungspflichtig
  RG = 1, // verschreibungspflichtig
  SG = 3, // Betäubungsmittel
}

export enum OccurrenceInt {
  ImHandel = 1, // = lieferbar
  AußerVertrieb = 2,
  VorMarkteinführung = 4,
  NichtVerkersfähig = 5,
}

export enum AdviceIntType {
  Einnahme = 151,
  Dosierung = 150,
  SchwangerschaftStillzeit = 152,
  Warnhinweis = 153,
  Intoleranzhinweis = 154,
  Aufbewahrung = 155,
}

export enum AdviceIntSection {
  AnwendungUndDosierung = 'A', // Anwendung und Dosierung
  Hilfsstoff = 'H', // Hilfsstoffe
  Laktation = 'L', // Laktation
  Schwangerschaft = 'S', // Schwangerschaft
  Warnhinweis = 'W', // Allgemeiner Hinweis oder Warnhinhweis
}

export type TCountryMode = 'national' | 'international'
