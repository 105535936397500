import styled from '@emotion/styled'
import { Flex } from '../Layout/Box'
import Typography from '@mui/material/Typography'

export const IconCard = styled(Flex)`
  flex-direction: column;
`

export const IconCardTitle = styled(Typography)`
  margin-bottom: 8px;
`

IconCardTitle.defaultProps = {
  variant: 'h6',
}

export const IconCardContent = styled.div`
  min-height: 32px;
  display: flex;
  align-items: center;
`
