import Typography from '@mui/material/Typography'
import { Flag } from '../../../components/Icons/Flag'
import { Anchor } from '../../../components/Layout/Anchor'
import { Box, Flex } from '../../../components/Layout/Box'
import { Section } from '../../../components/Section/Section'
import { useProductIntData } from '../../../contexts/entity'
import { getCountryByIsoCode } from '../../../helpers/country.helper'

export function IntProviderSection() {
  const product = useProductIntData()
  const contact = product.kontakteInt?.anbieter

  return (
    contact && (
      <Section width="small" heading="Anbieter">
        <Box>
          <Typography variant="h6">
            {contact.langname ?? contact.kurzname}
          </Typography>
          <Flex width="100%" flexDirection={['column', 'row']}>
            <Flex alignItems="center" flex="1 1 50%">
              <Box>
                <div>{contact.strasse}</div>
                <div>
                  {contact.plz} {contact.ort}
                </div>
                <div>
                  <Box as="span" mr={2}>
                    {getCountryByIsoCode(contact.isoCode)}
                  </Box>
                  <Flag code={contact.isoCode} />
                </div>
              </Box>
            </Flex>
            <Flex flexDirection="column" flex="1 1 50%" justifyContent="end">
              {contact.telefon && (
                <div>
                  Tel.:{' '}
                  <Anchor href={`tel:${contact.telefon}`}>
                    {contact.telefon}
                  </Anchor>
                </div>
              )}
              {contact.telefax && (
                <div>
                  Fax:{' '}
                  <Anchor href={`tel:${contact.telefax}`}>
                    {contact.telefax}
                  </Anchor>
                </div>
              )}
            </Flex>
          </Flex>
        </Box>
      </Section>
    )
  )
}
