import {
  EntityDataUnion,
  RouteOfAdministrationData,
} from '@austria-codex/types'
import {
  isProduct,
  isProductInternational,
  isSubstance,
  isVeterinaryProduct,
  isVeterinarySubstance,
} from '@austria-codex/utilities'
import {
  SerializedError,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { fetchEntitiesWithRelatedDataApi } from '../api/api'
import { EntitiesWithRelatedDataResponse } from '../api/queries/entity'
import { rootRouteOfAdministrationFromCode } from '../utilities/routeOfAdministration'
import { fetchAtcCodeHitsData } from './atcCodeHits'
import { selectedRoutesOfAdministrationFilterSelector } from './filter'
import { RootState } from './index'
import { getAtcCodesFromEntities } from './utilities/atcCodes'

type State = {
  loading: {
    [identifier: string]: boolean
  }
  error: {
    [identifier: string]: SerializedError | null
  }
}

export const fetchEntitiesWithRelatedData = createAsyncThunk<
  EntitiesWithRelatedDataResponse,
  // prettier-ignore
  Pick<EntityDataUnion, 'id'>[],
  { state: RootState }
>('entities/fetch', async (entityPartials, { getState, dispatch }) => {
  const state = getState()

  const newEntityIdentifiers = entityPartials
    ? entityPartials.map((entityPartial) => entityPartial.id)
    : Object.keys(state.entities.error)

  const currentEntityIdentifiers = state.entities.ids
  const searched = state.search.hitAdded

  try {
    const entitiesResult = await fetchEntitiesWithRelatedDataApi(
      newEntityIdentifiers,
      [...newEntityIdentifiers, ...currentEntityIdentifiers.map(String)],
      searched
    )

    if (entitiesResult.error) {
      return Promise.reject(entitiesResult.error)
    }

    const entitiesResultData = entitiesResult.data

    // Get atcCodes from entities
    const atcCodes = getAtcCodesFromEntities(entitiesResultData.entities)
    dispatch(fetchAtcCodeHitsData(atcCodes))

    const finalResult: EntitiesWithRelatedDataResponse = {
      entities: [...entitiesResultData.entities],
      interaction: entitiesResultData.interaction,
      multiSelect: entitiesResultData.multiSelect,
    }

    return Promise.resolve(finalResult)
  } catch (err) {
    return Promise.reject(err)
  }
})

const entitiesAdapter = createEntityAdapter<EntityDataUnion>()

export const entitiesSlice = createSlice({
  name: 'entities',
  initialState: entitiesAdapter.getInitialState<State>({
    loading: {},
    error: {},
  }),
  reducers: {
    remove: entitiesAdapter.removeOne,
    removeAll: entitiesAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEntitiesWithRelatedData.pending, (state, { meta }) => {
        meta.arg.forEach((entity) => {
          state.loading[entity.id] = true
          state.error[entity.id] = null
        })
      })
      .addCase(fetchEntitiesWithRelatedData.fulfilled, (state, { payload }) => {
        payload.entities.forEach((entity) => {
          state.loading[entity.id] = false
          state.error[entity.id] = null
        })

        // This also updates the 'ids' array of the state.
        entitiesAdapter.upsertMany(state, payload.entities)

        // Ids of new added entities are placed at the end
        // of the array, we cut those ids from the end.
        const addedIdentifiers = state.ids.splice(
          state.ids.length - payload.entities.length
        )

        // We need the newly added entities at the beginning of the array
        // because in that order the entites will be rendered. So the
        // newly added entities should always be on top/beginning
        // of the list.
        state.ids = [...addedIdentifiers, ...state.ids]
      })
      .addCase(
        fetchEntitiesWithRelatedData.rejected,
        (state, { error, meta }) => {
          meta.arg.forEach((entity) => {
            state.loading[entity.id] = true
            state.error[entity.id] = error
          })
        }
      )
  },
})

export default entitiesSlice.reducer

export const { remove: removeEntity, removeAll: removeAllEntities } =
  entitiesSlice.actions

export const {
  selectAll: entitiesSelector,
  selectIds: entityIdentifiersSelector,
} = entitiesAdapter.getSelectors<RootState>((state) => state.entities)

const entitiesLoadingSelector = (state: RootState) => state.entities.loading
const entitiesErrorSelector = (state: RootState) => state.entities.error

const entityLoadingStateEntityIdentifierProp = (
  _state: RootState,
  entityIdentifier: string
) => entityIdentifier

export const createEntityLoadingStateSelector = () =>
  createSelector(
    [
      entitiesLoadingSelector,
      entitiesErrorSelector,
      entityLoadingStateEntityIdentifierProp,
    ],
    (loading, error, entityIdentifier) => {
      return {
        loading: loading[entityIdentifier],
        error: error[entityIdentifier],
      }
    }
  )

export const filteredEntitiesSelector = createSelector(
  [
    entitiesSelector,
    selectedRoutesOfAdministrationFilterSelector,
    (state: RootState) => state.user.mode,
    (state: RootState) => state.user.selectedCountries,
    (state: RootState) => state.settings.includeVeterinary,
  ],
  (
    entities,
    selectedRoutesOfAdministration,
    mode,
    selectedCountries,
    includeVeterinary
  ) => {
    // No need to filter entities if no roa is selected
    // and veterinary products should be shown.
    if (selectedRoutesOfAdministration.length === 0 && includeVeterinary) {
      return entities
    }

    return entities.filter((entity) => {
      // Do not show entity if it is a veterinary product or substance
      // and settings is to not show veterinary entities.
      if (
        !includeVeterinary &&
        (isVeterinaryProduct(entity) || isVeterinarySubstance(entity))
      ) {
        return false
      }

      if (selectedRoutesOfAdministration.length === 0) {
        return true
      }

      let routesOfAdministration:
        | RouteOfAdministrationData[]
        | undefined
        | null = null

      if (isProduct(entity)) {
        routesOfAdministration = entity.klassifikation?.applikationsarten?.map(
          (roa) => roa.applikationsart
        )
      }

      if (isProductInternational(entity)) {
        routesOfAdministration =
          entity.klassifikationInt?.applikationsarten?.map((roa) => {
            return { id: roa.appAnwGrpCode } as RouteOfAdministrationData
          })
      }

      if (isSubstance(entity)) {
        const countries = mode === 'national' ? ['at'] : selectedCountries

        // Super hacky, we need to get the roa out of the anwendung because
        // we also need to take into account the selected countries in
        // international mode.
        const roas = entity.treffer?.anwendung
          ?.filter((a) => countries.includes(a.isoCode))
          .flatMap((a) => {
            const appArten: string[] = []
            if (a.mono?.human) {
              for (const [roa, count] of Object.entries(a.mono.human)) {
                if (roa !== '__typename' && count !== null) {
                  appArten.push(roa)
                }
              }
            }

            if (a.mono?.veterinaer) {
              for (const [roa, count] of Object.entries(a.mono.veterinaer)) {
                if (roa !== '__typename' && count !== null) {
                  appArten.push(roa)
                }
              }
            }

            if (a.kombination?.human) {
              for (const [roa, count] of Object.entries(a.kombination.human)) {
                if (roa !== '__typename' && count !== null) {
                  appArten.push(roa)
                }
              }
            }

            if (a.kombination?.veterinaer) {
              for (const [roa, count] of Object.entries(
                a.kombination.veterinaer
              )) {
                if (roa !== '__typename' && count !== null) {
                  appArten.push(roa)
                }
              }
            }

            return appArten
          })

        // Make em unique
        routesOfAdministration = [...new Set(roas)].map((roa) => {
          return { id: roa } as RouteOfAdministrationData
        })
      }

      return routesOfAdministration?.some((roa) => {
        const code = rootRouteOfAdministrationFromCode(roa.id)
        return selectedRoutesOfAdministration.includes(code)
      })
    })
  }
)

export const filteredEntityIdentifiersSelector = createSelector(
  filteredEntitiesSelector,
  (filteredEntitiesSelector) =>
    filteredEntitiesSelector.map((filteredEntity) => filteredEntity.id)
)
