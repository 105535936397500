import { setCollapse } from '../store/collapse'
import { useAppDispatch, useAppSelector } from './useStoreHooks'

export interface CollapseConfiguration {
  section: string
  group?: string
  identifier?: string
  defaultValue?: boolean
}

export const useCollapse = (
  config: CollapseConfiguration
): [boolean, (open?: boolean) => void] => {
  const dispatch = useAppDispatch()
  const collapseState = useAppSelector((state) => state.collapse)

  const {
    section,
    group = section,
    identifier = section,
    defaultValue = false,
  } = config

  const collapse =
    collapseState[section] === undefined
      ? defaultValue
      : collapseState[section][group] === undefined
        ? defaultValue
        : collapseState[section][group] === identifier

  const toggle = (collapse?: boolean) =>
    dispatch(setCollapse({ group, identifier, section, collapse }))

  return [collapse, toggle]
}
