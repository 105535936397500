import {
  DispensaryTypesEnum,
  FilterMatchTypesEnum,
  FilterModeTypesEnum,
  ProductsFilter,
  ReimbursementBoxTypesEnum,
  SubstanceRetailDeDispensaryHitsData,
  SubstanceRetailDePreparationHitsData,
  SubstanceRetailDispensaryHitsData,
  SubstanceRetailPreparationHitsData,
  SubstanceRetailReimbursementBoxHitsData,
} from '@austria-codex/types'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { useState } from 'react'
import { dispensaries, reimbursementBoxes } from '../../../common/constants'
import { AGENTS_TABLE_CONFIG_INT } from '../../../common/tableConfig/agents'
import { IntProductsDataTable } from '../../../components/DataTable/IntProductsDataTable'
import { ProductIcon } from '../../../components/EntityIcon/ProductIcon'
import { FilterHitsCount } from '../../../components/Filter/FilterHitsCount'
import { ReimbursementBoxIcon } from '../../../components/Icons/ReimbursementBoxIcon'
import { Flex } from '../../../components/Layout/Box'
import { Text } from '../../../components/Layout/Typography'
import { useSubstanceData } from '../../../contexts/entity'
import { useCollapse } from '../../../hooks/useCollapse'
import { useAppSelector } from '../../../hooks/useStoreHooks'
import { filterSelector } from '../../../store/filter'
import {
  isMonoPreparationToKey,
  isVeterinaryToKey,
} from '../../../utilities/entity'
import { ArticlesTable } from './ArticlesTable'
import { CountButton, CountButtonProps } from './CountButton'

type ACHMatrixIntProps = {
  preparation: SubstanceRetailPreparationHitsData | undefined
  preparationDe: SubstanceRetailDePreparationHitsData | undefined
  group: string
}

export function ArticleCompositionHitsMatrixInt({
  preparation,
  preparationDe,
  group,
}: ACHMatrixIntProps) {
  const theme = useTheme()
  const includesVeterinary = useAppSelector(
    (state) => state.settings.includeVeterinary
  )

  const monoHuman = preparation?.mono?.human
  const monoHumanDe = preparationDe?.mono?.human
  const kombiHuman = preparation?.kombination?.human
  const kombiHumanDe = preparationDe?.kombination?.human
  const monoVet = preparation?.mono?.veterinaer
  const monoVetDe = preparationDe?.mono?.veterinaer
  const kombiVet = preparation?.kombination?.veterinaer
  const kombiVetDe = preparationDe?.kombination?.veterinaer

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: 'flex', marginLeft: '128px' }}>
        {reimbursementBoxes.map((reimbursementBox) => (
          <Box
            sx={{ display: 'flex', justifyContent: 'center', width: '20%' }}
            key={reimbursementBox}
          >
            <ReimbursementBoxIcon type={reimbursementBox} />
          </Box>
        ))}
        <Box
          sx={{
            borderLeft: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 700,
            width: '20%',
          }}
        >
          DEU
        </Box>
      </Box>
      <Box>
        {(monoHuman || monoHumanDe) && (
          <ArticleDispensaryHitsGroup
            isMonoPreparation={true}
            isVeterinary={false}
            group={group}
            dispensary={monoHuman}
            dispensaryDe={monoHumanDe}
          />
        )}
        {(kombiHuman || kombiHumanDe) && (
          <ArticleDispensaryHitsGroup
            isMonoPreparation={false}
            isVeterinary={false}
            group={group}
            dispensary={kombiHuman}
            dispensaryDe={kombiHumanDe}
          />
        )}
        {includesVeterinary && (
          <>
            {(monoVet || monoVetDe) && (
              <ArticleDispensaryHitsGroup
                isMonoPreparation={true}
                isVeterinary={true}
                group={group}
                dispensary={monoVet}
                dispensaryDe={monoVetDe}
              />
            )}
            {(kombiVet || kombiVetDe) && (
              <ArticleDispensaryHitsGroup
                isMonoPreparation={false}
                isVeterinary={true}
                group={group}
                dispensary={kombiVet}
                dispensaryDe={kombiVetDe}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

type ArticleDispensaryHitsRowProps = {
  dispensary: SubstanceRetailDispensaryHitsData | undefined
  dispensaryDe: SubstanceRetailDeDispensaryHitsData | undefined
  group: string
  isVeterinary: boolean
  isMonoPreparation: boolean
}

export function ArticleDispensaryHitsGroup({
  dispensary,
  dispensaryDe,
  group,
  isMonoPreparation,
  isVeterinary,
}: ArticleDispensaryHitsRowProps) {
  return (
    <Group display={['block', 'flex']}>
      <Flex width={[1, 64]} flexShrink={0} my={[3, 0]} alignItems="center">
        <Text color="primary.main">
          <ProductIcon
            isVeterinary={isVeterinary}
            isMonoPreparation={isMonoPreparation}
          />
        </Text>
      </Flex>
      <Box sx={{ flexGrow: 1 }}>
        {dispensaries.map((disType) => {
          if (dispensary?.[disType] || dispensaryDe?.[disType]) {
            return (
              <ArticleReimbursementBoxHitsRow
                key={disType}
                dispensary={disType}
                reimbursementBox={dispensary?.[disType]}
                dispensaryHitsDataDe={dispensaryDe?.[disType]}
                group={group}
                isVeterinary={isVeterinary}
                isMonoPreparation={isMonoPreparation}
              />
            )
          }

          return null
        })}
      </Box>
    </Group>
  )
}

type ArticleReimbursementBoxHitsRowProps = {
  reimbursementBox: SubstanceRetailReimbursementBoxHitsData | undefined
  dispensaryHitsDataDe: number[][] | undefined
  group: string
  dispensary: DispensaryTypesEnum
  isVeterinary: boolean
  isMonoPreparation: boolean
}

export function ArticleReimbursementBoxHitsRow({
  reimbursementBox,
  dispensaryHitsDataDe,
  group,
  dispensary,
  isMonoPreparation,
  isVeterinary,
}: ArticleReimbursementBoxHitsRowProps) {
  const theme = useTheme()
  const substance = useSubstanceData()
  const { selectedRoutesOfAdministration } = useAppSelector(filterSelector)
  const [selectedBtn, setSelectedBtn] = useState<string | null>(null)

  const vetKey = isVeterinaryToKey(isVeterinary)
  const monKey = isMonoPreparationToKey(isMonoPreparation)
  const identifier = `int:${vetKey}:${monKey}:${dispensary}:${reimbursementBox}`

  const [collapse, onToggle] = useCollapse({
    section: substance.id,
    group,
    identifier,
    defaultValue: false,
  })

  function handleClick(btn: string) {
    onToggle(!collapse || selectedBtn !== btn)
    setSelectedBtn(btn)
  }

  // Filter for DE products table
  const filter: ProductsFilter = {
    agents: {
      value: [substance.id],
      match: isMonoPreparation
        ? FilterMatchTypesEnum.EXACT
        : FilterMatchTypesEnum.SUBSET,
    },
    dispensary,
    isAvailable: group.endsWith('hits:available'),
    isVeterinary,
    isMonoPreparation,
    routesOfAdministration: {
      value: selectedRoutesOfAdministration,
      mode: FilterModeTypesEnum.ROOT,
    },
    isoCodes: ['de'],
  }

  return (
    <Box my={3}>
      <Box sx={{ display: 'flex', flexGrow: 1, flexShrink: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '64px' }}>
          {dispensary}
        </Box>
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          {reimbursementBoxes.map((type) => (
            <Box
              sx={{
                display: 'flex',
                width: '20%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              key={type}
            >
              {reimbursementBox?.[type] ? (
                <HitsButton
                  active={collapse && selectedBtn === type}
                  onClick={() => handleClick(type)}
                  hits={reimbursementBox[type] as number[][]}
                  clickable={true}
                />
              ) : (
                '-'
              )}
            </Box>
          ))}
          <Box
            sx={{
              borderLeft: `1px solid ${theme.palette.divider}`,
              display: 'flex',
              width: '20%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {dispensaryHitsDataDe ? (
              <HitsButton
                active={collapse && selectedBtn === 'deu'}
                onClick={() => handleClick('deu')}
                hits={dispensaryHitsDataDe}
                clickable={true}
              />
            ) : (
              '-'
            )}
          </Box>
        </Box>
      </Box>
      {collapse && (
        <Box sx={{ mt: 1 }}>
          {selectedBtn !== 'deu' ? (
            <ArticlesTable
              dispensary={dispensary}
              isVeterinary={isVeterinary}
              isMonoPreparation={isMonoPreparation}
              reimbursementBox={selectedBtn as ReimbursementBoxTypesEnum}
              isAvailable={group.endsWith('hits:available')}
              onClose={onToggle}
            />
          ) : (
            <IntProductsDataTable
              heading={substance.bezeichnung}
              subheading="DEU"
              config={AGENTS_TABLE_CONFIG_INT}
              filter={filter}
              name={identifier}
              onClose={onToggle}
            />
          )}
        </Box>
      )}
    </Box>
  )
}

type HitsButtonProps = CountButtonProps & {
  hits: number[][]
  onClick: () => void
}

function HitsButton({ hits, active, clickable, onClick }: HitsButtonProps) {
  if (hits === null) {
    return <span>0</span>
  }

  return (
    <CountButton active={active} clickable={clickable} onClick={onClick}>
      <FilterHitsCount hits={hits} />
    </CountButton>
  )
}

const Group = styled(Box)`
  & + & {
    border-top: 1px solid ${(props) => props.theme.palette.divider};
  }
`
