import {
  FilterMatchTypesEnum,
  FilterModeTypesEnum,
  RoutesOfAdministrationTypesEnum,
  type ProductsFilter,
} from '@austria-codex/types'
import { Box } from '@mui/material'
import { useState } from 'react'
import { AGENTS_TABLE_CONFIG_INT } from '../../../common/tableConfig/agents'
import { IntProductsDataTable } from '../../../components/DataTable/IntProductsDataTable'
import { RouteOfAdministrationIcon } from '../../../components/Icons/RouteOfAdministrationIcon'
import { Anchor } from '../../../components/Layout/Anchor'
import { useSubstanceData } from '../../../contexts/entity'
import type { TransformedROAHitsData } from '../../../types/common.types'

interface SubstanceApplicationRowProps {
  hits: TransformedROAHitsData
  title: string
  routeOfAdministration: RoutesOfAdministrationTypesEnum
  isVeterinary: boolean
  isMonoPreparation: boolean
  otherCountries: string[]
}

type Column = 'at' | 'de' | 'int'

export function SubstanceApplicationHitsRowInt({
  hits,
  title,
  routeOfAdministration,
  isVeterinary,
  isMonoPreparation,
  otherCountries,
}: SubstanceApplicationRowProps) {
  const substance = useSubstanceData()
  const [open, setOpen] = useState(false)
  const [selectedColumn, setSelectedColumn] = useState<Column>('at')

  function handleClick(column: Column) {
    setSelectedColumn(column)

    if (column === selectedColumn && open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  const filter: ProductsFilter = {
    agents: {
      value: [substance.id],
      match: isMonoPreparation
        ? FilterMatchTypesEnum.EXACT
        : FilterMatchTypesEnum.PARTIAL,
    },
    isVeterinary,
    routesOfAdministration: {
      value: [routeOfAdministration],
      mode: FilterModeTypesEnum.ROOT,
    },
    isMonoPreparation,
    isoCodes:
      selectedColumn === 'at'
        ? ['at']
        : selectedColumn === 'de'
          ? ['de']
          : otherCountries,
  }

  // const identifier = `int:${selectedColumn}:${isVeterinary}:${isMonoPreparation}:${routeOfAdministration}`

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box width={48}>
          <RouteOfAdministrationIcon
            routeOfAdministration={routeOfAdministration}
            color="primary"
          />
        </Box>
        <Box width={128} sx={{ color: 'primary.main' }}>
          {title}
        </Box>
        <Box width={96} sx={{ textAlign: 'center', fontWeight: 700 }}>
          {hits.at > 0 ? (
            <Anchor onClick={() => handleClick('at')}>{hits.at}</Anchor>
          ) : (
            '-'
          )}
        </Box>
        <Box width={96} sx={{ textAlign: 'center', fontWeight: 700 }}>
          {hits.de > 0 ? (
            <Anchor onClick={() => handleClick('de')}>{hits.de}</Anchor>
          ) : (
            '-'
          )}
        </Box>
        {otherCountries.length > 0 && (
          <Box width={96} sx={{ textAlign: 'center', fontWeight: 700 }}>
            {hits.int > 0 ? (
              <Anchor onClick={() => handleClick('int')}>{hits.int}</Anchor>
            ) : (
              '-'
            )}
          </Box>
        )}
      </Box>
      {open && (
        <Box mt={1} mb={2}>
          <IntProductsDataTable
            heading={substance.bezeichnung}
            subheading={title}
            config={AGENTS_TABLE_CONFIG_INT}
            filter={filter}
            onClose={() => setOpen(false)}
          />
        </Box>
      )}
    </div>
  )
}
