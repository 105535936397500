import { FeatureEnum, ProductData } from '@austria-codex/types'
import { useMemo } from 'react'
import { warningNotice } from '../../common/translations'
import { Html } from '../../components/Html/Html'
import { IconTextBlock } from '../../components/IconTextBlock/IconTextBlock'
import {
  ChildrenWarningIcon,
  LactationWarningIcon,
  PregnancyWarningIcon,
} from '../../components/Icons/WarningIcon'
import { Paragraph } from '../../components/Layout/Typography'
import { DocumentationSection } from '../../components/Section/DocumentationSection'
import { useProductData } from '../../contexts/entity'
import { useAppSelector } from '../../hooks/useStoreHooks'
import { createPersonalCharacteristicsInHighlightsSelector } from '../../store/personalCharacteristics'
import { PersonalCharacteristicsEnum } from '../../types/highlight'

export function PregnancySection({ features }: { features: [FeatureEnum] }) {
  const product = useProductData()

  const personalCharacteristicsInHighlightsSelector = useMemo(
    createPersonalCharacteristicsInHighlightsSelector,
    []
  )

  const highlight = useAppSelector((state) =>
    personalCharacteristicsInHighlightsSelector(
      state,
      product,
      pregnancyHighlightOption
    )
  )

  const hasIcons = features.includes(FeatureEnum.PregnancySymbols)

  const hinwKinder = product.warnHinweise?.hinwKinder
  const hinwSchwangerschaft = product.warnHinweise?.hinwSchwangerschaft
  const hinwStillzeit = product.warnHinweise?.hinwStillzeit

  const heading = product.istVeterinaer
    ? 'Trächtigkeit, Laktation oder Legeperiode'
    : 'Schwangerschaft und Stillzeit'

  return hasPregnancyInformation(product) ? (
    <DocumentationSection
      inherit={
        product.dokumentation?.schnellhilfe?.schwangerschaftUndStillzeit
          ?.vererbt
      }
      width="small"
      heading={heading}
      highlight={highlight}
    >
      {hasIcons && (
        <>
          {hinwKinder !== undefined && hinwKinder > 0 && (
            <IconTextBlock Icon={<ChildrenWarningIcon level={hinwKinder} />}>
              {warningNotice('children', hinwKinder)}
            </IconTextBlock>
          )}
          {hinwSchwangerschaft !== undefined && hinwSchwangerschaft > 0 && (
            <IconTextBlock
              Icon={<PregnancyWarningIcon level={hinwSchwangerschaft} />}
            >
              {warningNotice('pregnancy', hinwSchwangerschaft)}
            </IconTextBlock>
          )}
          {hinwStillzeit !== undefined && hinwStillzeit > 0 && (
            <IconTextBlock
              Icon={<LactationWarningIcon level={hinwStillzeit} />}
            >
              {warningNotice('lactation', hinwStillzeit)}
            </IconTextBlock>
          )}
        </>
      )}
      {product.dokumentation?.schnellhilfe?.schwangerschaftUndStillzeit && (
        <Paragraph>
          <Html
            content={
              product.dokumentation?.schnellhilfe.schwangerschaftUndStillzeit
                .text
            }
          />
        </Paragraph>
      )}
    </DocumentationSection>
  ) : null
}

export const hasPregnancyInformation = (product: ProductData): boolean =>
  product.dokumentation?.schnellhilfe?.schwangerschaftUndStillzeit !== null ||
  (product.warnHinweise?.hinwKinder !== undefined &&
    product.warnHinweise?.hinwKinder > 0) ||
  product.warnHinweise?.hinwSchwangerschaft !== 0 ||
  product.warnHinweise?.hinwStillzeit !== 0

const pregnancyHighlightOption = [
  PersonalCharacteristicsEnum.CHILD,
  PersonalCharacteristicsEnum.PREGNANT,
  PersonalCharacteristicsEnum.BREAST_FEEDING,
]
