import { Anchor } from '../../components/Layout/Anchor'
import { Section } from '../../components/Section/Section'
import { UpSellingRibbon } from '../../components/UpSellingRibbon/UpSellingRibbon'
import { Config } from '../../config'

export function ProductApplicationTeaserSection() {
  return (
    <Section>
      <UpSellingRibbon>
        <>
          Mehr Informationen beim Auswählen und Suchfilter nach
          Applikationsarten, Kurztexte zur Anwendung entsprechend der
          Austria-Codex Schnellhilfe, eine interaktive Zusammensetzung mit Wirk-
          und Hilfsstoffen oder detaillierte Angaben zur Teilbarkeit und
          Sondengängigkeit finden Sie im Austria-Codex Online auf{' '}
          <Anchor href={Config.upselling.path} target="_blank">
            meinAPOVERLAG
          </Anchor>
          .
        </>
      </UpSellingRibbon>
    </Section>
  )
}
