import { Html } from '../../components/Html/Html'
import { DocumentationSection } from '../../components/Section/DocumentationSection'
import { useProductData } from '../../contexts/entity'

export function VeterinaryWaitingPeriodSection() {
  const product = useProductData()

  return product.dokumentation?.schnellhilfe?.veterinaereWartezeit ? (
    <DocumentationSection
      inherit={product.dokumentation.schnellhilfe.veterinaereWartezeit.vererbt}
      width="small"
      heading="Veterinär-Wartezeit"
    >
      <Html
        content={product.dokumentation.schnellhilfe.veterinaereWartezeit.text}
      />
    </DocumentationSection>
  ) : null
}
