import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { removeAllEntities, removeEntity } from './entities'

type SetCollapseActionPayload = {
  section: string
  group?: string
  identifier?: string
  collapse?: boolean
}

type State = {
  [uniqueIdentifier: string]: {
    [group: string]: string | null
  }
}

const initialState: State = {}

const collapseSlice = createSlice({
  name: 'collapse',
  initialState,
  reducers: {
    setCollapse: (
      state,
      { payload }: PayloadAction<SetCollapseActionPayload>
    ) => {
      const { section, group = section, identifier = group, collapse } = payload

      if (!state[section]) {
        state[section] = {}
      }

      state[section][group] =
        (collapse ?? identifier !== state[section][group]) ? identifier : null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(removeEntity, (state, { payload }) => {
      delete state[payload]
    })
    builder.addCase(removeAllEntities, () => initialState)
  },
})

export default collapseSlice.reducer

export const { setCollapse } = collapseSlice.actions
