import { Alternative, ProductData } from '@austria-codex/types'
import { Box } from '@mui/material'
import { BioaequivalenzScore } from '../../components/BioaequivalenzScore'
import { BioaequivalenzScoreInfo } from '../../components/BioaequivalenzScoreInfo'
import { DataTableConfig } from '../../components/DataTable/DataTable'
import { getBioaequivalenzScore } from '../../helpers/product-international.helper'
import {
  countryCell,
  descriptionCell,
  mainAgentsCell,
  productIconCell,
} from './common'

export const PRODUCTS_GENERICS_TABLE_CONFIG: DataTableConfig<
  ProductData,
  { alternatives: Alternative[]; showProviderIds?: string[] }
> = {
  columns: [
    productIconCell,
    descriptionCell,
    mainAgentsCell,
    {
      title: (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box component="span" mr={1}>
            Datenlage
          </Box>
          <BioaequivalenzScoreInfo />
        </Box>
      ),
      key: 'bio-score',
      render: (_data, product, { alternatives }) => {
        const bioScore = getBioaequivalenzScore(alternatives, product.id)
        return bioScore ? <BioaequivalenzScore score={bioScore} /> : null
      },
      width: 200,
    },
    countryCell,
  ],
}
