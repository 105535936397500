import React from 'react'
import { RenderElement } from './RenderElement'
import type { RenderElementType } from './types'

type RichTextRendererProps = {
  content: RenderElementType[]
}

export function RichTextRenderer({ content }: RichTextRendererProps) {
  return (
    <>
      {content.map((element) => (
        <RenderElement key={element._id} element={element} />
      ))}
    </>
  )
}
