import { gql } from '@apollo/client/core'
import { SearchFilter } from '@austria-codex/types'

export type AtcCodeHitsVariables = {
  atcCodes: string[]
  filter?: SearchFilter
}

export type AtcCodeHitsData = {
  atcCode: string
  hits: number
}

export type AtcCodeHitsResponse = {
  atcCodeHits: AtcCodeHitsData[]
}

export const ATC_CODE_HITS_QUERY = gql`
  query AtcCodeHits($atcCodes: [String!]!, $filter: SearchFilterInputType!) {
    atcCodeHits(atcCodes: $atcCodes, filter: $filter) {
      atcCode
      hits
    }
  }
`
