import {
  isProduct,
  isSubstance,
  isVeterinaryProduct,
} from '@austria-codex/utilities'
import { createSelector } from '@reduxjs/toolkit'
import { AppDispatch } from '.'
import {
  characteristicIdentifiersSelector,
  characteristicsSelector,
  resetCharacteristics,
} from './characteristics'
import { filteredEntitiesSelector } from './entities'
import {
  entitiesPersonalCharacteristicsSelector,
  personalCharacteristicsSelector,
  resetPersonalCharacteristics,
} from './personalCharacteristics'

export const hasHighlightSelected = createSelector(
  [personalCharacteristicsSelector, characteristicsSelector],
  (selectedPersonalCharacteristics, selectedCharacteristics) => {
    return (
      selectedPersonalCharacteristics.length > 0 ||
      selectedCharacteristics.length > 0
    )
  }
)

export const filteredEntitiesHaveHighlightSelector = createSelector(
  [
    filteredEntitiesSelector,
    personalCharacteristicsSelector,
    entitiesPersonalCharacteristicsSelector,
    characteristicIdentifiersSelector,
  ],
  (
    filteredEntities,
    selectedPersonalCharacteristics,
    entitiesPersonalCharacteristics,
    selectedCharacteristicIdentifiers
  ) =>
    filteredEntities.some((entity) => {
      // Personal characteristics only apply to "human" drugs
      if (isVeterinaryProduct(entity)) {
        return false
      }

      const first = entitiesPersonalCharacteristics[entity.id]?.some(
        (personalCharacteristic) =>
          selectedPersonalCharacteristics.includes(personalCharacteristic)
      )

      let second
      if (isProduct(entity) || isSubstance(entity)) {
        second = entity.allergien?.some((allergy) => {
          if (selectedCharacteristicIdentifiers.includes(allergy.id)) {
            return true
          }

          return allergy.kreuzallergien?.some((allergy) =>
            selectedCharacteristicIdentifiers.includes(allergy.id)
          )
        })
      }

      return first || second
    })
)

export const resetHighlight = () => {
  return (dispatch: AppDispatch) => {
    dispatch(resetPersonalCharacteristics())
    dispatch(resetCharacteristics())
  }
}
