export const PRODUCT_FRAGMENT = `
  fragment ProductFragment on ProductType {
    id
    bezeichnung
    isoCode
    suffix
    zulassungsnummer
    zulassungsnummerAlternativ
    istVeterinaer
    istVerlaufsTyp5
    ausVerlauf
    klassifikation {
      istErstattungsfaehig
      istMonopraeparat
      istHomoeopathSpez
      istTradPflanzSpez
      istRadioaktiveSpez
      generischeBezeichnung
      generischeKennung
      hatGenerischeBezeichnungsProdukte
      arzneiform {
        arzneiform {
          bezeichnung
        }
      }
      applikationsarten {
        applikationsart {
          anwGruppe
          id
          methode
          ort
          wegKurz
        }
      }
      atcCodes {
        atcCode {
          id
          bezeichnung
        }
        bezeichnung
      }
      indikationsgruppen {
        indikationsgruppe {
          id
          bezeichnung
        }
        bezeichnung
        treffer {
          human
          veterinaer
        }
      }
    }
    kontakte {
      zulassungsinhaber {
        ...ContactTypeFragment
      }
      vertriebsfirma {
        ...ContactTypeFragment
      }
      herstellerfirmen {
        ...ContactTypeFragment
      }
    }
    dokumentation {
      fachinformation
      gebrauchsinformation
      schnellhilfe {
        eigenschaftenUndWirksamkeit {
          vererbt
          text
        }
        dosierung {
          vererbt
          text
        }
        gegenanzeigen {
          vererbt
          text
        }
        anwendungsgebiete {
          vererbt
          text
        }
        schwangerschaftUndStillzeit {
          vererbt
          text
        }
        nebenwirkungen {
          vererbt
          text
        }
        wechselwirkungen {
          vererbt
          text
        }
        gewoehnungseffekte {
          vererbt
          text
        }
        warnhinweise {
          vererbt
          text
        }
        veterinaereWartezeit {
          vererbt
          text
        }
        artAnwendung {
          vererbt
          text
        }
      }
    }
    intoleranzen {
      glutenIntoleranz
      lactoseIntoleranz
      fructoseIntoleranz
      histaminIntoleranz
      galactoseIntoleranz
      saccharoseIntoleranz
      glucoseGalactoseMalabs
    }
    referenz {
      istBiosimilar
      biosimilarProdukt
      istParallelimport
      parallelimportProdukt
      hatReferenzprodukte
    }
    warnHinweise {
      vvAufloesen
      hinwAlkohol
      einnahmeMahlzeit
      einnahmeUnversehrt
      hinwKadersportler
      hinwStillzeit
      hinwDiabetiker
      hinwSonnenlicht
      hinwKadersportler
      hinwSchwangerschaft
      hinwVerkehrstuechtigkeit
      zusUeberwachung
      vvZubereiten
    }
    teilbarkeit {
      hinweisTeilbarkeit
      hinweisGleicheDosen
      hinweisErleichterteEinnahme
      hinweisOhneBruchrille
      maxTeilbarkeit
      gleicheDosen
      erleichterteEinnahme
      ohneBruchrille
    }
    zusammensetzung {
      bezug {
        einheit {
          bezeichnung
        }
        mengenAngabe
        alternativeBezuege {
          einheit {
            bezeichnung
          }
          mengenAngabe
        }
      }
      eintraege {
        ... on ProductCompositionSubstanceEntryType {
          eintragsTyp
          laufNummer
          bezLaufnummer
          stoff {
            stoff {
              id
              bezeichnung
            }
            basisStoff {
              id
              bezeichnung
            }
            minStaerke
            maxStaerke
            einheit {
              bezeichnung
            }
          }
          praefix
          suffix
        }
        ... on ProductCompositionDescriptionEntryType {
          eintragsTyp
          laufNummer
          bezLaufnummer
          bezeichnung
          praefix
          suffix
        }
      }
    }
    alternativeVerwendung {
      sondenGaengigkeit
      aufloesenSuspendieren
      kapselOeffnen
      zerMoersern
      cmrPotential
      lichtEmpfindlichkeit
      hinweisSondenGaengigkeit
      hinweisAufloesenSuspendieren
      hinweisOeffnen
      hinweisZerMoersern
      hinweisCmrPotential
      hinweisLichtempfindlichkeit
      hinweisEinnahme
    }
    artikel {
      pharmazentralNr
      istLieferbar
      istAktionsartikel
      bezeichnung
      referenzartikel {
        bezeichnungssuffix
      }
      packung {
        gebinde {
          einheit {
            bezeichnung
            beschreibung
          }
          mengenAngabe
        }
        fuellmenge {
          einheit {
            bezeichnung
            beschreibung
          }
          mengenAngabe
        }
      }
      preis {
        preisWaehrung
        steuerSatz
        preisFAP
        preisAEP
        preisUVP
        preisKVP
      }
      abgabe {
        ekoBox
        ekoMaxAbgabe
        ekoIndikationspflicht
        ekoBewilligungspflicht
        serialisierungVerfuegbar
        kennzeichen {
          bezeichnung
          beschreibung
          beschreibungSpezifisch
          kategorie
          synonym
        }
      }
    }
    lagerung {
      kennzeichen {
        bezeichnung
        beschreibung
        kategorie
      }
      haltbarkeit {
        dauerWert
        dauerEinheit {
          beschreibung
        }
      }
      lagerungsbedingung {
        tempMin
        tempMax
        tempEinheit {
          bezeichnung
        }
      }
    }
    abgabe {
      kennzeichen {
        bezeichnung
        beschreibung
        beschreibungKurz
        kategorie
        synonym
      }
    }
    allergien {
      ...AllergyTypeFragment
    }
  }
`
