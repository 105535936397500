import { gql } from '@apollo/client/core'
import { Query } from '@apollo/client/react/components'
import { memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Text } from '../Layout/Typography'

const QUERY = gql`
  query {
    version {
      date
    }
  }
`

export function ReleaseInfo() {
  return (
    <Query query={QUERY}>
      {({
        data,
        loading,
      }: {
        data: { version: { date: string } }
        loading: boolean
      }) =>
        loading ? (
          <Skeleton width={200} />
        ) : (
          <Text as="div" textAlign="right" color="gray">
            Austria-Codex Datenbestand{' '}
            <ReleaseDate date={data?.version?.date} />
          </Text>
        )
      }
    </Query>
  )
}

type ReleaseDateProps = {
  date?: string
}

const ReleaseDate = memo<ReleaseDateProps>(({ date }) => {
  if (!date) {
    return 'Unbekannt'
  }

  const parsedDate = new Date(date)
  const releaseMonth = parsedDate.getMonth() + 1
  const releaseYear = parsedDate.getFullYear()

  return `${releaseMonth}.${releaseYear}`
})
