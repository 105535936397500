import {
  FeatureEnum,
  HinweisTyp,
  Occurrence,
  ReimbursementBoxTypes,
  ProductCompositionSubstanceEntryData as SubstanceEntryData,
  type BundleInterface,
  type Maybe,
  type ProductAlternativeUsageData,
  type ProductData,
  type RouteOfAdministrationData,
} from '@austria-codex/types'
import { isCompositionAgent } from '@austria-codex/utilities'
import {
  alcoholWarningLevel,
  childrenWarningLevel,
  diabeticWarningLevel,
  lactationWarningLevel,
  pregnancyWarningLevel,
  roadworthyWarningLevel,
  sportWarningLevel,
  sunLightWarningLevel,
} from '../components/Icons/WarningIcon'
import { notNullOrUndefined } from '../utilities/array'

export function getAltUsageAdvice(
  altUsageData: ProductAlternativeUsageData,
  type: HinweisTyp
): string | undefined {
  return altUsageData.hinweise.find((hinweis) => hinweis.hinweisTyp === type)
    ?.hinweis
}

export function getEigenschaftenUndWirksamkeitText(
  product: ProductData
): Maybe<string> {
  return product.dokumentation?.schnellhilfe?.eigenschaftenUndWirksamkeit?.text
}

export function showShortInfo({
  anwGruppe,
  wegKurz,
}: RouteOfAdministrationData): boolean {
  if (!anwGruppe || !wegKurz) return false
  // We only want to show the "wegKurz" if the application group is one of these
  return ['parenteral', 'lokal', 'extrakorporal', 'urogenital'].includes(
    anwGruppe
  )
}

export function hasParenteralApplicationType(product: ProductData): boolean {
  if (!product.klassifikation?.applikationsarten) {
    return false
  }

  const applicationTypes = product.klassifikation.applikationsarten
    .map((a) => a.applikationsart.anwGruppe)
    .filter(notNullOrUndefined)

  return applicationTypes.includes('parenteral')
}

export function hasIntakeAdvisoryInformation(product: ProductData): boolean {
  return (
    product.warnHinweise?.einnahmeUnversehrt !== Occurrence.NotSpecified ||
    product.warnHinweise?.einnahmeMahlzeit !== 0 ||
    product.warnHinweise?.vvAufloesen !== 0 ||
    product.warnHinweise?.vvZubereiten !== 0
  )
}

export function hasDivisibilityInformation(product: ProductData): boolean {
  return product.teilbarkeit
    ? product.teilbarkeit.maxTeilbarkeit > 1 ||
        product.teilbarkeit.gleicheDosen !== 0 ||
        product.teilbarkeit.erleichterteEinnahme !== 0 ||
        product.teilbarkeit.hinweisTeilbarkeit !== null ||
        product.teilbarkeit.hinweisOhneBruchrille !== null
    : false
}

export function hasDocumentationInformation(product: ProductData): boolean {
  return (
    !!product.dokumentation?.gebrauchsinformation ||
    !!product.dokumentation?.fachinformation
  )
}

export function hasSideEffectsInformation(product: ProductData): boolean {
  return product.dokumentation?.schnellhilfe?.nebenwirkungen !== null
}

export function getBox(
  boxes: ReimbursementBoxTypes[] = []
): ReimbursementBoxTypes | null {
  return boxes.includes('G') ? 'G' : boxes.includes('Y') ? 'Y' : null
}

export function isInternational(product: ProductData) {
  return product.istInternational
}

/**
 * Transforms the name (Bezeichnung) of an AT product for the search
 * of international products. It cuts the text after the last '-'.
 */
export function getNameForIntSearch(name: string) {
  const index = name.lastIndexOf('-')

  if (index === -1) {
    return name
  }

  return name.slice(0, index).trim()
}

export function getSubstances(product: ProductData) {
  return (
    product.zusammensetzung?.eintraege
      ?.map((entry) => (entry as SubstanceEntryData).stoff?.basisStoff)
      .filter(Boolean) ?? []
  )
}

export function getActiveSubstances(product: ProductData) {
  return (
    product.zusammensetzung?.eintraege
      ?.filter(isCompositionAgent)
      .map((entry) => entry.stoff?.basisStoff)
      .filter(Boolean) ?? []
  )
}

export function agentIdentifiersFromProduct(product: ProductData) {
  return (
    product.zusammensetzung?.eintraege
      ?.filter(isCompositionAgent)
      .map((entry) => entry.stoff.stoff.id) ?? []
  )
}

export function getRootAgentIdentifiers(product: ProductData) {
  return (
    product.zusammensetzung?.eintraege
      ?.filter(isCompositionAgent)
      .map((entry) => entry.stoff.basisStoff.id) ?? []
  )
}

export function warningLevelsForProduct(
  product: ProductData,
  bundle: BundleInterface
) {
  const warningLevels = new Set<string>()

  const features = bundle.sequence.Safety.product

  if (features.find(([feature]) => feature === FeatureEnum.Pregnancy)) {
    warningLevels.add(
      lactationWarningLevel(product.warnHinweise?.hinwStillzeit ?? 0)
    )
    warningLevels.add(
      pregnancyWarningLevel(product.warnHinweise?.hinwSchwangerschaft ?? 0)
    )
    warningLevels.add(
      childrenWarningLevel(product.warnHinweise?.hinwKinder ?? 0)
    )
  }

  if (
    features.find(
      ([feature, functions]) =>
        feature === FeatureEnum.Advisory &&
        functions.includes(FeatureEnum.AdvisorySymbols)
    )
  ) {
    warningLevels.add(
      diabeticWarningLevel(product.warnHinweise?.hinwDiabetiker ?? 0)
    )
    warningLevels.add(
      roadworthyWarningLevel(
        product.warnHinweise?.hinwVerkehrstuechtigkeit ?? 0
      )
    )
    warningLevels.add(
      alcoholWarningLevel(product.warnHinweise?.hinwAlkohol ?? 0)
    )
    warningLevels.add(
      sunLightWarningLevel(product.warnHinweise?.hinwSonnenlicht ?? 0)
    )
    warningLevels.add(
      sportWarningLevel(product.warnHinweise?.hinwKadersportler ?? 0)
    )
  }

  return [...warningLevels]
}

export function formatReferenceArticleSuffix(
  suffix: string | undefined
): string | null {
  return suffix ? suffix.replace(/\(#(.*)\)/, '[$1]') : null
}

export function isType2Or3Product(
  ausVerlauf: number | undefined
): ausVerlauf is number {
  return ausVerlauf === 2 || ausVerlauf === 3
}
