import { Occurrence } from '@austria-codex/types'
import { getParagraph } from '../../common/paragraphs'
import { Paragraph } from '../../components/Layout/Typography'
import { Section } from '../../components/Section/Section'
import { useProductData } from '../../contexts/entity'
import {
  hasIntakeAdvisoryInformation,
  hasParenteralApplicationType,
} from '../../helpers/product.helper'

export function IntakeAdvisorySection() {
  const product = useProductData()

  const heading = hasParenteralApplicationType(product)
    ? 'Applikationshinweis'
    : 'Einnahmehinweis'

  const einnahmeMahlzeit = product.warnHinweise?.einnahmeMahlzeit
  const einnahmeUnversehrt = product.warnHinweise?.einnahmeUnversehrt
  const vvAufloesen = product.warnHinweise?.vvAufloesen
  const vvZubereiten = product.warnHinweise?.vvZubereiten

  return hasIntakeAdvisoryInformation(product) ? (
    <Section width="small" heading={heading}>
      {einnahmeMahlzeit !== undefined && einnahmeMahlzeit !== 0 && (
        <Paragraph>{getParagraph('intake', einnahmeMahlzeit)}</Paragraph>
      )}
      {einnahmeUnversehrt === Occurrence.Yes && (
        <Paragraph>
          Die Arzneispezialität darf nicht gekaut, gelutscht oder zerdrückt
          werden.
        </Paragraph>
      )}
      {vvAufloesen !== undefined && vvAufloesen !== 0 && (
        <Paragraph>{getParagraph('dissolving', vvAufloesen)}</Paragraph>
      )}
      {vvZubereiten !== undefined && vvZubereiten !== 0 && (
        <Paragraph>{getParagraph('preparation', vvZubereiten)}</Paragraph>
      )}
    </Section>
  ) : null
}
