import { QueryResult, useQuery } from '@apollo/client'
import {
  BaseDataResponse,
  FilterModeTypesEnum,
  SearchFilter,
} from '@austria-codex/types'
import { EntityId } from '@reduxjs/toolkit'
import { austriaCodexClient } from '../client/austriaCodex'
import { Config } from '../config'
import { isOnlyOneSubstance } from '../helpers/search.helper'
import { useAppSelector } from '../hooks/useStoreHooks'
import { FetchInteractionsActionPayload } from '../store/safety'
import { getAuthorizationHeader } from '../utilities/token/header'
import { BUNDLE_QUERY, BundleQueryResponse } from './bundle'
import { PROFILE_QUERY, ProfileQueryResponse } from './profile'
import {
  ATC_CODE_HITS_QUERY,
  AtcCodeHitsResponse,
  AtcCodeHitsVariables,
} from './queries/atcCodeHits'
import {
  ENTITIES_WITH_RELATED_DATA_QUERY,
  ENTITIES_WITH_RELATED_DATA_WITHOUT_INTERACTIONS_QUERY,
  EntitiesWithRelatedDataResponse,
  EntitiesWithRelatedDataVariables,
} from './queries/entity'
import {
  PRODUCT_VERLAUF_DIALOG_QUERY,
  ProductByIdVariable,
  ProductResponse,
  PRODUCTS_QUERY,
  ProductsByIdsVariables,
  ProductsResponse,
} from './queries/product'
import {
  FILTER_PRODUCTS_QUERY,
  FilterProductsResponse,
  FilterProductsVariables,
  PRODUCTS_INT_FULL_QUERY,
  PRODUCTS_INT_QUERY,
  ProductsIntResponse,
  ProductsIntVariables,
} from './queries/product-international'
import {
  SearchProductsResponse,
  SearchProductsVariables,
} from './queries/search'
import { INTERACTIONS_QUERY } from './safety'

const baseApiUrl = Config.api.url

export async function fetchBaseData(): Promise<BaseDataResponse> {
  const authHeader = getAuthorizationHeader()
  const response = await fetch(`${baseApiUrl}/api/base-data`, {
    headers: authHeader,
    credentials: 'include',
  })

  if (!response.ok) {
    return Promise.reject(response.statusText)
  }

  return response.json()
}

export async function fetchBundle() {
  const response = await austriaCodexClient.query<BundleQueryResponse>({
    query: BUNDLE_QUERY,
  })

  if (response.error) {
    return Promise.reject(response.error)
  }

  if (response.errors) {
    return Promise.reject(response.errors[0])
  }

  return response.data
}

export async function fetchProfile(): Promise<ProfileQueryResponse> {
  const response = await austriaCodexClient.query<ProfileQueryResponse>({
    query: PROFILE_QUERY,
  })

  if (response.error) {
    return Promise.reject(response.error)
  }

  if (response.errors) {
    return Promise.reject(response.errors[0])
  }

  return response.data
}

export async function fetchAtcCodeHits(
  atcCodes: string[],
  filter: SearchFilter
) {
  return austriaCodexClient.query<AtcCodeHitsResponse, AtcCodeHitsVariables>({
    query: ATC_CODE_HITS_QUERY,
    variables: {
      atcCodes,
      filter,
    },
  })
}

export async function fetchEntitiesWithRelatedDataApi(
  newEntityIdentifiers: string[],
  entityIdentifiers: string[],
  searched: boolean
) {
  let query = ENTITIES_WITH_RELATED_DATA_QUERY

  // We do not want to load all interactions if only one substance is selected.
  if (isOnlyOneSubstance([...newEntityIdentifiers, ...entityIdentifiers])) {
    query = ENTITIES_WITH_RELATED_DATA_WITHOUT_INTERACTIONS_QUERY
  }

  return austriaCodexClient.query<
    EntitiesWithRelatedDataResponse,
    EntitiesWithRelatedDataVariables
  >({
    query,
    variables: {
      newEntityIdentifiers,
      entityIdentifiers,
      searched,
    },
  })
}

export async function fetchInteractionsApi(identifiers: EntityId[]) {
  return austriaCodexClient.query<FetchInteractionsActionPayload>({
    query: INTERACTIONS_QUERY,
    variables: {
      entityIdentifiers: identifiers,
    },
  })
}

export async function fetchProductsInternational(ids: string[]) {
  if (ids.length === 0) return

  return austriaCodexClient.query<ProductsIntResponse, ProductsIntVariables>({
    query: PRODUCTS_INT_FULL_QUERY,
    variables: {
      ids,
    },
  })
}

export function useFetchProductsByIds(ids: string[]) {
  const query = useQuery<ProductsResponse, ProductsByIdsVariables>(
    PRODUCTS_QUERY,
    {
      client: austriaCodexClient,
      variables: {
        ids,
      },
      skip: ids.length === 0,
    }
  )
  if (ids.length === 0) {
    return {
      loading: false,
      data: undefined,
      error: undefined,
    } as QueryResult<ProductsResponse, ProductsByIdsVariables>
  }

  return query
}

export function useFetchProductVerlaufForDialog(id: string) {
  const query = useQuery<ProductResponse, ProductByIdVariable>(
    PRODUCT_VERLAUF_DIALOG_QUERY,
    {
      client: austriaCodexClient,
      variables: {
        id,
      },
      skip: id.length === 0,
    }
  )
  return query
}

export function useFetchProductsInternationalByIds(ids: string[]) {
  const query = useQuery<ProductsIntResponse, ProductsByIdsVariables>(
    PRODUCTS_INT_QUERY,
    {
      client: austriaCodexClient,
      variables: {
        ids,
      },
    }
  )
  if (ids.length === 0) {
    return { loading: false, data: undefined, error: undefined } as QueryResult
  }

  return query
}

export function useFilterProducts(variables: FilterProductsVariables) {
  // We basically always need to filter by selected
  // routes of adminstration and selected countries.
  const selectedRoutesOfAdministration = useAppSelector(
    (state) => state.filter.selectedRoutesOfAdministration
  )
  const mode = useAppSelector((state) => state.user.mode)
  const selectedCountries = useAppSelector(
    (state) => state.user.selectedCountries
  )

  // In 'national' mode we only want to search/filter AT products
  const isoCodes = mode === 'national' ? ['at'] : selectedCountries

  return useQuery<FilterProductsResponse, FilterProductsVariables>(
    FILTER_PRODUCTS_QUERY,
    {
      client: austriaCodexClient,
      variables: {
        ...variables,
        filter: {
          routesOfAdministration: {
            value: selectedRoutesOfAdministration,
            mode: FilterModeTypesEnum.ROOT,
          },
          isoCodes,
          // Overwrite filters with given filters
          ...variables.filter,
        },
      },
    }
  )
}

export function useSearchProducts(variables: SearchProductsVariables) {
  // We basically always need to filter by selected
  // routes of adminstration and selected countries.
  const selectedRoutesOfAdministration = useAppSelector(
    (state) => state.filter.selectedRoutesOfAdministration
  )
  const mode = useAppSelector((state) => state.user.mode)
  const selectedCountries = useAppSelector(
    (state) => state.user.selectedCountries
  )

  // In 'national' mode we only want to search/filter AT products
  const isoCodes = mode === 'national' ? ['at'] : selectedCountries

  return useQuery<SearchProductsResponse, SearchProductsVariables>(
    FILTER_PRODUCTS_QUERY,
    {
      client: austriaCodexClient,
      variables: {
        ...variables,
        filter: {
          routesOfAdministration: selectedRoutesOfAdministration,
          isoCodes,
          // Overwrite filters with given filters
          ...variables.filter,
        },
      },
    }
  )
}
