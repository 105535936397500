import type { EntityDataUnion } from '@austria-codex/types'
import ClearIcon from '@mui/icons-material/ClearRounded'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useAppDispatch, useAppSelector } from '../../hooks/useStoreHooks'
import {
  entitiesSelector,
  filteredEntitiesSelector,
  removeAllEntities,
} from '../../store/entities'
import { resetSafety } from '../../store/safety'
import { removeEntityAndUpdateRelatedData } from '../../store/thunk'
import { Flex } from '../Layout/Box'
import { EntityPill } from './EntityPill'

export function EntityPillsList() {
  const entities = useAppSelector(entitiesSelector)
  const filteredEntities = useAppSelector(filteredEntitiesSelector)
  const dispatch = useAppDispatch()

  function handleRemove(entity: EntityDataUnion) {
    dispatch(removeEntityAndUpdateRelatedData(entity.id))
  }

  function handleClear() {
    dispatch(removeAllEntities())
    dispatch(resetSafety())
  }

  return (
    <Flex px={[2, null, 0]}>
      <Flex flexDirection="row" flex="1" flexWrap="wrap">
        {entities.map((entity) => (
          <EntityPill
            key={entity.id}
            handleRemove={handleRemove}
            entity={entity}
            active={
              filteredEntities.find(
                (filteredEntity) => filteredEntity.id === entity.id
              ) !== undefined
            }
          />
        ))}
      </Flex>
      <Flex
        flex="0 0 auto"
        flexDirection="column"
        justifyContent="flex-start"
        my="-4px"
      >
        {entities.length > 0 && (
          <Tooltip title="Alle entfernen">
            <IconButton onClick={handleClear}>
              <ClearIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  )
}
