import { gql } from '@apollo/client/core'
import { BundleInterface } from '@austria-codex/types'
import { TContext } from '../components/BaseDataProvider/BaseDataProvider'

export type BundleQueryResponse = {
  bundle: BundleInterface
  settings: Record<string, boolean>
  context: TContext
}

export const BUNDLE_QUERY = gql`
  query Bundle {
    bundle
    settings
    context
  }
`
