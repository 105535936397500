import Grid from '@mui/material/Grid'
import { CharacteristicPill } from './CharacteristicPill'
import { CharacteristicData } from '@austria-codex/types'

type CharacteristicPillsListProps = {
  characteristics: CharacteristicData[]
  onRemove: (allergy: CharacteristicData) => void
}

export function CharacteristicPillsList({
  characteristics,
  onRemove,
}: CharacteristicPillsListProps) {
  return (
    <Grid container spacing={1}>
      {characteristics.map((allergy) => (
        <Grid item key={allergy.id}>
          <CharacteristicPill characteristic={allergy} onClick={onRemove} />
        </Grid>
      ))}
    </Grid>
  )
}
