import { CharacteristicData, EntityDataUnion } from '@austria-codex/types'
import {
  isProductInternational,
  isSubstanceInternational,
} from '@austria-codex/utilities'
import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { RootState } from '.'

const characteristicsAdapter = createEntityAdapter<CharacteristicData>()

export const characteristicsSlice = createSlice({
  name: 'characteristics',
  initialState: characteristicsAdapter.getInitialState(),
  reducers: {
    add: characteristicsAdapter.addOne,
    remove: characteristicsAdapter.removeOne,
    reset: characteristicsAdapter.removeAll,
  },
})

export default characteristicsSlice.reducer

export const {
  add: addCharacteristic,
  remove: removeCharacteristic,
  reset: resetCharacteristics,
} = characteristicsSlice.actions

export const {
  selectAll: characteristicsSelector,
  selectIds: characteristicIdentifiersSelector,
} = characteristicsAdapter.getSelectors<RootState>(
  (state) => state.highlight.characteristics
)

export const createAllergiesInHighlightsSelector = () =>
  createSelector(
    characteristicIdentifiersSelector,
    (_: unknown, entity: EntityDataUnion) => entity,
    (selectedAllergyIdentifiers, entity) => {
      if (isProductInternational(entity) || isSubstanceInternational(entity)) {
        return undefined
      }

      return entity.allergien?.some((allergy) => {
        if (selectedAllergyIdentifiers.includes(allergy.id)) {
          return true
        }

        return allergy.kreuzallergien?.some((allergy) =>
          selectedAllergyIdentifiers.includes(allergy.id)
        )
      })
    }
  )
