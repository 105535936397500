import { gql } from '@apollo/client/core'

export const CHARACTERISTICS_SEARCH_QUERY = gql`
  query search($query: String!) {
    characteristics(offset: 0, query: $query) {
      offset
      total
      limit
      hits {
        id
        bezeichnung
      }
    }
  }
`
