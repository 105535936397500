import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import EarthIcon from '../../../assets/images/earth.svg?react'
import { PRODUCTS_INT_BY_INT_PRODUCT_ID_TABLE_CONFIG } from '../../../common/tableConfig/products-international'
import { IntProductsDataTable } from '../../../components/DataTable/IntProductsDataTable'
import { Html } from '../../../components/Html/Html'
import { Section } from '../../../components/Section/Section'
import { useProductIntData } from '../../../contexts/entity'
import {
  getAllIntProductIds,
  getGenericGroupSubHeading,
} from '../../../helpers/product-international.helper'

function Heading() {
  return (
    <>
      <EarthIcon
        viewBox="0 0 24 24"
        style={{ height: '16px', width: '16px' }}
      />
      <Box component="span" sx={{ ml: 1 }}>
        Generische Gruppierung International
      </Box>
    </>
  )
}

export function IntGenericGroupIntSection() {
  const product = useProductIntData()

  // We need all INT products which are present in the
  // 'alternatives' sections of the INT product.
  const intIds = [product.id, ...getAllIntProductIds(product.alternativenInt)]
  const subHeading = getGenericGroupSubHeading(product)

  return (
    <Section heading={<Heading />} width="medium">
      <Typography variant="h5">
        <Html content={subHeading} />
      </Typography>
      <Box mt={3}>
        <IntProductsDataTable
          config={PRODUCTS_INT_BY_INT_PRODUCT_ID_TABLE_CONFIG}
          // We fake it here: if the INT product does not have
          // INT alternatives, we send a 'fake' id to get no data back.
          // Otherwise, we would have no filter, and all products
          // would be returned.
          filter={{ ids: intIds.length === 0 ? ['0'] : intIds }}
          heading={<Heading />}
          subheading={subHeading}
        />
      </Box>
    </Section>
  )
}
