import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Box } from '../Layout/Box'
import { Text } from '../Layout/Typography'
import type { DataTableConfig } from './DataTable'

interface TableEmptyMessageProps {
  config: DataTableConfig
}

export function TableEmptyRow({ config }: TableEmptyMessageProps) {
  return (
    <TableRow>
      <TableCell colSpan={config.columns.length}>
        <Box p={4}>
          <Text display="block" fontStyle="italic" textAlign="center">
            Keine Präparate vorhanden
          </Text>
        </Box>
      </TableCell>
    </TableRow>
  )
}
