import { ProductData } from '@austria-codex/types'
import { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Text } from '../../../components/Layout/Typography'
import { useAppSelector } from '../../../hooks/useStoreHooks'
import { createEntityLoadingStateSelector } from '../../../store/entities'

interface RetailMetaHeaderProps {
  product: ProductData
}

export function RetailMetaHeader({ product }: RetailMetaHeaderProps) {
  const entityLoadingStateSelector = useMemo(
    createEntityLoadingStateSelector,
    []
  )
  const { loading } = useAppSelector((state) =>
    entityLoadingStateSelector(state, product.id)
  )

  return (
    <Text variant="subHeading1" display="block">
      {loading ? (
        <Skeleton width={100} />
      ) : (
        `Zul.-Nr.: ${
          product.istVeterinaer && product.zulassungsnummer?.length === 6
            ? `${product.zulassungsnummer.slice(
                0,
                1
              )}-${product.zulassungsnummer.slice(1)}`
            : product.zulassungsnummer
        }`
      )}
    </Text>
  )
}
