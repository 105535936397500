export const isEmpty = (value: unknown[] | string) => value.length === 0

export const convertBitPositions = (value: number): number[] => {
  const result: number[] = []
  for (let i = 128; i > 0; i = i >> 1) {
    if (value >= i) {
      result.push(i)
      value -= i
    }
  }

  return result
}

export const isArrayEqual = <T>(a: T[], b: T[]) => {
  if (a.length !== b.length) return false

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false
  }

  return true
}

export function ucfirst(text: string) {
  if (text.length === 0) {
    return ''
  }

  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const addToArrayUnique = <T>(array: T[], values: T[]) => {
  values.forEach((value) => {
    if (!array.includes(value)) {
      array.push(value)
    }
  })
}
