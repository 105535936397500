import styled from '@emotion/styled'
import { AlignmentType, ListItemType } from '../types'
import { List } from './List'

type ListWrapperProps = {
  alignment?: AlignmentType
}

const ListWrapper = styled.ul<ListWrapperProps>`
  text-align: ${(props) => (props.alignment ? props.alignment : undefined)};
`

type UnorderedListProps = {
  children: ListItemType[]
  alignment?: AlignmentType
}

export function UnorderedList({ children, alignment }: UnorderedListProps) {
  return (
    <ListWrapper alignment={alignment}>
      <List children={children} />
    </ListWrapper>
  )
}
