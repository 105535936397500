import { EntityDataUnion } from '@austria-codex/types'
import {
  isProduct,
  isProductInternational,
  isSubstance,
} from '@austria-codex/utilities'

export function getAtcCodesFromEntities(entities: EntityDataUnion[]) {
  return entities
    .map((entity) => {
      const classification =
        isProduct(entity) || isSubstance(entity)
          ? entity.klassifikation
          : isProductInternational(entity)
            ? entity.klassifikationInt
            : entity.klassifikationSubstanceInt

      return (
        classification?.atcCodes
          ?.map((atcCodes) => atcCodes.map((atcCode) => atcCode.atcCode.id))
          .flat() ?? []
      )
    })
    .flat()
}
