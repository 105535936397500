import { ApolloError } from '@apollo/client'
import { BundleInterface, FeatureEnum } from '@austria-codex/types'
import * as Sentry from '@sentry/react'
import { GraphQLError } from 'graphql'
import { useEffect, useState, type ReactNode } from 'react'
import { fetchBundle, fetchProfile } from '../api/api'
import { loadSettings } from '../common/settings'
import { Config } from '../config'
import { BundleContext } from '../contexts/bundle'
import { ContextContext } from '../contexts/context'
import { useAppDispatch, useAppSelector } from '../hooks/useStoreHooks'
import { telemetrie } from '../services/telemetrie.service'
import { fetchEntitiesWithRelatedData } from '../store/entities'
import { setAllSettings } from '../store/settings.store'
import { setMode, setProfile } from '../store/user.store'
import { entityIdentifiersToEntityPartials } from '../utilities/entity'
import { getEntityIdentifiersFromSearchParameters } from '../utilities/url'
import { LoadingScreen } from './LoadingScreen/LoadingScreen'

type TProps = {
  children: ReactNode
}

const initialContext: TContext = { origin: null, sponsor: null }
export type TContext = { origin: string | null; sponsor: string | null }

export function BaseDataProvider({ children }: TProps) {
  const dispatch = useAppDispatch()
  const mode = useAppSelector((state) => state.user.mode)
  const [loading, setLoading] = useState(true)
  const [bundle, setBundle] = useState<BundleInterface | null>(null)
  const [context, setContext] = useState<TContext>(initialContext)

  useEffect(() => {
    Promise.all([fetchBundle(), fetchProfile(), loadSettings()])
      .then(([bundle, profile, settings]) => {
        dispatch(
          setAllSettings({
            ...bundle.settings,
            ...settings,
          })
        )
        dispatch(setProfile(profile))

        telemetrie.setUser(profile.profile.user, mode)
        telemetrie.start({
          appVersion: Config.app.version,
          userAgent: Config.app.userAgent,
        })

        // We need to check for international mode here, because if the previous user, who was logged in,
        // was an international user, and the current user is not, than the current user would still be
        // in international mode.
        if (!bundle.bundle.features.includes(FeatureEnum.International)) {
          dispatch(setMode('national'))
        }

        const entityIdentifiers = getEntityIdentifiersFromSearchParameters(
          window.location
        )
        if (entityIdentifiers) {
          const ids = entityIdentifiersToEntityPartials(entityIdentifiers)
          dispatch(fetchEntitiesWithRelatedData(ids))
        }

        setBundle(bundle.bundle)
        setContext(bundle.context)
        setLoading(false)
      })
      .catch((e: Error) => {
        // We handle those errors in the onErrorLink in 'src/client/austriaCodex.ts'
        if (e instanceof GraphQLError || e instanceof ApolloError) {
          return
        }

        Sentry.captureException(e)
      })
  }, [dispatch, mode])

  if (loading) {
    return <LoadingScreen />
  }

  return (
    <ContextContext.Provider value={context}>
      <BundleContext.Provider value={bundle}>{children}</BundleContext.Provider>
    </ContextContext.Provider>
  )
}
