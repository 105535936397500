import type { ProductData } from '@austria-codex/types'
import { Box } from '../../../components/Layout/Box'
import { Text } from '../../../components/Layout/Typography'
import { getEigenschaftenUndWirksamkeitText } from '../../../helpers/product.helper'
import { AgentsMetaHeader } from './AgentsMetaHeader'

type TProps = {
  product: ProductData
}

export function ProductMetaHeader({ product }: TProps) {
  const text = getEigenschaftenUndWirksamkeitText(product)

  return (
    <Box>
      <Text variant="subHeading2" as="div">
        {text ? (
          <span
            dangerouslySetInnerHTML={{ __html: text.replace('<br />', ' ') }}
          ></span>
        ) : null}
      </Text>
      <AgentsMetaHeader product={product} />
    </Box>
  )
}
