import {
  type RouteOfAdministrationData,
  type RoutesOfAdministrationTypes,
} from '@austria-codex/types'
import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import BiosimilarIcon from '../../assets/images/classifications/biosimilar.svg?react'
import HomeopathicIcon from '../../assets/images/classifications/homooe.svg?react'
import HerbalIcon from '../../assets/images/classifications/pflanzlich.svg?react'
import RadioactiveIcon from '../../assets/images/classifications/radioaktiv.svg?react'
import {
  IconCard,
  IconCardContent,
  IconCardTitle,
} from '../../components/IconCard/IconCard'
import { RouteOfAdministrationIcon } from '../../components/Icons/RouteOfAdministrationIcon'
import { Text } from '../../components/Layout/Typography'
import { Section } from '../../components/Section/Section'
import { useProductData } from '../../contexts/entity'
import { showShortInfo } from '../../helpers/product.helper'

export function ClassificationSection() {
  const product = useProductData()

  const appArten =
    product.klassifikation?.applikationsarten?.map((a) => a.applikationsart) ??
    []

  return (
    <Section width="small" heading="Klassifikation">
      <Grid container spacing={3}>
        {appArten.length !== 0 && (
          <Grid item md={6}>
            <IconCard>
              <IconCardTitle>Applikationsarten</IconCardTitle>
              <IconCardContent>
                <RoutesOfAdministrationContent
                  routesOfAdministration={appArten}
                />
              </IconCardContent>
            </IconCard>
          </Grid>
        )}
        <Grid item md={6}>
          <IconCard>
            <IconCardTitle>max. Teilbarkeit</IconCardTitle>
            <IconCardContent>
              {product.teilbarkeit?.maxTeilbarkeit &&
              product.teilbarkeit.maxTeilbarkeit > 1 ? (
                <>max. {product.teilbarkeit?.maxTeilbarkeit} Teile</>
              ) : (
                <>Nicht teilbar</>
              )}
            </IconCardContent>
          </IconCard>
        </Grid>
        {product.klassifikation?.istHomoeopathSpez && (
          <Grid item md={6}>
            <IconCard>
              <IconCardTitle>ist Homoeopathisch</IconCardTitle>
              <IconCardContent>
                <HomeopathicIcon />
              </IconCardContent>
            </IconCard>
          </Grid>
        )}
        {product.klassifikation?.istRadioaktiveSpez && (
          <Grid item md={6}>
            <IconCard>
              <IconCardTitle>ist Radioaktiv</IconCardTitle>
              <IconCardContent>
                <RadioactiveIcon />
              </IconCardContent>
            </IconCard>
          </Grid>
        )}
        {product.referenz?.istBiosimilar && (
          <Grid item md={6}>
            <IconCard>
              <IconCardTitle>Biosimilar</IconCardTitle>
              <IconCardContent>
                <BiosimilarIcon />
              </IconCardContent>
            </IconCard>
          </Grid>
        )}
        {product.klassifikation?.istTradPflanzSpez && (
          <Grid item md={6}>
            <IconCard>
              <IconCardTitle>Ist traditionell pflanzlich</IconCardTitle>
              <IconCardContent>
                <HerbalIcon />
              </IconCardContent>
            </IconCard>
          </Grid>
        )}
      </Grid>
    </Section>
  )
}

type RoutesOfAdministrationContentProps = {
  routesOfAdministration: RouteOfAdministrationData[]
}

function RoutesOfAdministrationContent({
  routesOfAdministration,
}: RoutesOfAdministrationContentProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mb: -2 }}>
      {routesOfAdministration.map((routeOfAdministration) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 1,
          }}
          key={routeOfAdministration.id}
          title={routeOfAdministration.ort}
        >
          <RouteOfAdministrationIcon
            routeOfAdministration={
              routeOfAdministration.id.charAt(0) as RoutesOfAdministrationTypes
            }
            color="primary"
          />
          <Box sx={{ display: 'flex', flexWrap: 'warp', ml: 2 }}>
            <Text mr={1}>{routeOfAdministration.methode}</Text>
            {showShortInfo(routeOfAdministration) && (
              <Text>({routeOfAdministration.wegKurz})</Text>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  )
}
