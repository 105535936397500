import { EntityDataUnion, ProductData } from '@austria-codex/types'
import { isProduct } from '@austria-codex/utilities'
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '.'
import { PersonalCharacteristicsEnum } from '../types/highlight'
import {
  fetchEntitiesWithRelatedData,
  removeAllEntities,
  removeEntity,
} from './entities'

type State = {
  selected: PersonalCharacteristicsEnum[]
  entities: Record<string, PersonalCharacteristicsEnum[]>
}

const initialState: State = {
  selected: [],
  entities: {},
}

const personalCharacteristicsSlice = createSlice({
  name: 'personalCharacteristics',
  initialState,
  reducers: {
    toggle: (
      state,
      { payload }: PayloadAction<PersonalCharacteristicsEnum>
    ) => {
      if (!state.selected.includes(payload)) {
        state.selected.push(payload)
      } else {
        state.selected = state.selected.filter(
          (personalCharacteristic) => personalCharacteristic !== payload
        )
      }
    },
    add: (state, { payload }: PayloadAction<PersonalCharacteristicsEnum>) => {
      if (!state.selected.includes(payload)) {
        state.selected.push(payload)
      }
    },
    remove: (
      state,
      { payload }: PayloadAction<PersonalCharacteristicsEnum>
    ) => {
      state.selected = state.selected.filter(
        (personalCharacteristic) => personalCharacteristic !== payload
      )
    },
    reset: (state) => {
      state.selected = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEntitiesWithRelatedData.fulfilled, (state, { payload }) => {
        payload.entities.forEach((entity) => {
          state.entities[entity.id] = isProduct(entity)
            ? personalCharacteristicsForEntity(entity)
            : []
        })
      })
      .addCase(removeEntity, (state, { payload }) => {
        delete state.entities[payload]
      })
      .addCase(removeAllEntities, () => initialState)
  },
})

function personalCharacteristicsForEntity(product: ProductData) {
  const highlights: PersonalCharacteristicsEnum[] = []

  if (product.warnHinweise?.hinwDiabetiker) {
    highlights.push(PersonalCharacteristicsEnum.DIABETES)
  }
  if (product.warnHinweise?.hinwAlkohol) {
    highlights.push(PersonalCharacteristicsEnum.ALCOHOL)
  }
  if (product.warnHinweise?.hinwAlkohol) {
    highlights.push(PersonalCharacteristicsEnum.SQUAD_SPORT)
  }
  if (product.intoleranzen?.histaminIntoleranz) {
    highlights.push(PersonalCharacteristicsEnum.HISTAMINE_INTOLERANCE)
  }
  if (product.intoleranzen?.galactoseIntoleranz) {
    highlights.push(PersonalCharacteristicsEnum.SUGAR_INTOLERANCE)
  }
  if (product.intoleranzen?.lactoseIntoleranz) {
    highlights.push(PersonalCharacteristicsEnum.SUGAR_INTOLERANCE)
  }
  if (product.intoleranzen?.saccharoseIntoleranz) {
    highlights.push(PersonalCharacteristicsEnum.SUGAR_INTOLERANCE)
  }
  if (product.intoleranzen?.glucoseGalactoseMalabs) {
    highlights.push(PersonalCharacteristicsEnum.SUGAR_INTOLERANCE)
  }
  if (product.intoleranzen?.fructoseIntoleranz) {
    highlights.push(PersonalCharacteristicsEnum.SUGAR_INTOLERANCE)
  }
  if (product.warnHinweise?.hinwKinder) {
    highlights.push(PersonalCharacteristicsEnum.CHILD)
  }
  if (product.warnHinweise?.hinwSchwangerschaft) {
    highlights.push(PersonalCharacteristicsEnum.PREGNANT)
  }
  if (product.warnHinweise?.hinwStillzeit) {
    highlights.push(PersonalCharacteristicsEnum.BREAST_FEEDING)
  }

  return highlights
}

export const personalCharacteristicsSelector = (state: RootState) =>
  state.highlight.personalCharacteristics.selected

export const entitiesPersonalCharacteristicsSelector = (state: RootState) =>
  state.highlight.personalCharacteristics.entities

export const {
  add: addPersonalCharacteristic,
  remove: removePersonalCharacteristic,
  toggle: togglePersonalCharacteristic,
  reset: resetPersonalCharacteristics,
} = personalCharacteristicsSlice.actions

export default personalCharacteristicsSlice.reducer

const personalCharacteristicsProductProp = (
  _: unknown,
  entity: EntityDataUnion,
  personalCharacteristicsFilter: PersonalCharacteristicsEnum[]
) => entity

const personalCharacteristicsFilterProp = (
  _: unknown,
  entity: EntityDataUnion,
  personalCharacteristicsFilter: PersonalCharacteristicsEnum[]
) => personalCharacteristicsFilter

export const createPersonalCharacteristicsInHighlightsSelector = () =>
  createSelector(
    [
      personalCharacteristicsSelector,
      entitiesPersonalCharacteristicsSelector,
      personalCharacteristicsProductProp,
      personalCharacteristicsFilterProp,
    ],
    (
      selectedPersonalCharacteristics,
      entitiesPersonalCharacteristics,
      entity,
      personalCharacteristicsFilter
    ) => {
      const entityPersonalCharacteristics =
        entitiesPersonalCharacteristics[entity.id]

      return personalCharacteristicsFilter.some(
        (personalCharacteristic) =>
          selectedPersonalCharacteristics.includes(personalCharacteristic) &&
          entityPersonalCharacteristics.includes(personalCharacteristic)
      )
    }
  )
