import { ArticleData, ReimbursementBoxTypesEnum } from '@austria-codex/types'

export function hasSpezifischeBeschreibung(article: ArticleData) {
  const kennzeichen = article.abgabe?.kennzeichen ?? []
  return kennzeichen.some((k) => Boolean(k.beschreibungSpezifisch))
}

export function isEkoBox(
  article: ArticleData,
  type: ReimbursementBoxTypesEnum
) {
  return article.abgabe?.ekoBox === type
}
