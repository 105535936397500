import { EntityDataUnion, EntityType } from '@austria-codex/types'
import {
  isProduct,
  isProductInternational,
  isSubstanceInternational,
  typeFromIdentifier,
} from '@austria-codex/utilities'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import RemoveIcon from '@mui/icons-material/CloseRounded'
import { Box } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { MouseEvent } from 'react'
import { formatReferenceArticleSuffix } from '../../helpers/product.helper'
import { smoothScrollTo } from '../../utilities/scroll'
import { EntityIcon } from '../EntityIcon/EntityIcon'
import { Html } from '../Html/Html'

type CompoundPillComponentProps = {
  active: boolean
  entity: EntityDataUnion
  handleRemove?: (entity: EntityDataUnion) => void
}

export function EntityPill({
  active,
  entity,
  handleRemove,
}: CompoundPillComponentProps) {
  const entityType = typeFromIdentifier(entity.id)

  let suffix: string | null = null
  if (isProduct(entity)) {
    suffix =
      entity.artikel
        ?.map((a) =>
          formatReferenceArticleSuffix(a.referenzartikel?.bezeichnungssuffix)
        )
        .join(' ') ?? null
  }

  return (
    <Pill type={entityType} active={active}>
      <Icon>
        <EntityIcon entity={entity} />
      </Icon>
      <EntityLink
        href="#"
        tabIndex={0}
        active={active}
        aria-disabled={!active}
        onClick={(event: MouseEvent) => {
          event.preventDefault()
          smoothScrollTo(`entity:${entity.id}`)
        }}
      >
        {/* International substances can have html tags in their names, like: <small>DL</small> ... */}
        {isSubstanceInternational(entity) ? (
          <Html content={entity.bezeichnung} />
        ) : isProductInternational(entity) ? (
          entity.acoSearchTerm
        ) : (
          entity.bezeichnung
        )}
        {suffix && (
          <Box component="span" ml={1}>
            {suffix}
          </Box>
        )}
      </EntityLink>
      <CloseButton onClick={() => handleRemove && handleRemove(entity)}>
        <Tooltip title="Entfernen">
          <RemoveIcon style={{ width: 16, height: 16 }} />
        </Tooltip>
      </CloseButton>
    </Pill>
  )
}

const Icon = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 24px;
  justify-content: center;
  margin-left: ${(props) => props.theme.space[2]}px;
  margin-right: ${(props) => props.theme.space[2]}px;
  width: 24px;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  border-radius: 50%;
  line-height: 0;
  margin: 0;
  padding: 8px;

  &:hover {
    cursor: pointer;
  }
`

type EntityLinkProps = {
  active: boolean
}

const EntityLink = styled.a<EntityLinkProps>`
  color: inherit;
  cursor: ${(props) => (props.active ? 'pointer' : 'default')};
  pointer-events: ${(props) => (props.active ? 'auto' : 'none')};
  text-decoration: none;
`

type PillProps = {
  type: EntityType
  active: boolean
}

const Pill = styled.div<PillProps>`
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-wrap: nowrap;
  flex: 0 1 auto;
  justify-content: space-between;
  margin: 0 ${(props) => props.theme.space[2]}px
    ${(props) => props.theme.space[2]}px 0;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  padding: 2px 0;
  transition: box-shadow 0.2s;

  ${(props) => {
    switch (props.type) {
      case EntityType.Product:
        return css`
          background: ${props.theme.palette.product.main};
          border: 1px solid ${props.theme.palette.product.dark};
        `

      case EntityType.ProductInternational:
        return css`
          background: ${props.theme.palette.product.main};
          border: 1px solid ${props.theme.palette.product.dark};
        `

      case EntityType.Substance:
        return css`
          background: ${props.theme.palette.substance.main};
          border: 1px solid ${props.theme.palette.substance.dark};
          color: ${props.theme.palette.common.white};
        `

      case EntityType.SubstanceInternational:
        return css`
          background: ${props.theme.palette.substance.main};
          border: 1px solid ${props.theme.palette.substance.dark};
          color: ${props.theme.palette.common.white};
        `
    }
  }}

  ${(props) => css`
    &:hover {
      box-shadow: 0 3px 6px
        ${props.type === EntityType.Product ||
        props.type === EntityType.ProductInternational
          ? props.theme.palette.product.dark
          : props.theme.palette.substance.dark};
    }
  `}
`
