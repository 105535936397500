import type { EntityDataUnion } from '@austria-codex/types'
import type { ReactElement } from 'react'
import NoEntitiesIcon from '../../assets/images/empty/ic-no-result.svg?react'
import { useAppSelector } from '../../hooks/useStoreHooks'
import {
  entitiesSelector,
  filteredEntitiesSelector,
} from '../../store/entities'
import { EmptyMessage } from '../Empty/EmptyMessage'
import { Flex } from '../Layout/Box'
import { Content } from '../Layout/Content'
import { LogoPlaceholder } from '../Placeholder/LogoPlaceholder'

type FilteredEntitiesProps = {
  children: (entities: EntityDataUnion[]) => ReactElement
}

export function FilteredEntities({ children }: FilteredEntitiesProps) {
  const filteredEntities = useAppSelector(filteredEntitiesSelector)
  const entities = useAppSelector(entitiesSelector)

  if (entities.length === 0) {
    return <LogoPlaceholder />
  }

  if (filteredEntities.length === 0) {
    return (
      <Content width="medium">
        <Flex height="300px" alignItems="center">
          <EmptyMessage Icon={<NoEntitiesIcon />}>
            Die derzeitige Auswahl an Applikationsarten enthält keine
            Ergebnisse.
          </EmptyMessage>
        </Flex>
      </Content>
    )
  }

  return children(filteredEntities)
}
