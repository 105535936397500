import type { Eintrag } from '@austria-codex/types'
import { useState } from 'react'
import { Box } from '../../../../components/Layout/Box'
import { TableCell } from '../../../../components/Table/TableCell'
import { TableRow } from '../../../../components/Table/TableRow'
import { useProductIntData } from '../../../../contexts/entity'
import { telemetrie } from '../../../../services/telemetrie.service'
import { IntCompositionProductsTable } from './IntCompositionProductsTable'
import { IntCompositionSubstanceRow } from './IntCompositionSubstanceRow'

type TProps = {
  entry: Eintrag
}

export function IntCompositionAgentRow({ entry }: TProps) {
  const product = useProductIntData()
  const [open, setOpen] = useState(false)

  const substanceId =
    entry.oeavBasisStoffId ??
    entry.oeavStoffId ??
    entry.basisStoff?.id ??
    entry.id

  function toggleOpen() {
    // If substance is closed, track click
    if (!open) {
      telemetrie.substanceClicked(
        product.id,
        product.bezeichnung,
        substanceId,
        entry.bezeichnung
      )
    }
    setOpen(!open)
  }

  return (
    <>
      <IntCompositionSubstanceRow entry={entry} onClick={toggleOpen} />
      {open && (
        <TableRow display={['flex', 'table-row']}>
          <TableCell colSpan={3} width="100%">
            <Box mt={2} mb={3}>
              <IntCompositionProductsTable
                substanceId={substanceId}
                heading="Präparate mit dem Wirkstoff"
                subheading={entry.bezeichnung}
                onClose={toggleOpen}
              />
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
