import {
  DispensaryTypesEnum,
  ReimbursementBoxTypesEnum,
  SubstanceRetailDispensaryHitsData,
  SubstanceRetailPreparationHitsData,
  SubstanceRetailReimbursementBoxHitsData,
} from '@austria-codex/types'
import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { useState } from 'react'
import { ProductIcon } from '../../../components/EntityIcon/ProductIcon'
import { FilterHitsCount } from '../../../components/Filter/FilterHitsCount'
import { ReimbursementBoxIcon } from '../../../components/Icons/ReimbursementBoxIcon'
import { Flex } from '../../../components/Layout/Box'
import { Text } from '../../../components/Layout/Typography'
import { useProductData } from '../../../contexts/entity'
import { useCollapse } from '../../../hooks/useCollapse'
import { useAppSelector } from '../../../hooks/useStoreHooks'
import {
  isMonoPreparationToKey,
  isVeterinaryToKey,
} from '../../../utilities/entity'
import { ArticlesTable } from './ArticlesTable'
import { CountButton, CountButtonProps } from './CountButton'

const reimbursementBoxes: ReimbursementBoxTypesEnum[] = [
  ReimbursementBoxTypesEnum.G,
  ReimbursementBoxTypesEnum.Y,
  ReimbursementBoxTypesEnum.R,
  ReimbursementBoxTypesEnum.N,
]

const dispensaries: DispensaryTypesEnum[] = [
  DispensaryTypesEnum.RP,
  DispensaryTypesEnum.SG,
  DispensaryTypesEnum.OTC,
]

type ArticleCompositionHitsMatrixProps = {
  preparation: SubstanceRetailPreparationHitsData
  group: string
}

export function ArticleCompositionHitsMatrix({
  preparation,
  group,
}: ArticleCompositionHitsMatrixProps) {
  const includesVeterinary = useAppSelector(
    (state) => state.settings.includeVeterinary
  )

  return (
    <>
      <Flex ml={128}>
        {reimbursementBoxes.map((reimbursementBox) => (
          <Flex width={1 / 4} justifyContent="center" key={reimbursementBox}>
            <ReimbursementBoxIcon type={reimbursementBox} />
          </Flex>
        ))}
      </Flex>
      <>
        {preparation.mono?.human && (
          <ArticleDispensaryHitsGroup
            isMonoPreparation={true}
            isVeterinary={false}
            group={group}
            dispensary={preparation.mono.human}
          />
        )}
        {preparation.kombination?.human && (
          <ArticleDispensaryHitsGroup
            isMonoPreparation={false}
            isVeterinary={false}
            group={group}
            dispensary={preparation.kombination.human}
          />
        )}
        {includesVeterinary && (
          <>
            {preparation.mono?.veterinaer && (
              <ArticleDispensaryHitsGroup
                isMonoPreparation={true}
                isVeterinary={true}
                group={group}
                dispensary={preparation.mono.veterinaer}
              />
            )}
            {preparation.kombination?.veterinaer && (
              <ArticleDispensaryHitsGroup
                isMonoPreparation={false}
                isVeterinary={true}
                group={group}
                dispensary={preparation.kombination.veterinaer}
              />
            )}
          </>
        )}
      </>
    </>
  )
}

type ArticleDispensaryHitsRowProps = {
  dispensary: SubstanceRetailDispensaryHitsData
  group: string
  isVeterinary: boolean
  isMonoPreparation: boolean
}

export function ArticleDispensaryHitsGroup({
  dispensary,
  group,
  isMonoPreparation,
  isVeterinary,
}: ArticleDispensaryHitsRowProps) {
  return (
    <Group display={['block', 'flex']}>
      <Flex width={[1, 64]} flexShrink={0} my={[3, 0]} alignItems="center">
        <Text color="primary.main">
          <ProductIcon
            isVeterinary={isVeterinary}
            isMonoPreparation={isMonoPreparation}
          />
        </Text>
      </Flex>
      <Box flexGrow={1}>
        {dispensaries.map(
          (type) =>
            dispensary[type] && (
              <ArticleReimbursementBoxHitsRow
                key={type}
                dispensary={type}
                reimbursementBox={
                  dispensary[type] as SubstanceRetailReimbursementBoxHitsData
                }
                group={group}
                isVeterinary={isVeterinary}
                isMonoPreparation={isMonoPreparation}
              />
            )
        )}
      </Box>
    </Group>
  )
}

type ArticleReimbursementBoxHitsRowProps = {
  reimbursementBox: SubstanceRetailReimbursementBoxHitsData
  group: string
  dispensary: DispensaryTypesEnum
  isVeterinary: boolean
  isMonoPreparation: boolean
}

export function ArticleReimbursementBoxHitsRow({
  dispensary,
  reimbursementBox,
  group,
  isMonoPreparation,
  isVeterinary,
}: ArticleReimbursementBoxHitsRowProps) {
  const entity = useProductData()
  const [selectedReimbursementBox, setSelectedReimbursementBox] =
    useState<ReimbursementBoxTypesEnum | null>(null)

  const vetKey = isVeterinaryToKey(isVeterinary)
  const monKey = isMonoPreparationToKey(isMonoPreparation)
  const identifier = `${vetKey}:${monKey}:${dispensary}:${reimbursementBox}`

  const [collapse, onToggle] = useCollapse({
    section: entity.id,
    group,
    identifier,
    defaultValue: false,
  })

  function handleClick(reimbursementBox: ReimbursementBoxTypesEnum) {
    onToggle(!collapse || selectedReimbursementBox !== reimbursementBox)
    setSelectedReimbursementBox(reimbursementBox)
  }

  return (
    <Box my={3}>
      <Flex flexGrow={1} flexShrink={0}>
        <Flex width={64} alignItems="center">
          {dispensary}
        </Flex>
        <Flex flexGrow={1}>
          {reimbursementBoxes.map((type) => (
            <Flex
              width={1 / 4}
              justifyContent="center"
              alignItems="center"
              key={type}
            >
              {reimbursementBox[type] ? (
                <HitsButton
                  active={collapse && type === selectedReimbursementBox}
                  onClick={() => handleClick(type)}
                  hits={reimbursementBox[type] as number[][]}
                  clickable={true}
                />
              ) : (
                '-'
              )}
            </Flex>
          ))}
        </Flex>
      </Flex>
      {collapse && (
        <Box sx={{ mt: 1 }}>
          <ArticlesTable
            dispensary={dispensary}
            isVeterinary={isVeterinary}
            isMonoPreparation={isMonoPreparation}
            reimbursementBox={
              selectedReimbursementBox as ReimbursementBoxTypesEnum
            }
            isAvailable={group.endsWith('hits:available')}
            onClose={onToggle}
          />
        </Box>
      )}
    </Box>
  )
}

type HitsButtonProps = CountButtonProps & {
  hits: number[][]
  onClick: () => void
}

function HitsButton({ hits, active, clickable, onClick }: HitsButtonProps) {
  if (hits === null) {
    return <span>0</span>
  }

  return (
    <CountButton active={active} clickable={clickable} onClick={onClick}>
      <FilterHitsCount hits={hits} />
    </CountButton>
  )
}

const Group = styled(Box)`
  & + & {
    border-top: 1px solid ${(props) => props.theme.palette.divider};
  }
`
