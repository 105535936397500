import { gql } from '@apollo/client'
import type {
  ProductInternationalData,
  ProductsFilter,
  ProductUnion,
} from '@austria-codex/types'

type PaginationVariables = {
  offset: number
  limit?: number
}

export type FilterProductsResponse = {
  products: {
    hits: ProductUnion[]
    offset: number
    limit: number
    total: number
  }
}

export type FilterProductsVariables = {
  filter: ProductsFilter
} & PaginationVariables

export type ProductsIntResponse = {
  productsInternational: ProductInternationalData[]
}

export type ProductsIntVariables = {
  ids: string[]
}

export const PRODUCTS_INT_FULL_QUERY = gql`
  query FetchProductsInternationalFullData($ids: [String!]!) {
    productsInternational(identifiers: $ids) {
      id
      bezeichnung
      acoSearchTerm
      isoCode
      abgabeBestimmung
      istVeterinaer
      zusammensetzungInt {
        komponentenName
        bezuege {
          menge
          einheit
          istAbsolut
        }
        eintraege {
          abdaStoffId
          id
          basisStoff {
            id
            bezeichnung
          }
          laufNummer
          eintragsTyp
          bezLaufnummer
          bezeichnung
          menge
          einheit
          oeavStoffId
          oeavBasisStoffId
        }
      }
      haltbarkeiten {
        lagerungsOrt
        haltbarkeitsDauer
        haltbarkeitsEinheit
      }
      kurzinfos {
        abschnittsInhalt
        text
      }
      kontakteInt {
        anbieter {
          kurzname
          langname
          ort
          plz
          strasse
          telefon
          telefax
          isoCode
        }
        mitvertreiber {
          kurzname
          langname
          ort
          plz
          strasse
          telefon
          telefax
          isoCode
        }
      }
      klassifikationInt {
        arzneiform
        istMonopraeparat
        applikationsarten {
          bezeichnung
          appAnwGrpCode
          appMethode
        }
        atcCodes {
          atcCode {
            id
            bezeichnung
          }
        }
        indikationsGruppen {
          indCode
          indNiveau
          bezeichnung
        }
        therapieGruppe {
          gruppenId
          bezeichnung
        }
      }
      alternativenInt {
        bezugsKatalog
        produkte {
          produktIds
          verknBewertung
        }
      }
      anwWarnHinweise {
        zusUeberwachung
        lichtSchutz
        feuchtSchutz
        sofortVerbrauch
        einmaligeAnwendung
        hinweise {
          abdaTyp
          hinweisTyp
          hinweis
        }
      }
    }
  }
`

export const PRODUCTS_INT_QUERY = gql`
  query FetchProductsInternational($ids: [String!]!) {
    productsInternational(identifiers: $ids) {
      id
      bezeichnung
      acoSearchTerm
      isoCode
      abgabeBestimmung
      istVeterinaer
      zusammensetzungInt {
        eintraege {
          id
          abdaStoffId
          eintragsTyp
          bezeichnung
          oeavStoffId
        }
      }
      klassifikationInt {
        istMonopraeparat
      }
    }
  }
`

export const FILTER_PRODUCTS_QUERY = gql`
  query FilterProducts(
    $filter: ProductsFilterInputType!
    $offset: Int!
    $limit: Int
  ) {
    products(filter: $filter, offset: $offset, limit: $limit) {
      offset
      limit
      total
      hits {
        ... on ProductType {
          id
          bezeichnung
          suffix
          isoCode
          istVeterinaer
          zusammensetzung {
            eintraege {
              ... on ProductCompositionSubstanceEntryType {
                eintragsTyp
                laufNummer
                bezLaufnummer
                stoff {
                  stoff {
                    id
                    bezeichnung
                  }
                  basisStoff {
                    id
                    bezeichnung
                  }
                }
              }
            }
          }
          klassifikation {
            istMonopraeparat
          }
          kontakte {
            vertriebsfirma {
              anzeigename
            }
          }
        }
        ... on ProductInternationalType {
          id
          bezeichnung
          acoSearchTerm
          isoCode
          istVeterinaer
          abgabeBestimmung
          zusammensetzungInt {
            eintraege {
              id
              abdaStoffId
              basisStoff {
                id
                oeavStoffId
                bezeichnung
              }
              eintragsTyp
              bezeichnung
              laufNummer
              bezLaufnummer
              oeavStoffId
              oeavBasisStoffId
            }
          }
          kontakteInt {
            anbieter {
              kurzname
            }
          }
          klassifikationInt {
            arzneiform
            istMonopraeparat
          }
          alternativenInt {
            bezugsKatalog
            produkte {
              produktIds
              verknBewertung
            }
          }
        }
      }
    }
  }
`
