import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { AuthenticationErrorTypes } from '@austria-codex/types'
import * as Sentry from '@sentry/react'
import { Config } from '../config'
import { getAuthorizationHeader } from '../utilities/token/header'
import { deleteAccessToken } from '../utilities/token/token'

const LOGIN_URL = `${Config.api.url}${Config.api.endpoints.login}`
const LOGOUT_URL = `${Config.api.url}${Config.api.endpoints.logout}`

const cache = new InMemoryCache({
  typePolicies: {
    CompositionEntry: {
      keyFields: ['id', 'laufNummer'],
    },
  },
})

const httpLink = createHttpLink({
  uri: `${Config.api.url}${Config.api.endpoints.graphql}`,
  fetchOptions: {
    credentials: 'include',
  },
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const errorCodes: string[] = graphQLErrors.map(
      (errors) => errors.extensions.code as string
    )

    if (
      errorCodes.includes(AuthenticationErrorTypes.NO_TOKEN) ||
      errorCodes.includes(AuthenticationErrorTypes.NO_MACHINE_LICENSE)
    ) {
      const { pathname, search } = window.location
      const referer = pathname.startsWith('/unauthorized')
        ? '/'
        : encodeURIComponent(pathname + search)

      window.location.replace(LOGIN_URL + `?redirect=${referer}`)
      return
    }

    if (
      errorCodes.includes(AuthenticationErrorTypes.INVALID_USER) ||
      errorCodes.includes(AuthenticationErrorTypes.NO_LICENSE)
    ) {
      window.location.replace('/unauthorized')
      return
    }

    // Send unhandled errors to Sentry
    graphQLErrors.forEach((error) =>
      Sentry.captureMessage('GraphQL Error: ' + error.message, {
        level: 'error',
        tags: {
          errorCode: error.extensions.code as string,
        },
        extra: {
          context: 'aco',
          middleware: 'errorLink',
          type: 'graphQLError',
        },
      })
    )

    // Try to freshly fetch and reload app when those error happen
    if (
      errorCodes.includes('INTERNAL_SERVER_ERROR') ||
      // If the GraphQL API changed and the client is not yet updated,
      // this error gets returned, so we try to reload.
      errorCodes.includes('GRAPHQL_VALIDATION_FAILED')
    ) {
      fetch(window.location.href, {
        headers: {
          accept: 'text/html',
          pragma: 'no-cache',
          expires: '-1',
          'cache-control': 'no-cache',
        },
      }).then(() => {
        // TODO: find a way to do that reliably
        // Problem is, that when we reload the page and the error still exists,
        // we would reload again, and then again, and so on.
        // window.location.reload()
      })
    }

    return
  }

  if (networkError) {
    Sentry.captureException(networkError, {
      extra: {
        context: 'aco',
        middleware: 'errorLink',
        type: 'networkError',
      },
    })
  }
})

const authLink = setContext((request, { headers = {} }) => ({
  headers: {
    ...headers,
    ...getAuthorizationHeader(),
  },
}))

export const austriaCodexClient = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache,
})

export const logout = () => {
  austriaCodexClient.clearStore().finally(() => {
    deleteAccessToken()
    window.location.replace(LOGOUT_URL)
  })
}

export const login = () => {
  austriaCodexClient.clearStore().finally(() => {
    deleteAccessToken()
    window.location.replace(LOGIN_URL)
  })
}
