import React from 'react'
import { ProductCompositionDescriptionEntryData } from '@austria-codex/types'
import { CellBorder, TableCell } from '../../../components/Table/TableCell'
import { CompositionTitleBlock } from './CompositionTitleBlock'
import { Text } from '../../../components/Layout/Typography'

type CompositionDescriptionRowProps = {
  entry: ProductCompositionDescriptionEntryData
}

export function CompositionDescriptionRow({
  entry,
}: CompositionDescriptionRowProps) {
  return (
    <tr>
      <TableCell colSpan={3}>
        <CellBorder indent>
          <CompositionTitleBlock entry={entry}>
            <Text fontWeight="bold">{entry.bezeichnung}</Text>
          </CompositionTitleBlock>
        </CellBorder>
      </TableCell>
    </tr>
  )
}
