import { EntityId } from '@reduxjs/toolkit'
import {
  NavigateFunction,
  Location as ReactRouterLocation,
} from 'react-router-dom'
import { store } from '../store'
import { isArrayEqual } from './common'

let currentIds: EntityId[] = []

export function subscribeToEntityHistory(
  location: ReactRouterLocation,
  navigate: NavigateFunction
) {
  return store.subscribe(() => {
    const entityState = store.getState().entities
    const ids = entityState.ids

    if (!isArrayEqual(ids, currentIds)) {
      const queryParameter = [...ids].join(',')
      const { hash, pathname, state } = location

      // If we come from the link page and only one PZN
      // was given, add the `open` query param.
      const open =
        state?.from === 'link-page' && ids.length === 1 ? '&open=true' : ''

      navigate(
        {
          pathname,
          search:
            queryParameter.length > 0
              ? `q=${queryParameter}${open}`
              : undefined,
          hash: hash.length > 0 ? hash : undefined,
        },
        {
          replace: true,
        }
      )

      currentIds = ids
    }
  })
}

export function getEntityIdentifiersFromSearchParameters(location: Location) {
  // Identifiers are given via the 'q' query parameter (eg: https://austria-codex.at/?q=c17d01,ac5001)
  const params = new URLSearchParams(location.search)
  const q = params.get('q')

  if (!q) {
    return null
  }

  const identifiers = q.split(',')

  return identifiers.length > 0 ? identifiers : null
}

export function toFullPath(location: ReactRouterLocation) {
  return `${location.pathname}${location.search}${location.hash}`
}
