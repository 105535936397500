import styled from '@emotion/styled'
import { memo, ReactNode } from 'react'
import {
  display,
  DisplayProps,
  ResponsiveValue,
  space,
  SpaceProps,
  system,
  typography,
  TypographyProps,
  variant,
} from 'styled-system'

type AnnotatedTextProps = {
  text: string
}

export const AnnotatedText = memo(({ text }: AnnotatedTextProps) => {
  const annotatedText = text.replace(/\^([0-9]+)/g, '<sup>$1</sup>')
  return <span dangerouslySetInnerHTML={{ __html: annotatedText }} />
})

type ParagraphsProps = {
  text: string[] | null
}

export const Paragraphs = ({ text }: ParagraphsProps) =>
  text === null ? null : (
    <>
      {text.map((paragraph, index) => (
        <Paragraph key={index}>{paragraph}</Paragraph>
      ))}
    </>
  )

export const Paragraph = styled.p`
  margin: 0;
`

type Headings = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

interface TextStyleProps {
  variant?:
    | Headings
    | 'caption'
    | 'subHeading1'
    | 'subHeading2'
    | 'breakability'
}

const textStyle = variant({
  key: 'typography',
})

interface TextTransformProps {
  textTransform?:
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'capitalize'
    | 'full-width'
    | 'lowercase'
    | 'none'
    | 'uppercase'
}

interface CursorProps {
  cursor?: string
}

const textTransform = system({
  textTransform: true,
})

export type ColorProps = {
  color?: ResponsiveValue<string>
  bg?: ResponsiveValue<string>
}

const color = system({
  color: {
    property: 'color',
    scale: 'palette',
  },
  bg: {
    property: 'backgroundColor',
    scale: 'palette',
  },
})

export const Text = styled.span<
  TypographyProps &
    ColorProps &
    TextStyleProps &
    TextTransformProps &
    SpaceProps &
    DisplayProps &
    CursorProps
>`
  ${typography}
  ${color}
  ${textStyle}
  ${textTransform}
  ${space}
  ${display}
  ${system({
    cursor: true,
  })}
`

type HeadingProps = {
  level?: 1 | 2 | 3 | 4 | 5 | 6
  color?: string
  children: ReactNode
}

export function Heading({
  level = 1,
  color = 'grey.800',
  children,
}: HeadingProps) {
  const heading = `h${level}` as Headings

  return (
    <Text color={color} as={heading} variant={heading} m={0} p={0}>
      {children}
    </Text>
  )
}
