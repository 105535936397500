export enum UserType {
  INTERNAL = 1,
  EXTERNAL = 2,
  MACHINE = 3,
}

interface BaseUser {
  type: UserType
  id: string
  customer: string
  profile?: string
  sectorId?: string
  specialisationId?: string
  sponsor?: string
  licenses?: string[]
}

export interface ExternalUser extends BaseUser {
  type: UserType.EXTERNAL
}

export interface MachineUser extends BaseUser {
  type: UserType.MACHINE
  machineUserName: string
}

export type User = ExternalUser | MachineUser
