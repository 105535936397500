import { ProductUnion } from '@austria-codex/types'
import { DataTableConfig } from '../../components/DataTable/DataTable'
import {
  agentsCell,
  countryCell,
  descriptionCell,
  mainAgentsCell,
  productIconCell,
} from './common'

export const AGENTS_TABLE_CONFIG: DataTableConfig<ProductUnion> = {
  hover: true,
  columns: [productIconCell, descriptionCell, agentsCell],
}

export const AGENTS_TABLE_CONFIG_INT: DataTableConfig<ProductUnion> = {
  hover: true,
  columns: [productIconCell, descriptionCell, mainAgentsCell, countryCell],
}
