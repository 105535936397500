import { ApplikationsArt } from '@austria-codex/types'
import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import HomoeoIcon from '../../../assets/images/classifications/homooe.svg?react'
import PflanzIcon from '../../../assets/images/classifications/pflanzlich.svg?react'
import {
  IconCard,
  IconCardContent,
  IconCardTitle,
} from '../../../components/IconCard/IconCard'
import { RouteOfAdministrationIcon } from '../../../components/Icons/RouteOfAdministrationIcon'
import { Section } from '../../../components/Section/Section'
import { useProductIntData } from '../../../contexts/entity'
import { ucfirst } from '../../../utilities/common'

export function IntClassificationSection() {
  const product = useProductIntData()

  const appArten = product.klassifikationInt?.applikationsarten ?? []
  const therapyGroup = product.klassifikationInt?.therapieGruppe
  const isHomoeopathic =
    therapyGroup?.gruppenId === 4 || therapyGroup?.gruppenId === 9
  const isPhytotherapeutikum = therapyGroup?.gruppenId === 2

  return (
    <Section width="small" heading="Klassifikation">
      <Grid container spacing={3}>
        {appArten.length > 0 && (
          <Grid item md={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ fontWeight: 700 }}>Applikationsarten</Box>
              <Box
                sx={{
                  mt: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 1,
                }}
              >
                {appArten.map((a) => (
                  <RouteOfAdministration key={a.bezeichnung} route={a} />
                ))}
              </Box>
            </Box>
          </Grid>
        )}
        {isHomoeopathic ? (
          <Grid item md={6}>
            <IconCard>
              <IconCardTitle>Ist homöopathisch</IconCardTitle>
              <IconCardContent>
                <HomoeoIcon />
              </IconCardContent>
            </IconCard>
          </Grid>
        ) : isPhytotherapeutikum ? (
          <Grid item md={6}>
            <IconCard>
              <IconCardTitle>{therapyGroup.bezeichnung}</IconCardTitle>
              <IconCardContent>
                <PflanzIcon />
              </IconCardContent>
            </IconCard>
          </Grid>
        ) : null}
      </Grid>
    </Section>
  )
}

type TRouteOfAdministrationProps = {
  route: ApplikationsArt
}

function RouteOfAdministration({ route }: TRouteOfAdministrationProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {route.appAnwGrpCode && (
        <RouteOfAdministrationIcon
          routeOfAdministration={route.appAnwGrpCode}
          color="primary"
        />
      )}
      <Box component="span" sx={{ ml: 2 }}>
        {route.appMethode ?? ucfirst(route.bezeichnung)}
      </Box>
    </Box>
  )
}
