import { FeatureEnum, type ProductsFilter } from '@austria-codex/types'
import { Box } from '@mui/material'
import { AGENTS_QUERY } from '../../api/agents'
import {
  AGENTS_TABLE_CONFIG,
  AGENTS_TABLE_CONFIG_INT,
} from '../../common/tableConfig/agents'
import { Contact } from '../../components/Contact/Contact'
import { ProductsDataSourceTable } from '../../components/PaginatedDataTable/ProductsDataSourceTable'
import { Section } from '../../components/Section/Section'
import { useProductData } from '../../contexts/entity'
import { useAppSelector } from '../../hooks/useStoreHooks'
import { FeatureProps } from '../../types/bundle'

const heading = 'Weitere Produkte des Zulassungsinhabers'

export function MarketingAuthorisationHolderSection({
  features,
}: FeatureProps) {
  const product = useProductData()
  const mode = useAppSelector((state) => state.user.mode)

  const hasMarketingAuthorisationHolderProducts = features.includes(
    FeatureEnum.MarketingAuthorisationHolderProducts
  )

  const tableConf =
    mode === 'international' ? AGENTS_TABLE_CONFIG_INT : AGENTS_TABLE_CONFIG

  const zulassungsinhaber = product.kontakte?.zulassungsinhaber

  if (!zulassungsinhaber) {
    return null
  }

  const filter: ProductsFilter = {
    authorisationHolder: zulassungsinhaber.id,
  }

  return (
    <>
      <Section width="small" heading="Zulassungsinhaber">
        <Contact contact={zulassungsinhaber} />
      </Section>
      {hasMarketingAuthorisationHolderProducts && (
        <Section width="small" heading={heading}>
          <Box mt={3}>
            <ProductsDataSourceTable
              query={AGENTS_QUERY}
              config={tableConf}
              filter={filter}
              name="alternatives:marketingAuthorisationHolder"
              heading={heading}
              subheading={zulassungsinhaber.anzeigename}
            />
          </Box>
        </Section>
      )}
    </>
  )
}
