import {
  EEvaluationDescription,
  EInteractionSeverity,
} from '@austria-codex/types'
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
} from '@mui/material'
import { ChangeEvent, MouseEvent, useState } from 'react'
import CloseIcon from '../../../assets/images/delete.svg?react'
import { useAppDispatch, useAppSelector } from '../../../hooks/useStoreHooks'
import {
  toggleEvaluation,
  toggleSeverity,
} from '../../../store/interactionsFilter.store'
import { evaluationDescription, interactionHeadings } from './constants'

const interactionFilters = Object.entries(interactionHeadings)
const evaluationFilters = Object.entries(evaluationDescription)

export function InteractionsFilter() {
  const dispatch = useAppDispatch()
  const selectedSeverities = useAppSelector(
    (state) => state.interactionsFilter.selectedSeverities
  )
  const selectedEvaluations = useAppSelector(
    (state) => state.interactionsFilter.selectedEvaluations
  )
  const mode = useAppSelector((state) => state.user.mode)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  function handleClick(event: MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  function handleClickAway() {
    setAnchorEl(null)
  }

  function handleChangeSeverity(event: ChangeEvent<HTMLInputElement>) {
    const v = Number(event.target.value) as EInteractionSeverity
    dispatch(toggleSeverity(v))
  }

  function handleChangeEvaluation(event: ChangeEvent<HTMLInputElement>) {
    const v = Number(event.target.value) as EEvaluationDescription
    dispatch(toggleEvaluation(v))
  }

  const open = Boolean(anchorEl)
  const id = open ? 'info-popper' : undefined

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        Filter:{' '}
        <Button
          sx={{ textTransform: 'none' }}
          onClick={handleClick}
          disabled={mode === 'international'}
        >
          Schweregrad
          <Box
            component="span"
            sx={{ ml: 0.5, mr: 1, width: '16px', textAlign: 'center' }}
          >
            ({selectedSeverities.length})
          </Box>
          Datenlage
          <Box
            component="span"
            sx={{ ml: 0.5, width: '16px', textAlign: 'center' }}
          >
            ({selectedEvaluations.length})
          </Box>
        </Button>
      </Box>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            sx={{ p: 2, maxWidth: '360px', color: 'grey.800' }}
            elevation={3}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ fontSize: '1rem', color: 'grey.500' }}>
                  Schweregrad
                </Box>
              </Box>
              <IconButton sx={{ p: 0 }} onClick={handleClick}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                mt: 1,
                display: 'flex',
                flexDirection: 'column',
                rowGap: '0.5rem',
              }}
            >
              {interactionFilters.map(([severity, heading]) => {
                const s = Number(severity)
                const id = `checkbox-sev-${severity}`
                // Only allow filter for "Keine Aussage möglich" and "Keine Interaktion zu erwarten"
                const disabled = s > EInteractionSeverity.NoInteraction
                const isSelected = selectedSeverities.includes(s)

                return (
                  <Box
                    component={'label'}
                    htmlFor={id}
                    key={id}
                    sx={{
                      alignItems: 'center',
                      borderRadius: '4px',
                      display: 'flex',
                      height: '28px',
                      gap: '0.5rem',
                      px: '0.25rem',
                    }}
                  >
                    <Checkbox
                      id={id}
                      sx={{
                        p: 0,
                        '&.Mui-disabled': { color: 'rgba(63, 165, 53, 0.4)' },
                      }}
                      checked={isSelected}
                      onChange={handleChangeSeverity}
                      value={s}
                      disabled={disabled}
                    />{' '}
                    {heading}
                  </Box>
                )
              })}
            </Box>
            <Box sx={{ mt: 2, fontSize: '1rem', color: 'grey.500' }}>
              Datenlage
            </Box>
            <Box
              sx={{
                mt: 1,
                display: 'flex',
                flexDirection: 'column',
                rowGap: '0.5rem',
              }}
            >
              {evaluationFilters.map(([evaluation, heading]) => {
                const e = Number(evaluation)
                const id = `checkbox-eva-${evaluation}`
                // Only allow filter for "Datenlage nicht bekannt" and "Datenlage nichst ausreichend"
                const disabled = e > EEvaluationDescription.NotSufficient
                const isSelected = selectedEvaluations.includes(e)

                return (
                  <Box
                    component={'label'}
                    htmlFor={id}
                    key={id}
                    sx={{
                      alignItems: 'center',
                      borderRadius: '4px',
                      display: 'flex',
                      height: '28px',
                      gap: '0.5rem',
                      px: '0.25rem',
                    }}
                  >
                    <Checkbox
                      id={id}
                      sx={{
                        p: 0,
                        '&.Mui-disabled': { color: 'rgba(63, 165, 53, 0.4)' },
                      }}
                      checked={isSelected}
                      onChange={handleChangeEvaluation}
                      value={e}
                      disabled={disabled}
                    />{' '}
                    {heading}
                  </Box>
                )
              })}
            </Box>
            <Box sx={{ mt: 3, color: 'grey.500' }}>
              Interaktionen ab dem Schweregrad "Keine Interaktion zu erwarten"
              oder ab “Datenlage nicht ausreichend” können manuell ausgeblendet
              werden.
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}
