import { ArticleData } from '@austria-codex/types'
import { DataTableConfig } from '../../components/DataTable/DataTable'
import { Numeral } from '../../components/Utility/Numeral'

export const ARTICLES_TABLE_CONFIG: DataTableConfig = {
  hover: true,
  columns: [
    {
      title: 'Bezeichnung',
      key: 'bezeichnung',
    },
    {
      title: 'Menge',
      key: 'amount',
      align: 'left',
      width: '180px',
      render: (config, row: ArticleData) =>
        row.packung?.gebinde && (
          <span style={{ whiteSpace: 'nowrap' }}>
            <Numeral input={row.packung.gebinde.mengenAngabe} />
            {row.packung.gebinde.einheit &&
              `  ${row.packung.gebinde.einheit.bezeichnung}`}
            {row.packung.fuellmenge && (
              <span>
                {' / '}
                <Numeral
                  input={row.packung.fuellmenge.mengenAngabe}
                  format="0[.][00000]"
                />
                {row.packung.fuellmenge.einheit &&
                  `  ${row.packung.fuellmenge.einheit.bezeichnung}`}
              </span>
            )}
          </span>
        ),
    },
    {
      title: 'PHZNR.',
      key: 'pharmazentralNr',
      align: 'left',
      width: '120px',
    },
  ],
}
