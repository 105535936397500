import { Html } from '../../components/Html/Html'
import { DocumentationSection } from '../../components/Section/DocumentationSection'
import { useProductData } from '../../contexts/entity'

export function SideEffectsSection() {
  const product = useProductData()
  const sideEffects = product.dokumentation?.schnellhilfe?.nebenwirkungen

  return sideEffects ? (
    <DocumentationSection
      inherit={sideEffects.vererbt}
      width="small"
      heading="Nebenwirkungen"
    >
      <Html content={sideEffects.text} />
    </DocumentationSection>
  ) : null
}
