import { Box, IconButton, Paper, Popper } from '@mui/material'
import { MouseEvent, useState } from 'react'
import CloseIcon from '../assets/images/delete.svg?react'
import InfoIcon from '../assets/images/info.svg?react'
import { BioaequivalenzScore } from './BioaequivalenzScore'

export function BioaequivalenzScoreInfo() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  function handleClick(event: MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'info-popper' : undefined

  return (
    <>
      <IconButton onClick={handleClick} type="button" aria-describedby={id}>
        <InfoIcon />
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
        <Paper
          sx={{ p: 2, maxWidth: '460px', color: 'grey.800' }}
          elevation={3}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ fontSize: '0.75rem' }}>Legende</Box>
              <Box sx={{ fontSize: '1rem', fontWeight: 700 }}>Datenlage</Box>
            </Box>
            <IconButton onClick={handleClick}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box component="p">
            Güte einer Datenverbindung zwischen nationalen und internationalen
            Arzneimitteln innerhalb einer generischen Gruppe.
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.5rem',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <BioaequivalenzScore score={2} />{' '}
              <Box component="span" sx={{ ml: 2 }}>
                Sehr gut
              </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <BioaequivalenzScore score={1} />{' '}
              <Box component="span" sx={{ ml: 2 }}>
                Exzellent
              </Box>
            </Box>
          </Box>
        </Paper>
      </Popper>
    </>
  )
}
