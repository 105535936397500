import { Box } from '../components/Layout/Box'
import { Anchor } from '../components/Layout/Anchor'
import { Heading } from '../components/Layout/Typography'
import { ErrorScreen } from '../components/ErrorScreen/ErrorScreen'

type TProps = {
  message?: string
}

export function ErrorPage({ message }: TProps) {
  message ??= 'Diese Seite wurde nicht gefunden.'

  return (
    <ErrorScreen>
      <Box mb={3}>
        <Heading level={1}>{message}</Heading>
      </Box>
      <Anchor href="/">Zurück zur Hauptseite</Anchor>
    </ErrorScreen>
  )
}
