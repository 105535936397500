import styled from '@emotion/styled'
import { Box } from '@mui/material'
import {
  compose,
  display,
  DisplayProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system'
import { TableRow } from './TableRow'

export const TableCell = styled.td<SpaceProps & DisplayProps & LayoutProps>(
  compose(space, display, layout),
  () => ({ verticalAlign: 'top' })
)

type CellBorderProps = {
  indent?: boolean
}

export const CellBorder = styled(Box)<CellBorderProps>`
  padding-top: 4px;
  padding-bottom: 4px;
  position: relative;

  ${(props) => props.theme.up(1, props.theme.breakpoints)} {
    &:before {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      left: ${(props) => (props.indent ? '40px' : 0)};
      height: 1px;
      background-color: ${(props) => props.theme.palette.divider};
    }
  }

  ${TableRow}:first-of-type > ${TableCell} > &:before {
    background-color: transparent;
  }

  ${(props) => props.theme.down(1, props.theme.breakpoints)} {
    &:empty {
      display: none;
    }
  }
`
