import { FilterMatchTypesEnum } from '@austria-codex/types'
import { useCallback } from 'react'
import { ProductsTable } from '../../components/ProductsTable/ProductsTable'
import { useAppSelector } from '../../hooks/useStoreHooks'

export function ProductsSameAgentSection() {
  const mode = useAppSelector((state) => state.user.mode)
  const heading = useCallback((agents: number) => {
    return `Präparate mit ${
      agents > 1 ? 'der Wirkstoffkombination' : 'dem Wirkstoff'
    }`
  }, [])

  const name =
    mode === 'national'
      ? 'products:containingAgent'
      : 'products:containingAgentInt'

  return (
    <ProductsTable
      name={name}
      match={FilterMatchTypesEnum.EXACT}
      heading={heading}
    />
  )
}
