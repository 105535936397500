import { useMemo } from 'react'
import { RETAIL_ARTICLE_TABLE_CONFIG } from '../../common/tableConfig/retailArticles'
import { DataTable } from '../../components/DataTable/DataTable'
import { Box } from '../../components/Layout/Box'
import { Section } from '../../components/Section/Section'
import { useProductData } from '../../contexts/entity'
import { usePagination } from '../../hooks/usePagination'

const rowsPerPage = 5

export function ArticlesSection() {
  const product = useProductData()
  const [page, setPage] = usePagination(product.id, 'retail:articles')
  const nichtImHandel = product.ausVerlauf === 2 || product.ausVerlauf === 3

  const paginatedArticles = useMemo(() => {
    const start = page * rowsPerPage
    const end = start + rowsPerPage

    return (
      product.artikel?.filter((a) => !a.istAktionsartikel).slice(start, end) ??
      []
    )
  }, [page, product])

  return product.artikel && !nichtImHandel ? (
    <Section width="large" heading="Artikel">
      <Box mt={2}>
        <DataTable
          config={RETAIL_ARTICLE_TABLE_CONFIG}
          data={paginatedArticles}
          total={product.artikel.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={setPage}
        />
      </Box>
    </Section>
  ) : null
}
