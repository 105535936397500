import { FilterMatchTypesEnum } from '@austria-codex/types'
import { useCallback } from 'react'
import { ProductsTable } from '../../components/ProductsTable/ProductsTable'

export function ProductsContainingAgentSection() {
  const heading = useCallback((agents: number) => {
    return `Präparate die ${
      agents > 1 ? 'den Wirkstoff' : 'die Wirkstoffkombination'
    } beinhalten`
  }, [])

  return (
    <ProductsTable
      name="products:containingAgent"
      match={FilterMatchTypesEnum.SUBSET}
      heading={heading}
    />
  )
}
