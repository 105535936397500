import styled from '@emotion/styled'
import {
  compose,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
} from 'styled-system'

export const Table = styled.table<DisplayProps & FlexboxProps>`
  ${compose(display, flexbox)};
  border-collapse: collapse;
`
