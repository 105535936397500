/* eslint-disable @typescript-eslint/no-explicit-any */
import { ArticleData, ProductUnion } from '@austria-codex/types'
import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/ClearRounded'
import { Box as MuiBox, type ClassNameMap } from '@mui/material'
import Button from '@mui/material/Button'
import ButtonBase from '@mui/material/ButtonBase'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { TableCellProps } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import classNames from 'classnames'
import { ChangeEvent, createElement, type ReactNode } from 'react'
import { makeStyles } from 'tss-react/mui'
import ModalIcon from '../../assets/images/modal_icon.svg?react'
import { Box, Flex } from '../Layout/Box'
import { Text } from '../Layout/Typography'
import { TableEmptyRow } from './TableEmptyRow'
import { TableSkeletonRows } from './TableSkeletonRows'

const useStyles = makeStyles()((theme) => ({
  paper: {
    position: 'relative',
  },
  wordWrap: {
    whiteSpace: 'nowrap',
  },
  clickable: {
    cursor: 'pointer',
  },
  table: {
    tableLayout: 'fixed',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  disabled: {
    background: 'rgba(224, 224, 224, 1)',
    cursor: 'default',
  },
  row: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  },
  head: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  body: {
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 auto',
    },
  },
  cell: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      '$row + $row > &:first-of-type': {
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.divider,
        marginTop: 2,
      },
      '&:empty': {
        display: 'none',
      },
    },
  },
}))

type ColumnRenderer<T = never, TMeta = never> = (
  data: unknown,
  row: T,
  meta: TMeta,
  onClickRow?: (row: unknown) => void
) => ReactNode

export type DataTableDefaultRowRenderer<T = DataTableData> = (
  config: DataTableConfig,
  row: T,
  meta: unknown,
  onClickRow?: (row: unknown) => void,
  disabled?: boolean
) => ReactNode

export type DataTableRowRenderer<T = DataTableData> = (
  config: DataTableConfig,
  row: T,
  meta: unknown,
  onClickRow?: (row: unknown) => void,
  disabled?: boolean,
  defaultRowRenderer?: DataTableDefaultRowRenderer<T>,
  classes?: ClassNameMap
) => ReactNode

export type RetailTableRowProps<T = DataTableData> = {
  config: DataTableConfig
  row: T
  meta: unknown
  onClickRow?: (row: unknown) => void
  disabled?: boolean
  defaultRowRenderer?: DataTableDefaultRowRenderer<T>
  classes?: ClassNameMap
}

export interface DataTableConfig<T = any, TMeta = any> {
  columns: Column<T, TMeta>[]
  hover?: boolean
  rowRenderer?: any
  key?: string
}

export interface Column<T = any, TMeta = any> {
  title?: string | ReactNode
  titleAddOn?: ReactNode
  hideTitle?: boolean
  key: string
  render?: ColumnRenderer<T, TMeta>
  noWordWrap?: boolean
  padding?: TableCellProps['padding']
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  width?: number | string
}

export type DataTableData = ArticleData | ProductUnion

export type DataTableProps = {
  config: DataTableConfig
  data: DataTableData[]
  total?: number
  page?: number
  error?: boolean
  rowsPerPage?: number
  loading?: boolean
  hasOverlay?: boolean
  meta?: unknown
  onPageChange?: (offset: number) => void
  onRowsPerPageChange?: (rowsPerPage: number) => void
  onReload?: () => void
  onClickRow?: (row: any) => void
  onClose?: (collapse?: boolean) => void
  onOpenOverlay?: () => Promise<void>
  isRowDisabled?: (row: any) => boolean
}

export function DataTable({
  config,
  data,
  total = 0,
  page,
  error,
  rowsPerPage = 3,
  loading,
  hasOverlay,
  meta,
  onPageChange,
  onRowsPerPageChange,
  onReload,
  onClickRow,
  onClose,
  onOpenOverlay,
  isRowDisabled,
}: DataTableProps) {
  const { classes } = useStyles()

  function handlePageChange(_event: any, page: number) {
    if (loading) return
    if (!onPageChange) return
    onPageChange(page)
  }

  function handleRowsPerPageChange(event: ChangeEvent<HTMLInputElement>) {
    if (loading) return
    if (!onRowsPerPageChange || !onPageChange) return

    const newLimit = parseInt(event.target.value)

    onRowsPerPageChange(newLimit)
    onPageChange(0)
  }

  const pagination = () =>
    showPagination(page, rowsPerPage, total) ? (
      <TablePagination
        component="div"
        rowsPerPageOptions={[3, 5, 10, 25, 50]}
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} von ${count}`
        }
        labelRowsPerPage="Anzahl pro Seite"
        slotProps={{
          actions: {
            previousButton: { 'aria-label': 'Vorherige Seite' },
            nextButton: { 'aria-label': 'Nächste Seite' },
          },
        }}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    ) : null

  return (
    <TableContainer
      component={Paper}
      className={classes.paper}
      sx={{ maxHeight: 'calc(90vh - 200px)' }}
    >
      <MuiBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 0.25,
          padding: 0.5,
          position: 'absolute',
          right: 0,
        }}
      >
        {onClose && (
          <CloseButton onClick={() => onClose()}>
            <CloseIcon fontSize="small" />
          </CloseButton>
        )}
        {hasOverlay && onOpenOverlay && (
          <Button
            variant="text"
            onClick={onOpenOverlay}
            sx={{ minWidth: 0, p: 0, zIndex: 3 }}
            style={{ backgroundColor: 'transparent' }}
          >
            <ModalIcon style={{ width: '20px', height: '20px' }} />
          </Button>
        )}
      </MuiBox>
      <Table className={classes.table} stickyHeader>
        <TableHead className={classes.head}>
          <TableRow>
            {config.columns.map((column) => (
              <TableCell
                key={column.key}
                align={column.align}
                padding={column.padding}
                width={column.width}
              >
                {column.title && column.hideTitle !== true && column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          {loading ? (
            <TableSkeletonRows config={config} rowsPerPage={rowsPerPage} />
          ) : error ? (
            <TableRow>
              <TableCell colSpan={config.columns.length}>
                <ErrorMessage onReload={onReload} />
              </TableCell>
            </TableRow>
          ) : data.length === 0 ? (
            <TableEmptyRow config={config} />
          ) : (
            <>
              {data.map((row) => {
                const disabled = isRowDisabled ? isRowDisabled(row) : false
                const key = keyFromConfig(config, row)

                return config.rowRenderer ? (
                  createElement(config.rowRenderer, {
                    key,
                    config,
                    row,
                    meta,
                    onClickRow,
                    disabled,
                    defaultRowRenderer,
                    classes,
                  })
                ) : (
                  <DefaultRow
                    key={key}
                    config={config}
                    row={row}
                    meta={meta}
                    onClick={onClickRow}
                    disabled={disabled}
                  />
                )
              })}
            </>
          )}
        </TableBody>
      </Table>
      {!pagination ? null : pagination()}
    </TableContainer>
  )
}

function defaultRowRenderer(
  config: DataTableConfig,
  row: DataTableData,
  meta: any,
  onClick?: (row: any) => void,
  disabled?: boolean
) {
  return (
    <DefaultRow
      config={config}
      row={row}
      meta={meta}
      onClick={onClick}
      disabled={disabled}
    />
  )
}

type DefaultRowProps = {
  config: DataTableConfig
  row: DataTableData
  meta: any
  onClick?: (row: unknown) => void
  disabled?: boolean
}

function DefaultRow({ config, row, meta, onClick, disabled }: DefaultRowProps) {
  const { classes } = useStyles()

  return (
    <TableRow
      onClick={() => !disabled && onClick && onClick(row)}
      hover={!disabled && config.hover}
      className={classNames(classes.row, {
        [classes.disabled]: disabled,
        [classes.clickable]: !disabled && !!onClick,
      })}
    >
      {config.columns.map((column) => (
        <TableCell
          key={column.key}
          align={column.align}
          padding={column.padding}
          className={classNames(classes.cell, {
            [classes.wordWrap]: column.noWordWrap,
          })}
        >
          <>
            {!column.hideTitle && column.title && (
              <InlineColumnTitle display={['block', 'none']}>
                {column.title}
              </InlineColumnTitle>
            )}
            {column.render
              ? column.render(row[column.key as keyof DataTableData], row, meta)
              : row[column.key as keyof DataTableData]}
          </>
        </TableCell>
      ))}
    </TableRow>
  )
}

const InlineColumnTitle = styled(Box)`
  letter-spacing: 0.05em;
  font-size: 0.625rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.54);
`

const CloseButton = styled(ButtonBase)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  border-radius: 2px;
  background-color: ${(props) => props.theme.palette.grey[200]};
  padding: 2px;
  z-index: 3;
`

function showPagination(
  page: number | undefined,
  rowsPerPage: number,
  total: number
): page is number {
  return (
    typeof page !== 'undefined' && !isNaN(page) && !!rowsPerPage && total > 0
  )
}

export function keyFromConfig(config: DataTableConfig, row: DataTableData) {
  return config.key
    ? (row[config.key as keyof DataTableData] as string)
    : (row.id as string)
}

type ErrorMessageProps = {
  onReload?: () => void
}

function ErrorMessage({ onReload }: ErrorMessageProps) {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      p={3}
    >
      <Box pb={2}>
        <Text fontStyle="italic">Fehler beim Laden der Daten.</Text>
      </Box>
      {onReload && (
        <Button variant="contained" color="primary" onClick={() => onReload()}>
          Neu Laden
        </Button>
      )}
    </Flex>
  )
}
