import { useCallback, useMemo } from 'react'
import { createPaginationSelector, setPagination } from '../store/pagination'
import { useAppDispatch, useAppSelector } from './useStoreHooks'

export const usePagination = (
  uniqueIdentifier: string,
  identifier: string,
  resettable = true
): [number, (offset: number) => void] => {
  const paginationConfig = useMemo(
    () => ({
      uniqueIdentifier,
      identifier,
      resettable,
    }),
    [identifier, resettable, uniqueIdentifier]
  )

  const dispatch = useAppDispatch()
  const paginationSelector = useMemo(createPaginationSelector, [])
  const page = useAppSelector((state) =>
    paginationSelector(state.pagination, paginationConfig)
  )

  const setPage = useCallback(
    (offset: number) => {
      dispatch(
        setPagination({
          ...paginationConfig,
          offset,
        })
      )
    },
    [paginationConfig, dispatch]
  )

  return [page, setPage]
}
