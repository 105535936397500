import {
  ArticleData,
  Occurrence,
  ProductData,
  ReimbursementBoxTypesEnum,
} from '@austria-codex/types'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Fragment, useContext } from 'react'
import OutOfStockIcon from '../../assets/images/ic-out-of-stock.svg?react'
import {
  DataTableConfig,
  RetailTableRowProps,
  keyFromConfig,
} from '../../components/DataTable/DataTable'
import { EntityProductIcon } from '../../components/EntityIcon/ProductIcon'
import { CollapseIcon } from '../../components/Icons/CollapseIcon'
import { ReimbursementBoxIcon } from '../../components/Icons/ReimbursementBoxIcon'
import { Anchor } from '../../components/Layout/Anchor'
import { Box, Flex } from '../../components/Layout/Box'
import {
  SeparatedList,
  SeparatedListItem,
} from '../../components/Layout/SeparatedList'
import { Text } from '../../components/Layout/Typography'
import { Numeral, PRICE_FORMAT } from '../../components/Utility/Numeral'
import { EntityContext } from '../../contexts/entity'
import {
  hasSpezifischeBeschreibung,
  isEkoBox,
} from '../../helpers/article.helper'
import { useCollapse } from '../../hooks/useCollapse'

export function RetailTableRowRenderer({
  config,
  row,
  meta,
  onClickRow,
  disabled,
  defaultRowRenderer,
  classes,
}: RetailTableRowProps<ArticleData>) {
  const product = useContext(EntityContext) as ProductData

  const [collapse, onToggle] = useCollapse({
    section: product.id,
    group: 'retail:productArticle',
    identifier: `${row.pharmazentralNr}`,
  })

  const kennzeichen = row.abgabe?.kennzeichen ?? []

  return (
    <Fragment key={keyFromConfig(config, row)}>
      {defaultRowRenderer &&
        defaultRowRenderer(
          config,
          row,
          { product, onToggle, collapse },
          onClickRow,
          disabled
        )}
      {collapse && (
        <TableRow className={classes?.row} key={row.id}>
          <TableCell colSpan={config.columns.length}>
            <Box mx={[0, null, 48]} mt={2}>
              {kennzeichen.map((k, index) => (
                <Box key={index} mb={2}>
                  {k.beschreibungSpezifisch && (
                    <div style={{ whiteSpace: 'pre-line' }}>
                      <Text color="primary.main">
                        {k.kategorie === 1 ? k.synonym : k.bezeichnung}:
                      </Text>{' '}
                      {k.beschreibungSpezifisch}
                    </div>
                  )}
                  {isEkoBox(row, ReimbursementBoxTypesEnum.N) && (
                    <>
                      <Text color="primary.main">
                        {k.kategorie === 1 ? k.synonym : k.bezeichnung}:
                      </Text>{' '}
                      {k.beschreibung}
                    </>
                  )}
                </Box>
              ))}
            </Box>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  )
}

export const RETAIL_ARTICLE_TABLE_CONFIG: DataTableConfig = {
  key: 'pharmazentralNr',
  rowRenderer: RetailTableRowRenderer,
  columns: [
    {
      key: 'icon',
      hideTitle: true,
      width: 48,
      render: (_, __, { product }) => {
        return (
          <Text color="primary.main">
            <EntityProductIcon product={product} />
          </Text>
        )
      },
    },
    {
      title: 'Bezeichnung',
      key: 'bezeichnung',
      render: (_, row: ArticleData) => (
        <>
          {row.bezeichnung}
          {row.referenzartikel?.bezeichnungssuffix
            ? ` ${row.referenzartikel.bezeichnungssuffix}`
            : ''}
        </>
      ),
      width: '100%',
    },
    {
      key: 'available',
      hideTitle: true,
      render: (_, row: ArticleData) =>
        row.istLieferbar === Occurrence.No && <OutOfStockIcon />,
      width: 48,
    },
    {
      title: 'Menge',
      key: 'amount',
      align: 'right',
      render: (config, row: ArticleData) =>
        row.packung?.gebinde && (
          <span style={{ whiteSpace: 'nowrap' }}>
            <Numeral input={row.packung.gebinde.mengenAngabe} />
            {row.packung.gebinde.einheit &&
              `  ${row.packung.gebinde.einheit.bezeichnung}`}
            {row.packung.fuellmenge && (
              <span>
                {' / '}
                <Numeral
                  input={row.packung.fuellmenge.mengenAngabe}
                  format="0[.][00000]"
                />{' '}
                {row.packung.fuellmenge.einheit &&
                  `  ${row.packung.fuellmenge.einheit.bezeichnung}`}
              </span>
            )}
          </span>
        ),
      width: 120,
    },
    {
      title: 'PHZNR.',
      key: 'pharmazentralNr',
      align: 'right',
      width: 64,
    },
    {
      title: 'EKO',
      key: 'eko',
      render: (config, row: ArticleData, { onToggle }) => (
        <Flex flexWrap="nowrap" alignItems="center">
          {row.abgabe?.ekoBox && (
            <Box display="inline" mr="3px">
              <ReimbursementBoxIcon type={row.abgabe.ekoBox} />
            </Box>
          )}
          {row.abgabe?.ekoBox && row.abgabe.ekoBox !== 'N' && (
            <Flex flexWrap="nowrap" alignItems="center">
              {row.abgabe?.kennzeichen?.length !== 0 && (
                <>
                  <Box style={{ whiteSpace: 'pre' }}>{' • '}</Box>
                  <Anchor onClick={() => onToggle && onToggle()}>
                    <SeparatedList separator={' • '} nowrap>
                      {row.abgabe?.kennzeichen?.map((indicator, index) => (
                        <SeparatedListItem key={index}>
                          {indicator.kategorie === 1
                            ? indicator.synonym
                            : indicator.bezeichnung}
                        </SeparatedListItem>
                      ))}
                    </SeparatedList>
                  </Anchor>
                </>
              )}
              {row.abgabe && row.abgabe.ekoMaxAbgabe !== null && (
                <Box style={{ whiteSpace: 'pre' }}>
                  {' • '}
                  {row.abgabe.ekoMaxAbgabe}
                </Box>
              )}
            </Flex>
          )}
        </Flex>
      ),
      width: 120,
    },
    {
      title: 'KVP',
      key: 'kvp',
      align: 'right',
      render: (config, row: ArticleData) =>
        row.abgabe?.ekoMaxAbgabe && row.preis?.preisKVP ? (
          <Numeral input={row.preis.preisKVP} format={PRICE_FORMAT} />
        ) : (
          <span>-</span>
        ),
      noWordWrap: true,
      width: 80,
    },
    {
      title: 'UVP',
      key: 'uvp',
      align: 'right',
      render: (data, row: ArticleData) =>
        row.preis?.preisUVP ? (
          <Numeral input={row.preis.preisUVP} format={PRICE_FORMAT} />
        ) : (
          <span>-</span>
        ),
      noWordWrap: true,
      width: 80,
    },
    {
      key: 'collapse',
      align: 'center',
      hideTitle: true,
      render: (data, row: ArticleData, { onToggle, collapse }) => {
        return hasSpezifischeBeschreibung(row) ||
          isEkoBox(row, ReimbursementBoxTypesEnum.N) ? (
          <Flex alignItems="center">
            <CollapseIcon
              onToggle={() => onToggle()}
              collapse={collapse}
              variant="small"
            />
            <Box display={['block', null, 'none']}>Mehr anzeigen</Box>
          </Flex>
        ) : null
      },
      width: 48,
    },
  ],
}
