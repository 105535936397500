import { useQuery } from '@apollo/client'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'
import { CONTENT_QUERY } from '../../api/content'
import { Box } from '../../components/Layout/Box'
import { RichTextRenderer } from '../../components/RichTextRenderer/RichTextRenderer'
import type { RichTextContentType } from '../../components/RichTextRenderer/types'

const pageMapping = {
  zeichen: 'iconDescription',
  fragen: 'faqPage',
}

function isPageMappingProp(
  page: string | undefined
): page is keyof typeof pageMapping {
  return page ? Object.keys(pageMapping).includes(page) : false
}

export function ContentPage() {
  const { page } = useParams()
  const { data, loading } = useQuery<{ content: RichTextContentType }>(
    CONTENT_QUERY,
    {
      variables: {
        page: isPageMappingProp(page) ? pageMapping[page] : pageMapping.zeichen,
      },
    }
  )

  if (loading) {
    return (
      <Box my={4} mx={3}>
        <p>
          <Skeleton width={700} />
          <Skeleton width={400} />
          <Skeleton width={500} />
          <Skeleton width={700} />
          <Skeleton width={600} />
          <Skeleton width={500} />
        </p>
      </Box>
    )
  }

  return (
    <Box my={4} mx={3}>
      <RichTextRenderer content={data?.content?.content ?? []} />
    </Box>
  )
}
