import { css } from '@emotion/react'
import styled from '@emotion/styled'

type ContentProps = {
  width?: 'large' | 'medium' | 'small'
}

export const Content = styled.div<ContentProps>`
  padding: 0;

  ${(props) =>
    props.width === 'small' &&
    css`
      padding: 0 24px;

      ${props.theme.media(1, props.theme.breakpoints)} {
        padding: 0 48px;
      }

      ${props.theme.media(2, props.theme.breakpoints)} {
        padding: 0 120px;
      }
    `}

  ${(props) =>
    props.width === 'medium' &&
    css`
      padding: 0 ${props.theme.spacing(3, props.theme.space)};

      ${props.theme.media(1, props.theme.breakpoints)} {
        padding: 0 ${props.theme.spacing(4, props.theme.space)};
      }

      ${props.theme.media(2, props.theme.breakpoints)} {
        padding: 0 ${props.theme.spacing(5, props.theme.space)};
      }
    `}

  ${(props) =>
    props.width === 'large' &&
    css`
      padding: 0 ${props.theme.spacing(3, props.theme.space)};

      ${props.theme.media(2, props.theme.breakpoints)} {
        padding: 0 ${props.theme.spacing(4, props.theme.space)};
      }
    `}
`
