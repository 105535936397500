import { FeatureEnum } from '@austria-codex/types'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import { FeatureProps } from '../types/bundle'
import { AdvisorySection } from './sequence/AdvisorySection'
import { AllergySection } from './sequence/AllergySection'
import { ArticleMatrixSection } from './sequence/ArticleMatrix/ArticleMatrixSection'
import { ArticlesSection } from './sequence/ArticlesSection'
import { AtcCodesSection } from './sequence/AtcCodes/AtcCodesSection'
import { BasicDocumentsSection } from './sequence/BasicDocumentsSection'
import { ClassificationSection } from './sequence/ClassificationSection'
import { CompositionListSection } from './sequence/CompositionList/CompositionListSection'
import { ContraindicationSection } from './sequence/ContraindicationSection'
import { DispensarySection } from './sequence/DispensarySection'
import { DistributionSection } from './sequence/DistributionSection'
import { DivisibilitySection } from './sequence/DivisibilitySection'
import { DocumentsSection } from './sequence/DocumentsSection'
import { DosageFormSection } from './sequence/DosageFormSection'
import { DosageSection } from './sequence/DosageSection'
import { FieldOfApplicationSection } from './sequence/FieldOfApplicationSection'
import { GenericGroupsSection } from './sequence/GenericProductTableSection'
import { HabituationEffectSection } from './sequence/HabituationEffectSection'
import { IndicationGroupsSection } from './sequence/IndicationGroups/IndicationGroupsSection'
import { IntakeAdvisorySection } from './sequence/IntakeAdvisorySection'
import { InteractionSection } from './sequence/InteractionSection'
import { IntAtcCodesIntSection } from './sequence/International/IntAtcCodesIntSection'
import { IntClassificationSection } from './sequence/International/IntClassificationSection'
import { IntCompositionSection } from './sequence/International/IntCompositionSection'
import { IntDispensarySection } from './sequence/International/IntDispensarySection'
import { IntDistributorSection } from './sequence/International/IntDistributorSection'
import { IntDocumentsSection } from './sequence/International/IntDocumentsSection'
import { IntDosageAdvisorySection } from './sequence/International/IntDosageAdvisorySection'
import { IntDosageFormSection } from './sequence/International/IntDosageFormSection'
import { IntGenericGroupAtSection } from './sequence/International/IntGenericGroupAtSection'
import { IntGenericGroupIntSection } from './sequence/International/IntGenericGroupIntSection'
import { IntIndicationGroupDeSection } from './sequence/International/IntIndicationGroupsDeSection'
import { IntIntakeAdvisorySection } from './sequence/International/IntIntakeAdvisorySection'
import { IntProductSafetySection } from './sequence/International/IntProductSafetySection'
import { IntProviderOtherProductsSection } from './sequence/International/IntProviderOtherProductsSection'
import { IntProviderSection } from './sequence/International/IntProviderSection'
import { IntSameProductsByNameSection } from './sequence/International/IntSameProductsByNameSection'
import { IntStorageSection } from './sequence/International/IntStorageSection'
import { IntSubstanceSafetySection } from './sequence/International/IntSubstanceSafetySection'
import { IntoleranceSection } from './sequence/IntolerancesSection'
import { ManufacturerSection } from './sequence/ManufacturerSection'
import { MarketingAuthorisationHolderSection } from './sequence/MarketingAuthorisationHolderSection'
import { PregnancySection } from './sequence/PregnancySection'
import { ProbeCompatibilitySection } from './sequence/ProbeCompatibilitySection'
import { ProductAlternativesTeaserSection } from './sequence/ProductAlternativesTeaserSection'
import { ProductApplicationTeaserSection } from './sequence/ProductApplicationTeaserSection'
import { ProductRetailTeaserSection } from './sequence/ProductRetailTeaserSection'
import { ProductSafetyTeaserSection } from './sequence/ProductSafetyTeaserSection'
import { ProductsContainingAgentSection } from './sequence/ProductsContainingAgentSection'
import { ProductsSameAgentSection } from './sequence/ProductsSameAgentSection'
import { ReferenceSection } from './sequence/ReferenceSection'
import { RouteOfAdministrationMatrixSection } from './sequence/RouteOfAdministrationMatrix/ApplicationHitsSection'
import { RouteOfAdministrationSection } from './sequence/RouteOfAdministrationSection'
import { SameProductsByNameSection } from './sequence/SameProductsByNameSection'
import { SideEffectsSection } from './sequence/SideEffectsSection'
import { StorageSection } from './sequence/StorageSection'
import { SubstanceAlternativesTeaserSection } from './sequence/SubstanceAlternativesTeaserSection'
import { SubstanceApplicationTeaserSection } from './sequence/SubstanceApplicationTeaserSection'
import { SubstanceRetailTeaserSection } from './sequence/SubstanceRetailTeaserSection'
import { SubstanceSafetyTeaserSection } from './sequence/SubstanceSafetyTeaserSection'
import { VeterinaryWaitingPeriodSection } from './sequence/VeterinaryWaitingPeriod'
import { VigilanceSection } from './sequence/VigilanceSection'

type FeatureComponent = (props: FeatureProps) => EmotionJSX.Element | null
type NoneFeatureComponent = () => EmotionJSX.Element | null

export const sections: {
  [key in FeatureEnum]?: FeatureComponent | NoneFeatureComponent
} = {
  [FeatureEnum.BasicDocuments]: BasicDocumentsSection,
  [FeatureEnum.Documents]: DocumentsSection,
  [FeatureEnum.ProductsSameAgent]: ProductsSameAgentSection,
  [FeatureEnum.RouteOfAdministrationMatrix]: RouteOfAdministrationMatrixSection,
  [FeatureEnum.ClassificationSymbols]: ClassificationSection,
  [FeatureEnum.DosageForm]: DosageFormSection,
  [FeatureEnum.RouteOfAdministration]: RouteOfAdministrationSection,
  [FeatureEnum.HabituationEffect]: HabituationEffectSection,
  [FeatureEnum.CompositionList]: CompositionListSection,
  [FeatureEnum.FieldOfApplication]: FieldOfApplicationSection,
  [FeatureEnum.Dosage]: DosageSection,
  [FeatureEnum.IntakeAdvisory]: IntakeAdvisorySection,
  [FeatureEnum.Divisibility]: DivisibilitySection,
  [FeatureEnum.ProbeCompatibility]: ProbeCompatibilitySection,
  [FeatureEnum.ProductApplicationTeaser]: ProductApplicationTeaserSection,
  [FeatureEnum.SubstanceApplicationTeaser]: SubstanceApplicationTeaserSection,
  [FeatureEnum.Vigilance]: VigilanceSection,
  [FeatureEnum.Contraindication]: ContraindicationSection,
  [FeatureEnum.Pregnancy]: PregnancySection,
  [FeatureEnum.SideEffects]: SideEffectsSection,
  [FeatureEnum.Interaction]: InteractionSection,
  [FeatureEnum.Advisory]: AdvisorySection,
  [FeatureEnum.VeterinaryWaitingPeriod]: VeterinaryWaitingPeriodSection,
  [FeatureEnum.Allergies]: AllergySection,
  [FeatureEnum.Intolerances]: IntoleranceSection,
  [FeatureEnum.ProductSafetyTeaser]: ProductSafetyTeaserSection,
  [FeatureEnum.SubstanceSafetyTeaser]: SubstanceSafetyTeaserSection,
  [FeatureEnum.Dispensary]: DispensarySection,
  [FeatureEnum.Storage]: StorageSection,
  [FeatureEnum.Articles]: ArticlesSection,
  [FeatureEnum.ArticleMatrix]: ArticleMatrixSection,
  [FeatureEnum.Distribution]: DistributionSection,
  [FeatureEnum.MarketingAuthorisationHolder]:
    MarketingAuthorisationHolderSection,
  [FeatureEnum.Manufacturer]: ManufacturerSection,
  [FeatureEnum.ProductRetailTeaser]: ProductRetailTeaserSection,
  [FeatureEnum.SubstanceRetailTeaser]: SubstanceRetailTeaserSection,
  [FeatureEnum.GenericGroupProducts]: GenericGroupsSection,
  [FeatureEnum.ATCCodes]: AtcCodesSection,
  [FeatureEnum.IndicationGroups]: IndicationGroupsSection,
  [FeatureEnum.ReferenceProducts]: ReferenceSection,
  [FeatureEnum.ProductsContainingAgent]: ProductsContainingAgentSection,
  [FeatureEnum.ProductTeaserAlternatives]: ProductAlternativesTeaserSection,
  [FeatureEnum.SubstanceAlternativesTeaser]: SubstanceAlternativesTeaserSection,
  [FeatureEnum.SameProductsByName]: SameProductsByNameSection,
  [FeatureEnum.IntDocuments]: IntDocumentsSection,
  [FeatureEnum.IntClassification]: IntClassificationSection,
  [FeatureEnum.IntDosageForm]: IntDosageFormSection,
  [FeatureEnum.IntComposition]: IntCompositionSection,
  [FeatureEnum.IntIntakeAdvisory]: IntIntakeAdvisorySection,
  [FeatureEnum.IntDosageAdvisory]: IntDosageAdvisorySection,
  [FeatureEnum.IntProvider]: IntProviderSection,
  [FeatureEnum.IntProviderOtherProducts]: IntProviderOtherProductsSection,
  [FeatureEnum.IntDistributor]: IntDistributorSection,
  [FeatureEnum.IntSameProductsByName]: IntSameProductsByNameSection,
  [FeatureEnum.IntDispensary]: IntDispensarySection,
  [FeatureEnum.IntStorage]: IntStorageSection,
  [FeatureEnum.IntAtcCodes]: IntAtcCodesIntSection,
  [FeatureEnum.IntGenericGroupAtProducts]: IntGenericGroupAtSection,
  [FeatureEnum.IntGenericGroupIntProducts]: IntGenericGroupIntSection,
  [FeatureEnum.IntIndicationGroupDe]: IntIndicationGroupDeSection,
  [FeatureEnum.IntProductSafety]: IntProductSafetySection,
  [FeatureEnum.IntSubstanceSafety]: IntSubstanceSafetySection,
}
