import { EInteractionSeverity } from '@austria-codex/types'

export const commonTheme = {
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      dark: '#52a472',
      main: '#3fa535',
      light: '#ECF6EB',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff4081',
      main: '#f50057',
      dark: '#c51162',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      light: '#bfdcf5',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    highlight: {
      main: '#ad44ab',
      light: 'rgba(173, 68, 171, 0.075)',
    },
    grey: {
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      activatedOpacity: 0.7,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.5,
      focus: 'rgba(0, 0, 0, 0.04)',
      focusOpacity: 0.5,
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
    },
    product: {
      main: '#F7D959',
      dark: '#EDAB46',
    },
    substance: {
      main: '#2667b3',
      dark: '#3a587c',
    },
    personalCharacteristic: {
      main: '#dfb4de',
      dark: '#ad44ab',
    },
    reimbursementBox: {
      G: {
        main: '#3FA535',
        light: '#d9edd7',
      },
      Y: {
        main: '#EDAB46',
        light: '#fdf7de',
      },
      R: {
        main: '#D0021B',
        light: '#f6ccd1',
      },
      N: {
        main: '#ACB3B2',
        light: '#dee1e0',
      },
    },
    safety: {
      grey: '#ACB3B2',
      green: '#3FA535',
      yellow: '#FF9800',
      red: '#E20714',
    },
    interactionSeverity: {
      [EInteractionSeverity.NoStatementPossible]: '#888888', // Keine Aussage möglich
      [EInteractionSeverity.NoInteraction]: '#0000ff', // Keine Interaktion zu erwarten
      [EInteractionSeverity.ProductSpecificWarning]: '#6c3b2a', // Produktspezifische Warnmeldung
      [EInteractionSeverity.Minor]: '#fadc0a', // Gering
      [EInteractionSeverity.Moderate]: '#ff8800', // Mittelschwer
      [EInteractionSeverity.Severe]: '#ff0000', // Schwerwiegend
      [EInteractionSeverity.Contraindicated]: '#c80000', // Kontraindiziert
    },
  },
}
