import type { EntityDataUnion } from '@austria-codex/types'
import {
  isFromCountry,
  isProduct,
  isProductInternational,
  isSubstance,
  isSubstanceInternational,
} from '@austria-codex/utilities'
import { Box } from '@mui/material'
import type { ReactNode } from 'react'
import type { ModuleEntityTypes, ModulesTypes } from '../../types/bundle'
import { EntityCollapse } from '../EntityCollapse/EntityCollapse'
import { SectionFeatures } from '../Features/SectionFeatures'
import { FilteredEntities } from '../FilteredEntities/FilteredEntities'
import { Content } from '../Layout/Content'

type ModuleSectionsProps = {
  module: ModulesTypes
  metaRenderer: (entity: EntityDataUnion) => ReactNode
  shouldRenderEntityType: (entityType: ModuleEntityTypes) => boolean
}

export function ModuleSections({
  module,
  metaRenderer,
  shouldRenderEntityType,
}: ModuleSectionsProps) {
  function shouldRenderEntity(entity: EntityDataUnion) {
    if (isProduct(entity)) {
      return shouldRenderEntityType('product')
    }

    if (isProductInternational(entity)) {
      return shouldRenderEntityType('product')
    }

    if (isSubstance(entity)) {
      return shouldRenderEntityType('substance')
    }

    if (isSubstanceInternational(entity)) {
      return shouldRenderEntityType('substance')
    }

    return false
  }

  return (
    <FilteredEntities>
      {(entities) => (
        <>
          {entities.map(
            (entity) =>
              shouldRenderEntity(entity) && (
                <EntityCollapse
                  key={entity.id}
                  entity={entity}
                  module={module}
                  metaRender={metaRenderer}
                >
                  {isProduct(entity) ? (
                    <SectionFeatures module={module} entityType="product" />
                  ) : isProductInternational(entity) ? (
                    <>
                      {/* We only can show safety stuff for DE products */}
                      {module === 'Safety' && !isFromCountry(entity, 'de') ? (
                        <Content width="small">
                          <Box sx={{ padding: '3rem 0' }}>
                            Keine Sicherheitsinformationen für diese Auswahl
                            vorhanden.
                          </Box>
                        </Content>
                      ) : (
                        <SectionFeatures
                          module={module}
                          entityType="product-international"
                        />
                      )}
                    </>
                  ) : isSubstance(entity) ? (
                    <SectionFeatures
                      module={module}
                      entityType="substance"
                      fallbackContent={
                        <Content width="small">
                          <Box sx={{ padding: '3rem 0' }}>
                            Keine Sicherheitsinformationen für diese Auswahl
                            vorhanden.
                          </Box>
                        </Content>
                      }
                    />
                  ) : (
                    <SectionFeatures
                      module={module}
                      entityType="substance-international"
                    />
                  )}
                </EntityCollapse>
              )
          )}
        </>
      )}
    </FilteredEntities>
  )
}
