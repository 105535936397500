import { useState } from 'react'
import styled from '@emotion/styled'
import { ReportDialog } from '../ReportDialog/ReportDialog'

export function Feedback() {
  const [open, setOpen] = useState(false)

  return (
    <>
      <FeedbackButton onClick={() => setOpen(true)}>Feedback</FeedbackButton>
      <ReportDialog open={open} onClose={() => setOpen(false)} />
    </>
  )
}

const FeedbackButton = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  font-size: inherit;
  font-weight: inherit;
  color: ${(props) => props.theme.palette.primary.main};
  text-decoration: underline;
  cursor: pointer;
`
