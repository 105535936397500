import { FeatureEnum, type EntityAtcCodeHitsData } from '@austria-codex/types'
import {
  isHumanAtcCode,
  isProductInternational,
} from '@austria-codex/utilities'
import { Box } from '@mui/material'
import { Fragment, useMemo } from 'react'
import { AtcCodeBlock } from '../../../components/Codes/AtcCodeBlock'
import { CodeGroup } from '../../../components/Codes/CodesGroup'
import { Section } from '../../../components/Section/Section'
import { useEntityDataInt } from '../../../contexts/entity'
import { useAppSelector } from '../../../hooks/useStoreHooks'
import { FeatureProps } from '../../../types/bundle'
import { isEmpty } from '../../../utilities/common'
import { IntAtcCodeDomainGroup } from './AtcCodes/IntAtcCodeDomainGroup'
import { IntAtcCodeProductsTable } from './AtcCodes/IntAtcCodeProductsTable'

type OrderedEntityClassificationAtcCodeInterface = {
  human: EntityAtcCodeHitsData[]
  veterinary: EntityAtcCodeHitsData[]
}

export function IntAtcCodesIntSection({ features }: FeatureProps) {
  const entityInt = useEntityDataInt()
  const hasProducts = features.includes(FeatureEnum.ATCCodesProducts)
  const includeVeterinary = useAppSelector(
    (state) => state.settings.includeVeterinary
  )

  const orderedAtcCodes = useMemo(() => {
    const atcCodes = isProductInternational(entityInt)
      ? (entityInt.klassifikationInt?.atcCodes ?? [])
      : (entityInt.klassifikationSubstanceInt.atcCodes ?? [])

    return atcCodes.map((atcCodes) => {
      const group = {
        human: [],
        veterinary: [],
      } as OrderedEntityClassificationAtcCodeInterface

      atcCodes.forEach((atcCode) => {
        if (isHumanAtcCode(atcCode.atcCode.id)) {
          group.human.push(atcCode)
        } else {
          group.veterinary.push(atcCode)
        }
      })

      return group
    })
  }, [entityInt])

  if (orderedAtcCodes.length === 0) {
    return null
  }

  return (
    <Section heading="Anatomisch-Therapeutisch-Chemische Gruppe" width="small">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
        {orderedAtcCodes.map((atcCodes, index) => {
          return (
            (!isEmpty(atcCodes.human) || !isEmpty(atcCodes.veterinary)) && (
              <Fragment key={index}>
                {!isEmpty(atcCodes.human) && (
                  <CodeGroup>
                    <IntAtcCodeDomainGroup isVeterinary={false}>
                      <AtcCodeList
                        section={entityInt.id}
                        atcCodes={atcCodes.human}
                        hasToggle={hasProducts}
                      />
                    </IntAtcCodeDomainGroup>
                  </CodeGroup>
                )}
                {includeVeterinary && !isEmpty(atcCodes.veterinary) && (
                  <CodeGroup>
                    <IntAtcCodeDomainGroup isVeterinary={true}>
                      <AtcCodeList
                        section={entityInt.id}
                        atcCodes={atcCodes.veterinary}
                        hasToggle={hasProducts}
                      />
                    </IntAtcCodeDomainGroup>
                  </CodeGroup>
                )}
              </Fragment>
            )
          )
        })}
      </Box>
    </Section>
  )
}

type AtcCodeListProps = {
  atcCodes: EntityAtcCodeHitsData[]
  section: string
  hasToggle: boolean
}

function AtcCodeList({ atcCodes, section, hasToggle }: AtcCodeListProps) {
  return (
    <>
      {atcCodes.map(
        ({ atcCode: { id, bezeichnung }, bezeichnung: caption }) => (
          <Box my={3} key={id}>
            <AtcCodeBlock
              code={id}
              title={bezeichnung}
              caption={caption}
              section={section}
              group="alternatives"
              identifier={id}
              hasToggle={hasToggle}
            >
              {(onToggle) =>
                hasToggle && (
                  <Box mt={2}>
                    <IntAtcCodeProductsTable
                      title={bezeichnung}
                      atcCode={id}
                      onClose={onToggle}
                    />
                  </Box>
                )
              }
            </AtcCodeBlock>
          </Box>
        )
      )}
    </>
  )
}
