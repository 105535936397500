import { ProductIntoleranceData } from '@austria-codex/types'
import { useMemo } from 'react'
import HistamineIcon from '../../assets/images/intolerances/histamin.svg?react'
import SugarIcon from '../../assets/images/intolerances/sugar.svg?react'
import { IconTextBlock } from '../../components/IconTextBlock/IconTextBlock'
import { Section } from '../../components/Section/Section'
import { useProductData } from '../../contexts/entity'
import { useAppSelector } from '../../hooks/useStoreHooks'
import { createPersonalCharacteristicsInHighlightsSelector } from '../../store/personalCharacteristics'
import { PersonalCharacteristicsEnum } from '../../types/highlight'

export function IntoleranceSection() {
  const product = useProductData()
  const personalCharacteristicsInHighlightsSelector = useMemo(
    createPersonalCharacteristicsInHighlightsSelector,
    []
  )
  const highlight = useAppSelector((state) =>
    personalCharacteristicsInHighlightsSelector(
      state,
      product,
      intoleranceHighlightOption
    )
  )

  return hasIntoleranceInformation(product.intoleranzen) ? (
    <Section width="small" heading="Intoleranzen" highlight={highlight}>
      {product.intoleranzen?.histaminIntoleranz === 1 && (
        <IconTextBlock Icon={<HistamineIcon />}>Histamin</IconTextBlock>
      )}
      {product.intoleranzen?.galactoseIntoleranz === 1 && (
        <IconTextBlock Icon={<SugarIcon />}>Galactoseintoleranz</IconTextBlock>
      )}
      {product.intoleranzen?.lactoseIntoleranz === 1 && (
        <IconTextBlock Icon={<SugarIcon />}>Lactoseintoleranz</IconTextBlock>
      )}
      {product.intoleranzen?.saccharoseIntoleranz === 1 && (
        <IconTextBlock Icon={<SugarIcon />}>Saccharoseintoleranz</IconTextBlock>
      )}
      {product.intoleranzen?.glucoseGalactoseMalabs === 1 && (
        <IconTextBlock Icon={<SugarIcon />}>
          Glucose-Galactose-Malabsorption
        </IconTextBlock>
      )}
      {product.intoleranzen?.fructoseIntoleranz === 1 && (
        <IconTextBlock Icon={<SugarIcon />}>Fructoseintoleranz</IconTextBlock>
      )}
    </Section>
  ) : null
}

const hasIntoleranceInformation = (
  intolerance: ProductIntoleranceData | undefined
) =>
  intolerance?.histaminIntoleranz ||
  intolerance?.galactoseIntoleranz ||
  intolerance?.lactoseIntoleranz ||
  intolerance?.saccharoseIntoleranz ||
  intolerance?.glucoseGalactoseMalabs ||
  intolerance?.fructoseIntoleranz

const intoleranceHighlightOption = [
  PersonalCharacteristicsEnum.HISTAMINE_INTOLERANCE,
  PersonalCharacteristicsEnum.SUGAR_INTOLERANCE,
]
