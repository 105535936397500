import { css, useTheme } from '@emotion/react'
import { Box } from '@mui/material'
import { Content } from './Layout/Content'
import { Text } from './Layout/Typography'

export function AvailabilityHintBanner() {
  const theme = useTheme()

  return (
    <Box sx={{ pt: 1, pb: 2, background: theme.palette.info.light }}>
      <Content
        width="large"
        css={css`
          text-align: center;
        `}
      >
        <Text fontWeight={700} fontSize={12}>
          Hinweis:
        </Text>{' '}
        <Text fontSize={12}>
          Dieser Abschnitt berücksichtigt ausschließlich zugelassene
          Arzneispezialitäten, die im Handel befindlich sind (inkl.
          Lieferbarkeitsstatus).
        </Text>
      </Content>
    </Box>
  )
}
