import { CompositionIntType } from '@austria-codex/types'
import { makeStyles } from 'tss-react/mui'
import { CollapseIcon } from '../../../components/Icons/CollapseIcon'
import { Text } from '../../../components/Layout/Typography'
import { Section } from '../../../components/Section/Section'
import { Table } from '../../../components/Table/Table'
import { TableCell } from '../../../components/Table/TableCell'
import { UnstyledButton } from '../../../components/UnstyledButton'
import { useProductIntData } from '../../../contexts/entity'
import { hasHilfsStoffe } from '../../../helpers/product-international.helper'
import { useCollapse } from '../../../hooks/useCollapse'
import { IntCompositionRow } from './Composition/IntCompositionRow'

const useStyles = makeStyles()(() => ({
  table: {
    borderCollapse: 'collapse',
    border: 'none',
    marginTop: '0.5rem',
    width: '100%',
  },
}))

export function IntCompositionSection() {
  const product = useProductIntData()
  const { classes } = useStyles()

  const [collapse, onToggle] = useCollapse({
    section: product.id,
    group: 'int-composition',
  })

  const zusammensetzungen = product.zusammensetzungInt ?? []
  if (zusammensetzungen.length === 0) {
    return null
  }

  const showCompName = zusammensetzungen.length > 1
  const showMoreBtn = hasHilfsStoffe(zusammensetzungen)

  return (
    <Section width="small" heading="Zusammensetzung">
      {zusammensetzungen.map((z, index) => {
        const bezug = z.bezuege[0]
        const eintraege = z.eintraege ?? []

        return (
          <Table
            key={index}
            display={['flex', 'table']}
            flexDirection="column"
            className={classes.table}
          >
            <thead>
              {showCompName && (
                <tr>
                  <TableCell colSpan={3}>
                    <Text fontSize="1.1rem" fontWeight={700}>
                      {z.komponentenName ?? `Komponente ${index + 1}`}
                    </Text>
                  </TableCell>
                </tr>
              )}
              <tr>
                <TableCell colSpan={3}>
                  <Text fontSize="1.1rem">
                    {bezug?.menge} {bezug?.einheit}{' '}
                    {bezug?.menge === 1 ? 'enthält' : 'enthalten'}{' '}
                  </Text>
                </TableCell>
              </tr>
            </thead>
            <tbody>
              {eintraege.map(
                (entry) =>
                  (entry.eintragsTyp === CompositionIntType.Wirkstoff ||
                    collapse) && (
                    <IntCompositionRow key={entry.abdaStoffId} entry={entry} />
                  )
              )}
            </tbody>
          </Table>
        )
      })}
      {showMoreBtn ? (
        <UnstyledButton role="button" onClick={() => onToggle()}>
          <Text>{collapse ? 'Weniger' : 'Mehr'} anzeigen</Text>
          <CollapseIcon component="span" collapse={collapse} />
        </UnstyledButton>
      ) : null}
    </Section>
  )
}
