const paragraphs = {
  intake: [
    'Keine Angabe',
    'Einnahme nüchtern: Einnahme auf leeren Magen nach einer nächtlichen Nüchternperiode (von mindestens 6 Stunden), mindestens 30 bis 60 Minuten vor der ersten Einnahme von Nahrung. Bei Einnahme untertags mindestens 2 bis 3 Stunden nach der Mahlzeit (gilt meistens für Arzneispezialitäten zur mehrmaligen täglichen Einnahme).',
    'Einnahme vor der Mahlzeit: Einnahme mindestens 30 bis 60 Minuten vor der Nahrungsaufnahme. Die Anwendung der Arzneispezialität soll zeitlich von einer Nahrungsaufnahme getrennt erfolgen.',
    'Einnahme zu der Mahlzeit: Einnahme während, unmittelbar bzw. kurz vor oder nach (0 bis 15 Minuten) der Nahrungsaufnahme.',
    'Einnahme nach der Mahlzeit: Einnahme mindestens 1 bis 2 Stunden nach der Nahrungsaufnahme. Die Anwendung der Arzneispezialität soll zeitlich von einer Nahrungsaufnahme getrennt erfolgen.',
    'Einnahme unversehrt: Die Arzneispezialität darf nicht gekaut, gelutscht oder zerdrückt werden.',
  ],
  dissolving: [
    'Keine Angabe',
    'Muss vor Verabreichung aufgelöst werden.',
    'Muss vor Verabreichung aseptisch aufgelöst werden.',
  ],
  preparation: [
    'Keine Angabe',
    'Vor Verabreichung ist eine Zubereitung erforderlich.',
    'Vor Verabreichung kann eine Zubereitung erforderlich sein.',
  ],
} as const

type ParagraphsKeyType = keyof typeof paragraphs

export const getParagraph = (
  key: ParagraphsKeyType,
  value: number,
  defaultValue: string | null = null
) => {
  return paragraphs[key][value] ?? defaultValue
}
