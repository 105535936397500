import { Heading } from './RenderElements/Heading'
import { Icon } from './RenderElements/Icon'
import { Image } from './RenderElements/Image'
import { OrderedList } from './RenderElements/OrderedList'
import { Paragraph } from './RenderElements/Paragraph'
import { UnorderedList } from './RenderElements/UnorderedList'
import {
  isHeading1,
  isHeading2,
  isHeading3,
  isIcon,
  isImage,
  isOrderedList,
  isParagraph,
  isUnorderedList,
  RenderElementType,
} from './types'

type RenderElementProps = {
  element: RenderElementType
}

export function RenderElement({ element }: RenderElementProps) {
  if (isHeading1(element)) {
    return (
      <Heading
        children={element.children}
        alignment={element.alignment}
        variant="h4"
      />
    )
  } else if (isHeading2(element)) {
    return (
      <Heading
        children={element.children}
        alignment={element.alignment}
        variant="h5"
      />
    )
  } else if (isHeading3(element)) {
    return (
      <Heading
        children={element.children}
        alignment={element.alignment}
        variant="h6"
      />
    )
  } else if (isIcon(element)) {
    return <Icon children={element.children} id={element.icon} />
  } else if (isImage(element)) {
    return <Image children={element.children} url={element.url} />
  } else if (isParagraph(element)) {
    return (
      <Paragraph children={element.children} alignment={element.alignment} />
    )
  } else if (isOrderedList(element)) {
    return (
      <OrderedList children={element.children} alignment={element.alignment} />
    )
  } else if (isUnorderedList(element)) {
    return (
      <UnorderedList
        children={element.children}
        alignment={element.alignment}
      />
    )
  }

  return null
}
