export const PRODUCT_INTERNATIONAL_FRAGMENT = `
  fragment ProductInternationalFragment on ProductInternationalType {
    id
    bezeichnung
    acoSearchTerm
    isoCode
    abgabeBestimmung
    istVeterinaer
    zusammensetzungInt {
      komponentenName
      bezuege {
        menge
        einheit
        istAbsolut
      }
      eintraege {
        abdaStoffId
        id
        basisStoff {
          id
          oeavStoffId
          bezeichnung
        }
        laufNummer
        eintragsTyp
        bezLaufnummer
        bezeichnung
        menge
        einheit
        oeavStoffId
        oeavBasisStoffId
      }
    }
    haltbarkeiten {
      lagerungsOrt
      haltbarkeitsDauer
      haltbarkeitsEinheit
    }
    kurzinfos {
      abschnittsInhalt
      text
    }
    kontakteInt {
      anbieter {
        kurzname
        langname
        ort
        plz
        strasse
        telefon
        telefax
        isoCode
      }
      mitvertreiber {
        kurzname
        langname
        ort
        plz
        strasse
        telefon
        telefax
        isoCode
      }
    }
    klassifikationInt {
      arzneiform
      istMonopraeparat
      applikationsarten {
        bezeichnung
        appAnwGrpCode
        appMethode
      }
      atcCodes {
        atcCode {
          id
          bezeichnung
        }
      }
      indikationsGruppen {
        indCode
        indNiveau
        bezeichnung
      }
      therapieGruppe {
        gruppenId
        bezeichnung
      }
    }
    alternativenInt {
      bezugsKatalog
      produkte {
        produktIds
        verknBewertung
      }
    }
    anwWarnHinweise {
      zusUeberwachung
      lichtSchutz
      feuchtSchutz
      sofortVerbrauch
      einmaligeAnwendung
      hinweise {
        abdaTyp
        hinweisTyp
        hinweis
      }
    }
  }
`
