import { type FunctionComponent, type ReactElement } from 'react'
import AlcoholYellowIcon from '../../assets/images/warnings/ic-alkohol-yellow.svg?react'
import DiabetesYellowIcon from '../../assets/images/warnings/ic-diabetiker-yellow.svg?react'
import RoadworthyYellowIcon from '../../assets/images/warnings/ic-driving-yellow.svg?react'
import SportRedIcon from '../../assets/images/warnings/ic-kadersport-red.svg?react'
import SportYellowIcon from '../../assets/images/warnings/ic-kadersport-yellow.svg?react'
import ChildrenBlueIcon from '../../assets/images/warnings/ic-kinderform-blue.svg?react'
import PregnancyBlueIcon from '../../assets/images/warnings/ic-pregnant-blue.svg?react'
import PregnancyGreyIcon from '../../assets/images/warnings/ic-pregnant-grey.svg?react'
import PregnancyRedIcon from '../../assets/images/warnings/ic-pregnant-red.svg?react'
import PregnancyYellowIcon from '../../assets/images/warnings/ic-pregnant-yellow.svg?react'
import SunlightYellowIcon from '../../assets/images/warnings/ic-sonnenlicht-yellow.svg?react'
import LactationBlueIcon from '../../assets/images/warnings/ic-stillzeit-blue.svg?react'
import LactationGreyIcon from '../../assets/images/warnings/ic-stillzeit-grey.svg?react'
import LactationRedIcon from '../../assets/images/warnings/ic-stillzeit-red.svg?react'
import LactationYellowIcon from '../../assets/images/warnings/ic-stillzeit-yellow.svg?react'
import MonitoringYellowIcon from '../../assets/images/warnings/ic-zusaetzliche-ueberwachung-yellow.svg?react'

type WarningLevelType = 'grey' | 'blue' | 'yellow' | 'red'

const createWarningLevel = (mapping: Record<number, WarningLevelType>) => {
  return (level: number): WarningLevelType => {
    return mapping[level] ?? 'grey'
  }
}

type WarningNoticeIconMapping = {
  [key: string]: ReactElement | null
  [key: number]: ReactElement | null
}

interface WarningNoticeIconProps<T> {
  level: T
}

function createWarningIcon<T extends WarningNoticeIconMapping>(
  mapping: T
): FunctionComponent<WarningNoticeIconProps<keyof T | number>> {
  return ({ level }) => mapping[level] ?? null
}

export const AlcoholWarningIcon = createWarningIcon({
  0: null,
  1: <AlcoholYellowIcon />,
})

export const alcoholWarningLevel = createWarningLevel({
  0: 'grey',
  1: 'yellow',
})

export const DiabeticWarningIcon = createWarningIcon({
  0: null,
  1: <DiabetesYellowIcon />,
})

export const diabeticWarningLevel = createWarningLevel({
  0: 'grey',
  1: 'yellow',
})

export const PregnancyWarningIcon = createWarningIcon({
  0: <PregnancyGreyIcon />,
  1: <PregnancyGreyIcon />,
  2: <PregnancyBlueIcon />,
  3: <PregnancyRedIcon />,
  4: <PregnancyYellowIcon />,
  5: <PregnancyBlueIcon />,
})

export const pregnancyWarningLevel = createWarningLevel({
  0: 'grey',
  1: 'grey',
  2: 'blue',
  3: 'red',
  4: 'yellow',
  5: 'blue',
})

export const LactationWarningIcon = createWarningIcon({
  0: <LactationGreyIcon />,
  1: <LactationGreyIcon />,
  2: <LactationBlueIcon />,
  3: <LactationRedIcon />,
  4: <LactationYellowIcon />,
  5: <LactationBlueIcon />,
})

export const lactationWarningLevel = createWarningLevel({
  0: 'grey',
  1: 'grey',
  2: 'blue',
  3: 'red',
  4: 'yellow',
  5: 'blue',
})

export const ChildrenWarningIcon = createWarningIcon({
  0: null,
  1: <ChildrenBlueIcon />,
})

export const childrenWarningLevel = createWarningLevel({
  0: 'grey',
  1: 'blue',
})

export const SportWarningIcon = createWarningIcon({
  0: null,
  1: <SportRedIcon />,
  2: <SportYellowIcon />,
})

export const sportWarningLevel = createWarningLevel({
  0: 'grey',
  1: 'red',
  2: 'yellow',
})

export const SunLightWarningIcon = createWarningIcon({
  0: null,
  1: <SunlightYellowIcon />,
})

export const sunLightWarningLevel = createWarningLevel({
  0: 'grey',
  1: 'yellow',
})

export const RoadworthyWarningIcon = createWarningIcon({
  0: null,
  1: <RoadworthyYellowIcon />,
})

export const roadworthyWarningLevel = createWarningLevel({
  0: 'grey',
  1: 'yellow',
})

export const MonitoringWarningIcon = createWarningIcon({
  0: null,
  1: <MonitoringYellowIcon />,
})

export const monitoringWarningLevel = createWarningLevel({
  0: 'grey',
  1: 'yellow',
})
