import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Flex } from '../Layout/Box'

type PillProps = {
  type: 'substance' | 'product' | 'personalCharacteristic'
  highlight?: boolean
}

export const Pill = styled(Flex)<PillProps>`
  background: ${(props) => props.theme.palette[props.type]?.main};
  border: 1px solid ${(props) => props.theme.palette[props.type]?.dark};
  display: flex;
  transition: box-shadow 0.2s;

  ${(props) =>
    props.highlight &&
    css`
      box-shadow: 0 0 5px ${props.theme.palette[props.type]?.dark};
    `};
`

Pill.defaultProps = {
  p: 2,
  borderRadius: 5,
  justifyContent: 'center',
  alignItems: 'center',
}

export const PillAddOn = styled(Flex)`
  flex-shrink: 0;
  width: 24px;
`

PillAddOn.defaultProps = {
  justifyContent: 'center',
  alignItems: 'center',
}

export const PillIcon = styled.div`
  height: 24px;
  width: 24px;
`

export const PillButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
