import type { ReactNode } from 'react'
import { Box, Flex } from '../Layout/Box'
import { Text } from '../Layout/Typography'

type EmptyMessageProps = {
  children: ReactNode
  Icon?: ReactNode
}

export function EmptyMessage({ children, Icon }: EmptyMessageProps) {
  return (
    <Box my={4}>
      <Flex alignItems="center">
        {Icon && <Box mr={3}>{Icon}</Box>}
        <Text fontStyle="italic">{children}</Text>
      </Flex>
    </Box>
  )
}
