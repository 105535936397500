import styled from '@emotion/styled'

export const Avatar = styled.img`
  display: inline-flex;
  position: relative;
  width: 40px;
  height: 40px;
  border: 1px solid ${(props) => props.theme.palette.primary.light};
  border-radius: 50%;
  overflow: hidden;
`
