import { FilterModeTypesEnum, type ProductsFilter } from '@austria-codex/types'
import { AGENTS_QUERY } from '../../../api/agents'
import {
  AGENTS_TABLE_CONFIG,
  AGENTS_TABLE_CONFIG_INT,
} from '../../../common/tableConfig/agents'
import { ProductsDataSourceTable } from '../../../components/PaginatedDataTable/ProductsDataSourceTable'
import { useAppSelector } from '../../../hooks/useStoreHooks'

type IndicationGroupProductsTableProps = {
  indicationGroup: string
  name: string
  heading?: string
  subheading?: string
  onClose: () => void
}

export function IndicationGroupProductsTable({
  indicationGroup,
  onClose,
  name,
  heading,
  subheading,
}: IndicationGroupProductsTableProps) {
  const mode = useAppSelector((state) => state.user.mode)
  const filter: ProductsFilter = {
    indicationGroup: {
      value: [indicationGroup],
      mode: FilterModeTypesEnum.HIERARCHY,
    },
    isoCodes: ['at'],
  }

  const tableConf =
    mode === 'national' ? AGENTS_TABLE_CONFIG : AGENTS_TABLE_CONFIG_INT

  return (
    <ProductsDataSourceTable
      filter={filter}
      name={name}
      heading={heading}
      subheading={subheading}
      config={tableConf}
      query={AGENTS_QUERY}
      onClose={onClose}
    />
  )
}
