import Icon from '@mui/icons-material/KeyboardArrowDownRounded'
import IconButton from '@mui/material/IconButton'
import classNames from 'classnames'
import { ElementType } from 'react'
import { makeStyles } from 'tss-react/mui'
import { StylingProps } from '../../types/components'

const useStyles = makeStyles()(() => ({
  icon: {
    transition: 'transform 300ms',
  },
  open: {
    transform: 'rotate(180deg)',
  },
  small: {
    padding: 0,
    '&:hover': {
      background: 'none',
    },
  },
  large: {
    padding: 8,
    background: '#fafafa',
    transition: 'background-color 0.3s',
  },
}))

interface CompoundIconProps {
  collapse: boolean
  onToggle?: () => void
  variant?: 'small' | 'large'
  component?: ElementType
}

export function CollapseIcon({
  collapse,
  onToggle,
  style,
  className,
  variant = 'small',
  component = 'button',
}: CompoundIconProps & StylingProps) {
  const { classes } = useStyles()

  return (
    <IconButton
      component={component}
      disableRipple={variant === 'small'}
      style={style}
      className={className}
      classes={{
        root: classes[variant],
      }}
      onClick={onToggle && onToggle}
      aria-label={collapse ? 'geschlossen' : 'offen'}
    >
      <Icon
        classes={{
          root: classNames(classes.icon, { [classes.open]: collapse }),
        }}
        color="primary"
      />
    </IconButton>
  )
}
