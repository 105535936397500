import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Skeleton from 'react-loading-skeleton'
import { Column, type DataTableConfig } from './DataTable'
import { Box } from '../Layout/Box'

interface TableSkeletonProps {
  config: DataTableConfig
  rowsPerPage: number
}

export function TableSkeletonRows({ config, rowsPerPage }: TableSkeletonProps) {
  return (
    <>
      {[...Array(rowsPerPage)].map((_, index) => (
        <TableRow key={index}>
          {config.columns.map((column: Column) => (
            <TableCell
              key={column.key}
              align={column.align}
              padding={column.padding}
            >
              <Box p={1}>
                <Skeleton width={'70%'} />
              </Box>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}
