import { FilterModeTypesEnum } from '@austria-codex/types'
import { ReactNode } from 'react'
import { PRODUCTS_BY_IND_GROUP_TABLE_CONFIG } from '../../../../common/tableConfig/products-international'
import { IntProductsDataTable } from '../../../../components/DataTable/IntProductsDataTable'

type TProps = {
  indicationGroupName: string
  indicationGroupCode: string
  onClose: () => void
  heading?: ReactNode
}

export function IntIndicationGroupProductsTable({
  indicationGroupName,
  indicationGroupCode,
  onClose,
  heading,
}: TProps) {
  return (
    <IntProductsDataTable
      config={PRODUCTS_BY_IND_GROUP_TABLE_CONFIG}
      filter={{
        indicationGroup: {
          value: [indicationGroupCode],
          mode: FilterModeTypesEnum.HIERARCHY,
        },
        // In 'International' mode only DE products have indication codes
        isoCodes: ['de'],
      }}
      onClose={onClose}
      heading={heading}
      subheading={indicationGroupName}
    />
  )
}
