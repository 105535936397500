import {
  EntityDataUnion,
  ProductUnion,
  SubstanceData,
  SubstanceInternationalData,
} from '@austria-codex/types'
import {
  isProduct,
  isProductInternational,
  isProductVerlauf,
  isSubstance,
  isSubstanceIdentifier,
  isSubstanceInternational,
  isSubstanceInternationalIdentifier,
} from '@austria-codex/utilities'

export function getSubstancesFromQueryResult(
  hits: EntityDataUnion[] | undefined
) {
  return (
    hits?.filter(
      (h): h is SubstanceData | SubstanceInternationalData =>
        isSubstance(h) || isSubstanceInternational(h)
    ) ?? []
  )
}

export function getProductsFromQueryResult(
  hits: EntityDataUnion[] | undefined
) {
  return (
    hits?.filter(
      (h): h is ProductUnion =>
        isProduct(h) || isProductInternational(h) || isProductVerlauf(h)
    ) ?? []
  )
}

type ProductGroupValue = {
  products: ProductUnion[]
  index: number
  isInternational: boolean
}

export function groupProductsByKey(
  hits: ProductUnion[]
): Array<[string, ProductGroupValue]> {
  if (hits.length === 0) return []

  const map: Record<string, ProductGroupValue> = {}

  hits.forEach((product) => {
    const isAtProduct = isProduct(product) || isProductVerlauf(product)

    // Need to prefix AT products with [AT] to make sure, to not combine AT products
    // with INT products. Eg: there exists 'Diprogenta - Salbe' in AT and DE. If we
    // would not add the prefix to the AT product, we would put both products
    // under the same key. But we want to always show AT products on its own,
    // never in a group.
    const key = isAtProduct
      ? `[AT]${product.bezeichnung}`
      : product.acoSearchTerm

    // If name already exists, add product to existing entry
    if (map[key]) {
      const data = map[key]
      data.products.push(product)
    } /* otherwise create new entry */ else {
      map[key] = {
        products: [product],
        index: 0,
        isInternational: !isAtProduct,
      }
    }
  })

  const entries = Object.entries(map)

  // We need to calculate an index based on how much products are
  // in each group. This index is needed for selecting the right
  // entry when moving with up and down keys through the result.
  let calculatedIndex = 0
  entries.forEach(([_name, data]) => {
    data.index = calculatedIndex
    // Calculate a new index, so that the next entry gets current index + count of products in group.
    calculatedIndex += data.products.length
  })

  return entries
}

export function isOnlyOneSubstance(identifiers: string[]) {
  // Make em unique
  const set = new Set(identifiers)

  if (set.size > 1) {
    return false
  }

  return (
    isSubstanceIdentifier(identifiers[0]) ||
    isSubstanceInternationalIdentifier(identifiers[0])
  )
}
