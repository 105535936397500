import styled from '@emotion/styled'
import {
  compose,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
} from 'styled-system'

export const TableRow = styled.tr<DisplayProps & FlexboxProps>(
  compose(display, flexbox)
)
