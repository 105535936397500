import styled from '@emotion/styled'
import { ReactNode } from 'react'
import BadgeImage from '../../assets/images/off-label.svg?react'
import { box, Box, BoxProps } from '../Layout/Box'

type OffLabelProps = {
  children: ReactNode
  className?: string
  offLabel?: boolean
}

function OffLabelContainer({ children, offLabel, className }: OffLabelProps) {
  return offLabel ? (
    <Border className={className}>
      <Badge />
      {children}
    </Border>
  ) : (
    children
  )
}

export const Border = styled(Box)`
  border: 1px solid #c3e3c0;
  border-radius: 4px;
  position: relative;
`

export const Badge = styled(BadgeImage)`
  position: absolute;
  top: -1px;
  right: -20px;
`

export const OffLabel = styled(OffLabelContainer)<BoxProps & OffLabelProps>(box)
