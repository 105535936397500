import { KeyboardEvent, useCallback, useMemo, useState } from 'react'

type SearchFieldEntryInterface = {
  id: unknown
}

export const useSearchField = <T extends SearchFieldEntryInterface>(
  entries: T[],
  disabled: T[]
) => {
  const [focus, setFocus] = useState(nextFocus(entries, disabled, -1))

  const keyHandler = useMemo(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowUp': {
          setFocus(prevFocus(entries, disabled, focus))
          break
        }
        case 'ArrowDown': {
          setFocus(nextFocus(entries, disabled, focus))
          break
        }
      }
    }

    return {
      onKeyDown,
    }
  }, [focus, entries, disabled])

  const itemProps = useCallback(
    (item: T, index: number) => {
      const isDisabled =
        disabled.find((disabledItem) => disabledItem.id === item.id) !==
        undefined

      const isSelected = focus === index

      const onMouseOver = () => {
        setFocus(index)
      }

      return {
        disabled: isDisabled,
        selected: isSelected,
        onMouseOver,
      }
    },
    [focus, disabled]
  )

  const reset = useCallback(() => {
    setFocus(nextFocus(entries, disabled, -1))
  }, [entries, disabled])

  const selected = useMemo(
    () => (focus !== -1 ? entries[focus] : null),
    [focus, entries]
  )

  return {
    keyHandler,
    itemProps,
    selected,
    reset,
  }
}

const nextFocus = (
  entities: SearchFieldEntryInterface[],
  disabled: SearchFieldEntryInterface[],
  focus: number
) => {
  for (let index = focus + 1; index < entities.length; index += 1) {
    if (
      !disabled.find((disabledEntry) => disabledEntry.id === entities[index].id)
    ) {
      return index
    }
  }

  return focus
}

const prevFocus = (
  entities: SearchFieldEntryInterface[],
  disabled: SearchFieldEntryInterface[],
  focus: number
) => {
  for (let index = focus - 1; index >= 0; index -= 1) {
    if (
      !disabled.find((disabledEntry) => disabledEntry.id === entities[index].id)
    ) {
      return index
    }
  }

  return focus
}
