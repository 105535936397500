import { useCallback, useMemo, useRef, useState } from 'react'

export const useFocusWithin = (): [boolean, object, () => void] => {
  const timeout = useRef<number>()
  const [hasFocus, setHasFocus] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const onBlur = useCallback(() => {
    timeout.current = setTimeout(() => {
      if (hasFocus) {
        setHasFocus(false)
      }
    }, 0) as unknown as number
  }, [hasFocus, setHasFocus])

  const onFocus = useCallback(() => {
    clearTimeout(timeout.current)
    if (!hasFocus) {
      setHasFocus(true)
    }
  }, [hasFocus, setHasFocus])

  const onMouseEnter = useCallback(() => {
    if (!isHovered) {
      setIsHovered(true)
    }
  }, [isHovered, setIsHovered])

  const onMouseLeave = useCallback(() => {
    if (isHovered) {
      setIsHovered(false)
    }
  }, [isHovered, setIsHovered])

  const blur = useCallback(() => {
    setHasFocus(false)
    setIsHovered(false)
  }, [setHasFocus, setIsHovered])

  const isActive = hasFocus || isHovered

  const handler = useMemo(
    () => ({
      onBlur,
      onFocus,
      onMouseEnter,
      onMouseLeave,
      tabIndex: -1,
    }),
    [onBlur, onFocus, onMouseEnter, onMouseLeave]
  )

  return [isActive, handler, blur]
}
