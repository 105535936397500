export type TLocalStorageKeys = 'user' | 'interactionsFilter' | 'events'

export class LocalStorage {
  static get(name: TLocalStorageKeys) {
    return localStorage.getItem(name)
  }

  static set(name: TLocalStorageKeys, value: string) {
    localStorage.setItem(name, value)
  }

  static remove(name: TLocalStorageKeys) {
    localStorage.removeItem(name)
  }
}
