import { useCallback } from 'react'
import { fetchAllAtcCodeHitsData } from '../store/atcCodeHits'
import { toggleRouteOfAdministrationFilter } from '../store/filter'
import {
  addCountries,
  addCountry,
  removeCountry,
  toggleCountry,
} from '../store/user.store'
import { useAppDispatch } from './useStoreHooks'

export function useAddCountry() {
  const dispatch = useAppDispatch()

  return useCallback(
    (...args: Parameters<typeof addCountry>) => {
      dispatch(addCountry(...args))
      dispatch(fetchAllAtcCodeHitsData())
    },
    [dispatch]
  )
}

export function useAddCountries() {
  const dispatch = useAppDispatch()

  return useCallback(
    (...args: Parameters<typeof addCountries>) => {
      dispatch(addCountries(...args))
      dispatch(fetchAllAtcCodeHitsData())
    },
    [dispatch]
  )
}

export function useRemoveCountry() {
  const dispatch = useAppDispatch()

  return useCallback(
    (...args: Parameters<typeof removeCountry>) => {
      dispatch(removeCountry(...args))
      dispatch(fetchAllAtcCodeHitsData())
    },
    [dispatch]
  )
}

export function useToggleCountry() {
  const dispatch = useAppDispatch()

  return useCallback(
    (...args: Parameters<typeof toggleCountry>) => {
      dispatch(toggleCountry(...args))
      dispatch(fetchAllAtcCodeHitsData())
    },
    [dispatch]
  )
}

export function useToggleRoutesOfAdministrationFilter() {
  const dispatch = useAppDispatch()

  return useCallback(
    (...args: Parameters<typeof toggleRouteOfAdministrationFilter>) => {
      dispatch(toggleRouteOfAdministrationFilter(...args))
      dispatch(fetchAllAtcCodeHitsData())
    },
    [dispatch]
  )
}
