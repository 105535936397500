import { gql } from '@apollo/client/core'

export interface LookupQueryResult {
  lookup: {
    matches: string[]
    noMatches: string[]
  }
}

export const LOOKUP_QUERY = gql`
  query lookup($pzn: [String!]!) {
    lookup(pzn: $pzn) {
      matches
      noMatches
    }
  }
`
