import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'

type LoadingOverlayProps = {
  open: boolean
  children: ReactNode
}

const Container = styled.div`
  position: relative;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

export function LoadingOverlay({ open, children }: LoadingOverlayProps) {
  return (
    <Container>
      {open && (
        <Overlay>
          <LoadingSpinner />
        </Overlay>
      )}
      {children}
    </Container>
  )
}
