import { FeatureEnum } from '@austria-codex/types'
import styled from '@emotion/styled'
import { Box } from '@mui/material'
import Button from '@mui/material/Button'
import { useState } from 'react'
import { CollapseIcon } from '../../../components/Icons/CollapseIcon'
import { Indicator } from '../../../components/Indicator/Indicator'
import { Flex } from '../../../components/Layout/Box'
import { useBundleFeature } from '../../../hooks/useBundleFeature'
import { useAppDispatch, useAppSelector } from '../../../hooks/useStoreHooks'
import { hasHighlightSelected, resetHighlight } from '../../../store/highlight'
import { CharacteristicSelect } from './Characteristics/CharacteristicSelect'
import { FeaturesOptions } from './PersonalFeaturesOptions'

export function PersonalCharacteristics() {
  const mode = useAppSelector((state) => state.user.mode)
  const [open, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const hasFeatures = useBundleFeature(
    FeatureEnum.PersonalCharacteristicsFeaturesOptions
  )
  const hasCharacteristicSelect = useBundleFeature(
    FeatureEnum.PersonalCharacteristicsIntoleranceOptions
  )
  const hasSelectedHighlight = useAppSelector(hasHighlightSelected)

  return (
    <>
      {open && (
        <Paper>
          {mode === 'international' && (
            <Box
              component="span"
              sx={{
                mb: 1,
                py: 0.5,
                px: 0.75,
                border: '1px solid blue',
                borderColor: 'info.main',
                borderRadius: '5px',
                backgroundColor: 'info.light',
                display: 'inline-block',
                fontSize: '0.75rem',
              }}
            >
              <strong>Hinweis:</strong> Bitte beachten Sie, dass die Auswahl von
              Personenmerkmalen nur bei österreichischen Präparaten
              berücksichtig werden kann.
            </Box>
          )}
          {hasFeatures && (
            <>
              <FeaturesOptions />
              <Divider />
            </>
          )}
          {hasCharacteristicSelect && <CharacteristicSelect />}
          <Flex justifyContent="flex-end">
            <Button color="primary" onClick={() => dispatch(resetHighlight())}>
              Zurücksetzen
            </Button>
          </Flex>
        </Paper>
      )}
      <Box px={1}>
        <ToggleButton onClick={() => setOpen(!open)}>
          {hasSelectedHighlight && <Indicator />}
          Personenmerkmale
          <CollapseIcon collapse={open} component="div" />
        </ToggleButton>
      </Box>
    </>
  )
}

const Paper = styled.div`
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  padding: ${(props) => props.theme.spacing(3, props.theme.space)};

  ${(props) => props.theme.up(2, props.theme.breakpoints)} {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

const ToggleButton = styled.button`
  background: white;
  border: none;
  margin: 0 30px;
  padding: 0 5px 0 25px;
  height: 40px;
  position: relative;
  font-size: 0.8125rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.primary.main};
  display: flex;
  align-items: center;

  &:before {
    display: block;
    content: '';
    width: 30px;
    height: 40px;
    position: absolute;
    left: -30px;
    top: 0;
    background-image: url("data:image/svg+xml,%3Csvg height='40' viewBox='0 0 30 40' width='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='m0 0h2c1.1 0 2.65 1.18 3.45 2.63 1.91 3.47 17.19 31.27 19.1 34.74.8 1.45 2.35 2.63 3.45 2.63h2v-40'/%3E%3C/svg%3E");
  }

  &:after {
    display: block;
    content: '';
    width: 30px;
    height: 40px;
    position: absolute;
    right: -30px;
    top: 0;
    background-image: url("data:image/svg+xml,%3Csvg height='40' viewBox='0 0 30 40' width='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='m30 0c-1.2 0-1.87 0-2 0-1.1 0-2.65 1.18-3.45 2.63-1.91 3.47-17.19 31.27-19.1 34.74-.8 1.45-2.35 2.63-3.45 2.63-.13 0-.8 0-2 0v-40'/%3E%3C/svg%3E");
  }

  &:hover,
  &:focus {
    background: ${(props) => props.theme.palette.primary.light};
    cursor: pointer;
    outline: none;

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg height='40' viewBox='0 0 30 40' width='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='${(
        props
      ) =>
        encodeURIComponent(
          props.theme.palette.primary.light
        )}' d='m0 0h2c1.1 0 2.65 1.18 3.45 2.63 1.91 3.47 17.19 31.27 19.1 34.74.8 1.45 2.35 2.63 3.45 2.63h2v-40'/%3E%3C/svg%3E");
    }

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg height='40' viewBox='0 0 30 40' width='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='${(
        props
      ) =>
        encodeURIComponent(
          props.theme.palette.primary.light
        )}' d='m30 0c-1.2 0-1.87 0-2 0-1.1 0-2.65 1.18-3.45 2.63-1.91 3.47-17.19 31.27-19.1 34.74-.8 1.45-2.35 2.63-3.45 2.63-.13 0-.8 0-2 0v-40'/%3E%3C/svg%3E");
    }
  }
`

const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: ${(props) => props.theme.palette.primary.main};
`
