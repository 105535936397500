import { type ProductCompositionEntryData } from '@austria-codex/types'
import { isNullOrUndefined } from '@austria-codex/utilities'
import { type ReactNode } from 'react'
import { Box, Flex } from '../../../components/Layout/Box'
import { Text } from '../../../components/Layout/Typography'

type TitleBlockProps = {
  bullet?: boolean
  children: ReactNode
  entry: ProductCompositionEntryData
  onClick?: () => void
}

export function CompositionTitleBlock({
  bullet,
  children,
  entry,
  onClick,
}: TitleBlockProps) {
  const isReference = !isNullOrUndefined(entry.bezLaufnummer)

  return (
    <Flex>
      {(isReference || bullet !== undefined) && (
        <Flex flexShrink={0} width={40} justifyContent="center">
          {bullet && <Text color="black">&#8226;</Text>}
        </Flex>
      )}
      <Box onClick={onClick} display="flex">
        {isReference && (
          <Text fontStyle="italic" mr={1}>
            entspr.
          </Text>
        )}
        {children}
      </Box>
    </Flex>
  )
}
