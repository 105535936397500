import styled from '@emotion/styled'
import { useContext } from 'react'
import logo from '../../assets/images/austria-codex-online.svg'
import msdLogo from '../../assets/images/msd-logo.svg'
import { ContextContext } from '../../contexts/context'
import { Box } from '../Layout/Box'

export function BrandLogo() {
  const context = useContext(ContextContext)

  return (
    <Box height={[38, null, 54]} marginLeft={[2, 0]} marginRight={2}>
      <Logo src={context?.sponsor === 'Msd' ? msdLogo : logo} alt="Logo" />
    </Box>
  )
}

const Logo = styled.img`
  width: auto;
  height: 100%;
  display: block;
`
