import { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { CollapseIcon } from '../../../components/Icons/CollapseIcon'
import { Text } from '../../../components/Layout/Typography'
import { Section } from '../../../components/Section/Section'
import { Table } from '../../../components/Table/Table'
import { TableCell } from '../../../components/Table/TableCell'
import { UnstyledButton } from '../../../components/UnstyledButton'
import { useProductData } from '../../../contexts/entity'
import { useCollapse } from '../../../hooks/useCollapse'
import { useAppSelector } from '../../../hooks/useStoreHooks'
import { telemetrie } from '../../../services/telemetrie.service'
import { CompositionReferenceUnits } from './CompositionReferenceUnits'
import { CompositionRow } from './CompositionRow'

const useStyles = makeStyles()(() => ({
  table: {
    borderCollapse: 'collapse',
    border: 'none',
    width: '100%',
  },
}))

export function CompositionListSection() {
  const product = useProductData()
  const visibility = useAppSelector(
    (state) => state.productComposition[product.id]?.visibility
  )
  const { classes } = useStyles()

  const [collapse, onToggle] = useCollapse({
    section: product.id,
    group: 'composition',
  })

  const [currentRow, setCurrentRow] = useState('')

  function toggleRow(id: string, name?: string) {
    // If substance is closed, track the click
    if (currentRow === '' && name) {
      telemetrie.substanceClicked(product.id, product.bezeichnung, id, name)
    }

    setCurrentRow(currentRow === id ? '' : id)
  }

  return product.zusammensetzung ? (
    <Section width="small" heading="Zusammensetzung">
      <Table
        display={['flex', 'table']}
        flexDirection="column"
        className={classes.table}
      >
        <thead>
          <tr>
            <TableCell colSpan={3}>
              <CompositionReferenceUnits
                referenceUnit={product.zusammensetzung.bezug}
              />
            </TableCell>
          </tr>
        </thead>
        <tbody>
          {product.zusammensetzung.eintraege?.map(
            (entry, index) =>
              (visibility[index] || collapse) && (
                <CompositionRow
                  key={entry.laufNummer}
                  entry={entry}
                  toggleRow={toggleRow}
                  currentRow={currentRow}
                />
              )
          )}
        </tbody>
        <tfoot>
          <tr>
            <TableCell colSpan={3}>
              <UnstyledButton role="button" onClick={() => onToggle()}>
                <Text color="primary.main" cursor="pointer">
                  {collapse ? 'Weniger' : 'Mehr'} anzeigen
                </Text>
                <CollapseIcon component="span" collapse={collapse} />
              </UnstyledButton>
            </TableCell>
          </tr>
        </tfoot>
      </Table>
    </Section>
  ) : null
}
