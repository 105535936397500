import { FilterModeTypesEnum } from '@austria-codex/types'
import { PRODUCTS_BY_ATC_TABLE_CONFIG } from '../../../../common/tableConfig/products-international'
import { IntProductsDataTable } from '../../../../components/DataTable/IntProductsDataTable'

type IntAtcCodeProductsTableProps = {
  atcCode: string
  title: string
  onClose: () => void
}

export function IntAtcCodeProductsTable({
  atcCode,
  title,
  onClose,
}: IntAtcCodeProductsTableProps) {
  return (
    <IntProductsDataTable
      config={PRODUCTS_BY_ATC_TABLE_CONFIG}
      heading={'Anatomisch-Therapeutisch-Chemische Gruppe'}
      subheading={title}
      filter={{
        atcCodes: {
          value: [atcCode],
          mode: FilterModeTypesEnum.HIERARCHY,
        },
      }}
      onClose={onClose}
    />
  )
}
