import { ListItemType } from '../types'
import { TextElement } from './TextElement'

type ListProps = {
  children: ListItemType[]
}

export function List({ children }: ListProps) {
  return (
    <>
      {children.map((child, index) => (
        <li key={index}>
          {child.children.map((item, index) => (
            <TextElement
              key={index}
              child={item}
              alignment={child.alignment}
              variant="span"
            />
          ))}
        </li>
      ))}
    </>
  )
}
