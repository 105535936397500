import {
  EntityDomainHitsData,
  RoutesOfAdministrationTypesEnum,
} from '@austria-codex/types'
import { memo, useMemo } from 'react'
import { useAppSelector } from '../../hooks/useStoreHooks'
import { routesOfAdministrationFilterSelector } from '../../store/filter'
import { convertBitPositions } from '../../utilities/common'

const filterValueLookup: Record<RoutesOfAdministrationTypesEnum, number> = {
  A: 1,
  B: 2,
  C: 4,
  D: 8,
  E: 16,
  F: 32,
  G: 64,
}

function countForFilterValue(hits: number[][], filterValue: number) {
  for (let i = 0; i < hits.length; i += 1) {
    // The first element of the array is the count value
    const count = hits[i][0]

    // All other elements - from index 1 - are sums of filter values.
    // If the given filter value is present, return the count.
    if (hits[i].includes(filterValue, 1)) {
      return count
    }
  }

  return null
}

function countForHits(hits: number[][], filterValue: number) {
  if (hits === null) {
    return 0
  }

  let count = countForFilterValue(hits, filterValue)

  if (!count) {
    count = convertBitPositions(filterValue).reduce(
      (accumulator, filterValue) => {
        const count = countForFilterValue(hits, filterValue)
        return count ? accumulator + count : accumulator
      },
      0
    )
  }

  return count
}

function routesOfAdministrationFilterValue(
  routesOfAdministrationFilter: Record<RoutesOfAdministrationTypesEnum, boolean>
) {
  let sum = 0

  ;(
    Object.entries(routesOfAdministrationFilter) as [
      [RoutesOfAdministrationTypesEnum, boolean],
    ]
  ).forEach(([key, active]) => {
    if (active) {
      sum += filterValueLookup[key]
    }
  })

  return sum
}

interface FilterHitsCountProps {
  hits: number[][]
  emptyValue?: string | number
}

export const FilterHitsCount = memo<FilterHitsCountProps>(
  ({ hits, emptyValue }) => {
    const routesOfAdministrationFilter = useAppSelector(
      routesOfAdministrationFilterSelector
    )
    let count = 0

    if (hits !== null) {
      const filterValue = routesOfAdministrationFilterValue(
        routesOfAdministrationFilter
      )
      count = countForHits(hits, filterValue)
    }

    return count === 0 && emptyValue ? emptyValue : count
  }
)

interface DomainHitsCountProps {
  hits: EntityDomainHitsData
  emptyValue?: string | number
}

export const DomainHitsCount = memo<DomainHitsCountProps>(
  ({ hits, emptyValue }) => {
    const routesOfAdministrationFilter = useAppSelector(
      routesOfAdministrationFilterSelector
    )
    const includesVeterinary = useAppSelector(
      (state) => state.settings.includeVeterinary
    )

    const count = useMemo(() => {
      const filterValue = routesOfAdministrationFilterValue(
        routesOfAdministrationFilter
      )

      if (hits === null) {
        return 0
      }

      if (includesVeterinary) {
        return (
          countForHits(hits.human, filterValue) +
          countForHits(hits.veterinaer, filterValue)
        )
      }

      return countForHits(hits.human, filterValue)
    }, [hits, routesOfAdministrationFilter, includesVeterinary])

    return count === 0 && emptyValue ? emptyValue : count
  }
)
