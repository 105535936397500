import { FeatureEnum } from '@austria-codex/types'
import { GraphQLError } from 'graphql'
import { useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { LOOKUP_QUERY, LookupQueryResult } from '../api/lookup'
import { austriaCodexClient } from '../client/austriaCodex'
import { LoadingScreen } from '../components/LoadingScreen/LoadingScreen'
import { useBundleFeature } from '../hooks/useBundleFeature'
import { useAppDispatch } from '../hooks/useStoreHooks'
import { addAlert } from '../store/alerts'
import { fetchEntitiesWithRelatedData } from '../store/entities'
import { setSessionMode } from '../store/user.store'
import { entityIdentifiersToEntityPartials } from '../utilities/entity'
import { Paths } from '../utilities/path.constants'
import { isString } from '../utilities/types'

const LINKS = {
  application: {
    route: Paths.Index,
    hash: undefined,
  },
  security: {
    route: Paths.Safety,
    hash: undefined,
  },
  uaw: {
    route: Paths.Safety,
    hash: 'uaw',
  },
  interaction: {
    route: Paths.Safety,
    hash: 'interaktionen',
  },
  multiselect: {
    route: Paths.Safety,
    hash: 'mehrfachauswahl',
  },
  market: {
    route: Paths.Retail,
    hash: undefined,
  },
  alternative: {
    route: Paths.Alternatives,
    hash: undefined,
  },
} as const

const sectionToInfo = {
  interaction: 'Interaktionsselektion',
  multiselect: 'Mehrfachauswahl',
}

function isLinksKey(key: unknown): key is keyof typeof LINKS {
  return isString(key) && Object.prototype.hasOwnProperty.call(LINKS, key)
}

export function LinkPage() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const hasInteractions = useBundleFeature(FeatureEnum.Interactions)

  useEffect(() => {
    const pzn = searchParams.get('pzn')
    const section = searchParams.get('section') ?? 'application'

    const pznIdentifiers = pzn?.split(',').filter((p) => p.length > 0) ?? []
    if (pznIdentifiers.length === 0) {
      navigate(Paths.Index, { replace: true })
      return
    }

    const sectionKey = isLinksKey(section) ? section : 'application'
    const link = LINKS[sectionKey]

    function dispatchMessage(message: string) {
      dispatch(
        addAlert({
          id: 'pzn-lookup',
          type: 'info',
          message,
        })
      )
    }

    austriaCodexClient
      .query<LookupQueryResult>({
        query: LOOKUP_QUERY,
        variables: { pzn: pznIdentifiers },
      })
      .then(({ data, error }) => {
        if (error) {
          throw new Error('Error in lookup')
        }

        if (!data) {
          throw new Error('No data provided')
        }

        const entityIdentifiers = data.lookup.matches
        const noMatches = data.lookup.noMatches

        const numberOfRequestedPzns = pznIdentifiers?.length ?? 0
        const numberOfFoundPzns = entityIdentifiers?.length ?? 0
        const numberOfNotFoundPzns = noMatches.length

        const message = getErrorMessages({
          sectionKey,
          numberOfNotFoundPzns,
          numberOfRequestedPzns,
          pznIdentifiers,
          noMatches,
          hasInteractions,
        })

        const scrollingIsDisabled =
          numberOfRequestedPzns === 0 ||
          numberOfFoundPzns === 0 ||
          numberOfNotFoundPzns > 0 ||
          message !== ''

        if (message !== '') {
          dispatchMessage(message)
        }

        if (numberOfFoundPzns > 0) {
          const ids = entityIdentifiersToEntityPartials(entityIdentifiers)

          // Switch to national mode, because link page
          // can only be used with AT pzn numbers, but
          // only for the session (state won't be
          // updated in local storage).
          dispatch(setSessionMode('national'))

          dispatch(fetchEntitiesWithRelatedData(ids)).then(() => {
            navigate(
              {
                pathname: link.route,
                search: `q=${entityIdentifiers.join(',')}`,
                hash: scrollingIsDisabled ? '' : link.hash,
              },
              {
                replace: true,
                state: {
                  from: 'link-page',
                },
              }
            )
          })
        } else {
          navigate(link.route, { replace: true })
        }
      })
      .catch((e) => {
        let message
        if (
          e.graphQLErrors?.some(
            (err: GraphQLError) => err.extensions?.code === 'FORBIDDEN'
          )
        ) {
          message =
            'Sie besitzen nicht die notwendigen Lizenzen für dieses Feature.'
        } else {
          message =
            'Die Pharmazentralnummernabfrage konnte nicht durchgeführt werden.'
        }

        dispatchMessage(message)

        navigate(Paths.Index, { replace: true })
      })
  }, [dispatch, hasInteractions, navigate, searchParams, location])

  return <LoadingScreen />
}

function getErrorMessages({
  sectionKey,
  numberOfNotFoundPzns,
  numberOfRequestedPzns,
  pznIdentifiers,
  noMatches,
  hasInteractions,
}: {
  sectionKey: string
  numberOfNotFoundPzns: number
  numberOfRequestedPzns: number
  pznIdentifiers: string[]
  noMatches: string[]
  hasInteractions: boolean
}): string {
  let message = ''

  if (sectionKey === 'interaction' || sectionKey === 'multiselect') {
    if (!hasInteractions && sectionKey === 'interaction') {
      message =
        'Das Aufrufen von Interaktionsinformationen erfordert die Lizenz für das Modul Sicherheit. Kontakt: verkauf@apoverlag.at, +43 1 402 35 88 535.'
      return message
    }

    if (!hasInteractions && sectionKey === 'multiselect') {
      message =
        'Für den Aufruf der Mehrfachauswahl muss das Modul Sicherheit lizenziert sein. Kontakt: verkauf@apoverlag.at, +43 1 402 35 88 535.'
      return message
    }

    const actionWord = sectionToInfo[sectionKey]
    if (numberOfNotFoundPzns === 1 && numberOfRequestedPzns <= 2) {
      message = `Die Pharmazentralnummer "${noMatches[0]}" wurde nicht gefunden. Für eine ${actionWord} müssen mindestens 2 Pharmazentralnummern übergeben werden.`
      return message
    }

    if (numberOfRequestedPzns <= 1) {
      message = `Für eine ${actionWord} müssen mindestens 2 Pharmazentralnummern übergeben werden.`
    }
  }

  if (numberOfRequestedPzns > 40) {
    message = `Es können maximal 40 Pharmazentralnummern übergeben werden.`

    const tooManyPzns = pznIdentifiers.slice(40)
    const pznString = tooManyPzns.join('", "')

    message +=
      tooManyPzns.length === 1
        ? ` Die Pharmazentralnummer "${pznString}" konnte nicht berücksichtigt werden.`
        : ` Die Pharmazentralnummern "${pznString}" konnten nicht berücksichtigt werden.`

    return message
  }

  if (numberOfNotFoundPzns > 0) {
    const notFoundPzns = noMatches.join('", "')
    message +=
      numberOfNotFoundPzns === 1
        ? ` Die Pharmazentralnummer "${notFoundPzns}" wurde nicht gefunden.`
        : ` Die Pharmazentralnummern "${notFoundPzns}" wurden nicht gefunden.`
  }

  return message
}
