import {
  ProductVerlaufApplicationWarningsData,
  ProductVerlaufClassificationData,
  ProductVerlaufDocumentationQuickHelpData,
  ProductVerlaufStorageData,
} from '@austria-codex/types'
import { isNullOrUndefined } from '@austria-codex/utilities'

export function formatData(value: unknown): string {
  if (typeof value === 'object' && value !== null) {
    return Object.entries(value)
      .map(([key, val]) => `<strong>${key}</strong>: ${formatData(val)}`)
      .join(', ')
  }

  return value ? (value as string) : ''
}

export function getKlassifikation(
  klassifikationVerlauf: ProductVerlaufClassificationData | undefined
): ProductVerlaufClassificationData | null {
  if (!klassifikationVerlauf) {
    return null
  }

  if (
    !klassifikationVerlauf.applikationsarten?.length &&
    !klassifikationVerlauf.atcCodes?.length &&
    !klassifikationVerlauf.indikationsgruppen?.length &&
    isNullOrUndefined(klassifikationVerlauf.arzneiform)
  ) {
    return null
  }

  return klassifikationVerlauf
}

// Teilbarkeit is not needed anymore due to lack of data

// export function getTeilbarkeit(
//   klassifikationVerlauf: ProductVerlaufClassificationData | undefined,
//   teilbarkeit: ProductVerlaufSplittabilityData | undefined
// ): number | null {
//   if (
//     !klassifikationVerlauf ||
//     !klassifikationVerlauf?.applikationsarten ||
//     !teilbarkeit?.maxTeilbarkeit
//   ) {
//     return null
//   }

//   // Prüfen, ob mindestens ein `anwGruppe`-Eintrag im Array den Wert "oral" hat
//   const hasOralAdministration = klassifikationVerlauf?.applikationsarten.some(
//     (item) => item.anwGruppe === 'oral'
//   )

//   if (!hasOralAdministration) {
//     return null
//   }

//   return teilbarkeit.maxTeilbarkeit
// }

export function getKurzinformation(
  kurzinformation: ProductVerlaufDocumentationQuickHelpData[] | undefined
): ProductVerlaufDocumentationQuickHelpData[] | null {
  if (!kurzinformation || kurzinformation.length === 0) {
    return null
  }
  return kurzinformation
}

export function getWarnhinweise(
  anwWarnHinweise: ProductVerlaufApplicationWarningsData | undefined
): ProductVerlaufApplicationWarningsData | null {
  if (!anwWarnHinweise) {
    return null
  }

  if (
    !anwWarnHinweise.arzneimittelRisiken?.length &&
    !anwWarnHinweise.geltungsbereiche?.length &&
    !anwWarnHinweise.vvAufloesen &&
    !anwWarnHinweise.vvZubereiten &&
    !anwWarnHinweise.einnahmeMahlzeit &&
    !anwWarnHinweise.einnahmeUnversehrt &&
    !anwWarnHinweise.hinwAlkohol &&
    !anwWarnHinweise.hinwAlterstauglichkeit &&
    !anwWarnHinweise.hinwDiabetiker &&
    (!anwWarnHinweise.hinwKadersportler ||
      anwWarnHinweise.hinwKadersportler === 'keine Angabe') &&
    !anwWarnHinweise.hinwKinder &&
    !anwWarnHinweise.hinwReligion &&
    !anwWarnHinweise.hinwSchwangerschaft &&
    !anwWarnHinweise.hinwSonnenlicht &&
    !anwWarnHinweise.hinwStillzeit &&
    !anwWarnHinweise.hinwVegan &&
    !anwWarnHinweise.hinwVerkehrstuechtigkeit &&
    !anwWarnHinweise.zusUeberwachung
  ) {
    return null
  }

  return anwWarnHinweise
}

export function hasLagerung(
  lagerung: ProductVerlaufStorageData | undefined
): boolean {
  if (!lagerung) {
    return false
  }

  return (
    // We don't care about the Kennzeichen now, as we do not show that in the UI.
    // (lagerung.kennzeichen && lagerung.kennzeichen.length > 0) ||
    Boolean(lagerung.haltbarkeit) || Boolean(lagerung.lagerungsbedingung)
  )
}
