const warningNoticeText = {
  roadworthy: [
    'Keine Angabe',
    'Vorsicht Einfluss auf Verkehrstüchtigkeit/Bedienen von Maschinen',
  ],
  monitoring: [
    'Keine Angabe',
    'Dieses Arzneimittel unterliegt einer zusätzlichen Überwachung.',
  ],
  children: ['Keine Angabe', 'Kinderform'],
  pregnancy: [
    'Keine Angabe',
    'Es kann keine genaue Aussage getroffen werden.',
    'Anwendung erlaubt',
    'Anwendung verboten',
    'Anwendung nur bei zwingender oder vitaler Indikation',
    'Anwendung unter sorgfältiger Kontrolle möglich',
  ],
  lactation: [
    'Keine Angabe',
    'Es kann keine genaue Aussage getroffen werden.',
    'Stillen erlaubt',
    'Stillen verboten',
    'Stillen nur bei zwingender oder vitaler Indikation',
    'Stillen unter sorgfältiger Kontrolle möglich',
  ],
  diabetes: ['Keine Angabe', 'Vorsicht Diabetiker'],
  alcohol: ['Keine Angabe', 'Alkoholeinnahme vermeiden'],
  sunlight: [
    'Keine Angabe',
    'Vorsicht Photosensibilität, übermäßige UV-Strahlung meiden',
  ],
  sport: [
    'Keine Angabe',
    'Bei Kadersportlern gemäß der Verbotsliste der WADA in der jeweils gültigen Fassung absolut verboten, TUE (Therapeutic Use Exemptions) jedoch möglich.',
    'Bedingt bei Kadersportlern verboten, hier sind spezielle Regeln zu beachten.',
  ],
} as const

function isWarningNoticeKey(
  key: string
): key is keyof typeof warningNoticeText {
  return Object.prototype.hasOwnProperty.call(warningNoticeText, key)
}

const defaultText = 'Keine Angabe'

export function warningNotice(type: string, level: number) {
  // Need to check if key is right to make TypeScript happy
  if (!isWarningNoticeKey(type)) {
    return defaultText
  }

  return warningNoticeText[type][level] ?? defaultText
}
