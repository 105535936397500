import { type ReactNode } from 'react'
import HumanIcon from '../../../../assets/images/alternative/human-alternative.svg?react'
import VeterinaryIcon from '../../../../assets/images/alternative/veterinary-alternative.svg?react'
import { Box, Flex } from '../../../../components/Layout/Box'

type AtcCodeDomainGroupProps = {
  children: ReactNode
  isVeterinary: boolean
}

export function IntAtcCodeDomainGroup({
  children,
  isVeterinary,
}: AtcCodeDomainGroupProps) {
  return (
    <Box borderLeft="1px solid" borderColor="primary.main" mt={3} ml={3}>
      <Flex ml={-3}>{isVeterinary ? <VeterinaryIcon /> : <HumanIcon />}</Flex>
      <Box ml={3}>{children}</Box>
    </Box>
  )
}
