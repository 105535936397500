import Grid from '@mui/material/Grid'
import { FeatureEnum } from '@austria-codex/types'
import { ReleaseInfo } from '../ReleaseInfo/ReleaseInfo'
import { Anchor, AnchorLink } from '../Layout/Anchor'
import { Feedback } from '../Feedback/Feedback'
import { useBundleFeature } from '../../hooks/useBundleFeature'

export function Footer() {
  const hasFeedbackForm = useBundleFeature(FeatureEnum.FooterFeedbackForm)

  return (
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid item>
        <ReleaseInfo />
      </Grid>
      {hasFeedbackForm && (
        <Grid item>
          <Feedback />
        </Grid>
      )}
      <Grid item>
        <AnchorLink to="/einstellungen/fragen">FAQ</AnchorLink>
      </Grid>
      <Grid item>
        <AnchorLink to="/einstellungen/zeichen">Hilfe</AnchorLink>
      </Grid>
      <Grid item>
        <Anchor
          target="_blank"
          href="https://www.apoverlag.at/ueber-uns/nutzungsbestimmungen-1193902"
        >
          Nutzungsbestimmungen
        </Anchor>
      </Grid>
      <Grid item>
        <Anchor
          target="_blank"
          href="https://www.apoverlag.at/ueber-uns/impressum-776988"
        >
          Impressum
        </Anchor>
      </Grid>
      <Grid item>
        <Anchor
          target="_blank"
          href="https://www.apoverlag.at/ueber-uns/agb-787635"
        >
          AGB
        </Anchor>
      </Grid>
    </Grid>
  )
}
