import { Html } from '../../components/Html/Html'
import { DocumentationSection } from '../../components/Section/DocumentationSection'
import { useProductData } from '../../contexts/entity'

export function DosageSection() {
  const product = useProductData()

  return product.dokumentation?.schnellhilfe?.dosierung ? (
    <DocumentationSection
      inherit={product.dokumentation.schnellhilfe.dosierung.vererbt}
      width="small"
      heading="Dosierung"
    >
      <Html content={product.dokumentation.schnellhilfe.dosierung.text} />
    </DocumentationSection>
  ) : null
}
