import { RoutesOfAdministrationTypesEnum } from '@austria-codex/types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Tooltip from '@mui/material/Tooltip'
import { useToggleRoutesOfAdministrationFilter } from '../../hooks/useFilterUpdate'
import { useAppSelector } from '../../hooks/useStoreHooks'
import { RouteOfAdministrationIcon } from '../Icons/RouteOfAdministrationIcon'
import { Anchor } from '../Layout/Anchor'
import { Flex } from '../Layout/Box'

export function RoutesOfAdministrationToolbar() {
  const toggleRouteOfAdministrationFilter =
    useToggleRoutesOfAdministrationFilter()
  const filter = useAppSelector((state) => state.filter.routesOfAdministration)

  function onToggle(routeOfAdministration: RoutesOfAdministrationTypesEnum) {
    toggleRouteOfAdministrationFilter(routeOfAdministration)
  }

  return (
    <Flex
      p="12px"
      width={['100%', 'auto']}
      justifyContent={['space-between', null, 'start']}
    >
      <FilterIcon
        type={RoutesOfAdministrationTypesEnum.D}
        onClick={onToggle}
        active={filter.D}
        title="Oral"
        className="tracking-filter-button"
      />
      <FilterIcon
        type={RoutesOfAdministrationTypesEnum.C}
        onClick={onToggle}
        active={filter.C}
        title="Pulmonal"
        className="tracking-filter-button"
      />
      <FilterIcon
        type={RoutesOfAdministrationTypesEnum.B}
        onClick={onToggle}
        active={filter.B}
        title="Lokal"
        className="tracking-filter-button"
      />
      <FilterIcon
        type={RoutesOfAdministrationTypesEnum.E}
        onClick={onToggle}
        active={filter.E}
        title="Parenteral"
        className="tracking-filter-button"
      />
      <FilterIcon
        type={RoutesOfAdministrationTypesEnum.F}
        onClick={onToggle}
        active={filter.F}
        title="Rektal"
        className="tracking-filter-button"
      />
      <FilterIcon
        type={RoutesOfAdministrationTypesEnum.G}
        onClick={onToggle}
        active={filter.G}
        title="Urogenital"
        className="tracking-filter-button"
      />
      <FilterIcon
        type={RoutesOfAdministrationTypesEnum.A}
        onClick={onToggle}
        active={filter.A}
        title="Extrakorporal"
        className="tracking-filter-button"
      />
    </Flex>
  )
}

type FilterIconLinkProps = {
  className?: string
  active: boolean
  type: RoutesOfAdministrationTypesEnum
  onClick: (routeOfAdministration: RoutesOfAdministrationTypesEnum) => void
  disabled?: boolean
  title: string
}

function FilterIconLink({
  className,
  onClick,
  type,
  active,
  disabled,
  title,
}: FilterIconLinkProps) {
  return (
    <Tooltip title={title}>
      <Anchor
        as="button"
        className={className}
        onClick={() => {
          if (!disabled) {
            onClick(type)
          }
        }}
      >
        <RouteOfAdministrationIcon
          routeOfAdministration={type}
          color={disabled || active ? 'white' : 'primary'}
        />
      </Anchor>
    </Tooltip>
  )
}

const FilterIcon = styled(FilterIconLink)<FilterIconLinkProps>`
  border-radius: 4px;
  padding: 3px;

  & {
    margin-left: ${(props) => props.theme.spacing(1, props.theme.space)};
  }

  ${(props) =>
    props.active &&
    css`
      background: ${props.theme.palette.primary.main};
    `}}

  ${(props) =>
    props.disabled &&
    css`
      background: #aaaaaa;
      cursor: default !important;
    `}
`
