import type { Eintrag } from '@austria-codex/types'
import { CellBorder, TableCell } from '../../../../components/Table/TableCell'
import { Text } from '../../../../components/Layout/Typography'
import { IntCompositionTitleBlock } from './IntCompositionTitleBlock'

type TProps = {
  entry: Eintrag
}

export function IntCompositionDescriptionRow({ entry }: TProps) {
  return (
    <tr>
      <TableCell colSpan={3}>
        <CellBorder indent>
          <IntCompositionTitleBlock entry={entry}>
            <Text fontWeight="bold">{entry.bezeichnung}</Text>
          </IntCompositionTitleBlock>
        </CellBorder>
      </TableCell>
    </tr>
  )
}
