import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { themeGet } from '@styled-system/theme-get'

type SeparatorZigZagProps = {
  variant: 'zigzag'
  bgTop?: `${string}.${string}`
  bgBottom?: `${string}.${string}`
  lineColor?: `${string}.${string}`
  lineWidth?: number
}

type SeparatorLineProps = {
  variant: 'line'
  lineColor?: `${string}.${string}`
  lineWidth?: number
}

export const Separator = styled.hr<SeparatorZigZagProps | SeparatorLineProps>`
  margin: 0;
  border: none;

  ${(props) =>
    props.variant === 'line' &&
    css`
      height: ${props.lineWidth}px;
      background-color: ${themeGet(`palette.${props.lineColor}`)(props)};
    `}

  ${(props) =>
    props.variant === 'zigzag' &&
    css`
      height: 8px;
      background-color: transparent;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' viewBox='0 0 16 8'%3E%3Cpolyline fill='${encodeURIComponent(
        themeGet(`palette.${props.bgTop}`, 'none')(props)
      )}' points='0,0 0,0.94 8,6.5 16,0.94 16,0'/%3E%3Cpolyline fill='${encodeURIComponent(
        themeGet(`palette.${props.bgBottom}`, 'none')(props)
      )}' points='0,0.94 8,6.5 16,0.94 16,8 0,8'/%3E%3Cpolyline fill='none' stroke='${encodeURIComponent(
        themeGet(`palette.${props.lineColor}`)(props)
      )}' stroke-width='${props.lineWidth}' points='-1,0.94 8,6.5 17,0.94 '/%3E%3C/svg%3E%0A");
    `};
`

Separator.defaultProps = {
  lineColor: 'grey.200',
  lineWidth: 2,
}
