import { ProductData } from '@austria-codex/types'
import { Anchor } from '../../components/Layout/Anchor'
import { List, ListItem } from '../../components/Layout/List'
import { Section } from '../../components/Section/Section'
import { Config } from '../../config'
import { useProductData } from '../../contexts/entity'

export function BasicDocumentsSection() {
  const product = useProductData()

  const baseUrl = `${Config.api.url}${Config.api.endpoints.document}/${product.zulassungsnummerAlternativ}`
  const fiUrl = `${baseUrl}/ac`
  const giUrl = `${baseUrl}/gi`

  return hasDocumentationInformation(product) ? (
    <Section width="small" heading="Dokumente">
      <List>
        {product.dokumentation?.fachinformation && (
          <ListItem>
            <Anchor target="_blank" rel="noopener noreferrer" href={fiUrl}>
              Fachinformation
            </Anchor>
          </ListItem>
        )}
        {product.dokumentation?.gebrauchsinformation && (
          <ListItem>
            <Anchor target="_blank" rel="noopener noreferrer" href={giUrl}>
              Gebrauchsinformation
            </Anchor>
          </ListItem>
        )}
      </List>
    </Section>
  ) : null
}

const hasDocumentationInformation = (product: ProductData) =>
  product.dokumentation?.gebrauchsinformation ||
  product.dokumentation?.fachinformation
