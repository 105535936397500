import {
  DispensaryTypesEnum,
  ReimbursementBoxTypesEnum,
  RoutesOfAdministrationTypesEnum,
} from '../misc'

export enum FilterModeTypesEnum {
  ID = 'ID',
  ROOT = 'ROOT',
  HIERARCHY = 'HIERARCHY',
}

export enum FilterMatchTypesEnum {
  EXACT = 'EXACT',
  SUBSET = 'SUBSET',
  PARTIAL = 'PARTIAL',
}

export type FilterLookup<T> = {
  value: T
}

export type FilterMode = {
  mode: FilterModeTypesEnum
}

export type FilterMatch = {
  match: FilterMatchTypesEnum
}

export type LookupModeFilter<T> = FilterLookup<T[]> & FilterMode

export type LookupMatchFilter<T> = FilterLookup<T[]> & FilterMatch

export type ProductsFilter = {
  ids?: string[]
  atcCodes?: LookupModeFilter<string>
  indicationGroup?: LookupModeFilter<string>
  routesOfAdministration?: LookupModeFilter<RoutesOfAdministrationTypesEnum>
  dosageForm?: LookupModeFilter<string>
  agents?: LookupMatchFilter<string>
  isAvailable?: boolean
  isVeterinary?: boolean
  isMonoPreparation?: boolean
  dispensary?: DispensaryTypesEnum
  reimbursementBoxes?: LookupMatchFilter<ReimbursementBoxTypesEnum>
  references?: string
  genericCode?: number
  authorisationHolder?: string
  // International specific
  isoCodes?: string[]
  name?: string
  provider?: string
  atProductId?: string
  intProductId?: string
}

export type ProductsIntFilter = {
  ids?: string[]
  atcCodes?: LookupModeFilter<string>
  indicationGroup?: LookupModeFilter<string>
  routesOfAdministration?: LookupModeFilter<RoutesOfAdministrationTypesEnum>
  dosageForm?: LookupModeFilter<string>
  agents?: LookupMatchFilter<string>
  isAvailable?: boolean
  isVeterinary?: boolean
  isMonoPreparation?: boolean
  dispensary?: DispensaryTypesEnum
  reimbursementBoxes?: LookupMatchFilter<ReimbursementBoxTypesEnum>
  references?: string
  genericCode?: number
  authorisationHolder?: string
  // International specific
  isoCodes?: string[]
  name?: string
  provider?: string
  atProductId?: string
  intProductId?: string
}

export type ArticlesFilterInterface = {
  isAvailable?: boolean
  isMonoPreparation?: boolean
  agents?: LookupMatchFilter<string>
  isVeterinary?: boolean
  dispensary?: DispensaryTypesEnum
  reimbursementBox?: ReimbursementBoxTypesEnum
  routesOfAdministration?: LookupModeFilter<RoutesOfAdministrationTypesEnum>
}

export type SearchFilter = {
  isoCodes?: string[]
  includeVeterinary?: boolean
  includeVerlaufsTyp5?: boolean
  includeOutOfStockProducts?: boolean
  routesOfAdministration?: RoutesOfAdministrationTypesEnum[]
}

export type SearchProductsFilter = {
  query: string
  isoCodes?: string[]
  includeVeterinary?: boolean
  includeVerlaufsTyp5?: boolean
  routesOfAdministration?: RoutesOfAdministrationTypesEnum[]
}
