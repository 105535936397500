import styled from '@emotion/styled'
import { AlignmentType, ListItemType } from '../types'
import { List } from './List'

type ListWrapperProps = {
  alignment?: AlignmentType
}

const ListWrapper = styled.ol<ListWrapperProps>`
  text-align: ${(props) => (props.alignment ? props.alignment : undefined)};
`

type OrderedListProps = {
  children: ListItemType[]
  alignment?: AlignmentType
}

export function OrderedList({ children, alignment }: OrderedListProps) {
  return (
    <ListWrapper alignment={alignment}>
      <List children={children} />
    </ListWrapper>
  )
}
