/**
 * Checks is the given value is a string.
 *
 * @param value
 * @returns true if value is of type string, false otherwise
 */
export const isString = (value: unknown): value is string =>
  typeof value === 'string'

/**
 * Checks if the given value is in the right format of a PZN.
 *
 * @param value a potential PZN
 * @returns true if value is a PZN, false otherwise
 */
export const isPZN = (value: string) => /^[0-9]{6,13}$/.test(value)
