import { FeatureEnum, type TCountryMode } from '@austria-codex/types'
import { useEffect, type ReactNode } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { ApplicationBar } from '../components/ApplicationBar/ApplicationBar'
import { IntModeSelector } from '../components/ApplicationBar/IntModeSelector'
import { EntityPillsList } from '../components/EntityPills/EntityPillsList'
import { Footer } from '../components/Footer/Footer'
import { Background } from '../components/Layout/Background'
import { Box } from '../components/Layout/Box'
import { Container } from '../components/Layout/Container'
import { Sheet } from '../components/Layout/Sheet'
import { MessageBox } from '../components/MessageBox/MessageBox'
import { LinkTabItem, Tabs } from '../components/Navigation/Tabs'
import { ScrollTop } from '../components/ScrollTop/ScrollTop'
import { SearchFieldInt } from '../components/SearchField/International/SearchFieldInt'
import { SearchField } from '../components/SearchField/SearchField'
import { PersonalCharacteristics } from '../features/components/Highlight/PersonalCharacteristics'
import { SafetyTabIndicator } from '../features/components/SafetyTabIndicator/SafetyTabIndicator'
import { useBundleFeature } from '../hooks/useBundleFeature'
import { useAppSelector } from '../hooks/useStoreHooks'
import { Paths } from '../utilities/path.constants'
import { subscribeToEntityHistory } from '../utilities/url'
import { AlternativesPage } from './Main/AlternativesPage'
import { ApplicationPage } from './Main/ApplicationPage'
import { RetailPage } from './Main/RetailPage'
import { SafetyPage } from './Main/SafetyPage'

const modeToSearchField: Record<TCountryMode, ReactNode> = {
  national: <SearchField />,
  international: <SearchFieldInt />,
}

export function MainPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const hasSearchBar = useBundleFeature(FeatureEnum.SearchBar)
  const hasInternational = useBundleFeature(FeatureEnum.International)
  const hasPersonalCharacteristics = useBundleFeature(
    FeatureEnum.PersonalCharacteristics
  )
  const hasEntityPills = useBundleFeature(FeatureEnum.Pills)
  const hasFooter = useBundleFeature(FeatureEnum.Footer)
  const hasSafetyIndicator = useBundleFeature(FeatureEnum.SafetyIndicator)
  const mode = useAppSelector((state) => state.user.mode)

  useEffect(() => {
    const unsubscribe = subscribeToEntityHistory(location, navigate)
    return () => unsubscribe()
  }, [location, navigate])

  const searchField = hasInternational
    ? modeToSearchField[mode]
    : modeToSearchField['national']

  return (
    <>
      <ApplicationBar>
        {hasInternational && <IntModeSelector />}
        {hasSearchBar && searchField}
      </ApplicationBar>
      {hasPersonalCharacteristics && mode === 'national' && (
        <Background>
          <Container>
            <PersonalCharacteristics />
          </Container>
        </Background>
      )}
      {hasEntityPills && (
        <Background>
          <Container>
            <Box py={3}>
              <EntityPillsList />
            </Box>
          </Container>
        </Background>
      )}
      <Background>
        <MessageBox />
      </Background>
      <Background pb={7} mb={-7} />
      <Container>
        <Box mb={4}>
          <Sheet>
            <Tabs>
              <LinkTabItem to={Paths.Index} trackingName="anwendung">
                Anwendung
              </LinkTabItem>
              <LinkTabItem
                to={Paths.Safety}
                metaRender={
                  hasSafetyIndicator ? <SafetyTabIndicator /> : undefined
                }
                trackingName="sicherheit"
              >
                Sicherheit
              </LinkTabItem>
              <LinkTabItem to={Paths.Retail} trackingName="handel">
                Handel
              </LinkTabItem>
              <LinkTabItem to={Paths.Alternatives} trackingName="alternativen">
                Alternativen
              </LinkTabItem>
            </Tabs>
            <Routes>
              <Route path={Paths.Index} element={<ApplicationPage />} />
              <Route path={Paths.Safety} element={<SafetyPage />} />
              <Route path={Paths.Retail} element={<RetailPage />} />
              <Route path={Paths.Alternatives} element={<AlternativesPage />} />
            </Routes>
          </Sheet>
        </Box>
      </Container>
      <Container>
        <Box as="footer" my={4} px={4}>
          {hasFooter && <Footer />}
        </Box>
      </Container>
      <ScrollTop />
    </>
  )
}
