import styled from '@emotion/styled'
import { Box } from './Box'

export const Background = styled(Box)`
  background: linear-gradient(
    to right,
    ${(props) => props.theme.palette.primary.dark},
    ${(props) => props.theme.palette.primary.main}
  );
`
