import { ProductInternationalData, ProductsFilter } from '@austria-codex/types'
import { isProduct, isProductInternational } from '@austria-codex/utilities'
import Box from '@mui/material/Box'
import { ReactNode, useCallback, useState } from 'react'
import { useFilterProducts } from '../../api/api'
import { useEntityDataUnion } from '../../contexts/entity'
import { getIdsOfDuplicateProducts } from '../../helpers/product-international.helper'
import { useAppDispatch, useAppSelector } from '../../hooks/useStoreHooks'
import { telemetrie } from '../../services/telemetrie.service'
import { fetchEntitiesWithRelatedData } from '../../store/entities'
import { DataTable, DataTableConfig } from './DataTable'
import { DataTableOverlay } from './DataTableOverlay'

type IntProductsDataTableProps = {
  config: DataTableConfig
  filter: ProductsFilter
  heading?: ReactNode | string
  subheading?: ReactNode | string
  name?: string
  meta?: Record<string, unknown>
  onClose?: () => void
}

const limit = 30

export function IntProductsDataTable({
  filter,
  heading,
  subheading,
  config,
  name,
  meta,
  onClose,
}: IntProductsDataTableProps) {
  const dispatch = useAppDispatch()
  const entity = useEntityDataUnion()
  const entityIdentifiers = useAppSelector((state) => state.entities.ids)

  const [isOpenOverlay, setIsOpenOverlay] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [isLoadingFetchMore, setIsLoadingFetchMore] = useState(false)

  const { loading, error, data, fetchMore, refetch } = useFilterProducts({
    filter,
    offset: page * rowsPerPage,
    limit: rowsPerPage,
  })

  const handleFetchMore = useCallback(async () => {
    setIsLoadingFetchMore(true)
    try {
      const fetchMoreResult = await fetchMore({
        variables: { offset },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult
          }
          const products = {
            ...fetchMoreResult.products,
            hits: [
              ...previousResult.products.hits,
              ...fetchMoreResult.products.hits,
            ],
          }
          return { products }
        },
      })
      setOffset((prev) => prev + fetchMoreResult.data.products.hits.length)
      setIsLoadingFetchMore(false)
    } catch (error) {
      console.error(error)
      setIsLoadingFetchMore(false)
    }
  }, [fetchMore, offset])

  const handleOpenOverlay = useCallback(async () => {
    setIsOpenOverlay(true)
    const refetchResponse = await refetch({ offset: 0, limit })
    setOffset(refetchResponse.data.products.hits.length)
  }, [refetch])

  const handleCloseOverlay = useCallback(async () => {
    setIsOpenOverlay(false)
    await refetch({ offset: page * rowsPerPage, limit: rowsPerPage })
  }, [page, refetch, rowsPerPage])

  const handleRowClick = useCallback(
    (id: string, name: string) => {
      if (isProduct(entity) || isProductInternational(entity)) {
        telemetrie.drugClicked(
          id,
          name,
          entity.id,
          entity.bezeichnung,
          null,
          null
        )
      } else {
        telemetrie.drugClicked(
          id,
          name,
          null,
          null,
          entity.id,
          entity.bezeichnung
        )
      }

      dispatch(fetchEntitiesWithRelatedData([{ id }]))
    },
    [dispatch, entity]
  )

  function table({ hasOverlay = false }: { hasOverlay?: boolean }) {
    return (
      <DataTable
        config={config}
        loading={loading}
        error={error !== undefined}
        data={hits}
        total={total}
        hasOverlay={hasOverlay}
        page={isOpenOverlay ? undefined : page}
        onPageChange={isOpenOverlay ? undefined : setPage}
        rowsPerPage={isOpenOverlay ? limit : rowsPerPage}
        onRowsPerPageChange={isOpenOverlay ? undefined : setRowsPerPage}
        onClickRow={(row: ProductInternationalData) =>
          handleRowClick(row.id, row.bezeichnung)
        }
        isRowDisabled={(row: ProductInternationalData) =>
          entityIdentifiers.includes(row.id)
        }
        meta={{ showProviderIds, ...meta }}
        onClose={onClose}
        onOpenOverlay={handleOpenOverlay}
      />
    )
  }

  const hits = data?.products.hits ?? []
  const total = data?.products.total ?? 0
  const needsOverlay = total > 7

  const showProviderIds = getIdsOfDuplicateProducts(hits)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {needsOverlay ? (
        <DataTableOverlay
          open={isOpenOverlay}
          onClose={handleCloseOverlay}
          table={table}
          isLoadingFetchMore={isLoadingFetchMore}
          handleFetchMore={handleFetchMore}
          heading={heading}
          subHeading1={filter.provider}
          subHeading2={subheading}
          hitsLength={hits.length}
          total={total}
        />
      ) : null}
      {!isOpenOverlay && table({ hasOverlay: needsOverlay })}
    </Box>
  )
}
