import { gql } from '@apollo/client/core'

export const GENERICS_QUERY = gql`
  query articles($offset: Int!, $filter: ProductsFilterInputType, $limit: Int) {
    entries: products(offset: $offset, filter: $filter, limit: $limit) {
      offset
      limit
      total
      hits {
        ... on ProductType {
          id
          isoCode
          bezeichnung
          suffix
          istVeterinaer
          klassifikation {
            generischeBezeichnung
            istMonopraeparat
          }
          zusammensetzung {
            eintraege(type: Wirkstoff) {
              ... on ProductCompositionSubstanceEntryType {
                stoff {
                  basisStoff {
                    id
                    bezeichnung
                  }
                  stoff {
                    id
                    bezeichnung
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
