import { FeatureEnum } from '@austria-codex/types'
import { useCallback } from 'react'
import { ModuleEntityTypes } from '../types/bundle'
import { useBundleFeature } from './useBundleFeature'

export function useShouldRenderEntityType(
  productFeature: FeatureEnum,
  substanceFeature: FeatureEnum
) {
  const hasProductsHeader = useBundleFeature(productFeature)
  const hasSubstanceHeader = useBundleFeature(substanceFeature)

  return useCallback(
    (entityType: ModuleEntityTypes): boolean => {
      return (
        (entityType === 'product' && hasProductsHeader) ||
        (entityType === 'substance' && hasSubstanceHeader)
      )
    },
    [hasProductsHeader, hasSubstanceHeader]
  )
}
