import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { atcCodeHitsCountSelector } from '../../store/atcCodeHits'

type AtcCodeHitsProps = {
  atcCode: string
}

export const AtcCodeHits = ({ atcCode }: AtcCodeHitsProps) => {
  const hits = useSelector(atcCodeHitsCountSelector)

  if (hits === undefined) {
    return <Skeleton width={20} />
  }

  return hits[atcCode] ?? 0
}
