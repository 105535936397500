import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const ToolbarButton = styled.button`
  width: 32px;
  height: 32px;
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  padding: 0;
  margin: 0;
  border: none;

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
`
