import { css } from '@emotion/react'
import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/ClearRounded'
import Typography from '@mui/material/Typography'
import type { ReactNode } from 'react'
import { Box } from './Layout/Box'

type WarningProps = {
  children: ReactNode
  title?: string
  close?: boolean
  onClose?: () => void
}

export function Warning({
  children,
  title,
  close = false,
  onClose,
}: WarningProps) {
  return (
    <WarningBox close={close}>
      {title && (
        <Box mb={2}>
          <Typography variant="h4">{title}</Typography>
        </Box>
      )}
      {children}
      {close && (
        <CloseButton onClick={() => onClose && onClose()}>
          <CloseIcon fontSize="small" />
        </CloseButton>
      )}
    </WarningBox>
  )
}

const WarningBox = styled.div<{ close: boolean }>`
  border: 1px solid ${(props) => props.theme.palette.info.main};
  background-color: ${(props) => props.theme.palette.info.light};
  border-radius: 5px;
  padding: ${(props) => props.theme.spacing(3, props.theme.space)};
  position: relative;
  ${(props) =>
    props.close &&
    css`
      padding-right: 38px;
    `};
`

const CloseButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: ${(props) => props.theme.spacing(3, props.theme.space)};
  right: 10px;
`
