import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LoadingScreen } from '../components/LoadingScreen/LoadingScreen'
import { Paths } from '../utilities/path.constants'
import { setAccessToken } from '../utilities/token/token'

export function RedirectPage() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const token = searchParams.get('token')
    if (!token) {
      navigate(Paths.Unauthorized, { replace: true })
      return
    }

    setAccessToken(token)

    navigate(Paths.Index, { replace: true })
  }, [searchParams, navigate])

  return <LoadingScreen />
}
