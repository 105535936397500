import { FeatureEnum } from '@austria-codex/types'
import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import { type ReactNode } from 'react'
import { useBundleFeature } from '../../hooks/useBundleFeature'
import { Flex } from '../Layout/Box'
import { BrandLogo } from './BrandLogo'
import { Options } from './OptionsMenu'

type ApplicationBarProps = {
  children: ReactNode
}

const globalStyles = (
  <Global
    styles={(props) => css`
      body {
        ${props.media(1, props.breakpoints)} {
          padding-top: 118px;
        }
        ${props.media(2, props.breakpoints)} {
          padding-top: 68px;
        }
      }
    `}
  />
)

export function ApplicationBar({ children }: ApplicationBarProps) {
  const hasLogo = useBundleFeature(FeatureEnum.Logo)

  return (
    <>
      {globalStyles}
      <Container
        id="application-bar"
        paddingX={[0, 2]}
        paddingBottom={[0, 2, 0]}
        alignItems="center"
        position={['relative', 'fixed']}
        top={0}
        justifyContent="center"
        flexWrap={['wrap', null, 'nowrap']}
      >
        <Side order={0}>{hasLogo && <BrandLogo />}</Side>
        <Middle order={[2, null, 1]}>{children}</Middle>
        <Side
          order={[1, null, 2]}
          justifyContent={['flex-end', null, 'flex-start']}
        >
          <Flex justifyContent="flex-end" marginRight={[2, 0]} marginLeft={2}>
            <Options />
          </Flex>
        </Side>
      </Container>
    </>
  )
}

const Container = styled(Flex)`
  align-items: center;
  top: 0;
  width: 100%;
  background: white;
  min-height: 118px;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

  ${(props) => props.theme.media(2, props.theme.breakpoints)} {
    min-height: 68px;
  }
`

const Side = styled(Flex)`
  flex: 1 1 0;
  align-items: center;
  min-height: 48px;
`

const Middle = styled(Flex)`
  flex: 1 1 100%;
  width: 100%;
  max-width: 992px;
  align-items: center;
`
