import React from 'react'
import styled from '@emotion/styled'
import Fab from '@mui/material/Fab'
import ArrowUpIcon from '@mui/icons-material/ArrowUpward'
import Tooltip from '@mui/material/Tooltip'

const onScrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
}

export function ScrollTop() {
  return (
    <Container>
      <Tooltip title="Nach Oben" aria-label="Nach Oben">
        <Fab onClick={onScrollToTop} color="primary">
          <ArrowUpIcon />
        </Fab>
      </Tooltip>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  bottom: 25px;
  right: 25px;

  ${(props) => props.theme.down(1, props.theme.breakpoints)} {
    display: none;
  }
`
