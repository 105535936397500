import { gql } from '@apollo/client/core'
import {
  INTERACTION_QUERY_FRAGMENT,
  INTERACTION_QUERY_PARTIAL,
} from './partials/interaction'

export const INTERACTIONS_QUERY = gql`
  ${INTERACTION_QUERY_FRAGMENT}

  query interactions($entityIdentifiers: [String!]!) {
    ${INTERACTION_QUERY_PARTIAL}
  }
`
