import type {
  EntityDataUnion,
  ProductData,
  ProductInternationalData,
  ProductUnion,
  ProductVerlaufData,
} from '@austria-codex/types'
import {
  isProduct,
  isProductInternational,
  isProductVerlauf,
  isSubstance,
  isSubstanceInternational,
} from '@austria-codex/utilities'
import { Box } from '@mui/material'
import ProductHumanCombinationLargeCircleIcon from '../../assets/images/entityCircleIcons/human-combi-large.svg?react'
import ProductHumanCombinationSmallCircleIcon from '../../assets/images/entityCircleIcons/human-combi-small.svg?react'
import ProductHumanMonoLargeCircleIcon from '../../assets/images/entityCircleIcons/human-mono-large.svg?react'
import ProductHumanMonoSmallCircleIcon from '../../assets/images/entityCircleIcons/human-mono-small.svg?react'
import SubstanceLargeCircleIcon from '../../assets/images/entityCircleIcons/substance-large.svg?react'
import SubstanceSmallCircleIcon from '../../assets/images/entityCircleIcons/substance-small.svg?react'
import ProductVeterinaryCombinationLargeCircleIcon from '../../assets/images/entityCircleIcons/vet-combi-large.svg?react'
import ProductVeterinaryCombinationSmallCircleIcon from '../../assets/images/entityCircleIcons/vet-combi-small.svg?react'
import ProductVeterinaryMonoLargeCircleIcon from '../../assets/images/entityCircleIcons/vet-mono-large.svg?react'
import ProductVeterinaryMonoSmallCircleIcon from '../../assets/images/entityCircleIcons/vet-mono-small.svg?react'

type IconSizesTypes = 'small' | 'large'

export function productCompositionType(product: ProductUnion) {
  if (isProduct(product)) {
    return product.klassifikation?.istMonopraeparat ? 'MONO' : 'COMBINATION'
  }

  if (isProductVerlauf(product)) {
    return product.klassifikationVerlauf?.istMonopraeparat
      ? 'MONO'
      : 'COMBINATION'
  }

  return product.klassifikationInt?.istMonopraeparat ? 'MONO' : 'COMBINATION'
}

type IconProps = {
  size: IconSizesTypes
}

type EntityIconProps = {
  entity: EntityDataUnion
}

export function EntityCircleIcon({
  entity,
  size,
}: EntityIconProps & IconProps) {
  if (isProduct(entity)) {
    return <ProductCircleIcon product={entity} size={size} />
  }

  if (isProductInternational(entity)) {
    return <ProductCircleIcon product={entity} size={size} />
  }

  if (isProductVerlauf(entity)) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'black',
            height: '100%',
            position: 'absolute',
            transform: 'rotate(45deg)',
            width: '1px',
          }}
        />
        <ProductCircleIcon product={entity} size={size} />
      </Box>
    )
  }

  if (isSubstance(entity)) {
    return <SubstanceCircleIcon size={size} />
  }

  if (isSubstanceInternational(entity)) {
    return <SubstanceCircleIcon size={size} />
  }

  return null
}

const productCircleIcons = {
  HUMAN: {
    MONO: {
      small: ProductHumanMonoSmallCircleIcon,
      large: ProductHumanMonoLargeCircleIcon,
    },
    COMBINATION: {
      small: ProductHumanCombinationSmallCircleIcon,
      large: ProductHumanCombinationLargeCircleIcon,
    },
  },
  VET: {
    MONO: {
      small: ProductVeterinaryMonoSmallCircleIcon,
      large: ProductVeterinaryMonoLargeCircleIcon,
    },
    COMBINATION: {
      small: ProductVeterinaryCombinationSmallCircleIcon,
      large: ProductVeterinaryCombinationLargeCircleIcon,
    },
  },
}

type ProductCircleIconProps = {
  product: ProductData | ProductInternationalData | ProductVerlaufData
}

export function ProductCircleIcon({
  product,
  size,
}: ProductCircleIconProps & IconProps) {
  const domain = product.istVeterinaer ? 'VET' : 'HUMAN'
  const type = productCompositionType(product)

  const Icon = productCircleIcons[domain]?.[type]?.[size]

  return Icon ? <Icon /> : null
}

const substanceCircleIcons = {
  small: SubstanceSmallCircleIcon,
  large: SubstanceLargeCircleIcon,
}

export function SubstanceCircleIcon({ size }: IconProps) {
  const Icon = substanceCircleIcons[size]
  return <Icon />
}
