import {
  AllergyWithCrossAllergiesData,
  ProductData,
} from '@austria-codex/types'
import { isVeterinaryProduct } from '@austria-codex/utilities'
import Typography from '@mui/material/Typography'
import { useContext, useMemo } from 'react'
import ArrowsIcon from '../../assets/images/common/ic-arrows.svg?react'
import { Html } from '../../components/Html/Html'
import { Box, Flex } from '../../components/Layout/Box'
import { List, ListItem } from '../../components/Layout/List'
import { Text } from '../../components/Layout/Typography'
import { Section } from '../../components/Section/Section'
import { EntityContext } from '../../contexts/entity'
import { useAppSelector } from '../../hooks/useStoreHooks'
import { createAllergiesInHighlightsSelector } from '../../store/characteristics'

export function AllergySection() {
  const entity = useContext(EntityContext) as ProductData

  const allergiesInHighlightsSelector = useMemo(
    createAllergiesInHighlightsSelector,
    []
  )
  const highlight = useAppSelector((state) =>
    allergiesInHighlightsSelector(state, entity)
  )

  const allergies = entity.allergien ?? []

  return allergies.length > 0 ? (
    <Section
      width="small"
      heading="Allergien"
      highlight={highlight && !isVeterinaryProduct(entity)}
    >
      <Box mb={-4}>
        {allergies.map((allergy) => (
          <Allergy key={allergy.id} allergy={allergy} />
        ))}
      </Box>
    </Section>
  ) : null
}

type AllergyProps = {
  allergy: AllergyWithCrossAllergiesData
}

function Allergy({ allergy }: AllergyProps) {
  return (
    <Box mb={4}>
      <Flex>
        <Box mr={2}>
          <Text color="primary.main">
            <ArrowsIcon />
          </Text>
        </Box>
        <Box>
          <Typography variant="h6">
            <Html content={allergy.bezeichnung} />
          </Typography>
          <Html content={allergy.beschreibung} />
          {allergy.kreuzallergien && allergy.kreuzallergien.length > 0 && (
            <Box mt={2}>
              <Typography variant="h6">Kreuzallergien</Typography>
              <List>
                {allergy.kreuzallergien.map((crossSensitivity) => (
                  <ListItem key={crossSensitivity.id}>
                    <Typography variant="h6">
                      <Html content={crossSensitivity.bezeichnung} />
                    </Typography>
                    <Html content={crossSensitivity.beschreibung} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  )
}
