import { EntityId } from '@reduxjs/toolkit'

/**
 * Transform a payload into an array. If an array is given, the same
 * array will be returned. If an object is given, the values will be
 * returned as an array.
 *
 * @param payload array or object
 * @returns array of values
 */
export const adapterPayloadAsArray = <T>(
  payload: T[] | Record<EntityId, T>
): T[] => (Array.isArray(payload) ? payload : Object.values(payload))

/**
 * Toggles a value in an array. If the value exists in the array,
 * it will be removed. Otherwise it will be appended.
 *
 * @param array of values
 * @param value value which should be toggled
 * @returns updated array of values
 */
export const toggleArrayValue = <T>(array: T[], value: T): T[] =>
  array.includes(value)
    ? array.filter((arrayValue) => arrayValue !== value)
    : [...array, value]
