import { useQuery } from '@apollo/client'
import {
  FeatureEnum,
  Occurrence,
  OccurrenceInt,
  ProductInternationalData,
  ProductVerlaufData,
  type EntityDataUnion,
  type EntitySearchResultInterface,
  type ProductData,
  type SearchFilter,
  type SearchResultTotalInterface,
} from '@austria-codex/types'
import {
  compareString,
  isNullOrUndefined,
  isProduct,
  isProductIdentifier,
  isProductInternational,
  isProductInternationalIdentifier,
  isProductVerlauf,
  isProductVerlaufIdentifier,
} from '@austria-codex/utilities'
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FilterIcon from '@mui/icons-material/FilterList'
import {
  Button,
  Checkbox,
  CircularProgress,
  Box as MuiBox,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import classNames from 'classnames'
import {
  useCallback,
  useMemo,
  useRef,
  useState,
  type KeyboardEvent,
  type MouseEvent,
  type ReactNode,
} from 'react'
import { useDebounce } from 'use-debounce'
import { SEARCH_QUERY } from '../../../api/search'
import EarthIcon from '../../../assets/images/earth.svg?react'
import OutOfStockIcon from '../../../assets/images/ic-out-of-stock.svg?react'
import { dispensaryIntMapping } from '../../../common/mappings'
import { getAlpha3ByAlpha2 } from '../../../helpers/country.helper'
import { getBox, isType2Or3Product } from '../../../helpers/product.helper'
import {
  getProductsFromQueryResult,
  getSubstancesFromQueryResult,
  groupProductsByKey,
} from '../../../helpers/search.helper'
import { useBundleFeature } from '../../../hooks/useBundleFeature'
import { useFocusWithin } from '../../../hooks/useFocusWithing'
import { useAppDispatch, useAppSelector } from '../../../hooks/useStoreHooks'
import { telemetrie } from '../../../services/telemetrie.service'
import {
  entityIdentifiersSelector,
  fetchEntitiesWithRelatedData,
} from '../../../store/entities'
import { setQuery } from '../../../store/search'
import { setSetting } from '../../../store/settings.store'
import { entityIdentifiersToEntityPartials } from '../../../utilities/entity'
import { closestUnselectedIndex } from '../../../utilities/search'
import { EntityCircleIcon } from '../../EntityIcon/EntityCircleIcon'
import { DispensaryIcon } from '../../Icons/DispensaryIcon'
import { ReimbursementBoxIcon } from '../../Icons/ReimbursementBoxIcon'
import { Box, Flex } from '../../Layout/Box'
import { Text } from '../../Layout/Typography'
import { Numeral, PRICE_FORMAT } from '../../Utility/Numeral'
import { RoutesOfAdministrationToolbar } from '../FiltersToolbar'
import { ProductVerlaufDialog } from '../ProductVerlaufDialog'
import { SearchInputInt } from './SearchInputInt'

enum Key {
  Enter = 'Enter',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
}

type TSearchQueryResult = { search: EntitySearchResultInterface }
type TSearchQueryVariables = {
  query: string
  filter: SearchFilter
}

export function SearchFieldInt() {
  const [focus, focusWithinHandler, blur] = useFocusWithin()
  const [loadingMore, setLoadingMore] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const hasFilter = useBundleFeature(FeatureEnum.SearchBarFilter)
  const selectedCountries = useAppSelector(
    (state) => state.user.selectedCountries
  )
  const selectedRoutesOfAdministration = useAppSelector(
    (state) => state.filter.selectedRoutesOfAdministration
  )
  const selectedEntityIdentifiers = useAppSelector(entityIdentifiersSelector)
  const includesVeterinary = useAppSelector(
    (state) => state.settings.includeVeterinary
  )

  const includesRevokedAuthorizations = useAppSelector(
    (state) => state.settings.includeRevokedAuthorizations
  )
  const includesOutOfStockProducts = useAppSelector(
    (state) => state.settings.includeOutOfStockProducts
  )

  const list = useRef<HTMLUListElement>(null)
  const input = useRef<HTMLInputElement>(null)

  const dispatch = useAppDispatch()
  const query = useAppSelector((state) => state.search.query)

  const [debouncedQuery] = useDebounce(query, 500)

  const { loading, data, fetchMore } = useQuery<
    TSearchQueryResult,
    TSearchQueryVariables
  >(SEARCH_QUERY, {
    variables: {
      query: debouncedQuery,
      filter: {
        isoCodes: selectedCountries,
        includeVeterinary: includesVeterinary,
        includeVerlaufsTyp5: includesRevokedAuthorizations,
        includeOutOfStockProducts: includesOutOfStockProducts,
        routesOfAdministration: selectedRoutesOfAdministration,
      },
    },
    skip: debouncedQuery.length < 3,
  })

  function onChangeQuery(query: string) {
    dispatch(setQuery({ query, hitAdded: false }))
  }

  const onAddEntry = useCallback(
    (id: string, name: string) => {
      const ids = entityIdentifiersToEntityPartials([id])

      dispatch(setQuery({ query: '', hitAdded: true }))
      dispatch(fetchEntitiesWithRelatedData(ids))

      blur()

      if (input.current) {
        input.current.blur()
      }

      if (
        isProductIdentifier(id) ||
        isProductInternationalIdentifier(id) ||
        isProductVerlaufIdentifier(id)
      ) {
        telemetrie.searchDrugClicked(id, name)
      } else {
        telemetrie.searchSubstanceClicked(id, name)
      }
    },
    [blur, dispatch]
  )

  const onKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const key = event.key

      if (key === Key.Enter && data) {
        if (data.search.hits.length > 0) {
          const hit = data.search.hits[selectedIndex]
          if (hit) {
            onAddEntry(hit.id, hit.bezeichnung)
          }
        }
        return
      }

      if ((key === Key.ArrowUp || key === Key.ArrowDown) && data) {
        const reverse = key === Key.ArrowUp

        const nextSelectedIndex = closestUnselectedIndex(
          selectedIndex,
          data.search.hits,
          selectedEntityIdentifiers,
          reverse
        )

        if (list.current) {
          const item = list.current.children.item(nextSelectedIndex)
          if (item) {
            item.scrollIntoView({ block: 'nearest' })
          }
        }

        setSelectedIndex(nextSelectedIndex)
      }
    },
    [
      selectedIndex,
      setSelectedIndex,
      data,
      onAddEntry,
      selectedEntityIdentifiers,
    ]
  )

  const onClickMore = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      input.current?.focus()

      setLoadingMore(true)

      const offset = data?.search.offset

      fetchMore({
        variables: {
          query: debouncedQuery,
          // The first search request returns the top suggestions with an 'offset' of 'null'.
          // So for the first 'fetchMore', the offset is 0, after that, the 'offset' is
          // the returned value + the next 30.
          offset: isNullOrUndefined(offset) ? 0 : offset + 30,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult
          }

          return {
            ...fetchMoreResult,
            search: {
              ...fetchMoreResult.search,
              hits: [
                ...previousResult.search.hits,
                ...fetchMoreResult.search.hits,
              ],
            },
          }
        },
      }).finally(() => {
        // Need to use timeout here to wait for the new elements to be rendered.
        setTimeout(() => {
          if (list && list.current) {
            const scrollTop = list.current.scrollTop
            list.current.scrollTo({ top: scrollTop + 32, behavior: 'smooth' })
          }

          setLoadingMore(false)
        }, 200)
      })
    },
    [fetchMore, debouncedQuery, data]
  )

  const total = data
    ? data.search.total.products + data.search.total.substances
    : 0

  const showLoadingMoreBtn = data && total > data.search.hits.length

  const [substances, groupedProducts] = useMemo(() => {
    const substances = getSubstancesFromQueryResult(data?.search.hits)
    const products = getProductsFromQueryResult(data?.search.hits)
    const groupedProducts = groupProductsByKey(products)

    return [substances, groupedProducts]
  }, [data])

  return (
    <Box
      position="relative"
      minHeight={[showFilter ? 104 : 54, 54]}
      display="flex"
      flexGrow={1}
    >
      <Container
        open={focus}
        onKeyDown={onKeyPress}
        borderRadius={[0, 3]}
        {...focusWithinHandler}
      >
        <Flex minHeight="54px" flexWrap={['wrap', 'nowrap']} flex={'0 0 auto'}>
          {hasFilter && (
            <FilterToolBar flexBasis={['100%', 0]} open={showFilter}>
              <RoutesOfAdministrationToolbar />
            </FilterToolBar>
          )}
          <Flex flex={1}>
            {hasFilter && (
              <>
                <Flex display={['flex', 'none']}>
                  <IconButton
                    color="primary"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <FilterIcon />
                  </IconButton>
                </Flex>
                <Flex width="2px" my="12px" bg="primary.main" />
              </>
            )}
            <SearchInputInt
              ref={input}
              value={query}
              onChange={onChangeQuery}
            />
          </Flex>
        </Flex>
        <MuiBox sx={{ display: focus ? 'block' : 'none' }}>
          {query.length === 0 ? null : loading ? (
            <Status large>Daten werden geladen...</Status>
          ) : query.length < 3 ? (
            <Status>Suchbegriff muss länger sein.</Status>
          ) : data?.search?.hits?.length === 0 ? (
            <Status>Keine Ergebnisse gefunden.</Status>
          ) : (
            <>
              <Hits ref={list}>
                {groupedProducts.map(([name, data], index) => {
                  if (data.isInternational && data.products.length > 1) {
                    const products = data.products as ProductInternationalData[]

                    return (
                      <GroupedHits
                        key={name}
                        name={name}
                        startIndex={data.index}
                        selectedIndex={selectedIndex}
                        products={products}
                        onHover={(index) => setSelectedIndex(index)}
                        onAdd={onAddEntry}
                      />
                    )
                  }

                  const entity = data.products[0]
                  return (
                    <Hit
                      key={entity.id}
                      entity={entity}
                      disabled={selectedEntityIdentifiers.includes(entity.id)}
                      selected={selectedIndex === data.index}
                      onHover={() => setSelectedIndex(data.index)}
                      onAdd={onAddEntry}
                    />
                  )
                })}
                {substances.map((s, index) => {
                  return (
                    <Hit
                      key={s.id}
                      entity={s}
                      disabled={selectedEntityIdentifiers.includes(s.id)}
                      selected={selectedIndex === index}
                      onHover={() => setSelectedIndex(index)}
                      onAdd={onAddEntry}
                    />
                  )
                })}
              </Hits>
              <MuiBox
                component="li"
                sx={{
                  display: 'flex',
                  flex: '1 1 auto',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                }}
              >
                <Meta total={data?.search.total}>
                  <MuiBox
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      width: 120,
                    }}
                  >
                    {loadingMore ? (
                      <CircularProgress size={20} thickness={4} />
                    ) : showLoadingMoreBtn ? (
                      <MoreButton onClick={onClickMore}>
                        Mehr anzeigen
                      </MoreButton>
                    ) : null}
                  </MuiBox>
                </Meta>
              </MuiBox>
            </>
          )}
        </MuiBox>
      </Container>
    </Box>
  )
}

type GroupedHitsProps = {
  name: string
  products: ProductInternationalData[]
  startIndex: number
  selectedIndex: number
  onHover: (index: number) => void
  onAdd: (identifier: string, name: string) => void
}

function GroupedHits({
  name,
  products,
  startIndex,
  selectedIndex,
  onHover,
  onAdd,
}: GroupedHitsProps) {
  const selectedEntityIdentifiers = useAppSelector(entityIdentifiersSelector)
  const [open, setOpen] = useState(false)

  // Sort by iso code
  products.sort((a, b) => compareString(a.isoCode, b.isoCode))

  return (
    <>
      <MuiBox
        component="li"
        // onKeyDown={handleKeyDown}
        sx={{
          backgroundColor:
            startIndex === selectedIndex && !open ? 'primary.light' : 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '64px',
          padding: '0.25rem 1rem',
        }}
      >
        <MuiBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            gap: '1rem',
          }}
        >
          <EntityCircleIcon entity={products[0]} size="small" />
          <MuiBox component="span">{name}</MuiBox>
        </MuiBox>
        <Button
          variant="text"
          sx={{ display: 'flex', alignItems: 'center' }}
          onClick={() => setOpen(!open)}
        >
          <EarthIcon width="14" height="14" viewBox="0 0 24 24" />
          <MuiBox
            component="span"
            sx={{ fontSize: '0.75rem', marginLeft: '0.5rem' }}
          >
            {products.length}
          </MuiBox>
          <ExpandMoreIcon sx={{ transform: open ? 'rotate(180deg)' : '' }} />
        </Button>
      </MuiBox>
      {open && (
        <MuiBox sx={{ borderLeft: '1px solid green', marginLeft: '46px' }}>
          {products.map((p, index) => {
            const realIndex = startIndex + index
            const isSelected = realIndex === selectedIndex
            const isDisabled = selectedEntityIdentifiers.includes(p.id)
            const alpha3 = getAlpha3ByAlpha2(p.isoCode)

            return (
              <MuiBox
                key={p.id}
                sx={{
                  backgroundColor: isDisabled
                    ? 'grey.200'
                    : isSelected
                      ? 'primary.light'
                      : 'white',
                  padding: '0.5rem 3rem 0.5rem 1rem',
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  pointerEvents: isDisabled ? 'none' : 'auto',
                  '&:hover': {
                    backgroundColor: isDisabled ? 'grey.200' : 'primary.light',
                  },
                }}
                onMouseEnter={() => onHover(realIndex)}
                onClick={() => onAdd(p.id, p.bezeichnung)}
              >
                <MuiBox>
                  {p.acoSearchTerm ?? p.bezeichnung}{' '}
                  <MuiBox component="span" sx={{ color: 'grey.500' }}>
                    {p.kontakteInt?.anbieter.kurzname ?? ''}
                  </MuiBox>
                </MuiBox>
                <MuiBox
                  sx={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}
                >
                  <ProductIntHit product={p} />
                  <MuiBox sx={{ color: 'grey.500' }}>
                    {alpha3.toUpperCase()}
                  </MuiBox>
                </MuiBox>
              </MuiBox>
            )
          })}
        </MuiBox>
      )}
    </>
  )
}

type FilterToolbarProps = {
  open: boolean
}

const FilterToolBar = styled(Flex)<FilterToolbarProps>`
  display: ${(props) => (props.open ? 'flex' : 'none')};

  ${(props) => props.theme.up(1, props.theme.breakpoints)} {
    display: flex;
    flex: 0 0 auto;
  },
`

type MetaProps = {
  total: SearchResultTotalInterface | undefined
  children: ReactNode
}

function Meta({ total, children }: MetaProps) {
  const hasProducts = total && total.products > 0
  const hasSubstances = total && total.substances > 0
  const theme = useTheme()

  return (
    <MuiBox
      sx={{
        alignItems: 'left',
        display: 'flex',
        justifyContent: 'space-between',
        height: 'auto',
        px: 2,
        py: 1,
        lineHeight: 2,
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
      }}
      css={css`
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top: 1px solid ${theme.palette.grey[200]};
        background: ${theme.palette.grey[50]};
      `}
    >
      <MuiBox sx={{ alignItems: 'center', columnGap: 1, display: 'flex' }}>
        <MuiBox>
          {!hasProducts && !hasSubstances ? (
            <Text fontStyle="italic">Es wurden keine Treffer gefunden.</Text>
          ) : (
            <Text>
              {hasProducts && (
                <>
                  <Text fontWeight="bold">{total.products}</Text> Produkte
                </>
              )}
              {hasProducts && hasSubstances && ' und '}
              {hasSubstances && (
                <>
                  <Text fontWeight="bold">{total.substances}</Text> Stoffe
                </>
              )}
            </Text>
          )}
        </MuiBox>
        {children}
      </MuiBox>
      <OutOfStockCheckbox />
    </MuiBox>
  )
}

function OutOfStockCheckbox() {
  const dispatch = useAppDispatch()
  const { includeOutOfStockProducts } = useAppSelector(
    (state) => state.settings
  )

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(
      setSetting({
        key: 'includeOutOfStockProducts',
        value: event.target.checked,
      })
    )
  }

  return (
    <MuiBox sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
      <Text>Nicht lieferbare Produkte anzeigen</Text>
      <Checkbox
        sx={{ padding: 0 }}
        checked={includeOutOfStockProducts}
        onChange={handleCheckboxChange}
      />
    </MuiBox>
  )
}

type ContainerProps = {
  open: boolean
}

const Container = styled(Box)<ContainerProps>(({ theme, open }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  zIndex: 10000,
  background: open ? 'white' : theme.palette.primary.light,
  boxShadow: open ? '0 0 10px rgba(0, 0, 0, 0.5)' : 'none',
  transition: 'background-color 0.3s, box-shadow 0.3s ease-out',
}))

type StatusProps = {
  large?: boolean
}

const Status = styled.div<StatusProps>`
  align-items: center;
  display: flex;
  font-style: italic;
  justify-content: center;
  padding: ${(props) => props.theme.spacing(2, props.theme.space)};

  ${(props) =>
    props.large &&
    css`
      height: ${64 * 5}px;
      height: ${64 * 5}px;
    `}
`

const MoreButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  line-height: inherit;
  font-family: inherit;
  font-size: inherit;
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  font-weight: bold;
`

const Hits = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  height: ${64 * 5}px;
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior: contain;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`

type HitProps = {
  entity: EntityDataUnion
  disabled: boolean
  selected: boolean
  onHover: () => void
  onAdd: (identifier: string, name: string) => void
}

function Hit({ entity, disabled, selected, onHover, onAdd }: HitProps) {
  const hasAdditionalInformation = useBundleFeature(
    FeatureEnum.SearchBarAdditionalInformation
  )

  const [dialogOpen, setDialogOpen] = useState(false)

  const isProdInt = isProductInternational(entity)
  const isProdAt = isProduct(entity)
  const isProdVerlauf = isProductVerlauf(entity)

  const bezeichnung = isProdInt ? entity.acoSearchTerm : entity.bezeichnung
  const isSub = !isProdAt && !isProdInt

  function handleHover() {
    onHover()
  }

  function handleClick() {
    if (disabled || dialogOpen) return

    if (isProductVerlauf(entity)) {
      setDialogOpen(true)
      return
    }

    onAdd(entity.id, entity.bezeichnung)
  }

  const classes = classNames({
    product: !isSub,
    substance: isSub,
  })

  const showCountry = isProdAt || isProdInt

  return (
    <li>
      <EntryLink
        disabled={disabled}
        selected={selected}
        className={classes}
        onMouseEnter={handleHover}
        onClick={handleClick}
      >
        <Flex marginRight={3}>
          <EntityCircleIcon entity={entity} size="small" />
        </Flex>
        <Flex flexWrap={['wrap', 'nowrap']} flex="1">
          <Flex
            flex="1 1 100%"
            alignItems="center"
            className="tracking-search-result"
          >
            <Text
              textAlign="left"
              dangerouslySetInnerHTML={{ __html: bezeichnung }}
            />
            {isProduct(entity) && entity.suffix && (
              <>
                &nbsp;
                <Text fontStyle="italic"> {entity.suffix}</Text>
              </>
            )}
          </Flex>
          {hasAdditionalInformation && (
            <Flex
              marginLeft={[0, 3]}
              flex="0 0 auto"
              alignItems="center"
              flexDirection={['row-reverse', 'row']}
            >
              {isProdAt && <ProductHit product={entity} />}
              {isProdInt && <ProductIntHit product={entity} />}
              {isProdVerlauf && <Text>Zulassung aufgehoben</Text>}
              {isProdVerlauf && entity.isoCode && (
                <MuiBox sx={{ marginLeft: '1.5rem', color: 'grey.500' }}>
                  {getAlpha3ByAlpha2(entity.isoCode).toUpperCase()}
                </MuiBox>
              )}
              {showCountry && (
                <MuiBox sx={{ marginLeft: '1.5rem', color: 'grey.500' }}>
                  {getAlpha3ByAlpha2(entity.isoCode).toUpperCase()}
                </MuiBox>
              )}
            </Flex>
          )}
        </Flex>
      </EntryLink>
      {dialogOpen && (
        <ProductVerlaufDialog
          open={dialogOpen}
          handleClose={() => setDialogOpen(false)}
          entity={entity as ProductVerlaufData}
        />
      )}
    </li>
  )
}

type ProductHitProps = {
  product: ProductData
}

function ProductHit({ product }: ProductHitProps) {
  const { abgabe, verkauf } = product
  const type2Or3Product = isType2Or3Product(product.ausVerlauf)
  const box = getBox(abgabe?.ekoBoxen)

  return (
    <>
      {!type2Or3Product && product.istLieferbar === Occurrence.No && (
        <Flex mx={1} alignItems="center" justifyContent="center">
          <OutOfStockIcon />
        </Flex>
      )}
      {type2Or3Product && (
        <Flex mx={1} alignItems="center" justifyContent="center">
          <Text>nicht im Handel</Text>
        </Flex>
      )}
      {!type2Or3Product && verkauf?.preisAb && verkauf?.anzahlArtikel && (
        <Box ml={2} mr={1}>
          <Price
            price={box ? verkauf.preisAb.preisKVP : verkauf.preisAb.preisUVP}
            praefix={verkauf.anzahlArtikel > 1 ? 'ab ' : ''}
            suffix={box ? ' KVP' : ' UVP'}
          />
        </Box>
      )}
      {box && (
        <Box ml={1}>
          <ReimbursementBoxIcon type={box} />
        </Box>
      )}
      {!type2Or3Product && abgabe?.art && (
        <Box ml={[0, 1]}>
          <DispensaryIcon type={abgabe.art} />
        </Box>
      )}
    </>
  )
}

type ProductIntHitProps = {
  product: ProductInternationalData
}

function ProductIntHit({ product }: ProductIntHitProps) {
  const abgabe = product.abgabeBestimmung
  const abgabeType =
    abgabe !== null && abgabe !== undefined
      ? dispensaryIntMapping[abgabe]
      : null

  return (
    <>
      {product.istLieferbar !== OccurrenceInt.ImHandel && (
        <Flex mx={1} alignItems="center" justifyContent="center">
          <OutOfStockIcon />
        </Flex>
      )}
      {abgabeType && (
        <Box ml={[0, 1]}>
          <DispensaryIcon type={abgabeType} />
        </Box>
      )}
    </>
  )
}

type PriceProps = {
  price: number | undefined
  praefix?: string
  suffix?: string
}

function Price({ price, praefix, suffix }: PriceProps) {
  return price ? (
    <Text fontStyle="italic">
      {praefix}
      <Numeral input={price} format={PRICE_FORMAT} />
      {suffix}
    </Text>
  ) : null
}

type EntryLinkProps = {
  selected: boolean
  disabled: boolean
}

const EntryLink = styled.button<EntryLinkProps>`
  align-items: center;
  background: white;
  border: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  min-height: 64px;
  padding: 0.25rem 3rem 0.25rem 1rem;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  width: 100%;

  & + & {
    border-top: 1px solid transparent;
  }

  &.product + &.substance,
  &.substance + &.product {
    border-color: ${(props) => props.theme.palette.grey[200]};
  }

  ${(props) =>
    props.disabled &&
    css`
      background: ${props.theme.palette.grey[200]};
    `}

  ${(props) =>
    props.selected &&
    !props.disabled &&
    css`
      background: ${props.theme.palette.primary.light};
      cursor: pointer;
    `}
`
