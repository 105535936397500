import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import Close from '@mui/icons-material/Close'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactNode } from 'react'

type DataTableOverlayProps = {
  open: boolean
  onClose: () => Promise<void>
  table: ({ hasOverlay }: { hasOverlay?: boolean }) => EmotionJSX.Element
  isLoadingFetchMore: boolean
  handleFetchMore: () => Promise<void>
  heading?: ReactNode | string
  subHeading1?: ReactNode | string
  subHeading2?: ReactNode | string
  hitsLength: number
  total: number
}

export function DataTableOverlay({
  open,
  onClose,
  table,
  isLoadingFetchMore,
  handleFetchMore,
  heading,
  subHeading1,
  subHeading2,
  hitsLength,
  total,
}: DataTableOverlayProps) {
  const theme = useTheme()
  return (
    <Box display="flex" justifyContent="flex-end">
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="data-table-overlay-title"
        sx={{ maxHeight: '90vh' }}
      >
        <DialogTitle
          sx={{ borderBottom: `2px solid ${theme.palette.grey[300]}` }}
          id="data-table-overlay-title"
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {typeof heading === 'string' ? (
                  <Typography
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    variant="h4"
                  >
                    {heading}
                  </Typography>
                ) : (
                  heading
                )}
              </Box>
              <Typography variant="h5" sx={{ color: theme.palette.grey[700] }}>
                {subHeading1} {subHeading2}
              </Typography>
            </Box>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          {table({ hasOverlay: false })}
          {hitsLength === total ? null : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              m={1}
              minHeight={40}
            >
              {isLoadingFetchMore ? (
                <CircularProgress size={24} />
              ) : (
                <Button onClick={handleFetchMore} disabled={isLoadingFetchMore}>
                  Mehr anzeigen
                </Button>
              )}
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  )
}
