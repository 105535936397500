import {
  EInteractionDescription,
  EInteractionSeverity,
  type InteractionData,
} from '@austria-codex/types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Paper from '@mui/material/Paper'
import { useMemo } from 'react'
import { CollapseIcon } from '../../../components/Icons/CollapseIcon'
import { Anchor } from '../../../components/Layout/Anchor'
import { Box, Flex } from '../../../components/Layout/Box'
import { Text } from '../../../components/Layout/Typography'
import {
  getDescriptionTitleForType,
  hasInteractionGroups,
} from '../../../helpers/interaction.helper'
import { useCollapse } from '../../../hooks/useCollapse'
import { getInteractionEntityPairs } from '../../../utilities/interactions'
import { DotIndicator } from './DotIndicator'
import { InteractionDescriptionsCollapse } from './InteractionDescriptionsCollapse'
import { InteractionHeader } from './InteractionHeader'
import { InteractionPair } from './InteractionPair'
import {
  evaluationDescription,
  mechanismDescription,
  toZeroBasedIndexNumber,
} from './constants'

type InteractionProps = {
  interaction: InteractionData
}

export function Interaction({ interaction }: InteractionProps) {
  const [collapse, onToggle] = useCollapse({
    section: interaction.id,
  })

  const pairs = useMemo(
    () => getInteractionEntityPairs(interaction),
    [interaction]
  )

  const [left, right] = interaction.bezeichnung.split('><')

  const title = getDescriptionTitleForType(
    interaction.beschreibungen,
    EInteractionDescription.PharmakologischerEffektKurz
  )

  const showInformation =
    interaction.beschreibungen.length > 0 &&
    interaction.schwereGrad > EInteractionSeverity.NoInteraction

  return (
    <Paper>
      <InteractionHeader severity={interaction.schwereGrad} title={title} />
      {interaction.schwereGrad > EInteractionSeverity.NoInteraction && (
        <>
          <Flex flexDirection={['column', 'row']} py={3}>
            <Flex
              flexGrow={1}
              alignItems="center"
              justifyContent="flex-end"
              width="47%"
            >
              <Text marginRight={2}>
                <b>Plausibilität: </b>
                {interaction.pharmPlausibilitaet
                  ? mechanismDescription[interaction.pharmPlausibilitaet]
                  : '---'}
              </Text>
              <DotIndicator
                value={toZeroBasedIndexNumber(interaction.pharmPlausibilitaet)}
                dotCount={2}
              />
            </Flex>
            <Flex justifyContent="center" width="6%" color="#D9D9D9">
              |
            </Flex>
            <Flex
              flexGrow={1}
              alignItems="center"
              justifyContent="flex-start"
              width="47%"
            >
              <Text marginRight={2}>
                <b>Datenlage: </b>
                {interaction.quellenBewertung
                  ? evaluationDescription[interaction.quellenBewertung]
                  : '---'}
              </Text>
              <DotIndicator
                value={toZeroBasedIndexNumber(interaction.quellenBewertung)}
                dotCount={4}
              />
            </Flex>
          </Flex>
          {<Divider mx={4} mb={3} />}
        </>
      )}
      <Box mx={2}>
        <Text display="block" textAlign="center" variant="caption" margin={1}>
          STOFFE BZW. STOFFGRUPPEN
        </Text>
        <InteractionPair
          left={left}
          right={right}
          direction={interaction.interaktionsRichtung}
        />
      </Box>
      {hasInteractionGroups(interaction) && (
        <Box mx={2}>
          <Text display="block" textAlign="center" variant="caption">
            BETROFFEN KÖNNEN SEIN
          </Text>
          {pairs.map(({ id, left, right }) => (
            <InteractionPair
              key={id}
              left={left}
              right={right}
              direction={interaction.interaktionsRichtung}
            />
          ))}
        </Box>
      )}
      {showInformation && (
        <>
          <Divider mx={4} />
          <Flex flexDirection="column" alignItems="center" py={2}>
            <Anchor
              css={css`
                align-items: center;
                display: flex;
                text-align: left;
              `}
              onClick={() => onToggle()}
            >
              <Text fontWeight="bold">Interaktionsinformation</Text>
              <CollapseIcon collapse={collapse} />
            </Anchor>
          </Flex>
          {collapse && (
            <InteractionDescriptionsCollapse
              descriptions={interaction.beschreibungen}
              interaction={interaction}
            />
          )}
        </>
      )}
    </Paper>
  )
}

const Divider = styled(Box)`
  height: 1px;
  background: ${(props) => props.theme.palette.grey[300]};
`
