import { Divider, List, ListItem, ListItemText, Switch } from '@mui/material'
import type { ChangeEvent } from 'react'
import { Box } from '../../components/Layout/Box'
import { Text } from '../../components/Layout/Typography'
import { Warning } from '../../components/Warning'
import { useAppDispatch, useAppSelector } from '../../hooks/useStoreHooks'
import { setPersistentSetting } from '../../store/settings.store'

export function AccountPage() {
  const dispatch = useAppDispatch()
  const { collapseOpenOnAdd, includeVeterinary, includeRevokedAuthorizations } =
    useAppSelector((state) => state.settings)

  return (
    <Box my={4}>
      <List
        subheader={
          <Box ml={3} my={2}>
            <Text variant="h4" fontWeight="bold">
              Einstellungen
            </Text>
          </Box>
        }
      >
        <Divider component="li" />
        <ListItem>
          <ListItemText primary="Veterinärmedizinische Spezialitäteninformation ausblenden" />
          <Switch
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              dispatch(
                setPersistentSetting('includeVeterinary', !event.target.checked)
              )
            }
            checked={!includeVeterinary}
          />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText primary="Spezialitäteninformation beim Hinzufügen automatisch ausklappen" />
          <Switch
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              dispatch(
                setPersistentSetting('collapseOpenOnAdd', event.target.checked)
              )
            }
            checked={collapseOpenOnAdd}
          />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText primary="Arzneispezialitäten mit aufgehobener Zulassung anzeigen" />
          <Switch
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              dispatch(
                setPersistentSetting(
                  'includeRevokedAuthorizations',
                  event.target.checked
                )
              )
            }
            checked={includeRevokedAuthorizations}
          />
        </ListItem>
        {includeRevokedAuthorizations && (
          <Box m={2}>
            <Warning>
              <b>Hinweis:</b> Bei zulassungsaufgehobenen Arzneispezialitäten
              sind möglicherweise nicht alle Informationen verfügbar. Aus diesem
              Grund verfügt diese Kategorie über eine separate Darstellung.
            </Warning>
          </Box>
        )}
      </List>
    </Box>
  )
}
