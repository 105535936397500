import { IndikationsGruppe } from '@austria-codex/types'
import { isProductInternational } from '@austria-codex/utilities'
import { Box, useTheme } from '@mui/material'
import { useState } from 'react'
import DeIcon from '../../../assets/images/countryFlagsSvg/de-sm.svg?react'
import { AbDataLogo } from '../../../components/AbDataLogo'
import { Section } from '../../../components/Section/Section'
import { useEntityDataInt } from '../../../contexts/entity'
import { FeatureProps } from '../../../types/bundle'
import { IntIndicationGroupProductsTable } from './IndicationGroups/IntIndicationGroupProductsTable'

function Heading() {
  return (
    <>
      <DeIcon />
      <Box sx={{ ml: 0.75 }}>Indikationsgruppe Deutschland</Box>
    </>
  )
}

export function IntIndicationGroupDeSection({ features }: FeatureProps) {
  const entityInt = useEntityDataInt()
  const theme = useTheme()

  const indiGroups = isProductInternational(entityInt)
    ? (entityInt?.klassifikationInt?.indikationsGruppen ?? [])
    : []

  if (indiGroups.length === 0) {
    return null
  }

  return (
    <Section
      heading={<Heading />}
      headingLogo={<AbDataLogo height="22px" />}
      width="small"
    >
      <Box
        sx={{
          mt: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '1.375rem',
          borderTop: `1px solid ${theme.palette.grey[600]}`,
          borderBottom: `1px solid ${theme.palette.grey[600]}`,
          py: 2,
        }}
      >
        {indiGroups.map((ig) => (
          <IndicationGroup key={ig.indCode} indicationGroup={ig} />
        ))}
      </Box>
    </Section>
  )
}

type TIndicationGroupProps = {
  indicationGroup: IndikationsGruppe
}

function IndicationGroup({ indicationGroup }: TIndicationGroupProps) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Box
        component="button"
        onClick={() => setOpen(!open)}
        sx={{
          background: 'inherit',
          border: 0,
          outline: 'none',
          color: 'primary.main',
          cursor: 'pointer',
          display: 'flex',
          fontSize: '0.875rem',
          justifyContent: 'space-between',
        }}
      >
        <Box>{indicationGroup.bezeichnung}</Box>
        <Box sx={{ color: 'grey.800', width: '54px', textAlign: 'left' }}>
          {indicationGroup.indCode}
        </Box>
      </Box>
      {open && (
        <Box>
          <IntIndicationGroupProductsTable
            indicationGroupName={indicationGroup.bezeichnung}
            indicationGroupCode={indicationGroup.indCode}
            onClose={() => setOpen(false)}
            heading={<Heading />}
          />
        </Box>
      )}
    </>
  )
}
