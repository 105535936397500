import {
  ProductVerlaufApplicationWarningsData,
  ProductVerlaufClassificationData,
  ProductVerlaufCompositionData,
  ProductVerlaufContactsData,
  ProductVerlaufData,
  ProductVerlaufDocumentationQuickHelpData,
  ProductVerlaufIntolerancesData,
  ProductVerlaufStorageData,
} from '@austria-codex/types'
import CloseIcon from '@mui/icons-material/Close'
import { Box, CircularProgress } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import Typography from '@mui/material/Typography'

import {
  forwardRef,
  ReactElement,
  Ref,
  UIEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useFetchProductVerlaufForDialog } from '../../api/api'
import { Config } from '../../config'
import {
  getKlassifikation,
  getKurzinformation,
  getWarnhinweise,
  hasLagerung,
} from '../../helpers/product-verlauf.helper'
import { EntityCircleIcon } from '../EntityIcon/EntityCircleIcon'
import { Anchor } from '../Layout/Anchor'
import { Text } from '../Layout/Typography'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

type ProductVerlaufDialogProps = {
  open: boolean
  handleClose: () => void
  entity: ProductVerlaufData
}

type FileStatus = 'unknown' | 'exists' | 'not-exists'

export function ProductVerlaufDialog({
  open,
  handleClose,
  entity,
}: ProductVerlaufDialogProps) {
  const [fiDocStatus, setFiDocStatus] = useState<FileStatus>('unknown')
  const [giDocStatus, setGiDocStatus] = useState<FileStatus>('unknown')
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [scrollActive, setScrollActive] = useState(false)
  const contentElem = useRef<HTMLDivElement | null>(null)

  const { id, bezeichnung } = entity

  const { loading, data } = useFetchProductVerlaufForDialog(id)

  function handleScroll(event: UIEvent<HTMLDivElement>) {
    if (scrollActive) return

    const elem = event.currentTarget
    const scrollTop = elem.scrollTop
    const headings = elem.querySelectorAll<HTMLDivElement>(
      'div[id^="vertical-tabpanel-"]'
    )

    // Find the id (index of the section),
    // which is currently active.
    let indexId = 0
    headings.forEach((h, index) => {
      // Skip the first section
      if (index === 0) return

      // Add some "padding" so that the id
      // increases when the heading is not
      // yet at the top.
      if (scrollTop + 150 > h.offsetTop) {
        indexId++
      }
    })

    setSelectedTabIndex(indexId)
  }

  function scrollToElement(index: number) {
    if (contentElem.current) {
      const elem = contentElem.current.querySelector(
        '#vertical-tabpanel-' + index
      )
      if (elem) {
        setScrollActive(true)

        elem.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })

        // Need to set a timeout here,
        // because the scroll itself
        // takes some time.
        setTimeout(() => {
          setScrollActive(false)
        }, 1000)
      }
    }
  }

  const {
    klassifikationVerlauf,
    zusammensetzung,
    dokumentation,
    intoleranzen,
    anwWarnHinweise,
    lagerung,
    kontakte,
    zulassungsnummer,
    zulassungsnummerAlternativ,
  } = data?.product || {}

  const baseUrl = zulassungsnummerAlternativ
    ? `${Config.api.url}${Config.api.endpoints.document}/${zulassungsnummerAlternativ}`
    : null
  const fiUrl = baseUrl ? `${baseUrl}/ac` : null
  const giUrl = baseUrl ? `${baseUrl}/gi` : null

  useEffect(() => {
    async function fiAndGiExists(zulassungsnummerAlternativ: string) {
      const fiUrl = `${Config.api.url}${Config.api.endpoints.documentExists}/${zulassungsnummerAlternativ}/ac`
      const giUrl = `${Config.api.url}${Config.api.endpoints.documentExists}/${zulassungsnummerAlternativ}/gi`

      const [fiData, giData] = await Promise.all([fetch(fiUrl), fetch(giUrl)])

      const fiBody = (await fiData.json()) as { exists: boolean }
      const giBody = (await giData.json()) as { exists: boolean }

      setFiDocStatus(fiBody.exists ? 'exists' : 'not-exists')
      setGiDocStatus(giBody.exists ? 'exists' : 'not-exists')
    }

    if (zulassungsnummerAlternativ) {
      fiAndGiExists(zulassungsnummerAlternativ)
    }
  }, [zulassungsnummerAlternativ])

  const productDataArray = [
    {
      title: 'Klassifikation',
      value: getKlassifikation(klassifikationVerlauf),
    },
    { title: 'Zusammensetzung', value: zusammensetzung },
    {
      title: 'Kurzinformation',
      value: getKurzinformation(dokumentation?.schnellhilfe),
    },
    { title: 'Intoleranzen', value: intoleranzen },
    { title: 'Warnhinweise', value: getWarnhinweise(anwWarnHinweise) },
    { title: 'Lagerung', value: hasLagerung(lagerung) ? lagerung : null },
    { title: 'Kontakte', value: kontakte },
  ]

  // Filter für Einträge mit definierten Werten
  const filteredProductArray = productDataArray.filter(
    (item) => item.value !== null && item.value !== undefined
  )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { maxWidth: '992px' } }}
      fullWidth
      sx={{ '> .MuiDialog-container > .MuiPaper-root': { height: '85%' } }}
      scroll="paper"
      keepMounted
      TransitionComponent={Transition}
    >
      <DialogTitle
        component="div"
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 2px 2px rgba(0,0,0,0.25)',
          color: 'text.primary',
          display: 'flex',
          fontWeight: 400,
          justifyContent: 'space-between',
          columnGap: '1rem',
          position: 'relative',
        }}
      >
        {/* Close Button in the Top Right absolute position*/}
        <IconButton
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box>
          <Typography variant="h4" sx={{ pb: 1 }}>
            Archiv
          </Typography>
          <Typography>{bezeichnung}</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              columnGap: 2,
              py: 1,
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            {fiDocStatus === 'exists' ? (
              <Anchor
                target="_blank"
                rel="noopener noreferrer"
                href={fiUrl ?? ''}
              >
                Fachinformation
              </Anchor>
            ) : fiDocStatus === 'not-exists' ? (
              <Box sx={{ color: 'red' }}>X Keine Fachinformation verfügbar</Box>
            ) : null}
            {giDocStatus === 'exists' ? (
              <Anchor
                target="_blank"
                rel="noopener noreferrer"
                href={giUrl ?? ''}
              >
                Gebrauchsinformation
              </Anchor>
            ) : giDocStatus === 'not-exists' ? (
              <Box sx={{ color: 'red' }}>
                X Keine Gebrauchsinformation verfügbar
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 2,
            flexDirection: { xs: 'column', sm: 'row' },
            mt: 2,
          }}
        >
          <EntityCircleIcon entity={entity} size="small" />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Text color="red">Zulassung aufgehoben</Text>
            {zulassungsnummer && (
              <Text title="Zulassungsnummer">
                Zulassungsnummer: {zulassungsnummer}
              </Text>
            )}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{ p: 0, display: 'flex', gap: '2rem', overflow: 'hidden' }}
      >
        {loading ? (
          // Loading
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
              padding: '2rem',
            }}
          >
            <CircularProgress />
            <Text>Lade Daten...</Text>
          </Box>
        ) : !filteredProductArray || filteredProductArray.length === 0 ? (
          // Keine-Daten
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              padding: '2rem',
            }}
          >
            <Typography>
              Für diese Zulassungsaufhebung steht keine Kurzinformation zur
              Verfügung.
            </Typography>
          </Box>
        ) : (
          // Daten vorhanden
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexShrink: 0,
                gap: '1rem',
                width: '160px',
                mt: 2,
              }}
            >
              {filteredProductArray.map((fpa, index) => (
                <NavigationItem
                  key={`${fpa.title}-${index}`}
                  fpa={fpa}
                  index={index}
                  selectedTabIndex={selectedTabIndex}
                  scrollToElement={scrollToElement}
                  setSelectedTabIndex={setSelectedTabIndex}
                />
              ))}
            </Box>
            <Box
              ref={contentElem}
              onScroll={handleScroll}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2rem',
                py: 2,
                overflowY: 'auto',
                paddingRight: '1rem',
                flexGrow: 1,
              }}
            >
              {filteredProductArray.map((fpa, index) => (
                <Content
                  key={`${fpa.title}-${index}`}
                  fpa={fpa}
                  index={index}
                />
              ))}
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

type NavigationItemProps = {
  fpa: { title: string; value: any }
  index: number
  selectedTabIndex: number
  scrollToElement: (index: number) => void
  setSelectedTabIndex: (index: number) => void
}

function NavigationItem({
  fpa,
  index,
  selectedTabIndex,
  scrollToElement,
  setSelectedTabIndex,
}: NavigationItemProps) {
  return (
    <Box
      key={fpa.title}
      component="button"
      id={`vertical-tab-${index}`}
      aria-controls={`vertical-tabpanel-${index}`}
      sx={{
        background: 'white',
        border: 'none',
        display: 'flex',
        gap: '0.5rem',
        cursor: 'pointer',
        color: 'primary.main',
        fontWeight: selectedTabIndex === index ? 700 : 400,
        paddingLeft: { xs: 1, sm: 2 },
        textDecoration: 'none',
      }}
      onClick={() => {
        scrollToElement(index)
        setSelectedTabIndex(index)
      }}
    >
      <Box component="span" sx={{ width: '13px' }}>
        {selectedTabIndex === index && (
          <svg
            width="13"
            height="9"
            viewBox="0 0 13 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L4.50017 4.5L1 8"
              stroke="#3FA535"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 1L11.5002 4.5L8 8"
              stroke="#3FA535"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </Box>
      {fpa.title}
    </Box>
  )
}

type ContentProps = {
  fpa: { title: string; value: any }
  index: number
}

function Content({ fpa, index }: ContentProps) {
  let content = null

  switch (fpa.title) {
    case 'Klassifikation':
      content = <KlassifikationContent klassifikationVerlauf={fpa.value} />
      break

    case 'Zusammensetzung':
      content = <ZusammensetzungContent zusammensetzung={fpa.value} />
      break

    case 'Kurzinformation':
      content = <KurzinformationContent schnellhilfe={fpa.value} />
      break

    case 'Intoleranzen':
      content = <IntoleranzenContent intoleranzen={fpa.value} />
      break

    case 'Warnhinweise':
      content = <WarnhinweiseContent warnhinweise={fpa.value} />
      break

    case 'Lagerung':
      content = <LagerungContent lagerung={fpa.value} />
      break

    case 'Kontakte':
      content = <KontakteContent kontakte={fpa.value} />
      break

    default:
      console.error('switch error')
      content = null
  }

  if (content === null) return null

  return (
    <Box
      key={fpa.title}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      <Typography sx={{ fontSize: '1.25rem', fontWeight: 700 }}>
        {fpa.title}
      </Typography>
      <Box sx={{ mt: 3 }}>{content}</Box>
    </Box>
  )
}

type KlassifikationContentProps = {
  klassifikationVerlauf: ProductVerlaufClassificationData
}

function KlassifikationContent({
  klassifikationVerlauf,
}: KlassifikationContentProps) {
  const { arzneiform, applikationsarten, atcCodes, indikationsgruppen } =
    klassifikationVerlauf

  const arzneiformData = arzneiform?.arzneiform

  const anwGruppe = applikationsarten?.map((item) => item.anwGruppe) ?? []
  const weg = applikationsarten?.map((item) => item.weg) ?? []
  const indikationsgruppenData =
    indikationsgruppen?.map((item) => item.text) ?? []

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {indikationsgruppenData.length > 0 && (
        <Box>
          <strong>Indikationsgruppen</strong>
          <Box>{indikationsgruppenData.join(', ')}</Box>
        </Box>
      )}
      {atcCodes && atcCodes.length > 0 && (
        <Box>
          <strong>ATC Codes</strong>
          {atcCodes.map((atc, index) => (
            <Box key={index} sx={{ display: 'flex', gap: 1 }}>
              <span>{atc.atcCode}</span>
              {atc.text && <span>{atc.text}</span>}
            </Box>
          ))}
        </Box>
      )}
      {arzneiformData && (
        <Box>
          <strong>Arzneiform</strong>
          <Box>{arzneiformData}</Box>
        </Box>
      )}
      {anwGruppe.length > 0 && (
        <Box>
          <strong>Applikationsarten</strong>
          <Box>{anwGruppe.join(', ')}</Box>
        </Box>
      )}
      {weg.length > 0 && (
        <Box>
          <strong>Applikationswege</strong>
          <Box>{weg.join(', ')}</Box>
        </Box>
      )}
    </Box>
  )
}

type ZusammensetzungContentProps = {
  zusammensetzung: ProductVerlaufCompositionData
}

function ZusammensetzungContent({
  zusammensetzung,
}: ZusammensetzungContentProps) {
  const { verordnungshinweise, bezug, eintraege, eigenschaften } =
    zusammensetzung

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {verordnungshinweise && verordnungshinweise.length > 0 && (
        <Box>
          <Box sx={{ fontWeight: 'bold' }}>Verordnungshinweise</Box>
          {verordnungshinweise?.map((hinweis, index) => (
            <Box key={index}>
              {hinweis.verordnungsTyp} ({hinweis.einheitsAngabe}) - Reihenfolge:{' '}
              {hinweis.reihenFolge}
            </Box>
          ))}
        </Box>
      )}

      <Box>
        <Box sx={{ fontWeight: 'bold' }}>Bezug</Box>
        {bezug.mengenAngabe} {bezug.einheitsAngabe}
        {bezug.alternativeBezuege &&
          bezug.alternativeBezuege.length > 0 &&
          bezug.alternativeBezuege.map((alt, index) => (
            <Box key={index}>
              Alternative: {alt.mengenAngabe} {alt.einheitsAngabe} (
              {alt.quellenAngabe})
            </Box>
          ))}
      </Box>

      {eintraege && eintraege.length > 0 && (
        <Box>
          <Box sx={{ fontWeight: 'bold' }}>Einträge</Box>
          {eintraege.map((eintrag) => (
            <Box key={eintrag.laufNummer}>
              {eintrag.praefix && `${eintrag.praefix} `}
              {eintrag.stoff?.bezeichnung || eintrag.eintragsTyp}
              {eintrag.stoff?.minStaerke &&
                ` (${eintrag.stoff.minStaerke}-${eintrag.stoff.maxStaerke} ${eintrag.stoff.einheitsAngabe})`}
              {eintrag.suffix && ` ${eintrag.suffix}`}
            </Box>
          ))}
        </Box>
      )}

      {eigenschaften && eigenschaften?.length > 0 && (
        <Box>
          <Box sx={{ fontWeight: 'bold' }}>Eigenschaften</Box>
          {eigenschaften.map((eigenschaft, index) => (
            <Box key={index}>
              {eigenschaft.eigenschaftsTyp} - Menge:{' '}
              {eigenschaft.minMenge || '-'}
              {eigenschaft.maxMenge && `-${eigenschaft.maxMenge}`}{' '}
              {eigenschaft.einheitsAngabe} (Geltungsbereich:{' '}
              {eigenschaft.geltungsbereiche
                ?.map((g) => g.geltungsbereich)
                .join(', ')}
              )
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

type KurzinformationContentProps = {
  schnellhilfe: ProductVerlaufDocumentationQuickHelpData[]
}

function KurzinformationContent({ schnellhilfe }: KurzinformationContentProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {schnellhilfe.length === 0 ? (
        <Box sx={{ color: 'red' }}>
          {' '}
          &gt;&gt; Keine Produktinformation verfügbar
        </Box>
      ) : (
        schnellhilfe.map((item, index) => (
          <Box key={index}>
            <Box fontWeight="bold">{item.abschnittsInhalt}</Box>
            <Box>{item.text}</Box>
          </Box>
        ))
      )}
    </Box>
  )
}

type IntoleranzenContentProps = {
  intoleranzen: ProductVerlaufIntolerancesData
}

function IntoleranzenContent({ intoleranzen }: IntoleranzenContentProps) {
  const {
    lactoseIntoleranz,
    galactoseIntoleranz,
    glucoseGalactoseMalabs,
    saccharoseIntoleranz,
    fructoseIntoleranz,
    histaminIntoleranz,
    glutenIntoleranz,
  } = intoleranzen

  return (
    <Box component={'ul'}>
      {lactoseIntoleranz && <li>Lactoseintoleranz</li>}
      {galactoseIntoleranz && <li>Galactoseintoleranz</li>}
      {glucoseGalactoseMalabs && <li>Glucose-Galactose-Malabsorption</li>}
      {saccharoseIntoleranz && <li>Saccharoseintoleranz</li>}
      {fructoseIntoleranz && <li>Fructoseintoleranz</li>}
      {histaminIntoleranz && <li>Histaminintoleranz</li>}
      {glutenIntoleranz && <li>Glutenintoleranz</li>}
    </Box>
  )
}

type WarnhinweiseContentProps = {
  warnhinweise: ProductVerlaufApplicationWarningsData
}

function WarnhinweiseContent({ warnhinweise }: WarnhinweiseContentProps) {
  const {
    vvAufloesen,
    vvZubereiten,
    einnahmeMahlzeit,
    einnahmeUnversehrt,
    hinwAlkohol,
    hinwDiabetiker,
    hinwSchwangerschaft,
    hinwStillzeit,
    hinwKinder,
    hinwKadersportler,
    hinwSonnenlicht,
    hinwVerkehrstuechtigkeit,
    zusUeberwachung,
    hinwReligion,
    hinwVegan,
    hinwAlterstauglichkeit,
  } = warnhinweise

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {vvAufloesen && (
        <Box>
          <strong>Vor Verwendung auflösen:</strong> {` ${vvAufloesen}`}
        </Box>
      )}
      {vvZubereiten && (
        <Box>
          <strong>Vor Verwendung zubereiten:</strong> {` ${vvZubereiten}`}
        </Box>
      )}
      {einnahmeMahlzeit && (
        <Box>
          <strong>Einnahme mit Mahlzeit:</strong> {` ${einnahmeMahlzeit}`}
        </Box>
      )}
      {einnahmeUnversehrt && (
        <Box>
          <strong>Unversehrte Einnahme:</strong> {` ${einnahmeUnversehrt}`}
        </Box>
      )}
      {hinwAlkohol && (
        <Box>
          <strong>Alkohol:</strong> {` ${hinwAlkohol}`}
        </Box>
      )}
      {hinwDiabetiker && (
        <Box>
          <strong>Diabetiker:</strong> {` ${hinwDiabetiker}`}
        </Box>
      )}
      {hinwSchwangerschaft && (
        <Box>
          <strong>Schwangerschaft:</strong> {` ${hinwSchwangerschaft}`}
        </Box>
      )}
      {hinwStillzeit && (
        <Box>
          <strong>Stillzeit:</strong> {` ${hinwStillzeit}`}
        </Box>
      )}
      {hinwKinder && (
        <Box>
          <strong>Kinder:</strong> {` ${hinwKinder}`}
        </Box>
      )}
      {hinwKadersportler && hinwKadersportler !== 'keine Angabe' && (
        <Box>
          <strong>Kadersportler:</strong> {` ${hinwKadersportler}`}
        </Box>
      )}
      {hinwSonnenlicht && (
        <Box>
          <strong>Sonnenlicht:</strong> {` ${hinwSonnenlicht}`}
        </Box>
      )}
      {hinwVerkehrstuechtigkeit && (
        <Box>
          <strong>Verkehrstüchtigkeit:</strong> {` ${hinwVerkehrstuechtigkeit}`}
        </Box>
      )}
      {zusUeberwachung && (
        <Box>
          <strong>Zusätzliche Überwachung:</strong> {` ${zusUeberwachung}`}
        </Box>
      )}
      {hinwReligion && (
        <Box>
          <strong>Religion:</strong> {` ${hinwReligion}`}
        </Box>
      )}
      {hinwVegan && (
        <Box>
          <strong>Veganer:</strong> {` ${hinwVegan}`}
        </Box>
      )}
      {hinwAlterstauglichkeit && (
        <Box>
          <strong>Alterstauglichkeit:</strong> {` ${hinwAlterstauglichkeit}`}
        </Box>
      )}
    </Box>
  )
}

type LagerungContentProps = {
  lagerung: ProductVerlaufStorageData
}

function LagerungContent({ lagerung }: LagerungContentProps) {
  const { haltbarkeit, lagerungsbedingung } = lagerung

  return (
    <Box>
      {haltbarkeit && (
        <Box component={'ul'}>
          <li>
            Haltbarkeit: {haltbarkeit.dauerWert}
            {haltbarkeit.dauerEinheitsAngabe === 'M'
              ? ' Monat(e)'
              : ` ${haltbarkeit.dauerEinheitsAngabe}`}
            <Box>
              {haltbarkeit.hinweis && (
                <Box>
                  {' '}
                  <strong>Hinweis:</strong>
                  {haltbarkeit.hinweis}
                </Box>
              )}
            </Box>
          </li>
          {lagerungsbedingung && (
            <li>
              Lagerungsbedingung:{' '}
              {`${lagerungsbedingung.tempMin} bis ${lagerungsbedingung.tempMax} ${lagerungsbedingung.tempEinheitsAngabe}`}
            </li>
          )}
        </Box>
      )}
    </Box>
  )
}

type KontakteContentProps = {
  kontakte: ProductVerlaufContactsData
}

function KontakteContent({ kontakte }: KontakteContentProps) {
  const { zulassungsinhaber, vertriebsfirma, herstellerfirmen } = kontakte

  return (
    <Box>
      {zulassungsinhaber && (
        <Box sx={{ mb: 2 }}>
          <strong>Zulassungsinhaber:</strong>
          <Box>
            <Box>
              {zulassungsinhaber.anzeigename || zulassungsinhaber.firmenname}
            </Box>
            {zulassungsinhaber.strasse && (
              <Box>{zulassungsinhaber.strasse}</Box>
            )}
            {zulassungsinhaber.plz && zulassungsinhaber.ort && (
              <Box>{`${zulassungsinhaber.plz} ${zulassungsinhaber.ort}`}</Box>
            )}
            <Box>{zulassungsinhaber.land.bezeichnung}</Box>
            {zulassungsinhaber.telefon && (
              <Box>
                <strong>Telefon:</strong> {zulassungsinhaber.telefon}
              </Box>
            )}
            {zulassungsinhaber.telefax && (
              <Box>
                <strong>Telefax:</strong> {zulassungsinhaber.telefax}
              </Box>
            )}
            {zulassungsinhaber.email && (
              <Box>
                <strong>Email:</strong> {zulassungsinhaber.email}
              </Box>
            )}
          </Box>
        </Box>
      )}
      {vertriebsfirma && (
        <Box sx={{ mb: 2 }}>
          <strong>Vertriebsfirma:</strong>
          <Box>
            <Box>{vertriebsfirma.anzeigename || vertriebsfirma.firmenname}</Box>
            {vertriebsfirma.strasse && <Box>{vertriebsfirma.strasse}</Box>}
            {vertriebsfirma.plz && vertriebsfirma.ort && (
              <Box>{`${vertriebsfirma.plz} ${vertriebsfirma.ort}`}</Box>
            )}
            <Box>{vertriebsfirma.land.bezeichnung}</Box>
            {vertriebsfirma.telefon && (
              <Box>
                <strong>Telefon:</strong> {vertriebsfirma.telefon}
              </Box>
            )}
            {vertriebsfirma.telefax && (
              <Box>
                <strong>Telefax:</strong> {vertriebsfirma.telefax}
              </Box>
            )}
            {vertriebsfirma.email && (
              <Box>
                <strong>Email:</strong> {vertriebsfirma.email}
              </Box>
            )}
          </Box>
        </Box>
      )}
      {herstellerfirmen && herstellerfirmen.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <strong>Hersteller:</strong>
          {herstellerfirmen.map((hf, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Box>{hf.anzeigename || hf.firmenname}</Box>
              {hf.strasse && <Box>{hf.strasse}</Box>}
              {hf.plz && hf.ort && <Box>{`${hf.plz} ${hf.ort}`}</Box>}
              <Box>{hf.land.bezeichnung}</Box>
              {hf.telefon && (
                <Box>
                  <strong>Telefon:</strong> {hf.telefon}
                </Box>
              )}
              {hf.telefax && (
                <Box>
                  <strong>Telefax:</strong> {hf.telefax}
                </Box>
              )}
              {hf.email && (
                <Box>
                  <strong>Email:</strong> {hf.email}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}
