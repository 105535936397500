import {
  ProductCompositionReferenceData,
  ProductCompositionReferencesData,
} from '@austria-codex/types'
import {
  SeparatedList,
  SeparatedListItem,
} from '../../../components/Layout/SeparatedList'
import { Text } from '../../../components/Layout/Typography'
import { Numeral } from '../../../components/Utility/Numeral'

type CompositionReferenceUnitsProps = {
  referenceUnit: ProductCompositionReferencesData | undefined
}

export function CompositionReferenceUnits({
  referenceUnit,
}: CompositionReferenceUnitsProps) {
  if (!referenceUnit) {
    return null
  }

  const { einheit, mengenAngabe, alternativeBezuege } = referenceUnit

  return (
    <Text variant="subHeading1" as="div" fontSize="1.1em">
      <ReferenceUnit referenceUnit={referenceUnit} />
      {einheit?.beschreibung}
      {alternativeBezuege && alternativeBezuege.length > 0 && (
        <>
          {' ('}
          <SeparatedList>
            {alternativeBezuege.map((ru, index) => (
              <SeparatedListItem key={index}>
                <ReferenceUnit referenceUnit={ru} />
              </SeparatedListItem>
            ))}
          </SeparatedList>
          {')'}
        </>
      )}
      {mengenAngabe === 1 ? ' enthält' : ' enthalten'}:
    </Text>
  )
}

type ReferenceUnitProps = {
  referenceUnit: ProductCompositionReferenceData
}

function ReferenceUnit({ referenceUnit }: ReferenceUnitProps) {
  const mengenAngabe = referenceUnit.mengenAngabe
  return mengenAngabe ? (
    <>
      <Numeral input={mengenAngabe} format="0[.][00000]" />{' '}
      {referenceUnit.einheit?.bezeichnung}
    </>
  ) : null
}
