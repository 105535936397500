import styled from '@emotion/styled'

export const Indicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #a14ca6;
  margin-left: -15px;
  margin-right: 5px;
`
