import { Typography, useTheme } from '@mui/material'
import { PRODUCTS_INT_BY_PROVIDER_TABLE_CONFIG } from '../../../common/tableConfig/products-international'
import { IntProductsDataTable } from '../../../components/DataTable/IntProductsDataTable'
import { Box } from '../../../components/Layout/Box'
import { Section } from '../../../components/Section/Section'
import { useProductIntData } from '../../../contexts/entity'
import { getCountryByIsoCode } from '../../../helpers/country.helper'

const heading = 'Produkte des Anbieters'

export function IntProviderOtherProductsSection() {
  const theme = useTheme()
  const product = useProductIntData()

  const provider = product.kontakteInt.anbieter.kurzname
  const country = getCountryByIsoCode(product.isoCode)

  return (
    <Section width="small" heading={heading}>
      <Typography variant="h5" sx={{ color: theme.palette.grey[700] }}>
        {provider} {country}
      </Typography>
      <Box mt={2}>
        <IntProductsDataTable
          config={PRODUCTS_INT_BY_PROVIDER_TABLE_CONFIG}
          filter={{ provider, isoCodes: [product.isoCode] }}
          heading={heading}
          subheading={country}
        />
      </Box>
    </Section>
  )
}
