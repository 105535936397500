import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { Field, FieldAttributes, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { FEEDBACK_MUTATION } from '../../api/feedback'
import { LoadingOverlay } from '../Loading/LoadingOverlay'

type InitialValues = {
  phone: string
  topic: number
  description: string
}

const initialValues: InitialValues = {
  phone: '',
  topic: 1,
  description: '',
}

const validate = (values: InitialValues) => {
  const errors: Record<string, string> = {}

  if (
    values.phone &&
    !/^((\+\d+\s?\d+\s?\d+-?(\d+)?)|(\d+\/\d+-?(\d+)?))$/u.test(values.phone)
  ) {
    errors.phone =
      'Ungültige Telefonnummer. Bitte verwenden Sie dieses Format: +4314023588'
  }

  if (!values.description) {
    errors.description = 'Beschreibung darf nicht leer sein.'
  }

  return errors
}

type ReportDialogProps = {
  open: boolean
  onClose: () => void
}

export function ReportDialog({ open, onClose }: ReportDialogProps) {
  const [sendReport, { loading, error }] = useMutation(FEEDBACK_MUTATION)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    // Reset on open to avoid showing the reset form in the closing animation
    if (open) {
      setSubmitted(false)
    }
  }, [open])

  const isSuccess = !loading && submitted
  const isError = !!error

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <Header>Feedback</Header>
      </DialogTitle>
      {isSuccess ? (
        <>
          <DialogContent>
            <DialogContentText>
              {isError
                ? 'Leider ist ein Fehler aufgetretten. Bitte versuchen Sie es später noch einmal.'
                : 'Vielen Dank für Ihr Feedback.'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="contained" color="primary">
              Schließen
            </Button>
          </DialogActions>
        </>
      ) : (
        <Formik
          onSubmit={(values, { setSubmitting }) => {
            sendReport({
              variables: values,
            }).then(() => {
              setSubmitting(false)
              setSubmitted(true)
            })
          }}
          initialValues={initialValues}
          validate={validate}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, isSubmitting, errors, touched }) => (
            <LoadingOverlay open={loading}>
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <DialogContentText>
                    Senden Sie uns Feedback.
                  </DialogContentText>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormTextField
                        name="phone"
                        label="Telefonnummer"
                        error={!!(touched.phone && errors.phone)}
                        helperText={
                          touched.phone && errors.phone
                            ? errors.phone
                            : 'Falls wir Sie kontaktieren sollen. Optional.'
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        name="topic"
                        select
                        label="Thema der Meldung"
                        margin="normal"
                        variant="outlined"
                      >
                        <MenuItem value={1}>Verbesserungvorschlag</MenuItem>
                        <MenuItem value={2}>Anmerkung</MenuItem>
                        <MenuItem value={3}>
                          Meldung zum dargestellten Inhalt
                        </MenuItem>
                        <MenuItem value={4}>Sonstige</MenuItem>
                      </FormTextField>
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField
                        name="description"
                        label="Beschreibung"
                        placeholder="Ihre Nachricht"
                        multiline
                        minRows={4}
                        error={!!(touched.description && errors.description)}
                        helperText={
                          touched.description &&
                          errors.description &&
                          errors.description
                        }
                        autoFocus
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color="error">
                    Abbrechen
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    Senden
                  </Button>
                </DialogActions>
              </form>
            </LoadingOverlay>
          )}
        </Formik>
      )}
    </Dialog>
  )
}

function FormTextField(props: TextFieldProps) {
  return (
    <Field name={props.name}>
      {({
        field,
        form: { errors, touched },
      }: FieldAttributes<{
        field: { name: string }
        form: {
          errors: Record<string, boolean>
          touched: Record<string, boolean>
        }
      }>) => (
        <TextField
          fullWidth
          error={!!errors[field.name] && touched[field.name]}
          margin="normal"
          {...field}
          {...props}
        />
      )}
    </Field>
  )
}

const Header = styled.h6`
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.0075em;
  margin: 0;
  padding: 0;
`
