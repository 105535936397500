import { Anchor } from '../../components/Layout/Anchor'
import { Section } from '../../components/Section/Section'
import { UpSellingRibbon } from '../../components/UpSellingRibbon/UpSellingRibbon'
import { Config } from '../../config'

export function ProductSafetyTeaserSection() {
  return (
    <Section>
      <UpSellingRibbon>
        Weitere Inhalte wie Arzneimittel-Interaktionen, Arzneimittel-Allergien
        und -Kreuzallergien, Hinweise zu Intoleranzen, Kurztexte zu UAWs
        entsprechend der Austria-Codex Schnellhilfe oder Hinweise für
        Diabetiker, Kadersportler, Schwangere und Stillende finden Sie im
        Austria-Codex Online auf{' '}
        <Anchor href={Config.upselling.path} target="_blank">
          meinAPOVERLAG
        </Anchor>
        .
      </UpSellingRibbon>
    </Section>
  )
}
