import { ProductData } from '@austria-codex/types'
import { AgentsMetaHeader } from './AgentsMetaHeader'

type AlternativesMetaHeaderProps = {
  product: ProductData
}

export function AlternativesMetaHeader({
  product,
}: AlternativesMetaHeaderProps) {
  return <AgentsMetaHeader product={product} />
}
