import { ProductCompositionEntryData } from '@austria-codex/types'
import {
  isCompositionAgent,
  isCompositionDescription,
} from '@austria-codex/utilities'
import { CompositionAgentRow } from './CompositionAgentRow'
import { CompositionDescriptionRow } from './CompositionDescriptionRow'
import { CompositionSubstanceRow } from './CompositionSubstanceRow'

type CompositionRowProps = {
  entry: ProductCompositionEntryData
  toggleRow: (stoffId: string, name?: string) => void
  currentRow: string
}

export function CompositionRow({
  entry,
  toggleRow,
  currentRow,
}: CompositionRowProps) {
  if (isCompositionDescription(entry)) {
    return <CompositionDescriptionRow entry={entry} />
  }

  if (isCompositionAgent(entry)) {
    return (
      <CompositionAgentRow
        entry={entry}
        toggleRow={toggleRow}
        currentRow={currentRow}
      />
    )
  }

  return <CompositionSubstanceRow entry={entry} />
}
