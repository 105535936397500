export enum PersonalCharacteristicsEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  CHILD = 'CHILD',
  PREGNANT = 'PREGNANT',
  BREAST_FEEDING = 'BREAST_FEEDING',
  SQUAD_SPORT = 'SQUAD_SPORT',
  DIABETES = 'DIABETES',
  ALCOHOL = 'ALCOHOL',
  HISTAMINE_INTOLERANCE = 'HISTAMINE_INTOLERANCE',
  SUGAR_INTOLERANCE = 'SUGAR_INTOLERANCE',
}
