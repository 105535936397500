import { Box } from '@mui/material'
import { Paragraph } from '../../../components/Layout/Typography'
import { Section } from '../../../components/Section/Section'
import { useProductIntData } from '../../../contexts/entity'
import { getDosageAdvisories } from '../../../helpers/product-international.helper'

export function IntDosageAdvisorySection() {
  const product = useProductIntData()
  const dosageAdvisories = getDosageAdvisories(product)

  return dosageAdvisories.length > 0 ? (
    <Section width="small" heading="Dosierung">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        {dosageAdvisories.map((advice, index) => (
          <Paragraph key={`${advice.abdaTyp}-${index}`}>
            {advice.hinweis}
          </Paragraph>
        ))}
      </Box>
    </Section>
  ) : null
}
