import styled from '@emotion/styled'
import {
  createTextMappingComponent,
  styledTextIcon,
  TextMappingComponentProps,
} from '../Icons/Base'

const mapping = {
  R: 'R',
  Y: 'Y',
  G: 'G',
  N: 'N',
}

export const ReimbursementBoxIcon = styled(
  // @ts-expect-error: some type error
  styledTextIcon(createTextMappingComponent(mapping))
)<TextMappingComponentProps<typeof mapping>>`
  border-color: ${(props) =>
    props.theme.palette.reimbursementBox[props.type].main};
  background: ${(props) =>
    props.theme.palette.reimbursementBox[props.type].light};
`
