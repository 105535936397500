import { gql } from '@apollo/client/core'

export const REFERENCES_QUERY = gql`
  query articles($offset: Int!, $filter: ProductsFilterInputType, $limit: Int) {
    entries: products(offset: $offset, filter: $filter, limit: $limit) {
      offset
      limit
      total
      hits {
        ... on ProductType {
          id
          bezeichnung
          suffix
          referenz {
            istBiosimilar
            istParallelimport
          }
          istVeterinaer
          klassifikation {
            istMonopraeparat
          }
        }
      }
    }
  }
`
