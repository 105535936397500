import { UserType } from '@austria-codex/types'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { MouseEvent, useState } from 'react'
import MaleProfileIcon from '../../assets/images/manProfile.svg?react'
import FemaleProfileIcon from '../../assets/images/womanProfile.svg?react'
import { logout } from '../../client/austriaCodex'
import { useAppSelector } from '../../hooks/useStoreHooks'
import { Avatar } from '../Layout/Avatar'
import { profileRedirect } from './actions'

const profileIconMap = {
  '1': <MaleProfileIcon />,
  '2': <FemaleProfileIcon />,
}

export function Profile() {
  const profile = useAppSelector((state) => state.user.profile)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  if (!profile) {
    return null
  }

  // Do not show "Profile" for IP/Machine users
  const showProfileBtn = profile.user.type !== UserType.MACHINE
  const genderId = (profile.gender?.id as keyof typeof profileIconMap) ?? '2'

  return (
    <>
      <IconButton
        id="profile-button"
        color="primary"
        onClick={handleClick}
        aria-controls={open ? 'profile-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
      >
        {profile.image ? (
          <Avatar
            src={`data:image/jpeg;base64, ${profile.image.file}`}
            alt="avatar"
          />
        ) : (
          profileIconMap[genderId]
        )}
      </IconButton>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        transitionDuration={0}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'profile-button' }}
      >
        {showProfileBtn && (
          <MenuItem onClick={() => profileRedirect()}>Profil</MenuItem>
        )}
        <MenuItem onClick={() => logout()}>Abmelden</MenuItem>
      </Menu>
    </>
  )
}
