import { FeatureEnum } from '@austria-codex/types'
import { Navigate } from 'react-router-dom'
import { useBundleFeature } from '../../hooks/useBundleFeature'

type FeatureRouteProps = {
  // TODO: reevaluate
  // Need to use JSX.Element instead of ReactNode here,
  // otherwise react-router complains in App.tsx.
  children: JSX.Element
  feature: FeatureEnum
}

export function FeatureRoute({ children, feature }: FeatureRouteProps) {
  const hasFeature = useBundleFeature(feature)

  if (!children) {
    throw new Error('Children cannot be null in FeatureRoute.')
  }

  if (!hasFeature) {
    return <Navigate to={'/'} />
  }

  return children
}
