import { Section } from '../../components/Section/Section'
import { useProductData } from '../../contexts/entity'

export function DosageFormSection() {
  const product = useProductData()

  return product.klassifikation?.arzneiform?.arzneiform ? (
    <Section width="small" heading="Arzneiform">
      {product.klassifikation.arzneiform.arzneiform.bezeichnung}
    </Section>
  ) : null
}
