import { EntityDataUnion, InteractionData } from '@austria-codex/types'
import { isSubstance, separateEntities } from '@austria-codex/utilities'
import { Box as MuiBox } from '@mui/material'
import { useEffect, useMemo } from 'react'
import NoInteractionsIcon from '../../../assets/images/empty/ic-no-multi.svg?react'
import { EmptyMessage } from '../../../components/Empty/EmptyMessage'
import { Box } from '../../../components/Layout/Box'
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner'
import { Section } from '../../../components/Section/Section'
import { Warning } from '../../../components/Warning'
import { useAppDispatch, useAppSelector } from '../../../hooks/useStoreHooks'
import { filteredEntitiesSelector } from '../../../store/entities'
import { fetchInteractions, filteredInteractions } from '../../../store/safety'
import { Interaction } from './Interaction'

function shouldLoadInteractions(
  interactions: InteractionData[] | null,
  entities: EntityDataUnion[],
  isLoading: boolean
) {
  return (
    // Initially interactions are null, if no interactions are
    // available after fetch it would be an empty array.
    interactions === null &&
    entities.length === 1 &&
    isSubstance(entities[0]) &&
    !isLoading
  )
}

export function InteractionsSection() {
  const dispatch = useAppDispatch()
  const mode = useAppSelector((state) => state.user.mode)
  const interactions = useAppSelector(filteredInteractions)
  const isLoadingInteractions = useAppSelector((state) => state.safety.loading)
  const filteredEntities = useAppSelector(filteredEntitiesSelector)

  useEffect(() => {
    if (
      shouldLoadInteractions(
        interactions,
        filteredEntities,
        isLoadingInteractions
      )
    ) {
      dispatch(fetchInteractions([filteredEntities[0].id]))
    }
  }, [dispatch, interactions, filteredEntities, isLoadingInteractions])

  const hasSubstanceInteractionWarning = useMemo(() => {
    const { products, substances } = separateEntities(filteredEntities)

    return (
      (products.length === 1 && substances.length === 0) ||
      (products.length === 0 && substances.length > 1)
    )
  }, [filteredEntities])

  const isInternational = mode === 'international'

  return (
    <Section width="medium">
      {isInternational ? (
        <MuiBox
          sx={{
            p: 2,
            border: '1px solid blue',
            borderColor: 'info.main',
            borderRadius: '5px',
            backgroundColor: 'info.light',
            fontSize: '0.75rem',
          }}
        >
          Bitte beachten Sie, dass für das Modul International keine
          Interaktionsinformationen zur Verfügung stehen.
        </MuiBox>
      ) : hasSubstanceInteractionWarning ? (
        <Box my={4}>
          <Warning title="Wichtig!">
            Bitte reduzieren Sie ihre Auswahl auf einen einzelnen Stoff, um
            Interaktionen für diesen angezeigt zu bekommen. Für eine
            Stoff-Produkt-Interaktion wählen Sie zusätzlich, zu einem
            ausgewählten Stoff, ein Präparat aus.
          </Warning>
        </Box>
      ) : isLoadingInteractions ? (
        <MuiBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingSpinner />
          Lade Interaktionen...
        </MuiBox>
      ) : interactions && interactions.length === 0 ? (
        <EmptyMessage Icon={<NoInteractionsIcon />}>
          Keine Interaktionsinformation für diese Auswahl vorhanden.
        </EmptyMessage>
      ) : (
        interactions &&
        interactions.map((interaction) => (
          <Box my={4} key={interaction.id}>
            <Interaction interaction={interaction} />
          </Box>
        ))
      )}
    </Section>
  )
}
