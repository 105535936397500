import {
  EInteractionDescription,
  InteractionData,
  InteractionDescriptionData,
} from '@austria-codex/types'
import { css } from '@emotion/react'
import { Html } from '../../../components/Html/Html'
import { CollapseIcon } from '../../../components/Icons/CollapseIcon'
import { Anchor } from '../../../components/Layout/Anchor'
import { Box, Flex } from '../../../components/Layout/Box'
import { Content } from '../../../components/Layout/Content'
import { Text } from '../../../components/Layout/Typography'
import {
  getDescriptionForType,
  getSections,
} from '../../../helpers/interaction.helper'
import { useCollapse } from '../../../hooks/useCollapse'
import { toLocalDateString } from '../../../utilities/date'

type InteractionCollapseProps = {
  readonly descriptions: InteractionDescriptionData[]
  readonly interaction: InteractionData
}

export function InteractionDescriptionsCollapse({
  descriptions,
  interaction,
}: InteractionCollapseProps) {
  const sections = getSections(descriptions)
  const [collapse, onToggle] = useCollapse({
    section: `${interaction.id}-interaction-descriptions`,
  })

  const revisionDate = toLocalDateString(interaction.revisionsDatum)
  const literatur = getDescriptionForType(
    interaction.beschreibungen,
    EInteractionDescription.Literatur
  )
  const datengrundlage = getDescriptionForType(
    interaction.beschreibungen,
    EInteractionDescription.Datengrundlage
  )

  return (
    <Content width="large">
      <Box py={3}>
        {sections.map(({ title, content }) => (
          <Box key={title} pb={3}>
            {title && (
              <Text fontWeight="bold" display="block">
                {title}
              </Text>
            )}
            <Text display="block">{content && <Html content={content} />}</Text>
          </Box>
        ))}
        <Box py={3}>
          <Flex flexDirection="column" alignItems="left" pb={2}>
            <Anchor
              css={css`
                align-items: left;
                display: flex;
                text-align: left;
              `}
              onClick={() => {
                onToggle()
              }}
            >
              <Text fontWeight="bold">Literatur</Text>
              <CollapseIcon collapse={collapse} />
            </Anchor>
          </Flex>
          {collapse && (
            <>
              {literatur && (
                <Box pb={3}>
                  <Text fontWeight="bold" display="block">
                    Literatur
                  </Text>
                  <Text display="block">
                    {<Html content={literatur.beschreibung} />}
                  </Text>
                </Box>
              )}
              {datengrundlage && (
                <Box pb={3}>
                  <Text fontWeight="bold" display="block">
                    Datengrundlage
                  </Text>
                  <Text display="block">
                    {<Html content={datengrundlage.beschreibung} />}
                  </Text>
                </Box>
              )}
              {revisionDate && (
                <Box pb={3}>
                  <Text fontWeight="bold" display="block">
                    Revisionsdatum
                  </Text>
                  <Text display="block">{revisionDate}</Text>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Content>
  )
}
