import { FeatureEnum, Maybe, TCountryMode } from '@austria-codex/types'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import { ReactNode } from 'react'
import { GENERICS_QUERY } from '../../api/generics'
import AtIcon from '../../assets/images/countryFlagsSvg/at-sm.svg?react'
import EarthIcon from '../../assets/images/earth.svg?react'
import {
  GENERICS_TABLE_CONFIG,
  GENERICS_TABLE_CONFIG_INT,
} from '../../common/tableConfig/generics'
import { PRODUCTS_INT_BY_AT_PRODUCT_ID_TABLE_CONFIG } from '../../common/tableConfig/products-international'
import { IntProductsDataTable } from '../../components/DataTable/IntProductsDataTable'
import { ProductsDataSourceTable } from '../../components/PaginatedDataTable/ProductsDataSourceTable'
import { Section } from '../../components/Section/Section'
import { useProductData } from '../../contexts/entity'
import { useBundleFeature } from '../../hooks/useBundleFeature'
import { useAppSelector } from '../../hooks/useStoreHooks'

function Heading() {
  return (
    <>
      <AtIcon />
      <Box component="span" sx={{ ml: 1 }}>
        Generische Gruppierung Österreich
      </Box>
    </>
  )
}

const modeToHeading: Record<TCountryMode, ReactNode> = {
  national: <>Generische Gruppierung</>,
  international: <Heading />,
}

export function GenericGroupsSection() {
  const product = useProductData()
  const mode = useAppSelector((state) => state.user.mode)
  const hasInternational = useBundleFeature(FeatureEnum.International)

  const klassifikation = product.klassifikation
  const filter = {
    genericCode: klassifikation?.generischeKennung,
  }
  const isInternational = hasInternational && mode === 'international'
  const width = isInternational ? 'medium' : 'small'
  const heading = modeToHeading[mode]
  const tableConf = isInternational
    ? GENERICS_TABLE_CONFIG_INT
    : GENERICS_TABLE_CONFIG

  return (
    <>
      {klassifikation?.hatGenerischeBezeichnungsProdukte && (
        <Section heading={heading} width={width}>
          <Box mb={3}>
            <Typography variant="h5">
              {klassifikation.generischeBezeichnung}
            </Typography>
          </Box>
          <Box mt={3}>
            <ProductsDataSourceTable
              query={GENERICS_QUERY}
              config={tableConf}
              filter={filter}
              name="alternatives:generic"
            />
          </Box>
        </Section>
      )}
      {isInternational && (
        <GenericProductsInt
          productId={product.id}
          name={klassifikation?.generischeBezeichnung}
        />
      )}
    </>
  )
}

function HeadingInt() {
  return (
    <>
      <EarthIcon
        viewBox="0 0 24 24"
        style={{ width: '16px', height: '16px' }}
      />
      <Box component="span" sx={{ ml: 1 }}>
        Generische Gruppierung International
      </Box>
    </>
  )
}

type TGenericProductsIntProps = {
  productId: string
  name: Maybe<string>
}

function GenericProductsInt({ productId, name }: TGenericProductsIntProps) {
  // We want all international products, where the id of the AT product
  // is present in the alternatives sections of the int product.

  return (
    <Section heading={<HeadingInt />} width="medium">
      <Box mb={3}>
        <Typography variant="h5">{name}</Typography>
      </Box>
      <Box mt={3}>
        <IntProductsDataTable
          config={PRODUCTS_INT_BY_AT_PRODUCT_ID_TABLE_CONFIG}
          filter={{
            atProductId: productId,
          }}
          meta={{
            atProductId: productId,
          }}
          heading={<HeadingInt />}
          subheading={name}
        />
      </Box>
    </Section>
  )
}
