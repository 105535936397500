import type { ProductData } from '@austria-codex/types'
import { Text } from '../../../components/Layout/Typography'
import { ProductSeparatedAgentList } from '../../../components/Utility/SeparatedActiveIngredientList'

type MetaHeaderAgentProps = {
  product: ProductData
}

export function AgentsMetaHeader({ product }: MetaHeaderAgentProps) {
  return (
    <Text variant="subHeading1" display="block" textTransform="uppercase">
      <ProductSeparatedAgentList
        product={product}
        separator=" • "
        limit={3}
        root
      />
    </Text>
  )
}
