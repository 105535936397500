import { useQuery } from '@apollo/client'
import {
  ProductsFilter,
  ProductUnion,
  ResultInterface,
} from '@austria-codex/types'
import { Box } from '@mui/material'
import { DocumentNode } from 'graphql'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { FilterProductsVariables } from '../../api/queries/product-international'
import { getIdsOfDuplicateProducts } from '../../helpers/product-international.helper'
import { usePagination } from '../../hooks/usePagination'
import {
  DataTable,
  DataTableConfig,
  DataTableData,
} from '../DataTable/DataTable'
import { DataTableOverlay } from '../DataTable/DataTableOverlay'

type DataSourceTableProps<T> = {
  query: DocumentNode
  config: DataTableConfig<T>
  initialLimit?: number
  variables: { filter: ProductsFilter }
  uniqueIdentifier: string
  identifier: string
  resettable?: boolean
  onClose?: () => void
  onRowClick?: (data: T) => void
  onRowsPerPageChange?: (limit: number) => void
  isRowDisabled?: (data: T) => boolean
  heading?: ReactNode | string
  subheading?: ReactNode | string
}

type Result = {
  entries: ResultInterface<DataTableData[]>
}

const overlayLimit = 30

export function DataSourceTable<T>({
  query,
  initialLimit = 3,
  resettable = true,
  config,
  uniqueIdentifier,
  identifier,
  onClose,
  onRowClick,
  onRowsPerPageChange,
  variables,
  isRowDisabled,
  heading,
  subheading,
}: DataSourceTableProps<T>) {
  const [page, setPage] = usePagination(
    uniqueIdentifier,
    identifier,
    resettable
  )
  const [open, setOpen] = useState(false)
  const [limit, setLimit] = useState(initialLimit)
  const [offset, setOffset] = useState(0)
  const [isLoadingFetchMore, setIsLoadingFetchMore] = useState(false)

  const options = useMemo(
    () => ({
      variables: {
        offset: page * limit,
        limit,
        ...variables,
      },
    }),
    [page, limit, variables]
  )

  const { data, error, loading, refetch, fetchMore } = useQuery<
    Result,
    FilterProductsVariables
  >(query, options)

  const handleFetchMore = useCallback(async () => {
    setIsLoadingFetchMore(true)
    try {
      const fetchMoreResult = await fetchMore({
        variables: { offset },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult
          }
          const entries = {
            ...fetchMoreResult.entries,
            hits: [
              ...previousResult.entries.hits,
              ...fetchMoreResult.entries.hits,
            ],
          }
          return { entries }
        },
      })
      setOffset((prev) => prev + fetchMoreResult.data.entries.hits.length)
      setIsLoadingFetchMore(false)
    } catch (error) {
      console.error(error)
      setIsLoadingFetchMore(false)
    }
  }, [fetchMore, offset])

  const handleOpenOverlay = useCallback(async () => {
    setOpen(true)
    const refetchResponse = await refetch({ offset: 0, limit: overlayLimit })
    setOffset(refetchResponse.data.entries.hits.length)
  }, [refetch])

  const handleCloseOverlay = useCallback(async () => {
    setOpen(false)
    await refetch({ offset: page * limit, limit })
  }, [limit, page, refetch])

  const hits = data?.entries.hits ?? []
  const total = data?.entries.total ?? 0
  const needsOverlay = total > 7
  const hasError = error != null
  const showProviderIds = !identifier.startsWith('articles')
    ? getIdsOfDuplicateProducts(hits as ProductUnion[])
    : null

  function table({ hasOverlay = false }: { hasOverlay?: boolean }) {
    return (
      <DataTable
        data={hits}
        meta={{ showProviderIds }}
        loading={loading}
        hasOverlay={hasOverlay}
        error={hasError}
        onReload={refetch}
        total={total}
        config={config}
        page={open ? undefined : page}
        onPageChange={open ? undefined : setPage}
        rowsPerPage={open ? overlayLimit : limit}
        onRowsPerPageChange={open ? undefined : setLimit}
        onClose={open ? undefined : onClose}
        onClickRow={onRowClick}
        isRowDisabled={isRowDisabled}
        onOpenOverlay={handleOpenOverlay}
      />
    )
  }

  return (
    <Box display="flex" flexDirection="column">
      {needsOverlay ? (
        <DataTableOverlay
          open={open}
          onClose={handleCloseOverlay}
          table={table}
          isLoadingFetchMore={isLoadingFetchMore}
          handleFetchMore={handleFetchMore}
          heading={heading}
          subHeading1={subheading}
          //   subHeading2={}
          hitsLength={hits.length}
          total={total}
        />
      ) : null}
      {!open && table({ hasOverlay: needsOverlay })}
    </Box>
  )
}
