import { Html } from '../../components/Html/Html'
import { DocumentationSection } from '../../components/Section/DocumentationSection'
import { useProductData } from '../../contexts/entity'

export function InteractionSection() {
  const product = useProductData()

  return product.dokumentation?.schnellhilfe?.wechselwirkungen ? (
    <DocumentationSection
      inherit={product.dokumentation.schnellhilfe.wechselwirkungen.vererbt}
      width="small"
      heading="Wechselwirkungen"
    >
      <Html
        content={product.dokumentation.schnellhilfe.wechselwirkungen.text}
      />
    </DocumentationSection>
  ) : null
}
