import {
  FeatureEnum,
  RoutesOfAdministrationTypesEnum,
  type SubstanceApplicationRouteOfAdministrationHitsData as ROAHitsData,
  type SubstanceApplicationCountryHitsData,
} from '@austria-codex/types'
import { Section } from '../../../components/Section/Section'
import { useSubstanceData } from '../../../contexts/entity'
import { getROAHits } from '../../../helpers/substance.helper'
import { useBundleFeature } from '../../../hooks/useBundleFeature'
import { useAppSelector } from '../../../hooks/useStoreHooks'
import type { ROAFilter, ROAHitsInt } from '../../../types/common.types'
import { SubstanceApplicationHitsBlock } from './SubstanceApplicationHitsBlock'
import { SubstanceApplicationHitsBlockInt } from './SubstanceApplicationHitsBlockInt'

function shouldShowSection(
  hits: ROAHitsData | null,
  filter: ROAFilter
): hits is ROAHitsData {
  // If no hits given, section should not be shown
  if (!hits) {
    return false
  }

  // If no filters are selected, the section should be shown
  if (Object.values(filter).every((value) => !value)) {
    return true
  }

  // If a filter is selected, the section should only be shown,
  // if data is available for the selected filter.
  const entries = Object.entries(hits) as Array<
    [RoutesOfAdministrationTypesEnum, boolean]
  >
  for (const [roa, hitsForRoute] of entries) {
    if (filter[roa] && hitsForRoute) {
      return true
    }
  }

  return false
}

function shouldShowSectionInt(hits: ROAHitsInt, filter: ROAFilter) {
  return (
    shouldShowSection(hits.at, filter) ||
    shouldShowSection(hits.de, filter) ||
    shouldShowSection(hits.int, filter)
  )
}

export function RouteOfAdministrationMatrixSection() {
  const substance = useSubstanceData()
  const hasInternational = useBundleFeature(FeatureEnum.International)
  const mode = useAppSelector((state) => state.user.mode)

  const anwendung = substance.treffer?.anwendung
  if (!anwendung) {
    return null
  }

  if (mode === 'national') {
    return <SubstanceApplicationHitsNational anwendung={anwendung} />
  }

  if (hasInternational && mode === 'international') {
    return <SubstanceApplicationHitsInternational anwendung={anwendung} />
  }

  return null
}

type SANationalProps = {
  anwendung: SubstanceApplicationCountryHitsData[]
}

function SubstanceApplicationHitsNational({ anwendung }: SANationalProps) {
  const filter = useAppSelector((state) => state.filter.routesOfAdministration)
  const includesVeterinary = useAppSelector(
    (state) => state.settings.includeVeterinary
  )

  if (anwendung.length === 0) {
    return null
  }

  const monoHumanAt = getROAHits(anwendung, ['at'], 'mono', 'human')
  const showMonoHuman = shouldShowSection(monoHumanAt, filter)

  const kombiHumanAt = getROAHits(anwendung, ['at'], 'kombination', 'human')
  const showKombiHuman = shouldShowSection(kombiHumanAt, filter)

  const monoVetAt = getROAHits(anwendung, ['at'], 'mono', 'veterinaer')
  const showMonoVet = shouldShowSection(monoVetAt, filter)

  const kombiVetAt = getROAHits(anwendung, ['at'], 'kombination', 'veterinaer')
  const showKombiVet = shouldShowSection(kombiVetAt, filter)

  return (
    <>
      {showMonoHuman && (
        <Section width="small" heading="Produkte, human, mono">
          <SubstanceApplicationHitsBlock
            hits={monoHumanAt}
            isVeterinary={false}
            isMonoPreparation={true}
          />
        </Section>
      )}
      {showKombiHuman && (
        <Section width="small" heading="Produkte, human, Kombination">
          <SubstanceApplicationHitsBlock
            hits={kombiHumanAt}
            isVeterinary={false}
            isMonoPreparation={false}
          />
        </Section>
      )}
      {includesVeterinary && (
        <>
          {showMonoVet && (
            <Section width="small" heading="Produkte, veterinär, mono">
              <SubstanceApplicationHitsBlock
                hits={monoVetAt}
                isVeterinary={true}
                isMonoPreparation={true}
              />
            </Section>
          )}
          {showKombiVet && (
            <Section width="small" heading="Produkte, veterinär, Kombination">
              <SubstanceApplicationHitsBlock
                hits={kombiVetAt}
                isVeterinary={true}
                isMonoPreparation={false}
              />
            </Section>
          )}
        </>
      )}
    </>
  )
}

type SAInternationalProps = {
  anwendung: SubstanceApplicationCountryHitsData[]
}

function SubstanceApplicationHitsInternational({
  anwendung,
}: SAInternationalProps) {
  const selectedCountries = useAppSelector(
    (state) => state.user.selectedCountries
  )
  const filter = useAppSelector((state) => state.filter.routesOfAdministration)
  const includesVeterinary = useAppSelector(
    (state) => state.settings.includeVeterinary
  )

  // For the INT column, we need all countries except 'at', 'de'.
  // Those get their own columns.
  const otherCountries = selectedCountries.filter(
    (c) => !['at', 'de'].includes(c)
  )

  const monoHuman: ROAHitsInt = {
    at: getROAHits(anwendung, ['at'], 'mono', 'human'),
    de: getROAHits(anwendung, ['de'], 'mono', 'human'),
    int: getROAHits(anwendung, otherCountries, 'mono', 'human'),
  }
  const showMonoHuman = shouldShowSectionInt(monoHuman, filter)

  const kombiHuman: ROAHitsInt = {
    at: getROAHits(anwendung, ['at'], 'kombination', 'human'),
    de: getROAHits(anwendung, ['de'], 'kombination', 'human'),
    int: getROAHits(anwendung, otherCountries, 'kombination', 'human'),
  }
  const showKombiHuman = shouldShowSectionInt(kombiHuman, filter)

  const monoVet: ROAHitsInt = {
    at: getROAHits(anwendung, ['at'], 'mono', 'veterinaer'),
    de: getROAHits(anwendung, ['de'], 'mono', 'veterinaer'),
    int: getROAHits(anwendung, otherCountries, 'mono', 'veterinaer'),
  }
  const showMonoVet = shouldShowSectionInt(monoVet, filter)

  const kombiVet: ROAHitsInt = {
    at: getROAHits(anwendung, ['at'], 'kombination', 'veterinaer'),
    de: getROAHits(anwendung, ['de'], 'kombination', 'veterinaer'),
    int: getROAHits(anwendung, otherCountries, 'kombination', 'veterinaer'),
  }
  const showKombiVet = shouldShowSectionInt(kombiVet, filter)

  return (
    <>
      {showMonoHuman && (
        <Section width="small" heading="Produkte, human, mono">
          <SubstanceApplicationHitsBlockInt
            hits={monoHuman}
            isVeterinary={false}
            isMonoPreparation={true}
            otherCountries={otherCountries}
          />
        </Section>
      )}
      {showKombiHuman && (
        <Section width="small" heading="Produkte, human, Kombination">
          <SubstanceApplicationHitsBlockInt
            hits={kombiHuman}
            isVeterinary={false}
            isMonoPreparation={false}
            otherCountries={otherCountries}
          />
        </Section>
      )}
      {includesVeterinary && (
        <>
          {showMonoVet && (
            <Section width="small" heading="Produkte, veterinär, mono">
              <SubstanceApplicationHitsBlockInt
                hits={monoVet}
                isVeterinary={true}
                isMonoPreparation={true}
                otherCountries={otherCountries}
              />
            </Section>
          )}
          {showKombiVet && (
            <Section width="small" heading="Produkte, veterinär, Kombination">
              <SubstanceApplicationHitsBlockInt
                hits={kombiVet}
                isVeterinary={true}
                isMonoPreparation={false}
                otherCountries={otherCountries}
              />
            </Section>
          )}
        </>
      )}
    </>
  )
}
