import {
  FeatureEnum,
  SubstanceRetailAvailabilityHitsData,
  SubstanceRetailDeAvailabilityHitsData,
} from '@austria-codex/types'
import Box from '@mui/material/Box'
import { Section } from '../../../components/Section/Section'
import { useSubstanceData } from '../../../contexts/entity'
import { useBundleFeature } from '../../../hooks/useBundleFeature'
import { useAppSelector } from '../../../hooks/useStoreHooks'
import { ArticleCompositionHitsMatrix } from './ArticleMatrix'
import { ArticleCompositionHitsMatrixInt } from './ArticleMatrixInt'

export function ArticleMatrixSection() {
  const substance = useSubstanceData()
  const hasInternational = useBundleFeature(FeatureEnum.International)
  const mode = useAppSelector((state) => state.user.mode)

  const handel = substance.treffer?.handel
  const handelDe = substance.treffer?.handelDe
  if (!handel && !handelDe) {
    return <Box m={4}>Keine Handelsinformation für diese Auswahl vorhanden</Box>
  }

  if (mode === 'national') {
    return <ArticleMatrixNational handel={handel} />
  }

  if (hasInternational && mode === 'international') {
    return <ArticleMatrixInternational handel={handel} handelDe={handelDe} />
  }

  return null
}

type AMNationalProps = {
  handel: SubstanceRetailAvailabilityHitsData | undefined
}

function ArticleMatrixNational({ handel }: AMNationalProps) {
  return (
    <>
      {handel?.lieferbar && (
        <Section width="small" heading="Lieferbar">
          <ArticleCompositionHitsMatrix
            preparation={handel.lieferbar}
            // Caution: do not change the group,
            // there is a check on the name
            // further down in an component.
            group="application:hits:available"
          />
        </Section>
      )}
      {handel?.nichtLieferbar && (
        <Section width="small" heading="Langfristig nicht lieferbar">
          <ArticleCompositionHitsMatrix
            preparation={handel.nichtLieferbar}
            // Caution: do not change the group,
            // there is a check on the name
            // further down in an component.
            group="application:hits:unavailable"
          />
        </Section>
      )}
    </>
  )
}

type AMInternationalProps = {
  handel: SubstanceRetailAvailabilityHitsData | undefined
  handelDe: SubstanceRetailDeAvailabilityHitsData | undefined
}

function ArticleMatrixInternational({
  handel,
  handelDe,
}: AMInternationalProps) {
  return (
    <>
      {(handel?.lieferbar || handelDe?.lieferbar) && (
        <Section width="small" heading="Lieferbar">
          <ArticleCompositionHitsMatrixInt
            preparation={handel?.lieferbar}
            preparationDe={handelDe?.lieferbar}
            // Caution: do not change the group,
            // there is a check on the name
            // further down in an component.
            group="int:application:hits:available"
          />
        </Section>
      )}
      {(handel?.nichtLieferbar || handelDe?.nichtLieferbar) && (
        <Section width="small" heading="Nicht lieferbar">
          <ArticleCompositionHitsMatrixInt
            preparation={handel?.nichtLieferbar}
            preparationDe={handelDe?.nichtLieferbar}
            // Caution: do not change the group,
            // there is a check on the name
            // further down in an component.
            group="int:application:hits:unavailable"
          />
        </Section>
      )}
    </>
  )
}
