import { type ReactNode, useReducer } from 'react'
import { WarningLevelContext } from '../contexts/warningLevel'

export type WarningLevelAddAction = { type: 'add'; payload: string }

export type WaringLevelAction = WarningLevelAddAction

const reducer = (state: string[], action: WaringLevelAction) => {
  if (action.type === 'add') {
    return [...state, action.payload]
  }

  return state
}

type TWarningLevelProviderProps = {
  children: ReactNode
}

export function WarningLevelProvider({ children }: TWarningLevelProviderProps) {
  const [levels, dispatch] = useReducer(reducer, [])

  return (
    <WarningLevelContext.Provider value={{ levels, dispatch }}>
      {children}
    </WarningLevelContext.Provider>
  )
}
