import { FeatureEnum, ProductData } from '@austria-codex/types'
import { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { CircleIcon } from '../../../components/Icons/CircleIcon'
import { Box, Flex } from '../../../components/Layout/Box'
import { useBundle } from '../../../contexts/bundle'
import { warningLevelsForProduct } from '../../../helpers/product.helper'
import { useBundleFeature } from '../../../hooks/useBundleFeature'
import { useAppSelector } from '../../../hooks/useStoreHooks'
import { createEntityLoadingStateSelector } from '../../../store/entities'

type SafetyMetaHeaderProps = {
  product: ProductData
}

export function SafetyMetaHeader({ product }: SafetyMetaHeaderProps) {
  const bundle = useBundle()
  const entityLoadingStateSelector = useMemo(
    createEntityLoadingStateSelector,
    []
  )
  const { loading } = useAppSelector((state) =>
    entityLoadingStateSelector(state, product.id)
  )
  const hasIcons = useBundleFeature(
    FeatureEnum.ProductSafetyHeaderWarningSymbols
  )

  const levels = useMemo(
    () => warningLevelsForProduct(product, bundle),
    [bundle, product]
  )

  return (
    <Box>
      {loading ? (
        <Skeleton width={100} />
      ) : hasIcons ? (
        <Flex>
          {levels.includes('red') && <CircleIcon color="#C80000" mr={1} />}
          {levels.includes('yellow') && <CircleIcon color="#FF8800" mr={1} />}
          {levels.includes('blue') && <CircleIcon color="#0000FF" />}
        </Flex>
      ) : null}
    </Box>
  )
}
