import { ProductInternationalData, ProductUnion } from '@austria-codex/types'
import { isProduct } from '@austria-codex/utilities'
import { Box } from '@mui/material'
import { BioaequivalenzScore } from '../../components/BioaequivalenzScore'
import { BioaequivalenzScoreInfo } from '../../components/BioaequivalenzScoreInfo'
import { DataTableConfig } from '../../components/DataTable/DataTable'
import { Text } from '../../components/Layout/Typography'
import { getBioaequivalenzScore } from '../../helpers/product-international.helper'
import {
  agentsCell,
  countryCell,
  descriptionCell,
  mainAgentsCell,
  productIconCell,
} from './common'

export const PRODUCTS_INT_BY_PROVIDER_TABLE_CONFIG: DataTableConfig<ProductInternationalData> =
  {
    columns: [
      productIconCell,
      {
        title: 'Bezeichnung',
        key: 'bezeichnung',
        render: (_data, product, { showProviderIds }) => {
          return (
            <>
              {product.acoSearchTerm}{' '}
              {showProviderIds.includes(product.id) ? (
                <Text color="grey.600">
                  ({product.kontakteInt.anbieter.kurzname})
                </Text>
              ) : (
                ''
              )}
            </>
          )
        },
        width: '100%',
      },
      agentsCell,
      countryCell,
    ],
  }

export const PRODUCTS_BY_ATC_TABLE_CONFIG: DataTableConfig<ProductUnion> = {
  columns: [productIconCell, descriptionCell, mainAgentsCell, countryCell],
}

export const PRODUCTS_BY_IND_GROUP_TABLE_CONFIG: DataTableConfig<ProductInternationalData> =
  {
    columns: [
      productIconCell,
      {
        title: 'Bezeichnung',
        key: 'bezeichnung',
        render: (_data, product, { showProviderIds }) => {
          const bezeichnung = product.acoSearchTerm

          if (showProviderIds.includes(product.id)) {
            const provider = product.kontakteInt.anbieter.kurzname

            return (
              <>
                {bezeichnung}{' '}
                {provider && <Text color="grey.600">({provider})</Text>}
              </>
            )
          }

          return bezeichnung
        },
        width: '100%',
      },
      mainAgentsCell,
      countryCell,
    ],
  }

export const PRODUCTS_BY_AGENT_TABLE_CONFIG: DataTableConfig<ProductUnion> = {
  columns: [
    productIconCell,
    {
      title: 'Bezeichnung',
      key: 'bezeichnung',
      render: (_data, product, { showProviderIds }) => {
        const isProd = isProduct(product)
        const bezeichnung = isProd ? product.bezeichnung : product.acoSearchTerm

        if (showProviderIds.includes(product.id)) {
          const provider = isProd
            ? product.kontakte?.vertriebsfirma?.anzeigename
            : product.kontakteInt.anbieter.kurzname

          return (
            <>
              {bezeichnung}{' '}
              {provider && <Text color="grey.600">({provider})</Text>}
            </>
          )
        }

        return bezeichnung
      },
      width: '100%',
    },
    mainAgentsCell,
    countryCell,
  ],
}

export const PRODUCTS_INT_BY_AT_PRODUCT_ID_TABLE_CONFIG: DataTableConfig<ProductInternationalData> =
  {
    columns: [
      productIconCell,
      {
        title: 'Bezeichnung',
        key: 'bezeichnung',
        render: (_data, product, { showProviderIds }) => {
          return (
            <>
              {product.acoSearchTerm}{' '}
              {showProviderIds.includes(product.id) ? (
                <Text color="grey.600">
                  ({product.kontakteInt.anbieter.kurzname})
                </Text>
              ) : (
                ''
              )}
            </>
          )
        },
      },
      mainAgentsCell,
      {
        title: (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box component="span" mr={1}>
              Datenlage
            </Box>
            <BioaequivalenzScoreInfo />
          </Box>
        ),
        key: 'bio-score',
        render: (_data, product, { atProductId }) => {
          const bioScore = getBioaequivalenzScore(
            product.alternativenInt ?? [],
            atProductId
          )
          return bioScore ? <BioaequivalenzScore score={bioScore} /> : null
        },
        width: 200,
      },
      countryCell,
    ],
  }

export const PRODUCTS_INT_BY_INT_PRODUCT_ID_TABLE_CONFIG: DataTableConfig<ProductInternationalData> =
  {
    columns: [
      productIconCell,
      {
        title: 'Bezeichnung',
        key: 'bezeichnung',
        render: (_data, product, { showProviderIds }) => {
          return (
            <>
              {product.acoSearchTerm}{' '}
              {showProviderIds.includes(product.id) ? (
                <Text color="grey.600">
                  ({product.kontakteInt.anbieter.kurzname})
                </Text>
              ) : (
                ''
              )}
            </>
          )
        },
      },
      mainAgentsCell,
      {
        title: (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box component="span" mr={1}>
              Datenlage
            </Box>
            <BioaequivalenzScoreInfo />
          </Box>
        ),
        key: 'bio-score',
        render: (_data, _product) => {
          return <BioaequivalenzScore score={1} />
        },
        width: 200,
      },
      countryCell,
    ],
  }
