import type { ProductInternationalData } from '@austria-codex/types'
import { Text } from '../../../components/Layout/Typography'
import { SeparatedAgentList } from '../../../components/Utility/SeparatedActiveIngredientList'
import { getActiveBaseSubstancesInt } from '../../../helpers/product-international.helper'

type TProps = {
  product: ProductInternationalData
}

export function ProductInternationalMetaHeader({ product }: TProps) {
  const activeSubstances = getActiveBaseSubstancesInt(product)

  return (
    <Text variant="subHeading1" display="block" textTransform="uppercase">
      <SeparatedAgentList agents={activeSubstances} limit={3} />
    </Text>
  )
}
