import styled from '@emotion/styled'

type DotIndicatorProps = {
  value: number
  dotCount: number
  justifyContent?: string
}

export function DotIndicator({
  value,
  dotCount,
  justifyContent = 'center',
}: DotIndicatorProps) {
  const range = Array(dotCount).fill(0)

  return (
    <Container justifyContent={justifyContent}>
      {range.map((_, index) => (
        <Dot key={index} active={value > index} />
      ))}
    </Container>
  )
}

type ContainterProps = {
  justifyContent: string
}

const Container = styled.div<ContainterProps>`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
  padding: 0;
  margin: 0;
`

type DotProps = {
  active: boolean
}

const Dot = styled.span<DotProps>`
  background: ${(props) => (props.active ? '#424242' : '#D9D9D9')};
  border-radius: 50%;
  height: 10px;
  margin: 0 2px;
  width: 10px;
`
