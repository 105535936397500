import styled from '@emotion/styled'
import { ReactNode } from 'react'

type HighlightSectionProps = {
  highlight?: boolean
  children: ReactNode
}

export function HighlightSection({
  children,
  highlight,
}: HighlightSectionProps) {
  return highlight ? (
    <HighlightBackground>
      <HighlightContent>{children}</HighlightContent>
    </HighlightBackground>
  ) : (
    children
  )
}

const HighlightBackground = styled.div`
  border-left: 10px solid ${(props) => props.theme.palette.highlight.main};
  background-color: ${(props) => props.theme.palette.highlight.light};
`

const HighlightContent = styled.div`
  margin-left: -10px;
`
