import { css } from '@emotion/react'
import { ParagraphChildType } from '../types'

type ImageProps = {
  children: ParagraphChildType[]
  url: string
}

export function Image({ url }: ImageProps) {
  return (
    <img
      src={url}
      alt=""
      css={css`
        max-height: 200px;
      `}
    />
  )
}
