import {
  EEvaluationDescription,
  EInteractionDescription,
  EInteractionOccurence,
  EInteractionSeverity,
  EMechanism,
  Maybe,
} from '@austria-codex/types'
import { isNullOrUndefined } from '@austria-codex/utilities'

export const interactionDescriptions = [
  {
    title: 'Pharmakologischer Effekt:',
    type: EInteractionDescription.PharmakologischerEffekt,
  },
  {
    title: 'Nebenwirkungen:',
    type: EInteractionDescription.Nebenwirkungen,
  },
  {
    title: 'Risikofaktoren:',
    type: EInteractionDescription.Risikofaktoren,
  },
  {
    title: 'Weitere Risikofaktoren:',
    type: EInteractionDescription.WeitereRisikofaktoren,
  },
  {
    title: 'Mögliche Symptome:',
    type: EInteractionDescription.MoeglicheSymptome,
  },
  {
    title: 'Wichtige Hinweise:',
    type: EInteractionDescription.WichtigeHinweise,
  },
  {
    title: 'Maßnahmen:',
    type: EInteractionDescription.Massnahmen,
  },
  {
    title: 'Zeitversetzte Einnahme/Anwendung:',
    type: EInteractionDescription.ZeitversetzteEinnahmeAnwendung,
  },
  {
    title: 'Dosis- bzw. Intervallanpassung:',
    type: EInteractionDescription.DosisBzwIntervallanpassung,
  },
  {
    title: 'Hinweise bei Kurzzeittherapie:',
    type: EInteractionDescription.HinweiseBeiKurzzeittherapie,
  },
  {
    title: 'Weitere Empfehlungen:',
    type: EInteractionDescription.WeitereEmpfehlungen,
  },
  {
    title: 'Mechanismus:',
    type: EInteractionDescription.Mechanismus,
  },
  {
    title: 'Messwerte für Monitoring:',
    type: EInteractionDescription.MesswerteFuerMonitoring,
  },
  {
    title: 'Beginn des Monitorings:',
    type: EInteractionDescription.BeginnDesMonitorings,
  },
  {
    title: 'Dauer des Monitorings:',
    type: EInteractionDescription.DauerDesMonitorings,
  },
  {
    title: 'Monitoring nach Absetzen:',
    type: EInteractionDescription.MonitoringNachAbsetzen,
  },
] as const

export const interactionHeadings = {
  [EInteractionSeverity.NoStatementPossible]: 'Keine Aussage möglich',
  [EInteractionSeverity.NoInteraction]: 'Keine Interaktion zu erwarten',
  [EInteractionSeverity.ProductSpecificWarning]:
    'Produktspezifische Warnmeldung',
  [EInteractionSeverity.Minor]: 'Gering',
  [EInteractionSeverity.Moderate]: 'Mittelschwer',
  [EInteractionSeverity.Severe]: 'Schwerwiegend',
  [EInteractionSeverity.Contraindicated]: 'Kontraindiziert',
} as const

export type TInteractionsHeadingKey = keyof typeof interactionHeadings

export const evaluationDescription = {
  [EEvaluationDescription.Unknown]: 'nicht bekannt',
  [EEvaluationDescription.NotSufficient]: 'nicht ausreichend',
  [EEvaluationDescription.Weak]: 'schwach',
  [EEvaluationDescription.Enough]: 'hinreichend',
  [EEvaluationDescription.Good]: 'gut',
} as const

export type TEvaluationDescriptionKey = keyof typeof evaluationDescription

export function evaluationMapping(value: Maybe<number>) {
  return value ? (value * 2) / 100 : null
}

export const mechanismDescription = {
  [EMechanism.Unknown]: 'unbekannter Mechanismus',
  [EMechanism.Plausible]: 'plausibler Mechanismus',
  [EMechanism.Proven]: 'Mechanismus belegt',
}

export type TMechanismDescriptionKey = keyof typeof mechanismDescription

// Transform a double digit number to a single digit 0-based index number.
// Eg: PharmPlausibilität has 10, 20, 30 as possible numbers - we want 0, 1 or 2.
export function toZeroBasedIndexNumber(
  value: Maybe<EMechanism | EEvaluationDescription>
) {
  if (isNullOrUndefined(value)) {
    return 0
  }

  const tmp = value / 10
  return tmp - 1
}

// Descriptions taken from KHIX Docs p. 113
export const occurrenceDescription = {
  [EInteractionOccurence.VeryOften]: 'sehr häufig (>= 10/100)',
  [EInteractionOccurence.Often]: 'häufig (>= 1/100 bis < 10/100)',
  [EInteractionOccurence.Sometimes]: 'gelegentlich (>= 1/1.000 bis < 1/100)',
  [EInteractionOccurence.Rarely]: 'selten (>= 0,1/1.000 bis < 1/1.000)',
  [EInteractionOccurence.VeryRarely]: 'sehr selten (< 0,1/1.000)',
  [EInteractionOccurence.Unknown]: 'nicht bekannt',
}

export type TOccurrenceDescriptionKey = keyof typeof occurrenceDescription

export function occurrenceMapping(value: Maybe<number>) {
  return value ? 6 - value : null
}
