import { TCountryMode } from '@austria-codex/types'
import { useTheme } from '@emotion/react'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  css,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { ChangeEvent, MouseEvent, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import AtIcon from '../../assets/images/countryFlagsSvg/at.svg?react'
import EarthIcon from '../../assets/images/earth.svg?react'
import UpDownArrowsIcon from '../../assets/images/up-down-arrows.svg?react'
import {
  getAlpha3ByAlpha2,
  getCountryByIsoCode,
} from '../../helpers/country.helper'
import { useAppDispatch, useAppSelector } from '../../hooks/useStoreHooks'
import { removeAllEntities } from '../../store/entities'
import { setBackUrl } from '../../store/settings.store'
import { setMode } from '../../store/user.store'
import { Paths } from '../../utilities/path.constants'
import { toFullPath } from '../../utilities/url'
import { AtLabel } from '../AtLabel'
import { IntLabel } from '../IntLabel'

const modeToBtnInfo = {
  national: { text: 'AUT', icon: <AtIcon /> },
  international: { text: 'INT', icon: <EarthIcon /> },
}

export function IntModeSelector() {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const theme = useTheme()
  const mode = useAppSelector((state) => state.user.mode)
  const selectedCountries = useAppSelector(
    (state) => state.user.selectedCountries
  )
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  function handleClick(event: MouseEvent<HTMLElement>) {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    event.stopPropagation()

    const mode = event.target.value as TCountryMode
    dispatch(setMode(mode))
    dispatch(setBackUrl(null))
    // Clean state on mode switch
    dispatch(removeAllEntities())
  }

  const open = Boolean(anchorEl)
  const id = open ? 'info-popper' : undefined
  const btnText = modeToBtnInfo[mode].text
  const btnIcon = modeToBtnInfo[mode].icon

  return (
    <>
      <Button
        sx={{
          alignItems: 'center',
          backgroundColor: 'primary.light',
          borderRadius: '3px',
          columnGap: '0.75rem',
          display: 'flex',
          marginRight: '0.5rem',
          minHeight: '54px',
          outline: 'none',
        }}
        onClick={handleClick}
        aria-describedby={id}
      >
        <Box
          component="span"
          sx={{ alignItems: 'center', display: 'flex', width: '24px' }}
        >
          {btnIcon}
        </Box>
        <Box
          component="span"
          sx={{ width: '26px', color: 'grey.800' }}
          display={['none', 'inline-block']}
        >
          {btnText}
        </Box>
        <UpDownArrowsIcon />
      </Button>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        sx={{ zIndex: 998 }}
      >
        <Paper sx={{ p: 2, width: '280px', color: 'grey.800' }} elevation={3}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <FormLabel
              id="mode-setting"
              sx={{ fontSize: '0.875rem', fontWeight: 700, color: 'grey.800' }}
            >
              Länderauswahl
            </FormLabel>
            <IconButton onClick={handleClose}>
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Box>
          <FormControl>
            <RadioGroup
              aria-labelledby="mode-setting"
              name="mode"
              value={mode}
              onChange={handleChange}
            >
              <FormControlLabel
                value="national"
                control={<Radio />}
                label={<AtLabel />}
              />
              <FormControlLabel
                value="international"
                control={<Radio />}
                label={<IntLabel />}
              />
            </RadioGroup>
          </FormControl>
          <Divider sx={{ backgroundColor: 'primary.main' }} />
          {mode === 'international' && (
            <>
              <Typography
                sx={{
                  mt: 2,
                  fontSize: '0.75rem',
                  fontWeight: 700,
                  color: 'grey.800',
                }}
              >
                Ausgewählte Länder
              </Typography>
              <ol
                css={css`
                  list-style-position: inside;
                  margin: 0;
                  margin-top: 4px;
                  max-height: 290px;
                  overflow-y: auto;
                  padding: 0;
                `}
              >
                {selectedCountries.map((sc) => (
                  <li
                    css={css`
                      padding: 4px 0;
                    `}
                    key={sc}
                  >
                    {getCountryByIsoCode(sc)} (
                    {getAlpha3ByAlpha2(sc).toUpperCase()})
                  </li>
                ))}
              </ol>
              <Box
                sx={{
                  mt: 2,
                  p: 2,
                  border: '1px solid blue',
                  borderColor: 'info.main',
                  borderRadius: '5px',
                  backgroundColor: 'info.light',
                  fontSize: '0.75rem',
                }}
              >
                <strong>Hinweis:</strong> Bitte beachten Sie, dass nur
                Ergebnisse aus den gewählten Ländern angezeigt werden.
              </Box>
            </>
          )}
          <Link
            css={css`
              color: ${theme.palette.primary.main};
              display: block;
              margin-top: 1rem;
              text-align: right;
              text-decoration: none;
              text-transform: uppercase;
            `}
            to={`${Paths.Settings}/module`}
            onClick={() => dispatch(setBackUrl(toFullPath(location)))}
          >
            Länder bearbeiten
          </Link>
        </Paper>
      </Popper>
    </>
  )
}
