import type {
  ProductData,
  SubstanceIntSummaryData,
  SubstanceSummaryData,
} from '@austria-codex/types'
import { useMemo } from 'react'
import { useAppSelector } from '../../hooks/useStoreHooks'
import { Html } from '../Html/Html'
import { SeparatedList, SeparatedListItem } from '../Layout/SeparatedList'

type ProductSeparatedAgentListProps = {
  product: ProductData
  separator?: string
  root?: boolean
  limit?: number
  ellipsis?: string
}

export function ProductSeparatedAgentList({
  product,
  separator,
  root,
  limit,
  ellipsis = 'et al.',
}: ProductSeparatedAgentListProps) {
  const agents = useAppSelector(
    (state) => state.productComposition[product.id]?.agents
  )

  return (
    <SeparatedAgentList
      agents={root ? agents.root : agents.self}
      limit={limit}
      separator={separator}
      ellipsis={ellipsis}
    />
  )
}

type SeparatedAgentListProps = {
  agents: (SubstanceSummaryData | SubstanceIntSummaryData)[] | undefined
  limit?: number
  separator?: string
  ellipsis?: string
}

export function SeparatedAgentList({
  agents,
  separator,
  limit,
  ellipsis = 'et al.',
}: SeparatedAgentListProps) {
  const slicedAgents = useMemo(() => {
    if (!agents) {
      return []
    }

    return agents.slice(0, limit)
  }, [agents, limit])

  const showEllipsis = limit && agents && agents.length > limit

  return (
    <SeparatedList separator={separator}>
      {slicedAgents.map((agent, index) => (
        <SeparatedListItem key={`${agent.id}-${index}`}>
          {/* Need to use Html here, because international substances can have tags in their names */}
          <Html content={agent.bezeichnung} />
        </SeparatedListItem>
      ))}
      {showEllipsis && <SeparatedListItem>{ellipsis}</SeparatedListItem>}
    </SeparatedList>
  )
}
