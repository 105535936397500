import { FeatureEnum, type EntityAtcCodeHitsData } from '@austria-codex/types'
import { isHumanAtcCode } from '@austria-codex/utilities'
import { Box } from '@mui/material'
import { Fragment, useMemo } from 'react'
import { AtcCodeBlock } from '../../../components/Codes/AtcCodeBlock'
import { CodeGroup } from '../../../components/Codes/CodesGroup'
import { Section } from '../../../components/Section/Section'
import { useProductData } from '../../../contexts/entity'
import { useAppSelector } from '../../../hooks/useStoreHooks'
import { FeatureProps } from '../../../types/bundle'
import { isEmpty } from '../../../utilities/common'
import { AtcCodeDomainGroup } from './AtcCodeDomainGroup'
import { AtcCodeProductsTable } from './AtcCodeProductsTable'

type OrderedEntityClassificationAtcCodeInterface = {
  human: EntityAtcCodeHitsData[]
  veterinary: EntityAtcCodeHitsData[]
}

export function AtcCodesSection({ features }: FeatureProps) {
  const entity = useProductData()
  const hasProducts = features.includes(FeatureEnum.ATCCodesProducts)
  const includeVeterinary = useAppSelector(
    (state) => state.settings.includeVeterinary
  )

  const orderedAtcCodes = useMemo(() => {
    return (
      entity.klassifikation?.atcCodes?.map((atcCodes) => {
        const group = {
          human: [],
          veterinary: [],
        } as OrderedEntityClassificationAtcCodeInterface

        atcCodes.forEach((atcCode) => {
          if (isHumanAtcCode(atcCode.atcCode.id)) {
            group.human.push(atcCode)
          } else {
            group.veterinary.push(atcCode)
          }
        })

        return group
      }) ?? []
    )
  }, [entity])

  return (
    <Section heading="Anatomisch-Therapeutisch-Chemische Gruppe" width="small">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
        {orderedAtcCodes.map((atcCodes, index) => {
          return (
            (!isEmpty(atcCodes.human) || !isEmpty(atcCodes.veterinary)) && (
              <Fragment key={index}>
                {!isEmpty(atcCodes.human) && (
                  <CodeGroup>
                    <AtcCodeDomainGroup isVeterinary={false}>
                      <AtcCodeList
                        section={entity.id}
                        atcCodes={atcCodes.human}
                        hasToggle={hasProducts}
                      />
                    </AtcCodeDomainGroup>
                  </CodeGroup>
                )}
                {includeVeterinary && !isEmpty(atcCodes.veterinary) && (
                  <CodeGroup>
                    <AtcCodeDomainGroup isVeterinary={true}>
                      <AtcCodeList
                        section={entity.id}
                        atcCodes={atcCodes.veterinary}
                        hasToggle={hasProducts}
                      />
                    </AtcCodeDomainGroup>
                  </CodeGroup>
                )}
              </Fragment>
            )
          )
        })}
      </Box>
    </Section>
  )
}

type AtcCodeListProps = {
  atcCodes: EntityAtcCodeHitsData[]
  section: string
  hasToggle: boolean
}

function AtcCodeList({ atcCodes, section, hasToggle }: AtcCodeListProps) {
  return (
    <>
      {atcCodes.map(
        ({ atcCode: { id, bezeichnung }, bezeichnung: caption }, index) => (
          <Box my={3} key={id}>
            <AtcCodeBlock
              code={id}
              title={bezeichnung}
              caption={caption}
              section={section}
              group="alternatives"
              identifier={id}
              hasToggle={hasToggle}
            >
              {(onToggle) =>
                hasToggle && (
                  <Box mt={2}>
                    <AtcCodeProductsTable
                      atcCode={id}
                      name={`alternatives:atcCode:${index}:${id}`}
                      heading={bezeichnung}
                      subheading={id}
                      onClose={onToggle}
                    />
                  </Box>
                )
              }
            </AtcCodeBlock>
          </Box>
        )
      )}
    </>
  )
}
