import type { Eintrag } from '@austria-codex/types'
import type { ReactNode } from 'react'
import { Box, Flex } from '../../../../components/Layout/Box'
import { Text } from '../../../../components/Layout/Typography'

type TProps = {
  bullet?: boolean
  children: ReactNode
  entry: Eintrag
  onClick?: () => void
}

export function IntCompositionTitleBlock({
  bullet,
  children,
  entry,
  onClick,
}: TProps) {
  const isReference = Boolean(entry.bezLaufnummer)

  return (
    <Flex>
      {(isReference || bullet !== undefined) && (
        <Flex flexShrink={0} width={40} justifyContent="center">
          {bullet && <Text color="black">&#8226;</Text>}
        </Flex>
      )}
      <Box onClick={onClick} display="flex">
        {isReference && (
          <Text fontStyle="italic" mr={1}>
            entspr.
          </Text>
        )}
        {children}
      </Box>
    </Flex>
  )
}
