import { Html } from '../../components/Html/Html'
import { DocumentationSection } from '../../components/Section/DocumentationSection'
import { useProductData } from '../../contexts/entity'

export function ContraindicationSection() {
  const product = useProductData()

  return product.dokumentation?.schnellhilfe?.gegenanzeigen ? (
    <DocumentationSection
      inherit={product.dokumentation.schnellhilfe.gegenanzeigen.vererbt}
      width="small"
      heading="Gegenanzeigen"
    >
      <Html content={product.dokumentation.schnellhilfe.gegenanzeigen.text} />
    </DocumentationSection>
  ) : null
}
