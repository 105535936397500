export const MULTI_SELECT_QUERY_PARTIAL = `
  multiSelect(identifiers: $entityIdentifiers) {
    agentBased {
      stoff {
        id
        bezeichnung
      }
      produkte {
        id
        bezeichnung
      }
    }
    atcCodeBased {
      atcCode {
        id
        bezeichnung
      }
      produkte {
        id
        bezeichnung
      }
    }
  }
`
