import type { EntityDataUnion } from '@austria-codex/types'
import {
  isProduct,
  isProductInternational,
  isSubstance,
  isSubstanceInternational,
} from '@austria-codex/utilities'
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { type ReactNode } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSearchParams } from 'react-router-dom'
import Earth from '../../assets/images/earth.svg?react'
import { EntityContext } from '../../contexts/entity'
import { getCountryByIsoCode } from '../../helpers/country.helper'
import { useCollapse } from '../../hooks/useCollapse'
import { useAppSelector } from '../../hooks/useStoreHooks'
import { ModulesTypes } from '../../types/bundle'
import { AbDataBanner } from '../AbDataBanner'
import { AvailabilityHintBanner } from '../AvailabilityHintBanner'
import { EntityCircleIcon } from '../EntityIcon/EntityCircleIcon'
import { CollapseIcon } from '../Icons/CollapseIcon'
import { Box, Flex } from '../Layout/Box'
import { Content } from '../Layout/Content'
import { Separator } from '../Layout/Separator'
import { AnnotatedText, Text } from '../Layout/Typography'
import { OtherCountryHintBanner } from '../OtherCountryHintBanner'
import { WarningLevelProvider } from '../WaringLevelProvider'

type EntityCollapseProps = {
  children: ReactNode
  entity: EntityDataUnion
  metaRender: (entity: EntityDataUnion) => ReactNode
  module: ModulesTypes
}

// Only show the banner under 'Anwendung', 'Sicherheit' and 'Handel'
function shouldRenderAbdataBanner(
  module: ModulesTypes,
  isInternational: boolean
) {
  if (module === 'Alternatives') {
    return false
  }

  return isInternational
}

// Only show the banner in international mode under 'Alternativen'
function shouldRenderOtherCountryHintBanner(
  module: ModulesTypes,
  isInternational: boolean
) {
  if (module === 'Alternatives') {
    return isInternational
  }

  return false
}

function shouldRenderAvailabiltyBanner(
  module: ModulesTypes,
  entity: EntityDataUnion
) {
  if (module !== 'Retail') {
    return false
  }

  if (!isSubstance(entity)) {
    return false
  }

  return entity.existiertInNichtLieferbarenProdukten
}

export function EntityCollapse({
  children,
  entity,
  metaRender,
  module,
}: EntityCollapseProps) {
  const [searchParams] = useSearchParams()
  const mode = useAppSelector((state) => state.user.mode)
  const collapseOpenOnAdd = useAppSelector(
    (state) => state.settings.collapseOpenOnAdd
  )

  // If user comes via API link page and only provided
  // one PZN, the entity should be open, no matter
  // the collapse setting. The param is set in
  // src/utilities/url.ts.
  const open = searchParams.get('open') === 'true'

  const [collapse, onToggle] = useCollapse({
    section: `main-page:${entity.id}`,
    defaultValue: open ? true : collapseOpenOnAdd,
  })

  const isProd = isProduct(entity)
  const isProdInt = isProductInternational(entity)
  const isSubInt = isSubstanceInternational(entity)
  const isInternational = isProdInt || isSubInt
  const renderAbDataBanner = shouldRenderAbdataBanner(module, isInternational)
  const renderOtherCountryHintBanner = shouldRenderOtherCountryHintBanner(
    module,
    mode === 'international'
  )
  const renderAvailabiltyBanner = shouldRenderAvailabiltyBanner(module, entity)

  return (
    <EntityContext.Provider value={entity}>
      <WarningLevelProvider>
        <Wrapper id={`entity:${entity.id}`} className="tracking-entity-section">
          <Content width="large">
            <Flex py={4}>
              <Flex
                height={[32, 56]}
                width={[32, 56]}
                justifyContent="center"
                alignItems="center"
              >
                <Box display={['none', 'block']}>
                  <EntityCircleIcon entity={entity} size="large" />
                </Box>
                <Box display={['block', 'none']}>
                  <EntityCircleIcon entity={entity} size="small" />
                </Box>
              </Flex>
              <Flex
                alignItems="flex-start"
                flexDirection="column"
                flex="1"
                px={[2, 4]}
                justifyContent="center"
              >
                {isInternational && isProdInt && (
                  <InternationalBadge isoCode={entity.isoCode} />
                )}
                <TitleLink onClick={() => onToggle(!collapse)}>
                  <Text
                    as="h3"
                    fontSize={['1rem', '1.75rem']}
                    p={0}
                    m={0}
                    className="tracking-entity-section-title"
                  >
                    {isInternational && isProdInt ? (
                      <AnnotatedText text={entity.acoSearchTerm} />
                    ) : entity.bezeichnung ? (
                      <AnnotatedText text={entity.bezeichnung} />
                    ) : (
                      <Skeleton width={300} />
                    )}
                    {isProd && entity.suffix && (
                      <Text fontStyle="italic"> {entity.suffix}</Text>
                    )}
                  </Text>
                </TitleLink>
                <div>{metaRender(entity)}</div>
              </Flex>
              <Flex
                height={[32, 56]}
                width={[32, 56]}
                justifyContent="center"
                alignItems="center"
              >
                <CollapseIcon
                  className="tracking-entity-toggle"
                  onToggle={() => onToggle(!collapse)}
                  collapse={collapse}
                  variant="large"
                />
              </Flex>
            </Flex>
          </Content>
          {collapse ? (
            <>
              {renderAbDataBanner ? (
                <>
                  <Separator variant="zigzag" bgBottom="primary.light" />
                  <AbDataBanner />
                </>
              ) : renderOtherCountryHintBanner ? (
                <>
                  <Separator
                    variant="zigzag"
                    bgBottom="info.light"
                    lineColor="common.white"
                  />
                  <OtherCountryHintBanner />
                </>
              ) : renderAvailabiltyBanner ? (
                <>
                  <Separator
                    variant="zigzag"
                    bgBottom="info.light"
                    lineColor="common.white"
                  />
                  <AvailabilityHintBanner />
                </>
              ) : (
                <Separator variant="zigzag" />
              )}
              {children}
            </>
          ) : null}
        </Wrapper>
      </WarningLevelProvider>
    </EntityContext.Provider>
  )
}

const TitleLink = styled.div`
  &:hover {
    cursor: pointer;
  }
`

const Wrapper = styled.div`
  & + & {
    border-top: 2px solid ${(props) => props.theme.palette.grey[200]};
  }
`

type TInternationalBadgeProps = {
  isoCode: string
}

function InternationalBadge({ isoCode }: TInternationalBadgeProps) {
  const theme = useTheme()
  const countryName = getCountryByIsoCode(isoCode)

  return (
    <span
      css={css`
        align-items: center;
        background-color: #ecf6eb;
        border-radius: 12px 4px 4px 12px;
        display: flex;
        height: 24px;
        padding: 0 8px 0 0;
      `}
    >
      <Earth
        css={css`
          path {
            fill: ${theme.palette.primary.main};
          }
        `}
      />
      <span
        css={css`
          margin-left: 8px;
        `}
      >
        {countryName}
      </span>
    </span>
  )
}
