import { Typography, useTheme } from '@mui/material'
import { PRODUCTS_INT_BY_PROVIDER_TABLE_CONFIG } from '../../../common/tableConfig/products-international'
import { IntProductsDataTable } from '../../../components/DataTable/IntProductsDataTable'
import { Box } from '../../../components/Layout/Box'
import { Section } from '../../../components/Section/Section'
import { useProductIntData } from '../../../contexts/entity'

const heading = 'Weitere Produkte mit demselben Handelsnamen'

export function IntSameProductsByNameSection() {
  const product = useProductIntData()
  const theme = useTheme()

  const name = product.bezeichnung

  return (
    <Section width="small" heading={heading}>
      <Typography variant="h5" sx={{ color: theme.palette.grey[700] }}>
        {name}
      </Typography>
      <Box mt={2}>
        <IntProductsDataTable
          config={PRODUCTS_INT_BY_PROVIDER_TABLE_CONFIG}
          filter={{
            name,
          }}
          heading={heading}
          subheading={name}
        />
      </Box>
    </Section>
  )
}
