import Button from '@mui/material/Button'
import { logout } from '../client/austriaCodex'
import { ErrorScreen } from '../components/ErrorScreen/ErrorScreen'
import { Box, Flex } from '../components/Layout/Box'
import { Heading, Text } from '../components/Layout/Typography'

export function UnauthorizedPage() {
  return (
    <ErrorScreen>
      <Box mb={2}>
        <Heading level={1}>Hoppala!</Heading>
      </Box>
      <Text textAlign="center" fontSize={16}>
        Sie haben derzeit keine Berechtigung, auf den Austria-Codex Online
        zuzugreifen.
        <br />
        Unter mein.apoverlag.at können Sie den Austria-Codex Online ausprobieren
        und lizenzieren!
        <br />
        Ihr APOVERLAG
      </Text>
      <Flex justifyContent="center" mt={3}>
        <Button color="primary" variant="contained" onClick={() => logout()}>
          Abmelden
        </Button>
      </Flex>
    </ErrorScreen>
  )
}
