import { css } from '@emotion/react'
import styled from '@emotion/styled'
import ClearIcon from '@mui/icons-material/ClearRounded'
import { forwardRef, useState } from 'react'
import SearchIcon from '../../assets/images/ic-search-green.svg?react'
import { ToolbarButton } from './ToolbarButton'

interface SearchInputProps {
  value: string
  onChange: (value: string) => void
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ value, onChange }, ref) => {
    const [focus, setFocus] = useState(false)

    return (
      <Wrapper focus={focus}>
        <ToolbarButton as="div">
          <SearchIcon />
        </ToolbarButton>
        <Input
          id="tracking-search-bar"
          ref={ref}
          value={value}
          autoComplete="off"
          autoCorrect="off"
          placeholder="Suche (Handelsname, Wirkstoff, Indikationsgruppe)"
          onChange={(event) => onChange(event.target.value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        {value.length > 0 && (
          <ToolbarButton onClick={() => onChange('')}>
            <ClearIcon color="primary" />
          </ToolbarButton>
        )}
      </Wrapper>
    )
  }
)

interface WrapperProps {
  focus: boolean
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  padding: 0 12px;
  border-radius: 3px;
  z-index: 1;

  ${(props) =>
    props.focus &&
    css`
      box-shadow: 0 0 2px 3px ${props.theme.palette.primary.main};
    `}
`

const Input = styled.input`
  width: 100%;
  border: none;
  background: none;
  font-size: 1.25rem;
  justify-self: stretch;
  padding: 12px;
  min-width: 1px;
  outline: none;

  &::placeholder {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: 1rem;
    opacity: 1;
  }

  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: ${(props) => props.theme.palette.primary.main};
  }
`
