import { Kurzinfo } from '@austria-codex/types'
import CloseIcon from '@mui/icons-material/Close'
import { Box, DialogContent, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'
import { TransitionProps } from '@mui/material/transitions'
import { ReactElement, Ref, UIEvent, forwardRef, useRef, useState } from 'react'
import { AbDataLogo } from '../../../components/AbDataLogo'
import { List, ListItem } from '../../../components/Layout/List'
import { Section } from '../../../components/Section/Section'
import { useProductIntData } from '../../../contexts/entity'
import { getShortInfoHeading } from '../../../helpers/product-international.helper'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export function IntDocumentsSection() {
  const product = useProductIntData()
  const [open, setOpen] = useState(false)

  const shortInfos = product.kurzinfos ?? []

  // Only AT and DE products have infos
  if (shortInfos.length === 0) {
    return null
  }

  return (
    <Section width="small" heading="Dokumente">
      <List>
        <ListItem>
          <Box
            component="button"
            sx={{
              background: 'white',
              border: 0,
              color: 'primary.main',
              cursor: 'pointer',
              outline: 'none',
              textDecoration: 'underline',
            }}
            onClick={() => setOpen(true)}
          >
            ABDA-Fertigarzneimitteltext
          </Box>
        </ListItem>
      </List>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title={product.acoSearchTerm}
        shortInfos={shortInfos}
      />
    </Section>
  )
}

type ModalProps = {
  title: string
  shortInfos: Kurzinfo[]
  open: boolean
  onClose: () => void
}

function Modal({ title, shortInfos, open, onClose }: ModalProps) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [scrollActive, setScrollActive] = useState(false)
  const contentElem = useRef<HTMLDivElement | null>(null)

  function handleScroll(event: UIEvent<HTMLDivElement>) {
    if (scrollActive) return

    const elem = event.currentTarget
    const scrollTop = elem.scrollTop
    const headings = elem.querySelectorAll<HTMLDivElement>(
      'div[id^="vertical-tabpanel-"]'
    )

    // Find the id (index of the section),
    // which is currently active.
    let id = 0
    headings.forEach((h, index) => {
      // Skip the first section
      if (index === 0) return

      // Add some "padding" so that the id
      // increases when the heading is not
      // yet at the top.
      if (scrollTop + 150 > h.offsetTop) {
        id++
      }
    })

    setSelectedTabIndex(id)
  }

  function scrollToElement(index: number) {
    if (contentElem.current) {
      const elem = contentElem.current.querySelector(
        '#vertical-tabpanel-' + index
      )
      if (elem) {
        setScrollActive(true)

        elem.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })

        // Need to set a timeout here,
        // because the scroll itself
        // takes some time.
        setTimeout(() => {
          setScrollActive(false)
        }, 1000)
      }
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      sx={{ '> .MuiDialog-container > .MuiPaper-root': { height: '80%' } }}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      scroll="paper"
      keepMounted
    >
      <DialogTitle
        component="div"
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 2px 2px rgba(0,0,0,0.25)',
          color: 'text.primary',
          display: 'flex',
          fontWeight: 400,
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography variant="h6">ABDA-Fertigarzneimitteltext</Typography>
          {title}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
          <AbDataLogo />
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{ p: 0, display: 'flex', gap: '2rem', overflow: 'hidden' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
            gap: '1rem',
            width: '160px',
            mt: 2,
          }}
        >
          {shortInfos.map((si, index) => (
            <Box
              key={si.abschnittsInhalt}
              component="button"
              id={`vertical-tab-${index}`}
              aria-controls={`vertical-tabpanel-${index}`}
              sx={{
                background: 'white',
                border: 'none',
                display: 'flex',
                gap: '0.5rem',
                cursor: 'pointer',
                color: 'primary.main',
                fontWeight: selectedTabIndex === index ? 700 : 400,
                paddingLeft: '1rem',
                textDecoration: 'none',
              }}
              onClick={() => {
                scrollToElement(index)
                setSelectedTabIndex(index)
              }}
            >
              <Box component="span" sx={{ width: '13px' }}>
                {selectedTabIndex === index && (
                  <svg
                    width="13"
                    height="9"
                    viewBox="0 0 13 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L4.50017 4.5L1 8"
                      stroke="#3FA535"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 1L11.5002 4.5L8 8"
                      stroke="#3FA535"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </Box>
              {getShortInfoHeading(si.abschnittsInhalt)}
            </Box>
          ))}
        </Box>
        <Box
          ref={contentElem}
          onScroll={handleScroll}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            py: 2,
            overflowY: 'auto',
            paddingRight: '1rem',
          }}
        >
          {shortInfos.map((si, index) => (
            <Box
              key={si.abschnittsInhalt}
              id={`vertical-tabpanel-${index}`}
              aria-labelledby={`vertical-tab-${index}`}
            >
              <Typography sx={{ fontSize: '1.25rem', fontWeight: 700 }}>
                {getShortInfoHeading(si.abschnittsInhalt)}
              </Typography>
              <Box
                sx={{ mt: 3 }}
                dangerouslySetInnerHTML={{ __html: si.text }}
              />
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  )
}
