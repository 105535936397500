export const SUBSTANCE_INTERNATIONAL_FRAGMENT = `
  fragment SubstanceInternationalFragment on SubstanceInternationalType {
    id
    bezeichnung
    treffer {
      handel {
        lieferbar {
          ...SubstanceRetailAvailabilityHitsFragment
        }
        nichtLieferbar {
          ...SubstanceRetailAvailabilityHitsFragment
        }
      }
      handelDe {
        lieferbar {
          ...SubstanceRetailDePreparationHitsFragment
        }
        nichtLieferbar {
          ...SubstanceRetailDePreparationHitsFragment
        }
      }
      anwendung {
        ...SubstanceApplicationCountryHitsFragment
      }
    }
    klassifikationSubstanceInt {
      applikationsarten {
        id
      }
      atcCodes {
        atcCode {
          id
          bezeichnung
        }
        bezeichnung
        treffer {
          human
          veterinaer
        }
      }
      indikationsgruppen {
        indikationsgruppe {
          id
          bezeichnung
        }
        bezeichnung
        treffer {
          human
          veterinaer
        }
      }
    }
  }
`
