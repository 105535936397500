import { SearchFilter } from '@austria-codex/types'
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { fetchAtcCodeHits } from '../api/api'
import { AtcCodeHitsData } from '../api/queries/atcCodeHits'
import { removeAllEntities } from './entities'
import { RootState } from './index'
import { getIsoCodes } from './utilities/isoCodes'

type State = {
  loading: boolean
  // Lookup for the number of hits for a given atcCode
  // [atcCode => hits]
  atcCodeHits: Record<string, number>
}

const initialState: State = {
  loading: false,
  atcCodeHits: {},
}

export const fetchAllAtcCodeHitsData = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('allAtcCodeHits', (_, { getState, dispatch }) => {
  const state = getState()

  const atcCodes = Object.keys(state.atcCodeHits.atcCodeHits)
  if (atcCodes.length === 0) {
    return
  }

  dispatch(fetchAtcCodeHitsData(atcCodes))
})

export const fetchAtcCodeHitsData = createAsyncThunk<
  AtcCodeHitsData[],
  string[],
  { state: RootState }
>('atcCodeHits/fetch', async (atcCodes, { getState }) => {
  const state = getState()

  const filter: SearchFilter = {
    routesOfAdministration: state.filter.selectedRoutesOfAdministration,
    includeVeterinary: state.settings.includeVeterinary,
    isoCodes: getIsoCodes(state),
  }

  const { data } = await fetchAtcCodeHits(atcCodes, filter)

  return data.atcCodeHits
})

const atcCodeHitsSlice = createSlice({
  name: 'atcCodeHits',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAtcCodeHitsData.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAtcCodeHitsData.fulfilled, (state, { payload }) => {
        payload.forEach(({ atcCode, hits }) => {
          state.atcCodeHits[atcCode] = hits
        })

        state.loading = false
      })
      .addCase(fetchAtcCodeHitsData.rejected, (state, { payload }) => {
        state.loading = false
      })
      .addCase(removeAllEntities, (state, { payload }) => {
        return initialState
      })
  },
})

export default atcCodeHitsSlice.reducer

const atcCodeHitsLoadingSelector = (state: RootState) =>
  state.atcCodeHits.loading

const atcCodeHitsSelector = (state: RootState) => state.atcCodeHits.atcCodeHits

export const atcCodeHitsCountSelector = createSelector(
  [atcCodeHitsLoadingSelector, atcCodeHitsSelector],
  (loading, atcCodeHits) => {
    return loading ? undefined : atcCodeHits
  }
)
