import { alpha2ToAlpha3, isoCodeToCountryMapping } from '../common/mappings'

export function getCountryByIsoCode(isoCode: string): string {
  isoCode = isoCode.toLowerCase()
  return isValidIsoCode(isoCode)
    ? isoCodeToCountryMapping[isoCode]
    : 'Unbekannter Länder ISO Code'
}

export function getAlpha3ByAlpha2(isoCode: string): string {
  isoCode = isoCode.toLowerCase()
  return isValidIsoCode(isoCode)
    ? alpha2ToAlpha3[isoCode]
    : 'Unbekannter Länder ISO Code'
}

function isValidIsoCode(
  isoCode: string
): isoCode is keyof typeof isoCodeToCountryMapping {
  return Object.keys(isoCodeToCountryMapping).includes(isoCode)
}
