import { gql } from '@apollo/client/core'

export const SEARCH_QUERY = gql`
  query search($query: String!, $offset: Int, $filter: SearchFilterInputType) {
    search(query: $query, offset: $offset, filter: $filter) {
      offset
      limit
      total {
        products
        substances
      }
      hits {
        ... on ProductType {
          id
          isoCode
          bezeichnung
          suffix
          istVeterinaer
          istLieferbar
          istVerlaufsTyp5
          ausVerlauf
          klassifikation {
            istMonopraeparat
          }
          verkauf {
            preisAb {
              preisUVP
              preisKVP
            }
            anzahlArtikel
          }
          abgabe {
            art
            ekoBoxen
          }
        }
        ... on ProductInternationalType {
          id
          isoCode
          bezeichnung
          acoSearchTerm
          istVeterinaer
          istLieferbar
          abgabeBestimmung
          klassifikationInt {
            istMonopraeparat
          }
          kontakteInt {
            anbieter {
              kurzname
            }
          }
        }
        ... on ProductVerlaufType {
          id
          isoCode
          bezeichnung
          suffix
          istVeterinaer
          istVerlaufsTyp5
          klassifikationVerlauf {
            istMonopraeparat
          }
        }
        ... on SubstanceType {
          id
          bezeichnung
        }
        ... on SubstanceInternationalType {
          id
          bezeichnung
        }
      }
    }
  }
`
