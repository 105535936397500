import {
  CompositionTypesEnum,
  type ProductCompositionSubstanceEntryData,
} from '@austria-codex/types'
import { css } from '@emotion/react'
import { Text } from '../../../components/Layout/Typography'
import { CellBorder, TableCell } from '../../../components/Table/TableCell'
import { TableRow } from '../../../components/Table/TableRow'
import { Numeral } from '../../../components/Utility/Numeral'
import { CompositionTitleBlock } from './CompositionTitleBlock'

type CompositionSubstanceRowProps = {
  entry: ProductCompositionSubstanceEntryData
  onClick?: () => void
}

export function CompositionSubstanceRow({
  entry,
  onClick,
}: CompositionSubstanceRowProps) {
  return (
    <TableRow display={['flex', 'table-row']} flexWrap={['wrap', 'nowrap']}>
      <TableCell width={'auto'}>
        {/* todo: [1, 'auto'] */}
        <CellBorder pr={3} indent>
          <CompositionTitleBlock
            bullet={entry.eintragsTyp !== CompositionTypesEnum.Beschreibung}
            entry={entry}
            onClick={onClick}
          >
            <Text
              color={onClick ? 'primary.main' : 'inherit'}
              role={onClick ? 'button' : undefined}
              cursor={onClick ? 'pointer' : 'inherit'}
            >
              {entry.stoff.stoff.bezeichnung}
            </Text>
          </CompositionTitleBlock>
        </CellBorder>
      </TableCell>
      <TableCell css={css({ whiteSpace: 'nowrap', textAlign: 'right' })}>
        <CellBorder pr={[1, 3]} pl={[40, 0]}>
          {entry.praefix && <Text mr={3}>{entry.praefix}</Text>}
          {entry.stoff.einheit && entry.stoff.minStaerke && (
            <>
              <Numeral input={entry.stoff.minStaerke} format="0,0[.][0000]" />
              {entry.stoff.maxStaerke !== entry.stoff.minStaerke && (
                <>
                  {' - '}
                  <Numeral
                    input={entry.stoff.maxStaerke}
                    format="0,0[.][0000]"
                  />
                </>
              )}
            </>
          )}
        </CellBorder>
      </TableCell>
      <TableCell width={'auto'}>
        <CellBorder>
          {entry.stoff.einheit && entry.stoff.einheit.bezeichnung}
          {entry.suffix && <> {entry.suffix}</>}
        </CellBorder>
      </TableCell>
    </TableRow>
  )
}
