import { AbDataLogo } from './AbDataLogo'
import { Box, Flex } from './Layout/Box'
import { Content } from './Layout/Content'

export function AbDataBanner() {
  return (
    <Box pt={2} pb={3} bg="primary.light">
      <Content width="large">
        <Flex alignItems="center" justifyContent="flex-end">
          <AbDataLogo height="24px" />
        </Flex>
      </Content>
    </Box>
  )
}
