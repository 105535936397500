import { List, ListItem } from '../../components/Layout/List'
import { Text } from '../../components/Layout/Typography'
import { Section } from '../../components/Section/Section'
import { useProductData } from '../../contexts/entity'

export function ManufacturerSection() {
  const product = useProductData()
  const firmen = product.kontakte?.herstellerfirmen ?? []

  return firmen.length > 0 ? (
    <Section width="small" heading="Hersteller">
      <List>
        {firmen.map((manufacturer) => (
          <ListItem key={manufacturer.id}>
            <Text>{manufacturer.anzeigename}</Text>
          </ListItem>
        ))}
      </List>
    </Section>
  ) : null
}
