import styled from '@emotion/styled'
import { Box } from '../Layout/Box'
import { system } from 'styled-system'

const colors = system({
  color: {
    property: 'backgroundColor',
    scale: 'palette.safety',
  },
})

export const CircleIcon = styled(Box)`
  border-radius: 50%;
  ${colors};
`

CircleIcon.defaultProps = {
  width: '16px',
  height: '16px',
}
