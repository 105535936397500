enum RenderElementTypeEnum {
  HEADING1 = 'heading-one',
  HEADING2 = 'heading-two',
  HEADING3 = 'heading-three',
  ICON = 'described-icon',
  IMAGE = 'image',
  PARAGRAPH = 'paragraph',
  ORDERED_LIST = 'numbered-list',
  UNORDERED_LIST = 'bulleted-list',
}

export type HeadingVariantType = 'h4' | 'h5' | 'h6'
export type TextVariantType = HeadingVariantType | 'p'
export type TextElementVariantType = TextVariantType | 'span'

export const isHeadingVariant = (
  variant: unknown
): variant is HeadingVariantType =>
  variant === 'h4' || variant === 'h5' || variant === 'h6'

export type AlignmentType = 'center' | 'right'

export type TextElementType = {
  text: string
  bold?: boolean
  italic?: boolean
  underline?: boolean
  linethrough?: boolean
}

export type BaseElementType = {
  children: TextElementType[]
  _id: string
  alignment?: AlignmentType
}

export type LinkType = {
  type: 'link'
  url: string
} & BaseElementType

export type ParagraphChildType = TextElementType | LinkType

export const isLink = (element: ParagraphChildType): element is LinkType =>
  (element as LinkType).type === 'link'

export type IconType = {
  type: RenderElementTypeEnum.ICON
  icon: string
} & Omit<BaseElementType, 'alignment'>

export type Heading1Type = {
  type: RenderElementTypeEnum.HEADING1
} & BaseElementType

export type Heading2Type = {
  type: RenderElementTypeEnum.HEADING2
} & BaseElementType

export type Heading3Type = {
  type: RenderElementTypeEnum.HEADING3
} & BaseElementType

export type ImageType = {
  type: RenderElementTypeEnum.IMAGE
  url: string
} & Omit<BaseElementType, 'alignment'>

export type ParagraphType = {
  type: RenderElementTypeEnum.PARAGRAPH
} & BaseElementType

export type ListItemType = {
  type: 'list-item'
} & BaseElementType

export type OrderedListType = {
  type: RenderElementTypeEnum.ORDERED_LIST
  children: ListItemType[]
} & BaseElementType

export type UnorderedListType = {
  type: RenderElementTypeEnum.UNORDERED_LIST
  children: ListItemType[]
} & BaseElementType

export type RenderElementType =
  | Heading1Type
  | Heading2Type
  | Heading3Type
  | IconType
  | ImageType
  | ParagraphType
  | OrderedListType
  | UnorderedListType

export const isHeading1 = (
  element: RenderElementType
): element is Heading1Type =>
  (element as Heading1Type).type === RenderElementTypeEnum.HEADING1

export const isHeading2 = (
  element: RenderElementType
): element is Heading2Type =>
  (element as Heading2Type).type === RenderElementTypeEnum.HEADING2

export const isHeading3 = (
  element: RenderElementType
): element is Heading3Type =>
  (element as Heading3Type).type === RenderElementTypeEnum.HEADING3

export const isIcon = (element: RenderElementType): element is IconType =>
  (element as IconType).type === RenderElementTypeEnum.ICON

export const isImage = (element: RenderElementType): element is ImageType =>
  (element as ImageType).type === RenderElementTypeEnum.IMAGE

export const isParagraph = (
  element: RenderElementType
): element is ParagraphType =>
  (element as ParagraphType).type === RenderElementTypeEnum.PARAGRAPH

export const isOrderedList = (
  element: RenderElementType
): element is OrderedListType =>
  (element as OrderedListType).type === RenderElementTypeEnum.ORDERED_LIST

export const isUnorderedList = (
  element: RenderElementType
): element is UnorderedListType =>
  (element as UnorderedListType).type === RenderElementTypeEnum.UNORDERED_LIST

export type RichTextContentType = {
  content: RenderElementType[]
  _id: string
  key: string
  title: string
}
