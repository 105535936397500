import styled from '@emotion/styled'
import Typography, { type TypographyProps } from '@mui/material/Typography'
import { Anchor } from '../../Layout/Anchor'
import {
  AlignmentType,
  HeadingVariantType,
  isHeadingVariant,
  isLink,
  ParagraphChildType,
  TextElementType,
  TextElementVariantType,
} from '../types'

type TextWrapperProps = {
  component: TextElementVariantType
  paragraph?: boolean
  bold?: boolean
  italic?: boolean
  underline?: boolean
  linethrough?: boolean
  alignment?: AlignmentType
}

const TextWrapper = styled(Typography)<TextWrapperProps & TypographyProps>`
  font-size: ${(props) => (props.paragraph ? '0.875rem' : undefined)};
  font-weight: ${(props) => (props.bold ? 'bold' : undefined)};

  text-decoration: ${(props) => (props.underline ? 'underline' : undefined)};
  text-decoration: ${(props) =>
    props.linethrough ? 'line-through' : undefined};
  text-decoration: ${(props) =>
    props.underline && props.linethrough
      ? 'underline line-through'
      : undefined};
  text-align: ${(props) => (props.alignment ? props.alignment : undefined)};
`

type TextProps = {
  child: TextElementType
  component: TextElementVariantType
  variant?: HeadingVariantType
  alignment?: AlignmentType
}

function Text({ child, component, variant, alignment }: TextProps) {
  return (
    <TextWrapper
      component={component}
      variant={variant}
      paragraph={!isHeadingVariant(variant)}
      bold={child.bold}
      italic={child.italic}
      underline={child.underline}
      linethrough={child.linethrough}
      alignment={alignment}
    >
      {child.text}
    </TextWrapper>
  )
}

type TextElementProps = {
  child: ParagraphChildType
  variant: TextElementVariantType
  alignment?: AlignmentType
}

export function TextElement({ child, variant, alignment }: TextElementProps) {
  if (isLink(child)) {
    return (
      <Anchor href={child.url}>
        {child.children.map((link, index) => (
          <Text
            key={index}
            child={link}
            alignment={alignment}
            component="span"
          />
        ))}
      </Anchor>
    )
  } else {
    return child.text === '' ? null : (
      <Text
        child={child}
        alignment={alignment}
        component={variant}
        variant={isHeadingVariant(variant) ? variant : undefined}
      />
    )
  }
}
