import { useAppDispatch, useAppSelector } from '../../hooks/useStoreHooks'
import { alertSelector, removeAlert } from '../../store/alerts'
import { Box } from '../Layout/Box'
import { Container } from '../Layout/Container'
import { Warning } from '../Warning'

export function MessageBox() {
  const alerts = useAppSelector(alertSelector)
  const dispatch = useAppDispatch()

  return (
    <Container>
      {alerts.map((alert, index) => (
        <Box pb={2} key={alert.id}>
          <Warning close onClose={() => dispatch(removeAlert(alert.id))}>
            {alert.message}
          </Warning>
        </Box>
      ))}
    </Container>
  )
}
