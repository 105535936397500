import { gql } from '@apollo/client/core'
import {
  EntityDataUnion,
  InteractionData,
  MultiSelectData,
} from '@austria-codex/types'
import { ENTITY_QUERY_FRAGMENT, ENTITY_QUERY_PARTIAL } from '../partials/entity'
import {
  INTERACTION_QUERY_FRAGMENT,
  INTERACTION_QUERY_PARTIAL,
} from '../partials/interaction'
import { MULTI_SELECT_QUERY_PARTIAL } from '../partials/multiSelect'
import { PRODUCT_INTERNATIONAL_FRAGMENT } from '../partials/product-international.partial'
import { PRODUCT_FRAGMENT } from '../partials/product.partial'
import { SUBSTANCE_INTERNATIONAL_FRAGMENT } from '../partials/substance-international.partial'
import { SUBSTANCE_FRAGMENT } from '../partials/substance.partial'

export type EntitiesWithRelatedDataVariables = {
  newEntityIdentifiers: string[]
  entityIdentifiers: string[]
  searched: boolean
}

export type EntitiesWithRelatedDataResponse = {
  entities: EntityDataUnion[]
  interaction: InteractionData[]
  multiSelect: MultiSelectData
}

export const ENTITIES_WITH_RELATED_DATA_QUERY = gql`
  ${INTERACTION_QUERY_FRAGMENT}
  ${ENTITY_QUERY_FRAGMENT}
  ${PRODUCT_FRAGMENT}
  ${PRODUCT_INTERNATIONAL_FRAGMENT}
  ${SUBSTANCE_FRAGMENT}
  ${SUBSTANCE_INTERNATIONAL_FRAGMENT}

  query entityWithRelatedData(
    $newEntityIdentifiers: [String!]!,
    $entityIdentifiers: [String!]!,
    $searched: Boolean!
  ) {
    ${ENTITY_QUERY_PARTIAL}
    ${INTERACTION_QUERY_PARTIAL}
    ${MULTI_SELECT_QUERY_PARTIAL}
  }
`

export const ENTITIES_WITH_RELATED_DATA_WITHOUT_INTERACTIONS_QUERY = gql`
  ${ENTITY_QUERY_FRAGMENT}
  ${PRODUCT_FRAGMENT}
  ${PRODUCT_INTERNATIONAL_FRAGMENT}
  ${SUBSTANCE_FRAGMENT}
  ${SUBSTANCE_INTERNATIONAL_FRAGMENT}

  query entityWithRelatedDataWithoutInteractions(
    $newEntityIdentifiers: [String!]!,
    $entityIdentifiers: [String!]!,
    $searched: Boolean!
  ) {
    ${ENTITY_QUERY_PARTIAL}
    ${MULTI_SELECT_QUERY_PARTIAL}
  }
`
