import { Html } from '../../components/Html/Html'
import { DocumentationSection } from '../../components/Section/DocumentationSection'
import { useProductData } from '../../contexts/entity'

export function RouteOfAdministrationSection() {
  const product = useProductData()

  return product.dokumentation?.schnellhilfe?.artAnwendung ? (
    <DocumentationSection
      inherit={product.dokumentation?.schnellhilfe?.artAnwendung.vererbt}
      width="small"
      heading="Art der Anwendung"
    >
      <Html content={product.dokumentation.schnellhilfe.artAnwendung.text} />
    </DocumentationSection>
  ) : null
}
