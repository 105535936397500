import { Store } from '@reduxjs/toolkit'
import { RootState } from '..'
import { LocalStorage } from '../../helpers/localstorage.helper'
import { setSessionMode } from '../user.store'

export function localStorageMiddleware(store: Store<RootState>) {
  return (next: (action: unknown) => { type: string }) => (action: unknown) => {
    const result = next(action)

    // Do not update value if change just for current session
    if (result.type === setSessionMode.type) {
      return result
    }

    // If something changed in the user store,
    // we need to write the changes back
    // to the local storage.
    if (result.type.startsWith('user/')) {
      const user = store.getState().user
      LocalStorage.set('user', JSON.stringify(user))
    }

    // If the user changed the interactions filter,
    // we need to store the changes back
    // to the local storage.
    if (result.type.startsWith('interactionsFilter/')) {
      const interactionsFilter = store.getState().interactionsFilter
      LocalStorage.set('interactionsFilter', JSON.stringify(interactionsFilter))
    }

    return result
  }
}
