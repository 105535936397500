import { ProductInternationalData } from '@austria-codex/types'
import { Box, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useFilterProducts } from '../../api/api'
import { PRODUCTS_INT_BY_PROVIDER_TABLE_CONFIG } from '../../common/tableConfig/products-international'
import { DataTable } from '../../components/DataTable/DataTable'
import { Section } from '../../components/Section/Section'
import { useProductData } from '../../contexts/entity'
import { getNameForIntSearch } from '../../helpers/product.helper'
import { useAppDispatch, useAppSelector } from '../../hooks/useStoreHooks'
import { fetchEntitiesWithRelatedData } from '../../store/entities'

const rowsPerPage = 5

export function SameProductsByNameSection() {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const entityIdentifiers = useAppSelector((state) => state.entities.ids)
  const product = useProductData()
  const [page, setPage] = useState(0)

  const name = getNameForIntSearch(product.bezeichnung)

  const { loading, error, data } = useFilterProducts({
    filter: {
      name,
    },
    offset: page * rowsPerPage,
  })

  function handleRowClick(id: string) {
    dispatch(fetchEntitiesWithRelatedData([{ id }]))
  }

  const hits = data?.products.hits ?? []
  const total = data?.products.total ?? 0

  return (
    <Section
      width="small"
      heading="Weitere Produkte mit demselben Handelsnamen"
    >
      <Typography variant="h5" sx={{ color: theme.palette.grey[700] }}>
        {name}
      </Typography>
      <Box mt={2}>
        <DataTable
          config={PRODUCTS_INT_BY_PROVIDER_TABLE_CONFIG}
          loading={loading}
          error={error !== undefined}
          data={hits}
          total={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={setPage}
          onClickRow={(row: ProductInternationalData) => handleRowClick(row.id)}
          isRowDisabled={(row: ProductInternationalData) =>
            entityIdentifiers.includes(row.id)
          }
        />
      </Box>
    </Section>
  )
}
