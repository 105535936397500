import { Box } from '@mui/material'
import { BioaequivalenzScore } from '../../components/BioaequivalenzScore'
import { BioaequivalenzScoreInfo } from '../../components/BioaequivalenzScoreInfo'
import { DataTableConfig } from '../../components/DataTable/DataTable'
import {
  agentsCell,
  countryCell,
  descriptionCell,
  productIconCell,
} from './common'

export const GENERICS_TABLE_CONFIG: DataTableConfig = {
  hover: true,
  columns: [productIconCell, descriptionCell, agentsCell],
}

export const GENERICS_TABLE_CONFIG_INT: DataTableConfig = {
  hover: true,
  columns: [
    productIconCell,
    descriptionCell,
    agentsCell,
    {
      title: (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box component="span" mr={1}>
            Datenlage
          </Box>
          <BioaequivalenzScoreInfo />
        </Box>
      ),
      key: 'bio-score',
      render: (_data, product) => {
        return <BioaequivalenzScore score={1} />
      },
      width: 200,
    },
    countryCell,
  ],
}
