import { DotIndicator } from '../features/components/Interactions/DotIndicator'

type TProps = {
  score: number
}

// Bio score of international products needs to be remapped.
// 1 is "Exzellent" - show all 3 dots filled
// 2 is "Sehr gut" - show 2 dots filled
const scoreMapping = {
  1: 3,
  2: 2,
} as Record<number, number>

export function BioaequivalenzScore({ score }: TProps) {
  const value = scoreMapping[score]
  return <DotIndicator value={value} dotCount={3} justifyContent="start" />
}
