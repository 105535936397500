import { gql } from '@apollo/client/core'

export const AGENTS_QUERY = gql`
  query FetchProductsByAgents(
    $offset: Int!
    $filter: ProductsFilterInputType
    $limit: Int
  ) {
    entries: products(offset: $offset, filter: $filter, limit: $limit) {
      offset
      limit
      total
      hits {
        ... on ProductType {
          id
          isoCode
          bezeichnung
          suffix
          istVeterinaer
          zusammensetzung {
            eintraege(type: Wirkstoff) {
              ... on ProductCompositionSubstanceEntryType {
                eintragsTyp
                stoff {
                  basisStoff {
                    id
                    bezeichnung
                  }
                  stoff {
                    id
                    bezeichnung
                  }
                }
              }
            }
          }
          klassifikation {
            istMonopraeparat
          }
        }
        ... on ProductInternationalType {
          id
          isoCode
          bezeichnung
          acoSearchTerm
          istVeterinaer
          klassifikationInt {
            istMonopraeparat
          }
          zusammensetzungInt {
            eintraege {
              id
              abdaStoffId
              eintragsTyp
              bezeichnung
              laufNummer
              bezLaufnummer
              oeavStoffId
              basisStoff {
                id
                oeavStoffId
                bezeichnung
              }
            }
          }
          kontakteInt {
            anbieter {
              kurzname
            }
          }
        }
      }
    }
  }
`
