import styled from '@emotion/styled'
import { Flex } from '../Layout/Box'
import Typography from '@mui/material/Typography'

export const ValueCard = styled(Flex)`
  flex-direction: column;
`

export const ValueCardValue = styled.div`
  margin: ${(props) => props.theme.spacing(2, props.theme.space)} 0
    ${(props) => props.theme.spacing(1, props.theme.space)} 0;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 1.125rem;
`

export const ValueCardKey = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(2, props.theme.space)};
`

ValueCardKey.defaultProps = {
  variant: 'h6',
}

export const ValueCardDescription = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(2, props.theme.space)};
  font-size: 0.75rem;
`
