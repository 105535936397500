import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import AtIcon from '../../../assets/images/countryFlagsSvg/at-sm.svg?react'
import { PRODUCTS_GENERICS_TABLE_CONFIG } from '../../../common/tableConfig/products'
import { IntProductsDataTable } from '../../../components/DataTable/IntProductsDataTable'
import { Html } from '../../../components/Html/Html'
import { Section } from '../../../components/Section/Section'
import { useProductIntData } from '../../../contexts/entity'
import {
  getAllAtProductIds,
  getGenericGroupSubHeading,
} from '../../../helpers/product-international.helper'

function Heading() {
  return (
    <>
      <AtIcon />
      <Box component="span" sx={{ ml: 1 }}>
        Generische Gruppierung Österreich
      </Box>
    </>
  )
}

export function IntGenericGroupAtSection() {
  const product = useProductIntData()

  // We need all AT products which are present in the
  // 'alternatives' sections of the INT product.
  const atIds = getAllAtProductIds(product.alternativenInt)
  const subHeading = getGenericGroupSubHeading(product)

  return (
    <Section heading={<Heading />} width="medium">
      <Typography variant="h5">
        <Html content={subHeading} />
      </Typography>
      <Box mt={3}>
        <IntProductsDataTable
          config={PRODUCTS_GENERICS_TABLE_CONFIG}
          filter={{ ids: atIds.length === 0 ? ['0'] : atIds }}
          // Provide the 'alternatives' to be able to filter
          // out the Bioäquivalenz Score in the table config.
          meta={{ alternatives: product.alternativenInt }}
          heading={<Heading />}
          subheading={subHeading}
        />
      </Box>
    </Section>
  )
}
