export enum FeatureEnum {
  Logo,
  SearchBar,
  SearchBarFilter,
  SearchBarAdditionalInformation,
  SearchBarIndicationGroupSearchable,
  Notifications,
  NotificationsExtendedNotifications,
  Settings,
  SettingsExtendedSettings,
  Profile,
  Pills,
  PersonalCharacteristics,
  PersonalCharacteristicsIntoleranceOptions,
  PersonalCharacteristicsFeaturesOptions,
  Footer,
  FooterFeedbackForm,
  ProductApplicationHeader,
  SubstanceApplicationHeader,
  BasicDocuments,
  Documents,
  ProductsSameAgent,
  RouteOfAdministrationMatrix,
  ClassificationSymbols,
  DosageForm,
  RouteOfAdministration,
  HabituationEffect,
  CompositionList,
  CompositionListProducts,
  FieldOfApplication,
  Dosage,
  IntakeAdvisory,
  Divisibility,
  ProbeCompatibility,
  ProductApplicationTeaser,
  SubstanceApplicationTeaser,
  SafetyIndicator,
  SafetyNavigation,
  SafetyNavigationAdverseDrugEffects,
  SafetyNavigationInteractionDoubleDosingNavigation,
  ProductSafetyHeader,
  ProductSafetyHeaderWarningSymbols,
  SubstanceSafetyHeader,
  Vigilance,
  Contraindication,
  Pregnancy,
  PregnancySymbols,
  SideEffects,
  Interaction,
  Advisory,
  AdvisorySymbols,
  VeterinaryWaitingPeriod,
  Allergies,
  Intolerances,
  Interactions,
  DoubleDosing,
  ProductSafetyTeaser,
  SubstanceSafetyTeaser,
  ProductRetailHeader,
  SubstanceRetailHeader,
  Dispensary,
  Storage,
  Articles,
  ArticleMatrix,
  Distribution,
  MarketingAuthorisationHolder,
  MarketingAuthorisationHolderProducts,
  Manufacturer,
  ProductRetailTeaser,
  SubstanceRetailTeaser,
  ProductAlternativesHeader,
  SubstanceAlternativesHeader,
  GenericGroupProducts,
  ATCCodes,
  ATCCodesProducts,
  IndicationGroups,
  IndicationGroupsProducts,
  ReferenceProducts,
  ProductsContainingAgent,
  ProductTeaserAlternatives,
  SubstanceAlternativesTeaser,
  SameProductsByName,
  International,
  IntDocuments,
  IntClassification,
  IntDosageForm,
  IntComposition,
  IntIntakeAdvisory,
  IntDosageAdvisory,
  IntProvider,
  IntProviderOtherProducts,
  IntDistributor,
  IntSameProductsByName,
  IntDispensary,
  IntStorage,
  IntAtcCodes,
  IntGenericGroupAtProducts,
  IntGenericGroupIntProducts,
  IntIndicationGroupDe,
  IntProductSafety,
  IntSubstanceSafety,
}

export enum ModulesEnum {
  General = 'General',
  Application = 'Application',
  Safety = 'Safety',
  Retail = 'Retail',
  Alternatives = 'Alternatives',
}

export enum LicenseEnum {
  PZNAccess = 'PZNAccess',
  PZNAccessFIAdvantage = 'PZNAccessFIAdvantage',
  PZNAccessFullAdvantage = 'PZNAccessFullAdvantage',
  WebserviceFind = 'WebserviceFind',
  WebserviceMasterdata = 'WebserviceMasterdata',
  WebserviceDoubleDosing = 'WebserviceMultiselect',
  WebserviceInteraction = 'WebserviceInteraction',
  WebserviceInteractionBase = 'WebserviceInteractionBase',
  WebserviceDocuments = 'WebserviceDocuments',
  WebserviceDocumentsGi = 'WebserviceGiDocuments',
  WebserviceDocumentsSpc = 'WebserviceSpcDocuments',
}

export const applicationModules = {
  General: {
    id: 'General',
    sequence: false,
  },
  Application: {
    id: 'Anwendung',
    sequence: true,
  },
  Safety: {
    id: 'Sicherheit',
    sequence: true,
  },
  Retail: {
    id: 'Handel',
    sequence: true,
  },
  Alternatives: {
    id: 'Alternativen',
    sequence: true,
  },
} as const

export type ApplicationModulesKeyType = keyof typeof applicationModules
export const applicationEntityTypes = [
  'product',
  'product-international',
  'substance',
  'substance-international',
] as const

export type BundleInterface = {
  features: FeatureEnum[]
  sequence: {
    [key in keyof typeof ModulesEnum]: {
      [key in (typeof applicationEntityTypes)[number]]: [
        FeatureEnum,
        FeatureEnum[],
      ][]
    }
  }
}

export type BundleInterfaceLocal = {
  features: FeatureEnum[]
  sequence: {
    [key in keyof Omit<typeof ModulesEnum, 'General'>]: {
      [key in (typeof applicationEntityTypes)[number]]: [
        FeatureEnum,
        FeatureEnum[],
      ][]
    }
  }
}
