import {
  Occurrence,
  ProductAlternativeUsageData,
  occurrenceDescription,
} from '@austria-codex/types'
import { Box as MuiBox } from '@mui/material'
import { Section } from '../../components/Section/Section'
import {
  ValueCard,
  ValueCardDescription,
  ValueCardKey,
  ValueCardValue,
} from '../../components/ValueCard/ValueCard'
import { useProductData } from '../../contexts/entity'

export function ProbeCompatibilitySection() {
  const product = useProductData()
  const altVerwendung = product.alternativeVerwendung

  if (!hasAlternativeApplicationInformation(altVerwendung)) {
    return null
  }

  const hinweisSondenGaengigkeit = altVerwendung?.hinweisSondenGaengigkeit
  const hinweisAufloesenSuspendieren =
    altVerwendung?.hinweisAufloesenSuspendieren
  const hinweisKapselOeffnen = altVerwendung?.hinweisKapselOeffnen
  const hinweisZerMoersern = altVerwendung?.hinweisZerMoersern
  const hinweisCmrPotential = altVerwendung?.hinweisCmrPotential
  const hinweisLichtEmpfindlichkeit = altVerwendung?.hinweisLichtempfindlichkeit
  const hinweisEinahme = altVerwendung?.hinweisEinnahme

  const sondenGaengigkeit =
    altVerwendung?.sondenGaengigkeit ?? Occurrence.NotSpecified
  const aufloesenSuspendieren =
    altVerwendung?.aufloesenSuspendieren ?? Occurrence.NotSpecified
  const kapselOeffnen = altVerwendung?.kapselOeffnen ?? Occurrence.NotSpecified
  const zerMoersern = altVerwendung?.zerMoersern ?? Occurrence.NotSpecified
  const cmrPotential = altVerwendung?.cmrPotential ?? Occurrence.NotSpecified
  const lichtEmpfindlichkeit =
    altVerwendung?.lichtEmpfindlichkeit ?? Occurrence.NotSpecified

  return (
    <Section width="small" heading="Sondengängigkeit" offLabel>
      <MuiBox sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {sondenGaengigkeit !== Occurrence.NotSpecified && (
          <ValueCard width={[1, 1 / 2, 1 / 3]} p={1}>
            <ValueCardValue>
              {occurrenceDescription[sondenGaengigkeit]}
            </ValueCardValue>
            <ValueCardKey>Sondengängigkeit (Magensonde)</ValueCardKey>
            {hinweisSondenGaengigkeit && (
              <ValueCardDescription>
                {hinweisSondenGaengigkeit}
              </ValueCardDescription>
            )}
          </ValueCard>
        )}
        {aufloesenSuspendieren !== Occurrence.NotSpecified && (
          <ValueCard width={[1, 1 / 2, 1 / 3]} p={1}>
            <ValueCardValue>
              {occurrenceDescription[aufloesenSuspendieren]}
            </ValueCardValue>
            <ValueCardKey>Suspendieren/Auflösen</ValueCardKey>
            {hinweisAufloesenSuspendieren && (
              <ValueCardDescription>
                {hinweisAufloesenSuspendieren}
              </ValueCardDescription>
            )}
          </ValueCard>
        )}
        {kapselOeffnen !== Occurrence.NotSpecified && (
          <ValueCard width={[1, 1 / 2, 1 / 3]} p={1}>
            <ValueCardValue>
              {occurrenceDescription[kapselOeffnen]}
            </ValueCardValue>
            <ValueCardKey>Kapsel öffnen</ValueCardKey>
            {hinweisKapselOeffnen && (
              <ValueCardDescription>
                {hinweisKapselOeffnen}
              </ValueCardDescription>
            )}
          </ValueCard>
        )}
        {zerMoersern !== Occurrence.NotSpecified && (
          <ValueCard width={[1, 1 / 2, 1 / 3]} p={1}>
            <ValueCardValue>
              {occurrenceDescription[zerMoersern]}
            </ValueCardValue>
            <ValueCardKey>Mörsern/Zerkleinern</ValueCardKey>
            {hinweisZerMoersern && (
              <ValueCardDescription>{hinweisZerMoersern}</ValueCardDescription>
            )}
          </ValueCard>
        )}
        {cmrPotential !== Occurrence.NotSpecified && (
          <ValueCard width={[1, 1 / 2, 1 / 3]} p={1}>
            <ValueCardValue>
              {occurrenceDescription[cmrPotential]}
            </ValueCardValue>
            <ValueCardKey>CMR Potential</ValueCardKey>
            {hinweisCmrPotential && (
              <ValueCardDescription>{hinweisCmrPotential}</ValueCardDescription>
            )}
          </ValueCard>
        )}
        {lichtEmpfindlichkeit !== Occurrence.NotSpecified && (
          <ValueCard width={[1, 1 / 2, 1 / 3]} p={1}>
            <ValueCardValue>
              {occurrenceDescription[lichtEmpfindlichkeit]}
            </ValueCardValue>
            <ValueCardKey>Lichtempfindlicher Wirkstoff</ValueCardKey>
            {hinweisLichtEmpfindlichkeit && (
              <ValueCardDescription>
                {hinweisLichtEmpfindlichkeit}
              </ValueCardDescription>
            )}
          </ValueCard>
        )}
        {hinweisEinahme && (
          <ValueCard width={[1, 1 / 2, 1 / 3]} p={1}>
            <ValueCardValue>&nbsp;</ValueCardValue>
            <ValueCardKey>Einnahmehinweis</ValueCardKey>
            <ValueCardDescription>{hinweisEinahme}</ValueCardDescription>
          </ValueCard>
        )}
      </MuiBox>
    </Section>
  )
}

const hasAlternativeApplicationInformation = (
  alternativeApplication: ProductAlternativeUsageData | undefined
) =>
  alternativeApplication?.sondenGaengigkeit !== Occurrence.NotSpecified ||
  alternativeApplication?.aufloesenSuspendieren !== Occurrence.NotSpecified ||
  alternativeApplication?.kapselOeffnen !== Occurrence.NotSpecified ||
  alternativeApplication?.zerMoersern !== Occurrence.NotSpecified ||
  alternativeApplication?.cmrPotential !== Occurrence.NotSpecified ||
  alternativeApplication?.lichtEmpfindlichkeit !== Occurrence.NotSpecified
