import {
  ArticleData,
  DispensaryTypesEnum,
  FilterMatchTypesEnum,
  FilterModeTypesEnum,
  ReimbursementBoxTypesEnum,
} from '@austria-codex/types'
import { Box } from '@mui/material'
import { ARTICLES_QUERY } from '../../../api/articles'
import { ARTICLES_TABLE_CONFIG } from '../../../common/tableConfig/articles'
import { DataSourceTable } from '../../../components/PaginatedDataTable/DataSourceTable'
import { useSubstanceData } from '../../../contexts/entity'
import { useAppDispatch, useAppSelector } from '../../../hooks/useStoreHooks'
import {
  entityIdentifiersSelector,
  fetchEntitiesWithRelatedData,
} from '../../../store/entities'
import { filterSelector } from '../../../store/filter'
import {
  entityIdentifiersToEntityPartials,
  isAvailableToKey,
  isMonoPreparationToKey,
  isVeterinaryToKey,
} from '../../../utilities/entity'

type ArticlesTableProps = {
  dispensary: DispensaryTypesEnum
  isVeterinary: boolean
  isMonoPreparation: boolean
  reimbursementBox: ReimbursementBoxTypesEnum
  isAvailable: boolean
  onClose: () => void
}

export function ArticlesTable({
  dispensary,
  isVeterinary,
  isMonoPreparation,
  reimbursementBox,
  isAvailable,
  onClose,
}: ArticlesTableProps) {
  const entity = useSubstanceData()
  const dispatch = useAppDispatch()
  const entityIdentifiers = useAppSelector(entityIdentifiersSelector)

  const { selectedRoutesOfAdministration } = useAppSelector(filterSelector)

  function onRowClick(article: ArticleData) {
    const ids = entityIdentifiersToEntityPartials([article.produkt])
    dispatch(fetchEntitiesWithRelatedData(ids))
  }

  const variables = {
    filter: {
      isAvailable: isAvailable,
      agents: {
        value: [entity.id],
        match: isMonoPreparation
          ? FilterMatchTypesEnum.EXACT
          : FilterMatchTypesEnum.SUBSET,
      },
      dispensary,
      isVeterinary,
      routesOfAdministration: {
        value: selectedRoutesOfAdministration,
        mode: FilterModeTypesEnum.ROOT,
      },
      isMonoPreparation,
      // Veterinary products/articles do not have a EKO box,
      // but are shown in the 'N' section by default.
      // However, for the query we need to set the filter null,
      // otherwise the articles wont be found.
      reimbursementBox: isVeterinary ? null : reimbursementBox,
    },
  }

  const vetKey = isVeterinaryToKey(isVeterinary)
  const avaKey = isAvailableToKey(isAvailable)
  const monKey = isMonoPreparationToKey(isMonoPreparation)
  const identifier = `articles:${vetKey}:${avaKey}:${monKey}:${dispensary}:${reimbursementBox}`

  return (
    <Box mb={2}>
      <DataSourceTable
        query={ARTICLES_QUERY}
        config={ARTICLES_TABLE_CONFIG}
        heading={entity.bezeichnung}
        subheading={`${dispensary} - ${reimbursementBox}`}
        initialLimit={3}
        variables={variables}
        uniqueIdentifier={entity.id}
        identifier={identifier}
        onRowClick={onRowClick}
        onClose={onClose}
        isRowDisabled={(article: ArticleData) =>
          entityIdentifiers.includes(article.produkt)
        }
      />
    </Box>
  )
}
