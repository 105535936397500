import { EntityDomainHitsData, Maybe } from '@austria-codex/types'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import { useCollapse } from '../../hooks/useCollapse'
import { DomainHitsCount } from '../Filter/FilterHitsCount'
import { Flex } from '../Layout/Box'
import { Text } from '../Layout/Typography'
import { CodeText } from './CodeText'

type CodeBlockProps = {
  code: string
  title: string
  caption: Maybe<string>
  hits?: EntityDomainHitsData
  section: string
  group: string
  identifier: string
  children: (onToggle: (open?: boolean) => void) => ReactNode
  hasToggle: boolean
}

export function CodeBlock({
  children,
  code,
  title,
  caption,
  hits,
  section,
  group,
  identifier,
  hasToggle,
}: CodeBlockProps) {
  const [collapse, onToggle] = useCollapse({
    section,
    group,
    identifier,
  })

  return (
    <Container hasToggle={hasToggle}>
      <Flex onClick={() => hasToggle && onToggle()}>
        <Flex flex={1} mr={3}>
          <Text color={hasToggle ? 'primary.main' : undefined}>
            {title} {hits && <>({<DomainHitsCount hits={hits} />})</>}
            {caption && (
              <div>
                <Text fontStyle="italic" fontSize={0} color="#606060">
                  {caption}
                </Text>
              </div>
            )}
          </Text>
        </Flex>
        <Flex width={72}>
          <CodeText code={code} />
        </Flex>
      </Flex>
      {collapse && <>{children(onToggle)}</>}
    </Container>
  )
}

type ContainerProps = {
  hasToggle: boolean
}

const Container = styled.div<ContainerProps>`
  &:hover {
    cursor: ${(props) => (props.hasToggle ? 'pointer' : 'default')};
  }
`
