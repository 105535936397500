import styled from '@emotion/styled'

export const UnstyledButton = styled.button`
  align-items: center;
  border: 0;
  background-color: transparent;
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  display: flex;
  outline: 0;
  padding: 0;

  &:hover {
    color: ${(props) => props.theme.palette.primary.dark};
  }
`
