import { ProductUnion } from '@austria-codex/types'
import { isProduct } from '@austria-codex/utilities'
import { createElement } from 'react'
import ProductHumanCombinationIcon from '../../assets/images/entityIcons/human-combi.svg?react'
import ProductHumanMonoIcon from '../../assets/images/entityIcons/human-mono.svg?react'
import ProductVeterinaryCombinationIcon from '../../assets/images/entityIcons/vet-combi.svg?react'
import ProductVeterinaryMonoIcon from '../../assets/images/entityIcons/vet-mono.svg?react'

const productIcons = {
  HUMAN: {
    MONO: ProductHumanMonoIcon,
    KOMBINATION: ProductHumanCombinationIcon,
  },
  VET: {
    MONO: ProductVeterinaryMonoIcon,
    KOMBINATION: ProductVeterinaryCombinationIcon,
  },
}

type ProductIconProps = {
  isVeterinary: boolean
  isMonoPreparation: boolean
}

export function ProductIcon({
  isVeterinary,
  isMonoPreparation,
}: ProductIconProps) {
  const domain = isVeterinary ? 'VET' : 'HUMAN'
  const preparation = isMonoPreparation ? 'MONO' : 'KOMBINATION'

  const Icon = productIcons[domain]?.[preparation]

  return Icon ? createElement(Icon, {}, null) : <div>unknown</div>
}

type EntityProductIcon = {
  product: ProductUnion
}

export function EntityProductIcon({ product }: EntityProductIcon) {
  const isVeterinary = product.istVeterinaer ?? false

  let klassifikation
  if (isProduct(product)) {
    klassifikation = product.klassifikation
  } else {
    klassifikation = product.klassifikationInt
  }

  const isMonoPreparation = klassifikation?.istMonopraeparat ?? false

  return (
    <ProductIcon
      isVeterinary={isVeterinary}
      isMonoPreparation={isMonoPreparation}
    />
  )
}
