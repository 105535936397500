import { Box } from '@mui/material'
import { warningNotice } from '../../../common/translations'
import { MonitoringWarningIcon } from '../../../components/Icons/WarningIcon'
import { IconTextBlock } from '../../../components/IconTextBlock/IconTextBlock'
import { Content } from '../../../components/Layout/Content'
import { DocumentationSection } from '../../../components/Section/DocumentationSection'
import { useProductIntData } from '../../../contexts/entity'
import {
  getIntolerances,
  getPregnancyAdvises,
  getWarnings,
} from '../../../helpers/product-international.helper'

export function IntProductSafetySection() {
  const product = useProductIntData()

  const pregnancyAdvises = getPregnancyAdvises(product)
  const warnings = getWarnings(product)
  const intolerances = getIntolerances(product)

  if (
    pregnancyAdvises.length === 0 &&
    warnings.length === 0 &&
    intolerances.length === 0
  ) {
    return (
      <Content width="small">
        <Box sx={{ py: 6 }}>
          Keine Sicherheitsinformation für diese Auswahl vorhanden.
        </Box>
      </Content>
    )
  }

  return (
    <>
      {pregnancyAdvises.length > 0 ? (
        <DocumentationSection
          width="small"
          heading="Schwangerschaft und Stillzeit"
        >
          <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {pregnancyAdvises.map((pa, index) => (
              <Box key={index} component="p" sx={{ m: 0 }}>
                {pa.hinweis}
              </Box>
            ))}
          </Box>
        </DocumentationSection>
      ) : null}
      {warnings.length > 0 ? (
        <DocumentationSection width="small" heading="Warnhinweise">
          {product.anwWarnHinweise?.zusUeberwachung && (
            <IconTextBlock Icon={<MonitoringWarningIcon level={1} />}>
              {warningNotice('monitoring', 1)}
            </IconTextBlock>
          )}
          <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {warnings.map((w, index) => (
              <Box key={index} component="p" sx={{ m: 0 }}>
                {w.hinweis}
              </Box>
            ))}
          </Box>
        </DocumentationSection>
      ) : null}
      {intolerances.length > 0 ? (
        <DocumentationSection width="small" heading="Intoleranzen">
          <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
            {intolerances.map((i, index) => (
              <Box key={index} component="p" sx={{ m: 0 }}>
                <HighlightIntolerancePrefix text={i.hinweis} />
              </Box>
            ))}
          </Box>
        </DocumentationSection>
      ) : null}
    </>
  )
}

function HighlightIntolerancePrefix({ text }: { text: string }) {
  // eg: Lactose: Vorsicht bei Zucker-Unverträglickeit...
  // We want to bold the "Lactose:" part
  const [prefix, rest] = text.split(': ', 2)

  return (
    <>
      <strong>{prefix}:</strong> {rest}
    </>
  )
}
