import { ComponentType, type FunctionComponent, type ReactNode } from 'react'
import styled from '@emotion/styled'

export const styledTextIcon = (component: ComponentType) => styled(component)`
  height: 24px;
  min-width: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #4c4c4c;
  color: #4c4c4c;
  font-size: 14px;
`

export type TextMappingComponentProps<T> = {
  type: keyof T
}

interface TextMappingProps<T> {
  type: T
  className?: string
}

type TextMapping = Record<string, ReactNode>

export function createTextMappingComponent<T extends TextMapping>(
  map: T
): FunctionComponent<TextMappingProps<keyof T>> {
  return ({ type, className }) => <div className={className}>{map[type]}</div>
}
