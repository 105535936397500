import {
  AgentBasedMultiSelectData,
  AtcCodeBasedMultiSelectData,
  MultiSelectData,
} from '@austria-codex/types'
import { separateEntityIdentifiers } from '@austria-codex/utilities'
import { EntityId } from '@reduxjs/toolkit'

export const filterMultiSelect = (
  multiSelect: MultiSelectData | null,
  entityIdentifiers: EntityId[]
): MultiSelectData | null => {
  if (multiSelect === null) {
    return null
  }

  const { productIdentifiers, productInternationalIdentifiers } =
    separateEntityIdentifiers(entityIdentifiers as string[])

  const ids = [...productIdentifiers, ...productInternationalIdentifiers]

  return {
    agentBased: filter(multiSelect.agentBased, ids),
    atcCodeBased: filter(multiSelect.atcCodeBased, ids),
  }
}

type MultiSelectEntry = AtcCodeBasedMultiSelectData | AgentBasedMultiSelectData

function filter<T extends MultiSelectEntry>(
  matches: T[],
  productIdentifiers: string[]
): T[] {
  return matches
    .map((match) => ({
      ...match,
      produkte: match.produkte.filter((product) =>
        productIdentifiers.includes(product.id)
      ),
    }))
    .filter((match) => match.produkte.length > 1)
}
