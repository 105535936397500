import { commonTheme } from './common'

const media = (index: number, breakpoints: string[]) =>
  index === 0
    ? '@media screen'
    : `@media screen and (min-width: ${breakpoints[index - 1]})`

const up = (index: number, breakpoints: string[]) =>
  index === 0
    ? '@media screen'
    : `@media screen and (min-width: ${breakpoints[index - 1]})`

const down = (index: number, breakpoints: string[]) =>
  index === 0
    ? '@media screen'
    : `@media screen and (max-width: ${parseInt(breakpoints[index - 1]) - 1}px)`

const spacing = (index: number, space: number[]) => `${space[index]}px`

export const emotionTheme = {
  ...commonTheme,
  media,
  up,
  down,
  spacing,
  breakpoints: ['768px', '992px', '1200px'],
  fontSizes: [12, 14, 16, 24, 32, 48, 64, 96, 128],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
}

export type EmotionTheme = typeof emotionTheme
