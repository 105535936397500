import styled from '@emotion/styled'
import {
  createTextMappingComponent,
  styledTextIcon,
  TextMappingComponentProps,
} from './Base'

const mapping = {
  OTC: 'OTC',
  RP: 'RP',
  SG: 'SG',
}

export const DispensaryIcon = styled(
  // @ts-expect-error: some type error
  styledTextIcon(createTextMappingComponent(mapping))
)<TextMappingComponentProps<typeof mapping>>`
  width: 38px;
`
