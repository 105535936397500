import {
  RoutesOfAdministrationTypes,
  RoutesOfAdministrationTypesEnum,
} from '@austria-codex/types'

export const rootRouteOfAdministrationFromCode = (
  code: string
): RoutesOfAdministrationTypesEnum => {
  return RoutesOfAdministrationTypesEnum[code[0] as RoutesOfAdministrationTypes]
}
