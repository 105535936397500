import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

const linkStyle = (color: string) => css`
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  font-size: inherit;
  display: inline;
  font-family: inherit;
  color: ${color};
  &:hover {
    cursor: pointer;
  }
`

export const Anchor = styled.a`
  ${(props) => linkStyle(props.theme.palette.primary.main)}
`

export const AnchorLink = styled(Link)`
  ${(props) => linkStyle(props.theme.palette.primary.main)}
`
export const AnchorButton = styled.button`
  ${(props) => linkStyle(props.theme.palette.primary.main)}
  text-decoration: underline;
`
