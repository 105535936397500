import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { settings } from '../common/settings'
import { fetchAllAtcCodeHitsData } from './atcCodeHits'
import { AppDispatch } from './index'

type TSettingsState = {
  collapseOpenOnAdd: boolean
  includeVeterinary: boolean
  includeRevokedAuthorizations: boolean
  includeOutOfStockProducts: boolean
  backUrl: string | null
}

type TSettingsKey =
  | 'collapseOpenOnAdd'
  | 'includeVeterinary'
  | 'includeRevokedAuthorizations'
  | 'includeOutOfStockProducts'

type SetSettingsPayload = {
  key: TSettingsKey
  value: boolean
}

const initialState: TSettingsState = {
  collapseOpenOnAdd: false,
  includeVeterinary: false,
  includeRevokedAuthorizations: false,
  includeOutOfStockProducts: true,
  backUrl: null,
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSetting(state, { payload }: PayloadAction<SetSettingsPayload>) {
      const { key, value } = payload
      state[key] = value
    },
    setAllSettings(state, { payload }: PayloadAction<Record<string, unknown>>) {
      return {
        ...state,
        ...payload,
      }
    },
    setBackUrl(state, { payload }: PayloadAction<string | null>) {
      state.backUrl = payload
    },
  },
})

export const setPersistentSetting = (key: TSettingsKey, value: boolean) => {
  return (dispatch: AppDispatch) => {
    if (key in settings) {
      const adapter = settings[key].adapter

      return adapter.set(value).then(() => {
        dispatch(
          settingsSlice.actions.setSetting({
            key,
            value,
          })
        )
        dispatch(fetchAllAtcCodeHitsData())
      })
    }

    console.error('Invalid settings key used to persist value')
  }
}

export const { setSetting, setAllSettings, setBackUrl } = settingsSlice.actions
export default settingsSlice.reducer
