import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@emotion/react'
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  THEME_ID,
} from '@mui/material'
import * as Sentry from '@sentry/react'
import { StrictMode, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { App } from './App'
import { austriaCodexClient } from './client/austriaCodex'
import { GlobalStyle } from './components/GlobalStyle/GlobalStyle'
import { Config } from './config'
import { telemetrie } from './services/telemetrie.service'
import store from './store'
import { emotionTheme } from './theme/emotion'
import { materialTheme } from './theme/material'

if (Config.sentry.dsn) {
  Sentry.init({
    dsn: Config.sentry.dsn,
    environment: Config.sentry.environment,
    release: 'austria-codex@' + Config.app.version,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

if (import.meta.env.PROD) {
  telemetrie.startTimer()
}

const container = document.getElementById('app')
const root = createRoot(container as HTMLElement)
root.render(
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={emotionTheme}>
        <MuiThemeProvider theme={{ [THEME_ID]: materialTheme }}>
          <Provider store={store}>
            <ApolloProvider client={austriaCodexClient}>
              <GlobalStyle />
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </ApolloProvider>
          </Provider>
        </MuiThemeProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </StrictMode>
)
