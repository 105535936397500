import {
  EntityDataUnion,
  ProductData,
  ProductInternationalData,
  SubstanceData,
  SubstanceInternationalData,
} from '@austria-codex/types'
import { createContext, useContext } from 'react'

export const EntityContext = createContext<EntityDataUnion | null>(null)

export function useEntityDataUnion() {
  const entity = useContext(EntityContext)
  if (!entity) {
    throw new Error('Entity context does not exist')
  }

  return entity
}

export function useEntityDataInt() {
  const entity = useContext(EntityContext)
  if (!entity) {
    throw new Error('Entity context does not exist')
  }

  return entity as ProductInternationalData | SubstanceInternationalData
}

export function useProductData() {
  const entity = useContext(EntityContext)
  if (!entity) {
    throw new Error('Entity context does not exist')
  }

  return entity as ProductData
}

export function useProductIntData() {
  const entity = useContext(EntityContext)
  if (!entity) {
    throw new Error('Entity context does not exist')
  }

  return entity as ProductInternationalData
}

export function useSubstanceData() {
  const entity = useContext(EntityContext)
  if (!entity) {
    throw new Error('Entity context does not exist')
  }

  return entity as SubstanceData
}

export function useSubstanceIntData() {
  const entity = useContext(EntityContext)
  if (!entity) {
    throw new Error('Entity context does not exist')
  }

  return entity as SubstanceInternationalData
}
