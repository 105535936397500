import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { toggleRouteOfAdministrationFilter } from './filter'
import { setSetting } from './settings.store'

type SetPageActionPayload = {
  uniqueIdentifier: string
  identifier: string
  offset: number
  resettable: boolean
}

type PaginationState = {
  [uniqueIdentifier: string]: {
    [identifier: string]: {
      offset: number
      resettable: boolean
    }
  }
}

const initialState: PaginationState = {}

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setPagination: (
      state,
      { payload }: PayloadAction<SetPageActionPayload>
    ) => {
      const { uniqueIdentifier, identifier, offset, resettable } = payload

      if (!state[uniqueIdentifier]) {
        state[uniqueIdentifier] = {}
      }

      state[uniqueIdentifier][identifier] = {
        offset,
        resettable,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(toggleRouteOfAdministrationFilter, (state) => {
        resetResettablePagination(state)
      })
      .addCase(setSetting, (state, { payload }) => {
        if (payload.key === 'includeVeterinary') {
          resetResettablePagination(state)
        }
      })
  },
})

export default paginationSlice.reducer

export const { setPagination } = paginationSlice.actions

const resetResettablePagination = (state: PaginationState) => {
  for (const uniqueIdentifier in state) {
    for (const identifier in state[uniqueIdentifier]) {
      if (state[uniqueIdentifier][identifier].resettable) {
        state[uniqueIdentifier][identifier].offset = 0
      }
    }
  }
}

const paginationEntriesSelector = (state: PaginationState) => state

type PaginationConfig = {
  uniqueIdentifier: string
  identifier: string
  resettable?: boolean
}

const paginationConfigProp = (_: unknown, config: PaginationConfig) => config

export const createPaginationSelector = () =>
  createSelector(
    [paginationEntriesSelector, paginationConfigProp],
    (paginationEntries, { uniqueIdentifier, identifier }) => {
      const data = paginationEntries[uniqueIdentifier]?.[identifier]
      if (data && data['offset']) {
        return data['offset']
      }

      return 0
    }
  )
