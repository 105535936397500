import { FeatureEnum } from '@austria-codex/types'
import {
  isProduct,
  isProductInternational,
  isSubstance,
} from '@austria-codex/utilities'
import { ModuleSections } from '../../components/ModuleSections/ModuleSections'
import { ProductInternationalMetaHeader } from '../../features/components/EntityHeader/ProductInternationalMetaHeader'
import { ProductMetaHeader } from '../../features/components/EntityHeader/ProductMetaHeader'
import { SubstanceMetaHeader } from '../../features/components/EntityHeader/SubstanceMetaHeader'
import { useShouldRenderEntityType } from '../../hooks/useShouldRenderEntityType'

export function ApplicationPage() {
  const shouldRenderEntityType = useShouldRenderEntityType(
    FeatureEnum.ProductApplicationHeader,
    FeatureEnum.SubstanceApplicationHeader
  )

  return (
    <ModuleSections
      module="Application"
      metaRenderer={(entity) =>
        isProduct(entity) ? (
          <ProductMetaHeader product={entity} />
        ) : isProductInternational(entity) ? (
          <ProductInternationalMetaHeader product={entity} />
        ) : isSubstance(entity) ? (
          <SubstanceMetaHeader substance={entity} />
        ) : null
      }
      shouldRenderEntityType={shouldRenderEntityType}
    />
  )
}
