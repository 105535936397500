import { type ReactNode } from 'react'
import Lock from '../../assets/images/ic-lock.svg?react'
import { Box, Flex } from '../Layout/Box'
import { Content } from '../Layout/Content'
import { Separator } from '../Layout/Separator'

export function UpSellingRibbon({ children }: { children: ReactNode }) {
  return (
    <>
      <Separator
        variant="zigzag"
        bgBottom="primary.light"
        lineWidth={1}
        lineColor="primary.main"
      />
      <Box bg="primary.light">
        <Content width="small">
          <Flex alignItems="center" py={3}>
            <Box mr={5} display={['none', 'block']}>
              <Lock />
            </Box>
            <div>{children}</div>
          </Flex>
        </Content>
      </Box>
      <Separator
        variant="zigzag"
        bgTop="primary.light"
        lineWidth={1}
        lineColor="primary.main"
      />
    </>
  )
}
