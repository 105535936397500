import { RoutesOfAdministrationTypesEnum } from '@austria-codex/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '.'
import type { ROAFilter } from '../types/common.types'
import { toggleArrayValue } from '../utilities/redux'

type State = {
  routesOfAdministration: ROAFilter
  selectedRoutesOfAdministration: RoutesOfAdministrationTypesEnum[]
}

const initialState: State = {
  routesOfAdministration: {
    A: false,
    B: false,
    C: false,
    D: false,
    E: false,
    F: false,
    G: false,
  },
  selectedRoutesOfAdministration: [],
}

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    toggleRouteOfAdministrationFilter: (
      state,
      { payload }: PayloadAction<RoutesOfAdministrationTypesEnum>
    ) => {
      state.routesOfAdministration[payload] =
        !state.routesOfAdministration[payload]
      state.selectedRoutesOfAdministration = toggleArrayValue(
        state.selectedRoutesOfAdministration,
        payload
      )
    },
  },
})

export default filterSlice.reducer

export const { toggleRouteOfAdministrationFilter } = filterSlice.actions

export const filterSelector = (state: RootState) => state.filter

export const routesOfAdministrationFilterSelector = (state: RootState) =>
  state.filter.routesOfAdministration

export const selectedRoutesOfAdministrationFilterSelector = (
  state: RootState
) => state.filter.selectedRoutesOfAdministration
