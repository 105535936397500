import {
  FilterMatchTypesEnum,
  FilterModeTypesEnum,
  RoutesOfAdministrationTypesEnum,
  type ProductsFilter,
} from '@austria-codex/types'
import { AGENTS_QUERY } from '../../../api/agents'
import { AGENTS_TABLE_CONFIG } from '../../../common/tableConfig/agents'
import { RouteOfAdministrationIcon } from '../../../components/Icons/RouteOfAdministrationIcon'
import { Anchor } from '../../../components/Layout/Anchor'
import { Box, Flex } from '../../../components/Layout/Box'
import { Text } from '../../../components/Layout/Typography'
import { ProductsDataSourceTable } from '../../../components/PaginatedDataTable/ProductsDataSourceTable'
import { useSubstanceData } from '../../../contexts/entity'
import { useCollapse } from '../../../hooks/useCollapse'

interface SubstanceApplicationRowProps {
  hits: number
  title: string
  routeOfAdministration: RoutesOfAdministrationTypesEnum
  isVeterinary: boolean
  isMonoPreparation: boolean
}

export function SubstanceApplicationHitsRow({
  hits,
  title,
  routeOfAdministration,
  isVeterinary,
  isMonoPreparation,
}: SubstanceApplicationRowProps) {
  const entity = useSubstanceData()

  const identifier = `${isVeterinary}:${isMonoPreparation}:${routeOfAdministration}`

  const [collapse, onToggle] = useCollapse({
    section: entity.id,
    group: 'substance:application',
    identifier,
  })

  const filter: ProductsFilter = {
    agents: {
      value: [entity.id],
      match: FilterMatchTypesEnum.PARTIAL,
    },
    isVeterinary,
    routesOfAdministration: {
      value: [routeOfAdministration],
      mode: FilterModeTypesEnum.ROOT,
    },
    isMonoPreparation,
  }

  return (
    <div>
      <Anchor onClick={() => onToggle()}>
        <Flex alignItems="center">
          <Flex width={48}>
            <RouteOfAdministrationIcon
              routeOfAdministration={routeOfAdministration}
              color="primary"
            />
          </Flex>
          <Flex width={128}>
            <Text color="normal">{title}</Text>
          </Flex>
          <Flex width={48}>
            <Text color="primary" fontWeight="bold">
              {hits}
            </Text>
          </Flex>
        </Flex>
      </Anchor>
      {collapse && (
        <Box mt={1} mb={2}>
          <ProductsDataSourceTable
            query={AGENTS_QUERY}
            config={AGENTS_TABLE_CONFIG}
            filter={filter}
            name={identifier}
            onClose={() => onToggle()}
            heading={title}
          />
        </Box>
      )}
    </div>
  )
}
