import Cookie from 'js-cookie'
import { Config } from '../../config'
import { Paths } from '../path.constants'

// URL to UserManager login
const LOGIN_URL = `${Config.api.url}${Config.api.endpoints.login}`

// TDOD: is this still used?
const ACCESS_TOKEN_KEY = 'portalAccessToken'

let FALLBACK_STORE: string | null = null

// Removes cookie of legacy iFrame solution which was previously used by MSD customers.
// This can be removed at some point.
if (window.location.href.endsWith(Paths.Msd)) {
  Cookie.remove(ACCESS_TOKEN_KEY)
}

// Users coming from a licensed ip address are logged in with their ip token by default.
// They need to explicitly visit austia-codex.at/login, in order to login with their
// personal credentials (aka get redirected to the login page).
if (window.location.href.endsWith(Paths.Login)) {
  window.location.replace(LOGIN_URL + `?redirect=/`)
}

export const setAccessToken = (token: string) => {
  Cookie.set(ACCESS_TOKEN_KEY, token, {
    expires: 60 * 60 * 24,
    sameSite: 'none',
    secure: true,
  })

  FALLBACK_STORE = token
}

export const getAccessToken = () => {
  return Cookie.get(ACCESS_TOKEN_KEY) ?? FALLBACK_STORE
}

export const deleteAccessToken = () => {
  Cookie.remove(ACCESS_TOKEN_KEY)
  FALLBACK_STORE = null
}
