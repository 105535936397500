import { SubstanceApplicationRouteOfAdministrationHitsData as ROAHitsData } from '@austria-codex/types'
import { roaMatrixRowConfig } from '../../../common/text'
import { useAppSelector } from '../../../hooks/useStoreHooks'
import { SubstanceApplicationHitsRow } from './SubstanceApplicationHitsRow'

type SubstanceApplicationListProps = {
  hits: ROAHitsData
  isVeterinary: boolean
  isMonoPreparation: boolean
}

export function SubstanceApplicationHitsBlock({
  hits,
  isVeterinary,
  isMonoPreparation,
}: SubstanceApplicationListProps) {
  const filter = useAppSelector((state) => state.filter)
  const noFilter = filter.selectedRoutesOfAdministration.length === 0

  return (
    <>
      {roaMatrixRowConfig.map(({ code, title }) => {
        const hitsForCode = hits[code]
        if (!hitsForCode) {
          return null
        }

        if (filter.routesOfAdministration[code] || noFilter) {
          return (
            <SubstanceApplicationHitsRow
              key={code}
              hits={hitsForCode}
              title={title}
              routeOfAdministration={code}
              isVeterinary={isVeterinary}
              isMonoPreparation={isMonoPreparation}
            />
          )
        }

        return null
      })}
    </>
  )
}
