import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

export function LoadingSpinner() {
  return (
    <Container>
      <Ripple />
      <Ripple />
    </Container>
  )
}

const ripple = keyframes`
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
`

const Ripple = styled.div`
  position: absolute;
  border: 4px solid ${(props) => props.theme.palette.primary.main};
  opacity: 1;
  border-radius: 50%;
  animation: ${ripple} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
`

const Container = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  & > ${Ripple}:nth-of-type(2) {
    animation-delay: -0.5s;
  }
`
