import { Box } from '@mui/material'
import AtIcon from '../assets/images/countryFlagsSvg/at.svg?react'

export function AtLabel() {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      <AtIcon />
      <Box component="span">Österreich (AUT)</Box>
    </Box>
  )
}
