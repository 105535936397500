import styled from '@emotion/styled'
import AbDataLogoSvg from '../assets/images/abdata-logo.svg?react'

type TProps = {
  height?: `${number}px`
}

export const AbDataLogo = styled(AbDataLogoSvg)<TProps>`
  height: ${(props) => props.height};
  width: auto;
  display: block;
`

AbDataLogo.defaultProps = {
  height: '35px',
}
