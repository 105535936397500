import type { RoutesOfAdministrationTypes } from '@austria-codex/types'
import styled from '@emotion/styled'
import { createElement, type FunctionComponent } from 'react'
import ExtrakorporalIcon from '../../assets/images/routesOfAdministration/ic-extrakorporal.svg?react'
import LokalIcon from '../../assets/images/routesOfAdministration/ic-lokal.svg?react'
import OralIcon from '../../assets/images/routesOfAdministration/ic-oral.svg?react'
import ParenteralIcon from '../../assets/images/routesOfAdministration/ic-parenteral.svg?react'
import PulmonalIcon from '../../assets/images/routesOfAdministration/ic-pulmonal.svg?react'
import RektalIcon from '../../assets/images/routesOfAdministration/ic-rektal.svg?react'
import UrogenitalIcon from '../../assets/images/routesOfAdministration/ic-urogenital.svg?react'

type RouteOfApplicationIconBaseProps = {
  routeOfAdministration: RoutesOfAdministrationTypes
}

const icon: { [key in RoutesOfAdministrationTypes]: FunctionComponent } = {
  A: ExtrakorporalIcon,
  B: LokalIcon,
  C: PulmonalIcon,
  D: OralIcon,
  E: ParenteralIcon,
  F: RektalIcon,
  G: UrogenitalIcon,
}

const RouteOfApplicationIconBase: FunctionComponent<
  RouteOfApplicationIconBaseProps
> = ({ routeOfAdministration, ...props }) => (
  <div {...props}>{createElement(icon[routeOfAdministration], {}, null)}</div>
)

type RouteOfApplicationIconProps = {
  color: string
}

export const RouteOfAdministrationIcon = styled(
  RouteOfApplicationIconBase
)<RouteOfApplicationIconProps>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${(props) =>
    props.color === 'primary' ? props.theme.palette.primary.main : props.color};
`
