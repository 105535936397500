import {
  FilterMatchTypesEnum,
  ProductCompositionSubstanceEntryData,
  ProductsFilter,
} from '@austria-codex/types'
import { AGENTS_QUERY } from '../../../api/agents'
import {
  AGENTS_TABLE_CONFIG,
  AGENTS_TABLE_CONFIG_INT,
} from '../../../common/tableConfig/agents'
import { Box } from '../../../components/Layout/Box'
import { ProductsDataSourceTable } from '../../../components/PaginatedDataTable/ProductsDataSourceTable'
import { TableCell } from '../../../components/Table/TableCell'
import { TableRow } from '../../../components/Table/TableRow'
import { useProductData } from '../../../contexts/entity'
import { useAppSelector } from '../../../hooks/useStoreHooks'
import { CompositionSubstanceRow } from './CompositionSubstanceRow'

type CompositionAgentRowProps = {
  entry: ProductCompositionSubstanceEntryData
  toggleRow: (entryStoffId: string, name?: string) => void
  currentRow: string
}

export function CompositionAgentRow({
  entry,
  toggleRow,
  currentRow,
}: CompositionAgentRowProps) {
  const product = useProductData()
  const mode = useAppSelector((state) => state.user.mode)

  const basisStoffId = entry.stoff.basisStoff.id
  const basisStoffName = entry.stoff.basisStoff.bezeichnung

  const filter = {
    agents: {
      value: [basisStoffId],
      match: product.klassifikation?.istMonopraeparat
        ? FilterMatchTypesEnum.EXACT
        : FilterMatchTypesEnum.PARTIAL,
    },
  } as ProductsFilter

  const tableConfig =
    mode === 'national' ? AGENTS_TABLE_CONFIG : AGENTS_TABLE_CONFIG_INT

  const isOpen = currentRow === basisStoffId

  return (
    <>
      <CompositionSubstanceRow
        entry={entry}
        onClick={() => toggleRow(basisStoffId, basisStoffName)}
      />
      {isOpen && (
        <TableRow display={['flex', 'table-row']}>
          <TableCell colSpan={3} width="100%">
            <Box mt={2} mb={3}>
              <ProductsDataSourceTable
                filter={filter}
                onClose={() => toggleRow(basisStoffId)}
                name={`composition:${entry.bezLaufnummer}:${basisStoffId}`}
                heading="Präparate mit dem Wirkstoff"
                subheading={entry.stoff.basisStoff.bezeichnung}
                config={tableConfig}
                query={AGENTS_QUERY}
              />
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
