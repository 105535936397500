import { Section } from '../../../components/Section/Section'
import { useProductIntData } from '../../../contexts/entity'

export function IntDosageFormSection() {
  const product = useProductIntData()
  const dosageForm = product.klassifikationInt?.arzneiform

  return dosageForm ? (
    <Section width="small" heading="Arzneiform">
      {dosageForm}
    </Section>
  ) : null
}
