export function smoothScrollTo(id: string) {
  const topElem = document.getElementById(id)
  const barElem = document.getElementById('application-bar')

  if (!topElem || !barElem) return

  const top = topElem.offsetTop
  const appBarHeight = barElem.offsetHeight

  window.scroll({ top: top - appBarHeight, behavior: 'smooth' })
}

export function trySmoothScrollTo(id: string) {
  let loop = 0
  const maxLoops = 20

  const interval = setInterval(() => {
    try {
      smoothScrollTo(id)
      clearInterval(interval)
    } catch {
      if (loop >= maxLoops) {
        clearInterval(interval)
      }
    }
    loop += 1
  }, 250)
}
