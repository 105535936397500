import { InteractionData, MultiSelectData } from '@austria-codex/types'
import {
  EntityId,
  PayloadAction,
  SerializedError,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { fetchInteractionsApi } from '../api/api'
import { filterInteractions } from '../utilities/interactions'
import { filterMultiSelect } from '../utilities/multiSelect'
import {
  fetchEntitiesWithRelatedData,
  filteredEntityIdentifiersSelector,
} from './entities'
import { RootState } from './index'

type State = {
  interactions: InteractionData[] | null
  multiSelect: MultiSelectData | null
  loading: boolean
  error: SerializedError | null
}

const initialState: State = {
  interactions: null,
  multiSelect: null,
  loading: false,
  error: null,
}

export type FetchInteractionsActionPayload = {
  interaction: InteractionData[]
}

export const fetchInteractions = createAsyncThunk<
  FetchInteractionsActionPayload,
  EntityId[],
  { state: RootState }
>('interactions/fetch', async (entityIdentifiers) => {
  try {
    const response = await fetchInteractionsApi(entityIdentifiers)

    if (response.errors) {
      return Promise.reject('Server Error')
    }

    return Promise.resolve(response.data)
  } catch (err) {
    return Promise.reject(err)
  }
})

const safetySlice = createSlice({
  name: 'safety',
  initialState,
  reducers: {
    setInteractions: (
      state,
      { payload }: PayloadAction<InteractionData[] | null>
    ) => {
      state.interactions = payload
    },
    setMultiSelect: (
      state,
      { payload }: PayloadAction<MultiSelectData | null>
    ) => {
      state.multiSelect = payload
    },
    resetSafety: (state) => {
      state.interactions = null
      state.multiSelect = null
      state.loading = false
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEntitiesWithRelatedData.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchEntitiesWithRelatedData.fulfilled, (state, { payload }) => {
        state.interactions = payload.interaction
        state.multiSelect = payload.multiSelect
        state.loading = false
        state.error = null
      })
      .addCase(fetchEntitiesWithRelatedData.rejected, (state, { error }) => {
        state.loading = true
        state.error = error
      })
      .addCase(fetchInteractions.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchInteractions.fulfilled, (state, { payload }) => {
        state.interactions = payload.interaction
        state.loading = false
        state.error = null
      })
      .addCase(fetchInteractions.rejected, (state, { error }) => {
        state.loading = true
        state.error = error
      })
  },
})

export default safetySlice.reducer

export const { setInteractions, setMultiSelect, resetSafety } =
  safetySlice.actions

const interactionsSelector = (state: RootState) => state.safety.interactions

export const filteredInteractions = createSelector(
  [
    interactionsSelector,
    filteredEntityIdentifiersSelector,
    (state: RootState) => state.interactionsFilter,
  ],
  filterInteractions
)

const multiSelectSelector = (state: RootState) => state.safety.multiSelect

export const filteredMultiSelectSelector = createSelector(
  [multiSelectSelector, filteredEntityIdentifiersSelector],
  filterMultiSelect
)
