import {
  FilterModeTypesEnum,
  ProductsIntFilter,
  type ProductData,
  type ProductsFilter,
} from '@austria-codex/types'
import { isProduct, isProductInternational } from '@austria-codex/utilities'
import { DocumentNode } from 'graphql'
import { ReactNode } from 'react'
import { useEntityDataUnion } from '../../contexts/entity'
import { useAppDispatch, useAppSelector } from '../../hooks/useStoreHooks'
import { telemetrie } from '../../services/telemetrie.service'
import {
  entityIdentifiersSelector,
  fetchEntitiesWithRelatedData,
} from '../../store/entities'
import { filterSelector } from '../../store/filter'
import { DataTableConfig } from '../DataTable/DataTable'
import { DataSourceTable } from './DataSourceTable'

type ProductsDataSourceTableProps = {
  filter: ProductsFilter | ProductsIntFilter
  name: string
  config: DataTableConfig
  query: DocumentNode
  onClose?: () => void
  heading?: ReactNode | string
  subheading?: ReactNode | string
}

export function ProductsDataSourceTable({
  filter,
  name,
  config,
  query,
  onClose,
  heading,
  subheading,
}: ProductsDataSourceTableProps) {
  const entity = useEntityDataUnion()
  const dispatch = useAppDispatch()
  const entityIdentifiers = useAppSelector(entityIdentifiersSelector)
  const mode = useAppSelector((state) => state.user.mode)
  const selectedCountries = useAppSelector(
    (state) => state.user.selectedCountries
  )
  const { selectedRoutesOfAdministration } = useAppSelector(filterSelector)
  /*const includesVeterinary = useAppSelector(
    (state) => state.settings.includeVeterinary
  )*/

  function onRowClick(product: ProductData) {
    if (isProduct(entity) || isProductInternational(entity)) {
      telemetrie.drugClicked(
        product.id,
        product.bezeichnung,
        entity.id,
        entity.bezeichnung,
        null,
        null
      )
    } else {
      telemetrie.drugClicked(
        product.id,
        product.bezeichnung,
        null,
        null,
        entity.id,
        entity.bezeichnung
      )
    }
    dispatch(fetchEntitiesWithRelatedData([product]))
  }

  const variables: { filter: ProductsFilter } = {
    filter: {
      // TODO: this would filter only products which are veterinary, we need another way
      // isVeterinary: includesVeterinary,
      routesOfAdministration: {
        value: selectedRoutesOfAdministration,
        mode: FilterModeTypesEnum.ROOT,
      },
      // In 'national' mode we only want to filter by AT products
      isoCodes: mode === 'national' ? ['at'] : selectedCountries,
      ...filter,
    },
  }

  return (
    <DataSourceTable
      query={query}
      config={config}
      variables={variables}
      uniqueIdentifier={entity.id}
      identifier={name}
      onRowClick={onRowClick}
      onClose={onClose}
      isRowDisabled={({ id }) => entityIdentifiers.includes(id)}
      heading={heading}
      subheading={subheading}
    />
  )
}
