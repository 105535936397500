import { FeatureEnum, Occurrence, ProductData } from '@austria-codex/types'
import { useMemo } from 'react'
import { warningNotice } from '../../common/translations'
import { Html } from '../../components/Html/Html'
import { IconTextBlock } from '../../components/IconTextBlock/IconTextBlock'
import {
  AlcoholWarningIcon,
  DiabeticWarningIcon,
  MonitoringWarningIcon,
  RoadworthyWarningIcon,
  SportWarningIcon,
  SunLightWarningIcon,
} from '../../components/Icons/WarningIcon'
import { Paragraph } from '../../components/Layout/Typography'
import { DocumentationSection } from '../../components/Section/DocumentationSection'
import { useProductData } from '../../contexts/entity'
import { useAppSelector } from '../../hooks/useStoreHooks'
import { createPersonalCharacteristicsInHighlightsSelector } from '../../store/personalCharacteristics'
import { FeatureProps } from '../../types/bundle'
import { PersonalCharacteristicsEnum } from '../../types/highlight'

export function AdvisorySection({ features }: FeatureProps) {
  const product = useProductData()
  const personalCharacteristicsInHighlightsSelector = useMemo(
    createPersonalCharacteristicsInHighlightsSelector,
    []
  )
  const highlight = useAppSelector((state) =>
    personalCharacteristicsInHighlightsSelector(
      state,
      product,
      advisoryHighlightOption
    )
  )

  const hasIcons = features.includes(FeatureEnum.AdvisorySymbols)

  const hinwDiabetiker = product.warnHinweise?.hinwDiabetiker
  const hinwVerkehrstuechtigkeit =
    product.warnHinweise?.hinwVerkehrstuechtigkeit
  const hinwAlkohol = product.warnHinweise?.hinwAlkohol
  const hinwSonnenlicht = product.warnHinweise?.hinwSonnenlicht
  const hinwKadersportler = product.warnHinweise?.hinwKadersportler

  return hasAdvisoryInformation(product) ? (
    <DocumentationSection
      inherit={product.dokumentation?.schnellhilfe?.warnhinweise?.vererbt}
      width="small"
      heading="Warnhinweise"
      highlight={highlight}
    >
      {product.warnHinweise && product.warnHinweise.zusUeberwachung > 0 && (
        <IconTextBlock
          Icon={
            <MonitoringWarningIcon
              level={product.warnHinweise.zusUeberwachung}
            />
          }
        >
          {warningNotice('monitoring', product.warnHinweise.zusUeberwachung)}
        </IconTextBlock>
      )}
      {hasIcons && (
        <>
          {hinwDiabetiker !== Occurrence.NotSpecified && (
            <IconTextBlock
              Icon={<DiabeticWarningIcon level={hinwDiabetiker as number} />}
            >
              {warningNotice('diabetes', hinwDiabetiker as number)}
            </IconTextBlock>
          )}
          {hinwVerkehrstuechtigkeit !== Occurrence.NotSpecified && (
            <IconTextBlock
              Icon={
                <RoadworthyWarningIcon
                  level={hinwVerkehrstuechtigkeit as number}
                />
              }
            >
              {warningNotice('roadworthy', hinwVerkehrstuechtigkeit as number)}
            </IconTextBlock>
          )}
          {hinwAlkohol !== Occurrence.NotSpecified && (
            <IconTextBlock
              Icon={<AlcoholWarningIcon level={hinwAlkohol as number} />}
            >
              {warningNotice('alcohol', hinwAlkohol as number)}
            </IconTextBlock>
          )}
          {hinwSonnenlicht !== Occurrence.NotSpecified && (
            <IconTextBlock
              Icon={<SunLightWarningIcon level={hinwSonnenlicht as number} />}
            >
              {warningNotice('sunlight', hinwSonnenlicht as number)}
            </IconTextBlock>
          )}
          {hinwKadersportler !== 0 && (
            <IconTextBlock
              Icon={<SportWarningIcon level={hinwKadersportler as number} />}
            >
              {warningNotice('sport', hinwKadersportler as number)}
            </IconTextBlock>
          )}
        </>
      )}
      {product.dokumentation?.schnellhilfe?.warnhinweise && (
        <Paragraph>
          <Html
            content={product.dokumentation.schnellhilfe.warnhinweise.text}
          />
        </Paragraph>
      )}
    </DocumentationSection>
  ) : null
}

export const hasAdvisoryInformation = (product: ProductData): boolean =>
  product.dokumentation?.schnellhilfe?.warnhinweise !== null ||
  product.warnHinweise?.hinwDiabetiker !== Occurrence.NotSpecified ||
  product.warnHinweise?.hinwVerkehrstuechtigkeit !== Occurrence.NotSpecified ||
  product.warnHinweise?.hinwAlkohol !== Occurrence.NotSpecified ||
  product.warnHinweise?.hinwSonnenlicht !== Occurrence.NotSpecified ||
  product.warnHinweise?.hinwKadersportler !== 0

const advisoryHighlightOption = [
  PersonalCharacteristicsEnum.DIABETES,
  PersonalCharacteristicsEnum.ALCOHOL,
  PersonalCharacteristicsEnum.SQUAD_SPORT,
]
