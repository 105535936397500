import styled from '@emotion/styled'

export const Sheet = styled.div`
  background-color: white;
  padding-top: 4px;
  overflow: hidden;
  box-shadow: 0 15px 55px rgba(0, 0, 0, 0.15);

  ${(props) => props.theme.media(2, props.theme.breakpoints)} {
    border-radius: 4px;
  }
`
