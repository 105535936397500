import styled from '@emotion/styled'
import classNames from 'classnames'
import { type ReactNode } from 'react'
import { Link, LinkProps, useLocation, useMatch } from 'react-router-dom'
import { telemetrie } from '../../services/telemetrie.service'
import { Flex } from '../Layout/Box'

type TTabsProps = {
  children: ReactNode
}

export function Tabs({ children }: TTabsProps) {
  return (
    <Flex>
      <TabsList>{children}</TabsList>
      <TabSpacer />
    </Flex>
  )
}

interface LinkTabItemProps extends LinkProps {
  children: ReactNode
  metaRender?: ReactNode
  to: string
  trackingName?: string
}

export function LinkTabItem({
  children,
  metaRender,
  to,
  trackingName,
  ...rest
}: LinkTabItemProps) {
  const match = useMatch(to)
  const location = useLocation()

  function handleClick() {
    // If trackingName is set, track click on tab.
    if (trackingName) {
      telemetrie.mainTabClicked(trackingName)
    }
  }

  return (
    <li className={classNames({ active: Boolean(match) })}>
      <Link
        onClick={handleClick}
        to={{ pathname: to, search: location.search }}
        {...rest}
      >
        {metaRender ?? null}
        {children}
      </Link>
    </li>
  )
}

const TabSpacer = styled.div`
  flex-grow: 1;
  border-bottom: 2px solid ${(props) => props.theme.palette.grey[200]};
`

const TabsList = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;

  ${(props) => props.theme.media(1, props.theme.breakpoints)} {
    width: auto;
  }

  & > li {
    display: flex;
    flex: 1 1 auto;

    & > a {
      display: flex;
      align-items: center;
      border-top: 2px solid transparent;
      border-bottom: 2px solid ${(props) => props.theme.palette.grey[200]};
      color: ${(props) => props.theme.palette.primary.main};
      padding: 10px 5px;
      text-decoration: none;
      text-transform: uppercase;
      line-height: ${22 / 16};
      font-weight: bold;
      letter-spacing: 0.05em;
      justify-content: center;
      flex: 1 1 auto;
      font-size: ${(props) => props.theme.fontSizes[0]}px;

      ${(props) => props.theme.media(1, props.theme.breakpoints)} {
        padding: 10px 20px;
        font-size: 0.8125rem;
      }

      &:hover {
        cursor: pointer;
      }
    }

    ${(props) => props.theme.media(1, props.theme.breakpoints)} {
      &:first-of-type > a {
        padding-left: 30px;
      }

      &:not(.active) + li:not(.active):before {
        width: 30px;
        height: 40px;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='40' viewBox='0 0 30 40'%3E%3Cline x1='8.95' y1='10' x2='19.95' y2='30' fill='none' stroke='${(
          props
        ) =>
          encodeURIComponent(
            props.theme.palette.grey[200]
          )}' stroke-width='2'/%3E%3C/svg%3E");
        border-bottom: 2px solid ${(props) => props.theme.palette.grey[200]};
      }
    }

    &.active {
      & > a {
        color: ${(props) => props.theme.palette.common.black};

        border-bottom-color: ${(props) => props.theme.palette.primary.main};
      }

      ${(props) => props.theme.media(1, props.theme.breakpoints)} {
        border-bottom: none;

        & > a {
          border-top: 2px solid ${(props) => props.theme.palette.grey[200]};
          border-bottom-color: transparent;
        }

        &:after,
        &:not(:first-of-type):before {
          width: 30px;
          height: 42px;
          content: '';
        }

        &:after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='42' viewBox='0 0 30 42'%3E%3Cpath d='M30,41H28c-1.1,0-2.65-1.18-3.45-2.63L5.45,3.63C4.65,2.18,3.1,1,2,1H0' fill='none' stroke='${(
            props
          ) =>
            encodeURIComponent(
              props.theme.palette.grey[200]
            )}' stroke-width='2'/%3E%3C/svg%3E");
        }

        &:not(:first-of-type):before {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='42' viewBox='0 0 30 42'%3E%3Cpath d='M0,41H2c1.1,0,2.65-1.18,3.45-2.63L24.55,3.63C25.35,2.18,26.9,1,28,1h2' fill='none' stroke='${(
            props
          ) =>
            encodeURIComponent(
              props.theme.palette.grey[200]
            )}' stroke-width='2'/%3E%3C/svg%3E");
        }
      }
    }
  }
`
