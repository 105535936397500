type FlagIconProps = {
  code: string
}

export function Flag({ code }: FlagIconProps) {
  return (
    <img
      aria-hidden="true"
      alt={code}
      src={`/images/countryFlags/${code}.png`}
      style={{ height: 14, width: 'auto' }}
    />
  )
}
