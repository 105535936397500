import { FeatureEnum } from '@austria-codex/types'
import { useMemo } from 'react'
import { useBundle } from '../contexts/bundle'

export function useBundleFeature(feature: FeatureEnum) {
  const bundle = useBundle()

  return useMemo(() => {
    return bundle.features.includes(feature)
  }, [bundle, feature])
}
