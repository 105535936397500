import styled from '@emotion/styled'

export const SeparatedListItem = styled.li``

type SeparatedListProps = {
  separator?: string
  nowrap?: boolean
}

export const SeparatedList = styled.ol<SeparatedListProps>`
  list-style: none;
  display: inline-flex;
  flex-wrap: ${(props) => (props.nowrap ? 'nowrap' : 'wrap')};
  padding: 0;
  margin: 0;

  ${SeparatedListItem} {
    white-space: ${(props) => (props.nowrap ? 'pre' : 'pre-wrap')};
  }

  ${SeparatedListItem}:after {
    content: '${(props) => props.separator}';
  }

  ${SeparatedListItem}:last-child:after {
    display: none;
  }
`

SeparatedList.defaultProps = {
  separator: ', ',
}
