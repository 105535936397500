import { Contact } from '../../components/Contact/Contact'
import { Section } from '../../components/Section/Section'
import { useProductData } from '../../contexts/entity'

export function DistributionSection() {
  const product = useProductData()

  return product.kontakte?.vertriebsfirma ? (
    <Section width="small" heading="Vertrieb">
      <Contact contact={product.kontakte.vertriebsfirma} />
    </Section>
  ) : null
}
