import { gql } from '@apollo/client/core'

export const ARTICLES_QUERY = gql`
  query articles($offset: Int!, $filter: ArticleFilterInputType, $limit: Int) {
    entries: articles(offset: $offset, filter: $filter, limit: $limit) {
      offset
      limit
      total
      hits {
        id
        bezeichnung
        produkt
        pharmazentralNr
        istLieferbar
        referenzartikel {
          bezeichnungssuffix
        }
        packung {
          gebinde {
            einheit {
              bezeichnung
              beschreibung
            }
            mengenAngabe
          }
          fuellmenge {
            einheit {
              bezeichnung
              beschreibung
            }
            mengenAngabe
          }
        }
      }
    }
  }
`
