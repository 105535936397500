import { type SubstanceData } from '@austria-codex/types'
import { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Text } from '../../../components/Layout/Typography'
import { useAppSelector } from '../../../hooks/useStoreHooks'
import { createEntityLoadingStateSelector } from '../../../store/entities'

type TProps = {
  substance: SubstanceData
}

export function SubstanceMetaHeader({ substance }: TProps) {
  const entityLoadingStateSelector = useMemo(
    createEntityLoadingStateSelector,
    []
  )
  const { loading } = useAppSelector((state) =>
    entityLoadingStateSelector(state, substance.id)
  )

  return (
    <Text variant="subHeading2" as="div">
      {loading ? <Skeleton width={300} /> : substance.hinweis}
    </Text>
  )
}
