import { ProductData } from '@austria-codex/types'
import { DataTableConfig } from '../../components/DataTable/DataTable'
import { descriptionCell, productIconCell } from './common'

export const REFERENCES_TABLE_CONFIG: DataTableConfig<ProductData> = {
  hover: true,
  columns: [
    productIconCell,
    descriptionCell,
    {
      title: 'Typ',
      key: 'type',
      render: (_, product) => {
        const type: string[] = []

        if (product.referenz?.istBiosimilar) {
          type.push('Biosimilar')
        }

        if (product.referenz?.istParallelimport) {
          type.push('Parallelimport')
        }

        if (type.length > 0) {
          return <>{type.join(', ')}</>
        }

        return <>Originalprodukt</>
      },
      width: 230,
    },
  ],
}
