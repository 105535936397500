import { Box } from '@mui/material'
import { Content } from '../../../components/Layout/Content'

export function IntSubstanceSafetySection() {
  return (
    <Content width="small">
      <Box sx={{ py: 6 }}>
        Keine Sicherheitsinformation für diese Auswahl vorhanden.
      </Box>
    </Content>
  )
}
