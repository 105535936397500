import type {
  ProductCompositionEntryData,
  ProductCompositionSubstanceEntryData,
  SubstanceSummaryData,
} from '@austria-codex/types'
import { CompositionTypesEnum } from '@austria-codex/types'
import { isCompositionAgent, isProduct } from '@austria-codex/utilities'
import { createSlice } from '@reduxjs/toolkit'
import {
  fetchEntitiesWithRelatedData,
  removeAllEntities,
  removeEntity,
} from './entities'

type State = {
  [productIdentifier: string]: {
    visibility: boolean[]
    agents: {
      self: SubstanceSummaryData[]
      root: SubstanceSummaryData[]
    }
  }
}

const initialState: State = {}

export const productCompositionSlice = createSlice({
  name: 'productComposition',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEntitiesWithRelatedData.fulfilled, (state, { payload }) => {
        payload.entities.forEach((entity) => {
          if (isProduct(entity)) {
            const entries = entity?.zusammensetzung?.eintraege ?? null

            state[entity.id] = {
              visibility: entries ? calculateVisibility(entries) : [],
              agents: {
                self: filterUniqueAgents(
                  entries,
                  (entity) => entity.stoff.stoff
                ),
                root: filterUniqueAgents(
                  entries,
                  (entry) => entry.stoff.basisStoff
                ),
              },
            }
          }
        })
      })
      .addCase(removeEntity, (state, { payload }) => {
        delete state[payload]
      })
      .addCase(removeAllEntities, () => initialState)
  },
})

export default productCompositionSlice.reducer

function filterUniqueAgents(
  entries: ProductCompositionEntryData[] | null,
  mapFn: (entry: ProductCompositionSubstanceEntryData) => SubstanceSummaryData
) {
  if (!entries) {
    return []
  }

  const agents: SubstanceSummaryData[] = []

  entries
    .filter(isCompositionAgent)
    .map(mapFn)
    .forEach((agent) => {
      if (!agents.find((filteredAgent) => filteredAgent.id === agent.id)) {
        agents.push(agent)
      }
    })

  return agents
}

function calculateVisibility(
  compositionEntries: ProductCompositionEntryData[]
) {
  const visibility: boolean[] = []

  // Set agents to visible
  compositionEntries.forEach((compositionEntry, index) => {
    switch (compositionEntry.eintragsTyp) {
      case CompositionTypesEnum.Beschreibung:
        visibility[index] = false
        break
      case CompositionTypesEnum.Wirkstoff:
        visibility[index] = true
        break
      case CompositionTypesEnum.Hilfsstoff:
        visibility[index] = false
        break
    }
  })

  // Set references to agents visible
  compositionEntries.forEach((compositionEntry, index) => {
    if (
      compositionEntry.bezLaufnummer !== undefined &&
      compositionEntries[compositionEntry.bezLaufnummer - 1] !== undefined
    ) {
      if (
        compositionEntries[compositionEntry.bezLaufnummer - 1].eintragsTyp ===
        CompositionTypesEnum.Wirkstoff
      ) {
        visibility[index] = true
      }
    }
  })

  // Set descriptions for agents to true
  compositionEntries.forEach((compositionEntry, index) => {
    if (compositionEntry.eintragsTyp === CompositionTypesEnum.Wirkstoff) {
      let lastCompositionEntry: ProductCompositionEntryData | null = null
      // For each agent loop back
      for (let agentIndex = index; agentIndex >= 0; agentIndex -= 1) {
        const currentCompositionEntry = compositionEntries[agentIndex]

        // If another agent is found stop searching
        if (
          currentCompositionEntry.eintragsTyp === CompositionTypesEnum.Wirkstoff
        ) {
          break
        }

        if (
          currentCompositionEntry.eintragsTyp ===
            CompositionTypesEnum.Beschreibung &&
          currentCompositionEntry.bezLaufnummer === undefined
        ) {
          visibility[agentIndex] = true
        }

        if (
          currentCompositionEntry.eintragsTyp !==
            CompositionTypesEnum.Beschreibung &&
          lastCompositionEntry &&
          lastCompositionEntry.eintragsTyp === CompositionTypesEnum.Beschreibung
        ) {
          break
        }

        lastCompositionEntry = currentCompositionEntry
      }
    }
  })

  return visibility
}
