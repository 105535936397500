import {
  EEvaluationDescription,
  EInteractionSeverity,
  type InteractionData,
  type InteractionEntityPairData,
  type Maybe,
} from '@austria-codex/types'
import {
  isNullOrUndefined,
  separateEntityIdentifiers,
} from '@austria-codex/utilities'
import { EntityId } from '@reduxjs/toolkit'
import { TInteractionsFilterState } from '../store/interactionsFilter.store'

export const filterInteractions = (
  interactions: Maybe<InteractionData[]>,
  entityIdentifiers: EntityId[],
  interactionsFilter: TInteractionsFilterState
): InteractionData[] | null => {
  if (!interactions) {
    return null
  }

  if (interactions.length === 0) {
    return []
  }

  const { productIdentifiers, substanceIdentifiers } =
    separateEntityIdentifiers(entityIdentifiers as string[])

  let filteredInteractions = interactions
    // Only show interactions which are selected by severity level.
    .filter((i) =>
      interactionsFilter.selectedSeverities.includes(i.schwereGrad)
    )
    // Only show interactions which are selected by evaluation level
    .filter((i) => {
      // If severity is lower than 20, we do not want to filter by evaluation
      // because we do not show the evaluation on those interactions.
      if (i.schwereGrad < EInteractionSeverity.ProductSpecificWarning) {
        return true
      }

      // If quellenBewertung is null or undefined give it the value 10 = "nicht bekannt"
      const q = isNullOrUndefined(i.quellenBewertung)
        ? EEvaluationDescription.Unknown
        : i.quellenBewertung

      return interactionsFilter.selectedEvaluations.includes(q)
    })
    .map((interaction) => ({
      ...interaction,
      gruppeLinks: {
        ...interaction.gruppeLinks,
      },
      gruppeRechts: {
        ...interaction.gruppeRechts,
      },
    }))

  /** Reduce interaction groups to only contain provided identifiers */
  filteredInteractions.forEach(({ gruppeLinks, gruppeRechts }) => {
    gruppeLinks.produkte = gruppeLinks.produkte.filter(({ id }) =>
      productIdentifiers.includes(id)
    )
    gruppeLinks.stoffe = gruppeLinks.stoffe.filter(({ id }) =>
      substanceIdentifiers.includes(id)
    )
    gruppeRechts.produkte = gruppeRechts.produkte.filter(({ id }) =>
      productIdentifiers.includes(id)
    )
    gruppeRechts.stoffe = gruppeRechts.stoffe.filter(({ id }) =>
      substanceIdentifiers.includes(id)
    )
  })

  filteredInteractions = filteredInteractions.filter(
    ({ gruppeLinks, gruppeRechts }) => {
      if (
        gruppeLinks.produkte.length === 1 &&
        gruppeRechts.produkte.length === 1
      ) {
        if (gruppeLinks.produkte[0].id === gruppeRechts.produkte[0].id) {
          return false
        }
      }

      if (gruppeLinks.stoffe.length === 1 && gruppeRechts.stoffe.length === 1) {
        if (gruppeLinks.stoffe[0].id === gruppeRechts.stoffe[0].id) {
          return false
        }
      }

      return true
    }
  )

  const isSingleSubstanceInteraction =
    productIdentifiers.length === 0 && substanceIdentifiers.length === 1

  if (!isSingleSubstanceInteraction) {
    filteredInteractions = filteredInteractions.filter(
      (interaction) =>
        (interaction.gruppeLinks.produkte.length > 0 ||
          interaction.gruppeLinks.stoffe.length > 0) &&
        (interaction.gruppeRechts.produkte.length > 0 ||
          interaction.gruppeRechts.stoffe.length > 0)
    )
  }

  filteredInteractions = filteredInteractions.filter(
    (interaction) => interaction.istAllgemein === true
  )

  return filteredInteractions
}

export const getInteractionEntityPairs = (
  interaction: InteractionData
): InteractionEntityPairData[] => {
  const inverseMatched: string[] = []
  const pairs: InteractionEntityPairData[] = []

  interaction.gruppeLinks.produkte.forEach((productLeft) => {
    interaction.gruppeRechts.produkte.forEach((productRight) => {
      if (productLeft.id === productRight.id) {
        return
      }

      if (inverseMatched.includes(`${productLeft.id}:${productRight.id}`)) {
        return
      }

      pairs.push({
        id: `${productLeft.id}:${productRight.id}`,
        left: productLeft.bezeichnung,
        right: productRight.bezeichnung,
      })

      inverseMatched.push(`${productRight.id}:${productLeft.id}`)
    })

    interaction.gruppeRechts.stoffe.forEach((substanceRight) => {
      inverseMatched.push(`${substanceRight.id}:${productLeft.id}`)

      pairs.push({
        id: `${productLeft.id}:${substanceRight.id}`,
        left: productLeft.bezeichnung,
        right: substanceRight.bezeichnung,
      })
    })
  })

  interaction.gruppeLinks.stoffe.forEach((substanceLeft) => {
    interaction.gruppeRechts.produkte.forEach((productRight) => {
      if (inverseMatched.includes(`${substanceLeft.id}:${productRight.id}`)) {
        return
      }

      pairs.push({
        id: `${substanceLeft.id}:${productRight.id}`,
        left: substanceLeft.bezeichnung,
        right: productRight.bezeichnung,
      })
    })
  })

  return pairs
}
