import styled from '@emotion/styled'
import {
  BorderProps,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  ResponsiveValue,
  space,
  SpaceProps,
  system,
} from 'styled-system'

export type ColorProps = {
  color?: ResponsiveValue<string>
  bg?: ResponsiveValue<string>
}

const color = system({
  color: {
    property: 'color',
    scale: 'palette',
  },
  bg: {
    property: 'backgroundColor',
    scale: 'palette',
  },
})

const border = system({
  border: {
    property: 'border',
    scale: 'borders',
  },
  borderWidth: {
    property: 'borderWidth',
    scale: 'borderWidths',
  },
  borderStyle: {
    property: 'borderStyle',
    scale: 'borderStyles',
  },
  borderColor: {
    property: 'borderColor',
    scale: 'palette',
  },
  borderRadius: {
    property: 'borderRadius',
    scale: 'radii',
  },
  borderTop: {
    property: 'borderTop',
    scale: 'borders',
  },
  borderTopLeftRadius: {
    property: 'borderTopLeftRadius',
    scale: 'radii',
  },
  borderTopRightRadius: {
    property: 'borderTopRightRadius',
    scale: 'radii',
  },
  borderRight: {
    property: 'borderRight',
    scale: 'borders',
  },
  borderBottom: {
    property: 'borderBottom',
    scale: 'borders',
  },
  borderBottomLeftRadius: {
    property: 'borderBottomLeftRadius',
    scale: 'radii',
  },
  borderBottomRightRadius: {
    property: 'borderBottomRightRadius',
    scale: 'radii',
  },
  borderLeft: {
    property: 'borderLeft',
    scale: 'borders',
  },
  borderX: {
    properties: ['borderLeft', 'borderRight'],
    scale: 'borders',
  },
  borderY: {
    properties: ['borderTop', 'borderBottom'],
    scale: 'borders',
  },
  borderTopWidth: {
    property: 'borderTopWidth',
    scale: 'borderWidths',
  },
  borderTopColor: {
    property: 'borderTopColor',
    scale: 'palette',
  },
  borderTopStyle: {
    property: 'borderTopStyle',
    scale: 'borderStyles',
  },
})

export type BoxProps = SpaceProps &
  ColorProps &
  BorderProps &
  PositionProps &
  LayoutProps &
  FlexboxProps

export const box = compose(
  color,
  space,
  border,
  position,
  layout,
  space,
  flexbox
)

export const Box = styled('div')<BoxProps>(box)

type FlexProps = BoxProps & FlexboxProps

export const Flex = styled(Box)<FlexProps>({})

Flex.defaultProps = {
  display: 'flex',
}
