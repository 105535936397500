import {
  AdviceIntSection,
  AdviceIntType,
  Alternative,
  CompositionIntType,
  Haltbarkeit,
  Komponente,
  ProductInternationalData,
  ProductUnion,
} from '@austria-codex/types'
import { isProduct } from '@austria-codex/utilities'
import {
  dispensaryMapping,
  shortInfoHeadings,
  storageMapping,
} from '../common/mappings'

export function getSubstancesInt(product: ProductInternationalData) {
  if (!product.zusammensetzungInt) {
    return []
  }

  return product.zusammensetzungInt[0].eintraege.filter(
    (e) =>
      (e.eintragsTyp === CompositionIntType.Wirkstoff ||
        e.eintragsTyp === CompositionIntType.Hilfsstoff) &&
      !e.bezLaufnummer
  )
}

export function getActiveSubstancesInt(product: ProductInternationalData) {
  if (!product.zusammensetzungInt) {
    return []
  }

  return product.zusammensetzungInt.flatMap((z) => {
    return z.eintraege.filter(
      (e) => e.eintragsTyp === CompositionIntType.Wirkstoff && !e.bezLaufnummer
    )
  })
}

export function getActiveBaseSubstancesInt(product: ProductInternationalData) {
  return getActiveSubstancesInt(product).map((s) => {
    if (s.basisStoff) {
      return s.basisStoff
    }

    return {
      id: s.id,
      bezeichnung: s.bezeichnung,
      oeavStoffId: s.oeavStoffId,
    }
  })
}

export function getActiveSubstancesIntIds(product: ProductInternationalData) {
  if (!product.zusammensetzungInt) {
    return []
  }

  return product.zusammensetzungInt.flatMap((z) => {
    return z.eintraege
      .filter(
        (e) =>
          e.eintragsTyp === CompositionIntType.Wirkstoff && !e.bezLaufnummer
      )
      .map((e) => e.id)
  })
}

export function getShortInfoHeading(id: number) {
  if (shortInfoHeadingKeyExists(id)) {
    return shortInfoHeadings[id]
  }

  return 'Unbekannte Kurzinfo ID'
}

function shortInfoHeadingKeyExists(
  id: number
): id is keyof typeof shortInfoHeadings {
  return Object.keys(shortInfoHeadings).includes(String(id))
}

export function hasIntakeAdvisoryInformation(
  product: ProductInternationalData
) {
  return (
    product.anwWarnHinweise?.hinweise.some(
      (h) => h.hinweisTyp === AdviceIntType.Einnahme
    ) ?? false
  )
}

export function getIntakeAdvisories(product: ProductInternationalData) {
  return (
    product.anwWarnHinweise?.hinweise.filter(
      (h) => h.hinweisTyp === AdviceIntType.Einnahme
    ) ?? []
  )
}

export function hasDosageAdvisoryInformation(
  product: ProductInternationalData
) {
  return (
    product.anwWarnHinweise?.hinweise.some(
      (h) => h.hinweisTyp === AdviceIntType.Dosierung
    ) ?? false
  )
}

export function getDosageAdvisories(product: ProductInternationalData) {
  return (
    product.anwWarnHinweise?.hinweise.filter(
      (h) => h.hinweisTyp === AdviceIntType.Dosierung
    ) ?? []
  )
}

export function getDispensaryPolicy(abgabeBestimmung: number | undefined) {
  if (dispensaryKeyExists(abgabeBestimmung)) {
    return dispensaryMapping[abgabeBestimmung]
  }

  return 'Unbekannte Abgabebestimmung ID'
}

function dispensaryKeyExists(
  key: number | undefined
): key is keyof typeof dispensaryMapping {
  return key !== undefined
    ? Object.keys(dispensaryMapping).includes(String(key))
    : false
}

export function getStorageLife(product: ProductInternationalData) {
  return product.haltbarkeiten?.[0]
}

export function getStoragePlace(haltbarkeit: Haltbarkeit | undefined) {
  if (!haltbarkeit) {
    return
  }

  if (storagePlaceKeyExists(haltbarkeit.lagerungsOrt)) {
    return storageMapping[haltbarkeit.lagerungsOrt]
  }

  return 'Unbekannte LagerungsOrt ID'
}

function storagePlaceKeyExists(
  key: number
): key is keyof typeof storageMapping {
  return Object.keys(storageMapping).includes(String(key))
}

export function getBioaequivalenzScore(
  alternatives: Alternative[],
  atProductId: string
): number | undefined {
  // The score only exists on 'National' sections
  const nationalSections = alternatives.filter(
    (a) => a.bezugsKatalog === 'National'
  )

  let score
  nationalSections.forEach((s) => {
    const id = s.produkte.produktIds.find((id) => id === atProductId)
    if (id) {
      score = s.produkte.verknBewertung
    }
  })

  return score
}

export function getAllAtProductIds(alternatives: Alternative[] | undefined) {
  // We only need AT products ids, those are in the 'National' sections
  const nationalSections =
    alternatives?.filter((a) => a.bezugsKatalog === 'National') ?? []

  return nationalSections
    .filter(
      (ns) =>
        // We only want "Exzellent" and "Sehr gut"
        ns.produkte.verknBewertung === 1 || ns.produkte.verknBewertung === 2
    )
    .flatMap((ns) => {
      return ns.produkte.produktIds
    })
}

export function getAllIntProductIds(alternatives: Alternative[] | undefined) {
  // We only need INT products ids, those are in the 'International' sections
  const internationalSections =
    alternatives?.filter((a) => a.bezugsKatalog === 'International') ?? []

  return internationalSections.flatMap((ns) => {
    return ns.produkte.produktIds
  })
}

export function getPregnancyAdvises(product: ProductInternationalData) {
  return (
    product.anwWarnHinweise?.hinweise.filter(
      (h) =>
        h.abdaTyp.startsWith(AdviceIntSection.Laktation) ||
        h.abdaTyp.startsWith(AdviceIntSection.Schwangerschaft)
    ) ?? []
  )
}

export function getWarnings(product: ProductInternationalData) {
  return (
    product.anwWarnHinweise?.hinweise.filter((h) =>
      h.abdaTyp.startsWith(AdviceIntSection.Warnhinweis)
    ) ?? []
  )
}

export function getIntolerances(product: ProductInternationalData) {
  return (
    product.anwWarnHinweise?.hinweise.filter(
      (h) => h.hinweisTyp === AdviceIntType.Intoleranzhinweis
    ) ?? []
  )
}

export function getGenericGroupSubHeading(product: ProductInternationalData) {
  const activeSubstances = getActiveSubstancesInt(product)
  if (activeSubstances.length === 0) {
    // Fallback to product name
    return product.acoSearchTerm
  }

  const bezug = product.zusammensetzungInt?.[0].bezuege[0]

  // Wirkstoffnamen, der Wirkstoffmenge + Mengenangabe + Applikationsart (wenn Vorhanden die Mengeneinheit, zb: Stk.)
  const substanceNames: string[] = []
  activeSubstances.forEach((as) => {
    substanceNames.push(
      `${as.bezeichnung} ${as.menge?.toLocaleString('de-DE') ?? ''} ${
        as.einheit ?? ''
      }`
    )
  })

  const nameParts = [
    substanceNames.join(', '),
    product.klassifikationInt?.applikationsarten?.[0].bezeichnung ?? '',
    bezug ? '/' : '',
    bezug ? bezug.einheit : '',
  ]

  return nameParts.join(' ')
}

/**
 * Get all ids of those products, which are duplicates by a specific key.
 * The key is constructed by the description + isoCode. If a key appears
 * more than once, the ids of those duplicates are returned.
 */
export function getIdsOfDuplicateProducts(products: ProductUnion[]) {
  if (products.length <= 1) {
    return []
  }

  const uniqueNames = new Map<string, string[]>()
  const ids: string[] = []

  products.forEach((p) => {
    const name = isProduct(p) ? p.bezeichnung : p.acoSearchTerm
    const isoCode = p.isoCode
    // Create unique name
    const uName = name + isoCode

    // If the unique name already exists in the map,
    // add the id of the duplicate to the list.
    if (uniqueNames.has(uName)) {
      const pIds = uniqueNames.get(uName)
      pIds?.push(p.id)
    } else {
      uniqueNames.set(uName, [p.id])
    }
  })

  uniqueNames.forEach((pIds, _uName) => {
    // Only add ids, which are duplicates.
    if (pIds.length > 1) {
      ids.push(...pIds)
    }
  })

  return ids
}

export function hasHilfsStoffe(zusammensetzungen: Komponente[]) {
  return zusammensetzungen.some((z) => {
    return z.eintraege.some(
      (e) =>
        e.eintragsTyp === CompositionIntType.Hilfsstoff ||
        e.eintragsTyp === CompositionIntType.ErklaerenderText
    )
  })
}
