import { FeatureEnum } from '@austria-codex/types'
import {
  isProduct,
  isProductInternational,
  isSubstance,
} from '@austria-codex/utilities'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { AbDataLogo } from '../../components/AbDataLogo'
import { Anchor } from '../../components/Layout/Anchor'
import { Text } from '../../components/Layout/Typography'
import { ModuleSections } from '../../components/ModuleSections/ModuleSections'
import {
  AnchorMenu,
  AnchorProvider,
  Anchor as AnchorTab,
} from '../../components/Navigation/Anchor'
import { SafetySectionHeader } from '../../components/Section/SafetySectionHeader'
import { ProductInternationalMetaHeader } from '../../features/components/EntityHeader/ProductInternationalMetaHeader'
import { SafetyMetaHeader } from '../../features/components/EntityHeader/SafetyMetaHeader'
import { SubstanceMetaHeader } from '../../features/components/EntityHeader/SubstanceMetaHeader'
import { InteractionsFilter } from '../../features/components/Interactions/InteractionsFilter'
import { InteractionsSection } from '../../features/components/Interactions/InteractionsSection'
import { MultiSelectSection } from '../../features/components/MultiSelect/MultiSelectSection'
import { useBundleFeature } from '../../hooks/useBundleFeature'
import { useShouldRenderEntityType } from '../../hooks/useShouldRenderEntityType'
import { useAppSelector } from '../../hooks/useStoreHooks'
import { entitiesSelector } from '../../store/entities'
import { trySmoothScrollTo } from '../../utilities/scroll'

export function SafetyPage() {
  const location = useLocation()
  const entities = useAppSelector(entitiesSelector)
  const mode = useAppSelector((state) => state.user.mode)
  const hasInteractions = useBundleFeature(FeatureEnum.Interactions)
  const hasDoubleDosing = useBundleFeature(FeatureEnum.DoubleDosing)
  const hasNavigation = useBundleFeature(FeatureEnum.SafetyNavigation)
  const hasNavigationAdverseDrugEffects = useBundleFeature(
    FeatureEnum.SafetyNavigationAdverseDrugEffects
  )
  const hasNavigationInteractionDoubleDosing = useBundleFeature(
    FeatureEnum.SafetyNavigationInteractionDoubleDosingNavigation
  )

  const shouldRenderEntityType = useShouldRenderEntityType(
    FeatureEnum.ProductSafetyHeader,
    FeatureEnum.SubstanceSafetyHeader
  )

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.substring(1)
      trySmoothScrollTo(id)
    }
  }, [location.hash])

  const hasEntities = entities.length > 0

  return (
    <AnchorProvider>
      {hasNavigation && <AnchorMenu />}
      {hasEntities && hasNavigationAdverseDrugEffects && (
        <SafetySectionHeader title="Unerwünschte Arzneimittelwirkungen">
          <AnchorTab name="UAW" hash="uaw" position={0} />
          <ReportingLogoLink />
        </SafetySectionHeader>
      )}
      <ModuleSections
        module="Safety"
        metaRenderer={(entity) =>
          isProduct(entity) ? (
            <SafetyMetaHeader product={entity} />
          ) : isProductInternational(entity) ? (
            <ProductInternationalMetaHeader product={entity} />
          ) : isSubstance(entity) ? (
            <SubstanceMetaHeader substance={entity} />
          ) : null
        }
        shouldRenderEntityType={shouldRenderEntityType}
      />
      {hasEntities && (
        <>
          {hasInteractions && (
            <>
              {hasNavigationInteractionDoubleDosing && (
                <SafetySectionHeader
                  title="Interaktionen (Human)"
                  subTitle={<InteractionsFilter />}
                  disabled={mode === 'international'}
                >
                  {mode === 'national' ? (
                    <AnchorTab
                      name="Interaktionen"
                      hash="interaktionen"
                      position={1}
                    />
                  ) : null}
                  <AbDataLogo />
                </SafetySectionHeader>
              )}
              <InteractionsSection />
            </>
          )}
          {hasDoubleDosing && (
            <>
              {hasNavigationInteractionDoubleDosing && (
                <SafetySectionHeader title="Mehrfachauswahl (Produkte)">
                  <AnchorTab
                    name="Mehrfachauswahl"
                    hash="mehrfachauswahl"
                    position={2}
                  />
                </SafetySectionHeader>
              )}
              <MultiSelectSection />
            </>
          )}
        </>
      )}
    </AnchorProvider>
  )
}

export function ReportingLogoLink() {
  return (
    <Text textAlign="right">
      <Anchor
        href={'//www.basg.gv.at/marktbeobachtung/meldewesen/nebenwirkungen'}
        target="_blank"
      >
        Pharmakovigilanz
        <br />
        (BASG-Meldung)
      </Anchor>
    </Text>
  )
}
