import { FeatureEnum } from '@austria-codex/types'
import * as Sentry from '@sentry/react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { BaseDataProvider } from './components/BaseDataProvider'
import { FeatureRoute } from './components/FeatureRoute/FeatureRoute'
import { ErrorPage } from './pages/ErrorPage'
import { LinkPage } from './pages/LinkPage'
import { MainPage } from './pages/MainPage'
import { RedirectPage } from './pages/RedirectPage'
import { AccountPage } from './pages/Settings/AccountPage'
import { ContentPage } from './pages/Settings/ContentPage'
import { ModulePage } from './pages/Settings/ModulePage'
import { SettingsPage } from './pages/SettingsPage'
import { UnauthorizedPage } from './pages/UnauthorizedPage'
import { Paths } from './utilities/path.constants'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export function App() {
  return (
    <SentryRoutes>
      <Route path={Paths.Msd} element={<Navigate to={Paths.Index} replace />} />
      <Route
        path={Paths.Login}
        element={<Navigate to={Paths.Index} replace />}
      />
      <Route path={Paths.Redirect} element={<RedirectPage />} />
      <Route path={Paths.Unauthorized} element={<UnauthorizedPage />} />
      <Route
        path="/error/document-not-found"
        element={
          <ErrorPage message="Das Dokument konnte nicht gefunden werden." />
        }
      />
      <Route
        path={Paths.Link}
        element={
          <BaseDataProvider>
            <LinkPage />
          </BaseDataProvider>
        }
      />
      <Route
        path={Paths.Settings}
        element={
          <BaseDataProvider>
            <FeatureRoute feature={FeatureEnum.Settings}>
              <SettingsPage />
            </FeatureRoute>
          </BaseDataProvider>
        }
      >
        <Route path="" element={<AccountPage />} />
        <Route path="module" element={<ModulePage />} />
        <Route path=":page" element={<ContentPage />} />
      </Route>
      <Route
        path={`${Paths.Index}*`}
        element={
          <BaseDataProvider>
            <MainPage />
          </BaseDataProvider>
        }
      />
      <Route path="*" element={<ErrorPage />} />
    </SentryRoutes>
  )
}
