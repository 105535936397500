export function toLocalDateTimeString(date: string): string {
  const d = new Date(date)
  const dateStr = d.toLocaleDateString('de-AT', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
  const timeStr = d.toLocaleTimeString('de-AT', {
    hour: 'numeric',
    minute: 'numeric',
  })
  return `${dateStr} ${timeStr}`
}

export function toLocalDateString(date: string): string {
  return new Date(date).toLocaleDateString('de-AT', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}
