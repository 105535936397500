import {
  EntityType,
  type EntityDataUnion,
  type ProductData,
  type ProductInternationalData,
} from '@austria-codex/types'
import { typeFromIdentifier } from '@austria-codex/utilities'
import { createElement } from 'react'
import ProductHumanCombinationIcon from '../../assets/images/entityIcons/human-combi.svg?react'
import ProductHumanMonoIcon from '../../assets/images/entityIcons/human-mono.svg?react'
import SubstanceIcon from '../../assets/images/entityIcons/substance.svg?react'
import ProductVeterinaryCombinationIcon from '../../assets/images/entityIcons/vet-combi.svg?react'
import ProductVeterinaryMonoIcon from '../../assets/images/entityIcons/vet-mono.svg?react'
import { productCompositionType } from './EntityCircleIcon'

type EntityIconProps = {
  entity: EntityDataUnion
}

export function EntityIcon({ entity }: EntityIconProps) {
  const entityType = typeFromIdentifier(entity.id)

  switch (entityType) {
    case EntityType.Product:
    case EntityType.ProductInternational:
      return (
        <ProductIcon
          product={entity as ProductData | ProductInternationalData}
        />
      )

    case EntityType.Substance:
    case EntityType.SubstanceInternational:
      return <SubstanceIcon />

    default:
      return null
  }
}

const productIcons = {
  HUMAN: {
    MONO: ProductHumanMonoIcon,
    COMBINATION: ProductHumanCombinationIcon,
  },
  VET: {
    MONO: ProductVeterinaryMonoIcon,
    COMBINATION: ProductVeterinaryCombinationIcon,
  },
}

type ProductIconProps = {
  product: ProductData | ProductInternationalData
}

export function ProductIcon({ product }: ProductIconProps) {
  const domain = product.istVeterinaer ? 'VET' : 'HUMAN'
  const type = productCompositionType(product)

  const title = `${product.bezeichnung} - ${domain} - ${type}`
  const Icon = productIcons[domain]?.[type]

  return Icon ? createElement(Icon, { title }, null) : null
}
