import { CompositionIntType, type Eintrag } from '@austria-codex/types'
import { isNullOrUndefined } from '@austria-codex/utilities'
import { IntCompositionAgentRow } from './IntCompositionAgentRow'
import { IntCompositionDescriptionRow } from './IntCompositionDescriptionRow'
import { IntCompositionSubstanceRow } from './IntCompositionSubstanceRow'

type TProps = {
  entry: Eintrag
}

export function IntCompositionRow({ entry }: TProps) {
  switch (entry.eintragsTyp) {
    case CompositionIntType.Wirkstoff:
      if (isNullOrUndefined(entry.bezLaufnummer)) {
        return <IntCompositionAgentRow entry={entry} />
      }
      return <IntCompositionSubstanceRow entry={entry} />
    case CompositionIntType.ErklaerenderText:
      return <IntCompositionDescriptionRow entry={entry} />
    default:
      return <IntCompositionSubstanceRow entry={entry} />
  }
}
