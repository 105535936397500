import { Box } from '@mui/material'
import { Section } from '../../components/Section/Section'

export function VigilanceSection() {
  return (
    <Section width="small" heading="Meldung des Verdachts auf Nebenwirkungen">
      <Box>
        Meldung des Verdachts auf Nebenwirkungen Die Meldung des Verdachts auf
        Nebenwirkungen nach der Zulassung ist von großer Wichtigkeit. Sie
        ermöglicht eine kontinuierliche Überwachung des
        Nutzen-Risiko-Verhältnisses des Arzneimittels. Angehörige von
        Gesundheitsberufen sind aufgefordert, jeden Verdachtsfall einer
        Nebenwirkung direkt über das nationale Meldesystem anzuzeigen:
      </Box>
      <Box mt={3}>
        <address>
          Bundesamt für Sicherheit im Gesundheitswesen
          <br />
          Traisengasse 5<br />
          1200 Wien, Österreich
          <br />
          Fax: + 43 (0) 50 555 36207
          <br />
          Website: www.basg.gv.at
        </address>
      </Box>
    </Section>
  )
}
