import type { ReactNode } from 'react'
import { Box, Flex } from '../Layout/Box'
import { Content } from '../Layout/Content'
import { Heading } from '../Layout/Typography'

type SafetySectionHeaderProps = {
  title: string
  subTitle?: string | ReactNode
  disabled?: boolean
  children?: ReactNode
}

export function SafetySectionHeader({
  title,
  subTitle,
  disabled = false,
  children,
}: SafetySectionHeaderProps) {
  return (
    <Box py={3} bg="primary.light">
      <Content width="large">
        <Flex alignItems="center" justifyContent="space-between">
          <div>
            <Heading color={disabled ? 'grey.500' : 'grey.800'} level={2}>
              {title}
            </Heading>
            {subTitle}
          </div>
          {children && children}
        </Flex>
      </Content>
    </Box>
  )
}
