import { css } from '@emotion/react'
import styled from '@emotion/styled'

export type CountButtonProps = {
  active?: boolean
  clickable?: boolean
}

export const CountButton = styled.button<CountButtonProps>`
  border: none;
  background-color: transparent;
  border-radius: 3px;
  font-weight: bold;
  height: 32px;
  width: 32px;

  ${(props) =>
    props.active &&
    css`
      background-color: white;
      box-shadow: 0 0 3px ${props.theme.palette.divider};
    `};

  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
      color: ${props.theme.palette.primary.main};
    `};
`
