import { BundleInterface } from '@austria-codex/types'
import { createContext, useContext } from 'react'

export const BundleContext = createContext<BundleInterface | null>(null)

export function useBundle() {
  const bundle = useContext(BundleContext)
  if (!bundle) {
    throw new Error('useBundle must be used within an BundleContext.Provider')
  }

  return bundle
}
