import { isNullOrUndefined } from '@austria-codex/utilities'
import { createElement } from 'react'
import { useBundle } from '../../contexts/bundle'
import { sections } from '../../features'
import type { ModuleEntityTypes, ModulesTypes } from '../../types/bundle'

export type SectionFeaturesProps = {
  module: ModulesTypes
  entityType: ModuleEntityTypes
  fallbackContent?: React.ReactNode
}

export function SectionFeatures({
  module,
  entityType,
  fallbackContent,
}: SectionFeaturesProps) {
  const bundle = useBundle()
  const features = bundle.sequence[module][entityType]

  const content = features
    .map(([feature, features], index) => {
      if (isNullOrUndefined(sections[feature])) {
        return null
      }

      return createElement(
        sections[feature],
        { features, key: `${module}:${feature}:${index}` },
        null
      )
    })
    // Filter out null values
    .filter(Boolean)

  // If the user does not have access to any features,
  // we render the fallback content if it is provided.
  if (content.length === 0) {
    return fallbackContent ?? null
  }

  return content
}
