export enum Occurrence {
  NotSpecified = 0,
  Yes = 1,
  No = 2,
  Conditional = 3,
}

export const occurrenceDescription = [
  'Keine Angabe',
  'Ja',
  'Nein',
  'Bedingt',
] as const

export enum Modification {
  NotSpecified = 0,
  New = 1,
  Changed = 2,
  Deleted = 3,
}

export enum HinweisTyp {
  Sondengaengigkeit = 21,
  AufloesenSuspendieren = 22,
  KapselOeffnen = 23,
  Zermoersern = 24,
  CrmPotential = 25,
  Lichtempfindlichkeit = 26,
  Einnahme = 27,
}
