import {
  DispensaryTypesEnum,
  ReimbursementBoxTypesEnum,
} from '@austria-codex/types'

export const reimbursementBoxes: ReimbursementBoxTypesEnum[] = [
  ReimbursementBoxTypesEnum.G,
  ReimbursementBoxTypesEnum.Y,
  ReimbursementBoxTypesEnum.R,
  ReimbursementBoxTypesEnum.N,
]

export const dispensaries: DispensaryTypesEnum[] = [
  DispensaryTypesEnum.RP,
  DispensaryTypesEnum.SG,
  DispensaryTypesEnum.OTC,
]
