import { Section, type SectionProps } from './Section'

type DocumentationSectionProps = {
  inherit?: boolean
}

export function DocumentationSection({
  heading,
  inherit = false,
  ...props
}: SectionProps & DocumentationSectionProps) {
  return (
    <Section
      heading={heading}
      addOn={inherit ? '(Originator)' : undefined}
      {...props}
    />
  )
}
