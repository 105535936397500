import numeral from 'numeral'
import { memo } from 'react'

export const PRICE_FORMAT = '$ 0,0.00'

numeral.register('locale', 'de', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'Tsd',
    million: 'Mio',
    billion: 'Bio',
    trillion: 'Tri',
  },
  ordinal: function (number) {
    return number + '.'
  },
  currency: {
    symbol: '€',
  },
})

numeral.locale('de')

type NumeralProps = {
  input: number
  format?: string
}

export const Numeral = memo(({ input, format }: NumeralProps) => (
  <>{numeral(input).format(format)}</>
))
