export function entityIdentifiersToEntityPartials(identifiers: string[]) {
  return identifiers.map((identifier) => ({
    id: identifier,
  }))
}

export const isVeterinaryToKey = (isVeterinary: boolean) =>
  isVeterinary ? 'veterinary' : 'human'

export const isMonoPreparationToKey = (isMonoPreparation: boolean) =>
  isMonoPreparation ? 'monoPreparation' : 'combinationPreparation'

export const isAvailableToKey = (isAvailable: boolean) =>
  isAvailable ? 'available' : 'notAvailable'
