import { AnwWarnHinweise, Haltbarkeit } from '@austria-codex/types'
import { List, ListItem } from '../../../components/Layout/List'
import { Section } from '../../../components/Section/Section'
import { useProductIntData } from '../../../contexts/entity'
import {
  getStorageLife,
  getStoragePlace,
} from '../../../helpers/product-international.helper'

export function IntStorageSection() {
  const product = useProductIntData()

  // Only show section if DE product
  if (product.isoCode !== 'de') {
    return null
  }

  const haltbarkeit = getStorageLife(product)
  const lagerungsOrt = getStoragePlace(haltbarkeit)
  const warnHinweise = product.anwWarnHinweise

  if (hideSection(haltbarkeit, lagerungsOrt, warnHinweise)) {
    return null
  }

  return (
    <Section width="small" heading="Lagerung">
      <List>
        {lagerungsOrt && <ListItem>Lagerung: {lagerungsOrt}</ListItem>}
        {haltbarkeit && (
          <ListItem>
            Haltbarkeit: {haltbarkeit.haltbarkeitsDauer}{' '}
            {haltbarkeit.haltbarkeitsEinheit}
          </ListItem>
        )}
        {warnHinweise?.lichtSchutz && <ListItem>Vor Licht schützen!</ListItem>}
        {warnHinweise?.feuchtSchutz && (
          <ListItem>Vor Feuchtigkeit schützen!</ListItem>
        )}
        {warnHinweise?.sofortVerbrauch && (
          <ListItem>Nach Anbruch/Zubereitung sofort verbrauchen!</ListItem>
        )}
        {warnHinweise?.einmaligeAnwendung && (
          <ListItem>
            Zur einmaligen Anwendung nach Anbruch/Zubereitung!
          </ListItem>
        )}
      </List>
    </Section>
  )
}

function hideSection(
  haltbarkeit: Haltbarkeit | undefined,
  lagerungsOrt: string | undefined,
  warnHinweise: AnwWarnHinweise | undefined
) {
  if (!haltbarkeit && !lagerungsOrt && !warnHinweise) {
    return true
  }

  if (
    !warnHinweise?.lichtSchutz &&
    !warnHinweise?.feuchtSchutz &&
    !warnHinweise?.sofortVerbrauch &&
    !warnHinweise?.einmaligeAnwendung
  ) {
    return true
  }

  return false
}
