type TCountryFlagProps = {
  countryName?: string
  isoCode: string
}

export function CountryFlag({ isoCode, countryName }: TCountryFlagProps) {
  return (
    <img
      loading="lazy"
      width="20"
      srcSet={`https://flagcdn.com/w40/${isoCode}.png 2x`}
      src={`https://flagcdn.com/w20/${isoCode}.png`}
      alt={countryName || isoCode.toUpperCase()}
    />
  )
}
