import { ReactNode } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Box } from './Box'
import { OffLabel } from '../OffLabel/OffLabel'

type ListProps = {
  singleChildHasBullet?: boolean
}

export const List = styled.ul<ListProps>`
  margin: 0;
  padding: 0 0 0 25px;

  ${(props) =>
    props.singleChildHasBullet &&
    css`
      & > li:only-child {
        list-style-type: none;
        margin-left: -40px;
      }
    `}
`

type ListItemProps = {
  offLabel?: boolean
  children: ReactNode
}

export function ListItem({ children, offLabel }: ListItemProps) {
  return (
    <Box as="li" pl="10px">
      <OffLabel
        offLabel={offLabel}
        ml="-44px"
        pl="44px"
        mr="-1px"
        pr={5}
        py={1}
        my={1}
      >
        {children}
      </OffLabel>
    </Box>
  )
}
