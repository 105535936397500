import { type ProductsFilter } from '@austria-codex/types'
import { REFERENCES_QUERY } from '../../api/references'
import { REFERENCES_TABLE_CONFIG } from '../../common/tableConfig/references'
import { Box } from '../../components/Layout/Box'
import { ProductsDataSourceTable } from '../../components/PaginatedDataTable/ProductsDataSourceTable'
import { Section } from '../../components/Section/Section'
import { useProductData } from '../../contexts/entity'

const heading = 'Referenzprodukte'

export function ReferenceSection() {
  const product = useProductData()

  const filter: ProductsFilter = {
    references: product.id,
  }

  return product.referenz?.hatReferenzprodukte ? (
    <Section width="small" heading={heading}>
      <Box mt={3}>
        <ProductsDataSourceTable
          query={REFERENCES_QUERY}
          config={REFERENCES_TABLE_CONFIG}
          filter={filter}
          name="alternatives:references"
          heading={heading}
        />
      </Box>
    </Section>
  ) : null
}
