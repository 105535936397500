import { AlignmentType, HeadingVariantType, TextElementType } from '../types'
import { TextElement } from './TextElement'

type HeadingProps = {
  children: TextElementType[]
  variant: HeadingVariantType
  alignment?: AlignmentType
}

export function Heading({ children, variant, alignment }: HeadingProps) {
  return (
    <>
      {children.map((child, index) => (
        <TextElement
          key={index}
          child={child}
          variant={variant}
          alignment={alignment}
        />
      ))}
    </>
  )
}
