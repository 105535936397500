import {
  RoutesOfAdministrationTypes,
  RoutesOfAdministrationTypesEnum,
} from '@austria-codex/types'

export const routeOfAdministrationLabels: {
  [K in RoutesOfAdministrationTypes]: string
} = {
  [RoutesOfAdministrationTypesEnum.A]: 'Extrakorporal',
  [RoutesOfAdministrationTypesEnum.B]: 'Lokal',
  [RoutesOfAdministrationTypesEnum.C]: 'Pulmonal',
  [RoutesOfAdministrationTypesEnum.D]: 'Oral',
  [RoutesOfAdministrationTypesEnum.E]: 'Parenteral',
  [RoutesOfAdministrationTypesEnum.F]: 'Rektal',
  [RoutesOfAdministrationTypesEnum.G]: 'Urogenital',
}

export const getRouteOfAdministrationLabel = (
  routeOfAdministration: RoutesOfAdministrationTypes
) => routeOfAdministrationLabels[routeOfAdministration]

type RowConfig = {
  code: RoutesOfAdministrationTypesEnum
  title: string
}

export const roaMatrixRowConfig: RowConfig[] = [
  {
    code: RoutesOfAdministrationTypesEnum.A,
    title: 'Extrakorporal',
  },
  {
    code: RoutesOfAdministrationTypesEnum.B,
    title: 'Lokal',
  },
  {
    code: RoutesOfAdministrationTypesEnum.C,
    title: 'Pulmonal',
  },
  {
    code: RoutesOfAdministrationTypesEnum.D,
    title: 'Oral',
  },
  {
    code: RoutesOfAdministrationTypesEnum.E,
    title: 'Parenteral',
  },
  {
    code: RoutesOfAdministrationTypesEnum.F,
    title: 'Rektal',
  },
  {
    code: RoutesOfAdministrationTypesEnum.G,
    title: 'Urogenital',
  },
]
