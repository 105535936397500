import {
  CompositionTypesEnum,
  EntityDataUnion,
  EntityType,
  ProductCompositionDescriptionEntryData,
  ProductCompositionEntryData,
  ProductCompositionSubstanceEntryData,
  ProductData,
  ProductInternationalData,
  ProductUnion,
  ProductVerlaufData,
  SubstanceData,
  SubstanceInternationalData,
} from '@austria-codex/types'

export function typeFromIdentifier(identifier: string): EntityType {
  if (identifier.length < 3) {
    return EntityType.Unknown
  }

  const lastTwoCharsAsHex = identifier.substring(identifier.length - 2)
  const type = parseInt(lastTwoCharsAsHex, 16)

  return isNaN(type) ? EntityType.Unknown : (type as EntityType)
}

export function separateEntityIdentifiers(identifiers: string[]) {
  const productIdentifiers: string[] = []
  const productInternationalIdentifiers: string[] = []
  const productVerlaufIdentifiers: string[] = []
  const substanceIdentifiers: string[] = []
  const substanceInternationalIdentifiers: string[] = []

  identifiers.forEach((identifier) => {
    const type = typeFromIdentifier(identifier)

    if (type === EntityType.Product) {
      if (!productIdentifiers.includes(identifier)) {
        productIdentifiers.push(identifier)
      }
    }

    if (type === EntityType.ProductInternational) {
      if (!productInternationalIdentifiers.includes(identifier)) {
        productInternationalIdentifiers.push(identifier)
      }
    }

    if (type === EntityType.ProductVerlauf) {
      if (!productVerlaufIdentifiers.includes(identifier)) {
        productVerlaufIdentifiers.push(identifier)
      }
    }

    if (type === EntityType.Substance) {
      if (!substanceIdentifiers.includes(identifier)) {
        substanceIdentifiers.push(identifier)
      }
    }

    if (type === EntityType.SubstanceInternational) {
      if (!substanceInternationalIdentifiers.includes(identifier)) {
        substanceInternationalIdentifiers.push(identifier)
      }
    }
  })

  return {
    productIdentifiers,
    productInternationalIdentifiers,
    productVerlaufIdentifiers,
    substanceIdentifiers,
    substanceInternationalIdentifiers,
  }
}

export function separateEntities(entities: EntityDataUnion[]) {
  const products: ProductData[] = []
  const productsInternational: ProductInternationalData[] = []
  const productsVerlauf: ProductVerlaufData[] = []
  const substances: SubstanceData[] = []
  const substancesInternational: SubstanceInternationalData[] = []

  entities.forEach((entity) => {
    const type = typeFromIdentifier(entity.id)

    if (type === EntityType.Product) {
      if (!products.find(({ id }) => id === entity.id)) {
        products.push(entity as ProductData)
      }
    }

    if (type === EntityType.ProductInternational) {
      if (!productsInternational.find(({ id }) => id === entity.id)) {
        productsInternational.push(entity as ProductInternationalData)
      }
    }

    if (type === EntityType.ProductVerlauf) {
      if (!productsVerlauf.find(({ id }) => id === entity.id)) {
        productsVerlauf.push(entity as ProductVerlaufData)
      }
    }

    if (type === EntityType.Substance) {
      if (!substances.find(({ id }) => id === entity.id)) {
        substances.push(entity as SubstanceData)
      }
    }

    if (type === EntityType.SubstanceInternational) {
      if (!substancesInternational.find(({ id }) => id === entity.id)) {
        substancesInternational.push(entity as SubstanceInternationalData)
      }
    }
  })

  return {
    products,
    substances,
  }
}

export const isProductIdentifier = (identifier: string): boolean =>
  typeFromIdentifier(identifier) === EntityType.Product

export const isProduct = (entity: EntityDataUnion): entity is ProductData =>
  isProductIdentifier(entity.id)

export const isVeterinaryProduct = (
  entity: EntityDataUnion
): entity is ProductUnion =>
  (isProduct(entity) ||
    isProductInternational(entity) ||
    isProductVerlauf(entity)) &&
  Boolean(entity.istVeterinaer)

export const isVeterinarySubstance = (
  entity: EntityDataUnion
): entity is SubstanceData =>
  isSubstance(entity) && Boolean(entity.istVeterinaer)

export const isProductInternationalIdentifier = (identifier: string): boolean =>
  typeFromIdentifier(identifier) === EntityType.ProductInternational

export const isProductVerlaufIdentifier = (identifier: string): boolean =>
  typeFromIdentifier(identifier) === EntityType.ProductVerlauf

export const isProductInternational = (
  entity: EntityDataUnion
): entity is ProductInternationalData =>
  isProductInternationalIdentifier(entity.id)

export const isProductVerlauf = (
  entity: EntityDataUnion
): entity is ProductVerlaufData => isProductVerlaufIdentifier(entity.id)

export function isFromCountry(
  entity: ProductInternationalData,
  isoCode: string
) {
  return entity.isoCode === isoCode
}

export const isSubstanceInternationalIdentifier = (
  identifier: string
): boolean =>
  typeFromIdentifier(identifier) === EntityType.SubstanceInternational

export const isSubstanceInternational = (
  entity: EntityDataUnion
): entity is SubstanceInternationalData =>
  isSubstanceInternationalIdentifier(entity.id)

export const isSubstanceIdentifier = (identifier: string): boolean =>
  typeFromIdentifier(identifier) === EntityType.Substance

export const isSubstance = (entity: EntityDataUnion): entity is SubstanceData =>
  isSubstanceIdentifier(entity.id)

export const isCompositionSubstance = (
  entry: ProductCompositionEntryData
): entry is ProductCompositionSubstanceEntryData =>
  (entry as ProductCompositionDescriptionEntryData).bezeichnung === undefined

export const isCompositionAgent = (
  entry: ProductCompositionEntryData
): entry is ProductCompositionSubstanceEntryData =>
  !isCompositionDescription(entry) &&
  entry.eintragsTyp === CompositionTypesEnum.Wirkstoff

export const isCompositionDescription = (
  entry: ProductCompositionEntryData
): entry is ProductCompositionDescriptionEntryData =>
  (entry as ProductCompositionDescriptionEntryData).bezeichnung !== undefined

export const entitiesIncludeProducts = (entities: EntityDataUnion[]): boolean =>
  entities.some((entity) => isProduct(entity))

export const entitiesOnlyContainsSingleSubstance = (
  entities: EntityDataUnion[]
): boolean => entities.length === 1 && isSubstance(entities[0])

export const isEmptyEntity = (entity: EntityDataUnion) =>
  entity.bezeichnung === undefined
