import { EntityDomainHitsData, Maybe } from '@austria-codex/types'
import { Box } from '@mui/material'
import { ReactNode } from 'react'
import { useCollapse } from '../../hooks/useCollapse'
import { Flex } from '../Layout/Box'
import { Text } from '../Layout/Typography'
import { AtcCodeHits } from './AtcCodeHits'
import { CodeText } from './CodeText'

type CodeBlockProps = {
  code: string
  title: string
  caption: Maybe<string>
  hits?: EntityDomainHitsData
  section: string
  group: string
  identifier: string
  children: (onToggle: (open?: boolean) => void) => ReactNode
  hasToggle: boolean
}

export function AtcCodeBlock({
  children,
  code,
  title,
  caption,
  section,
  group,
  identifier,
  hasToggle,
}: CodeBlockProps) {
  const [collapse, onToggle] = useCollapse({
    section,
    group,
    identifier,
  })

  return (
    <Box sx={{ '&:hover': { cursor: hasToggle ? 'pointer' : 'default' } }}>
      <Flex onClick={() => hasToggle && onToggle()}>
        <Flex flex={1} mr={3}>
          <Text color={hasToggle ? 'primary.main' : undefined}>
            <Flex>
              {title} (<AtcCodeHits atcCode={code} />)
            </Flex>
            {caption && (
              <div>
                <Text fontStyle="italic" fontSize={0} color="#606060">
                  {caption}
                </Text>
              </div>
            )}
          </Text>
        </Flex>
        <Flex width={72}>
          <CodeText code={code} />
        </Flex>
      </Flex>
      {collapse && <>{children(onToggle)}</>}
    </Box>
  )
}
