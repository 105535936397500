import { css, useTheme } from '@emotion/react'
import { Box } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { useAppDispatch } from '../hooks/useStoreHooks'
import { setBackUrl } from '../store/settings.store'
import { Paths } from '../utilities/path.constants'
import { toFullPath } from '../utilities/url'
import { Content } from './Layout/Content'
import { Text } from './Layout/Typography'

export function OtherCountryHintBanner() {
  const theme = useTheme()
  const location = useLocation()
  const dispatch = useAppDispatch()

  return (
    <Box sx={{ pt: 1, pb: 2, background: theme.palette.info.light }}>
      <Content
        width="large"
        css={css`
          text-align: center;
        `}
      >
        <Text fontWeight={700} fontSize={12}>
          Hinweis:
        </Text>{' '}
        <Text fontSize={12}>
          Bitte beachten Sie, dass dieses Arzneimittel vielleicht in anderen
          Ländern verfügbar sein könnte.{' '}
          <Link
            css={css`
              color: ${theme.palette.grey[800]};
              font-weight: 700;

              &:hover {
                color: ${theme.palette.grey[900]};
              }
            `}
            to={`${Paths.Settings}/module`}
            onClick={() => dispatch(setBackUrl(toFullPath(location)))}
          >
            Ländereinstellungen ändern
          </Link>
        </Text>
      </Content>
    </Box>
  )
}
