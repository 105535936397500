import { ProductSummaryData } from './product.data'
import { SubstanceSummaryData } from './substance.data'

// Schweregrad - KHIX Docs p. 113
export enum EInteractionSeverity {
  NoStatementPossible = 0,
  NoInteraction = 10,
  ProductSpecificWarning = 20,
  Minor = 30,
  Moderate = 40,
  Severe = 50,
  Contraindicated = 60,
}

// Quellenbewertung - KHIX Docs p. 114
export enum EEvaluationDescription {
  Unknown = 10,
  NotSufficient = 20,
  Weak = 30,
  Enough = 40,
  Good = 50,
}

// Interaktionshäufigkeit - KHIX Docs p. 113
export enum EInteractionOccurence {
  VeryOften = 1,
  Often = 2,
  Sometimes = 3,
  Rarely = 4,
  VeryRarely = 5,
  Unknown = 6,
}

// Pharm Plausibilität - KHIX Docs p. 114
export enum EMechanism {
  Unknown = 10,
  Plausible = 20,
  Proven = 30,
}

// Interaktionsrichtung - KHIX Docs p. 113
export enum EInteractionDirection {
  Undirected = 0,
  Unidirectional = 1,
  Bidirectional = 2,
}

export enum EInteractionDescription {
  Literatur = 9,
  PharmakologischerEffekt = 40,
  PharmakologischerEffektKurz = 50,
  WichtigeHinweise = 100,
  Massnahmen = 110,
  Mechanismus = 140,
  MesswerteFuerMonitoring = 230,
  BeginnDesMonitorings = 240,
  DauerDesMonitorings = 250,
  MonitoringNachAbsetzen = 260,
  WeitereRisikofaktoren = 270,
  ZeitversetzteEinnahmeAnwendung = 280,
  DosisBzwIntervallanpassung = 290,
  WeitereEmpfehlungen = 300,
  HinweiseBeiKurzzeittherapie = 310,
  MoeglicheSymptome = 330,
  Datengrundlage = 340,
  Risikofaktoren = 350,
  Nebenwirkungen = 360,
  ApplikationswegeLinks = 370,
  ApplikationswegeRechts = 380,
  Individualselektoren = 390,
}

export interface InteractionGroupData {
  bezeichnung: string
  stoffe: SubstanceSummaryData[]
  produkte: ProductSummaryData[]
}

export interface InteractionCharacteristicData {
  id: string
  typ: number
}

export interface InteractionDescriptionData {
  id: number
  typ: number
  beschreibung: string
}

export interface InteractionData {
  id: string
  gruppe?: number
  revisionsDatum: string
  schwereGrad: EInteractionSeverity
  schwereGradLaie?: number
  istAllgemein?: boolean
  istSpezifisch?: boolean
  haeufigkeit?: EInteractionOccurence
  pharmPlausibilitaet?: EMechanism
  interaktionsRichtung?: EInteractionDirection
  beschreibungen: InteractionDescriptionData[]
  quellenBewertung?: EEvaluationDescription
  bezeichnung: string
  gruppeLinks: InteractionGroupData
  gruppeRechts: InteractionGroupData
  merkmale: InteractionCharacteristicData[]
}

export interface LiteratureData {
  datasource: string
  literature: string
}
export interface LiteratureDataV3 {
  datasource: string
  content: string
}

export interface InteractionEntityPairData {
  id: string
  left: string
  right: string
}
