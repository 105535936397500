import { TCountryMode } from '@austria-codex/types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { ChangeEvent, ReactNode, SyntheticEvent, useState } from 'react'
import EarthIcon from '../../assets/images/earth.svg?react'
import { isoCodeToCountryMapping } from '../../common/mappings'
import { AtLabel } from '../../components/AtLabel'
import { CountryFlag } from '../../components/CountryFlags'
import { IntLabel } from '../../components/IntLabel'
import {
  getAlpha3ByAlpha2,
  getCountryByIsoCode,
} from '../../helpers/country.helper'
import { useToggleCountry } from '../../hooks/useFilterUpdate'
import { useAppDispatch, useAppSelector } from '../../hooks/useStoreHooks'
import { removeAllEntities } from '../../store/entities'
import { setBackUrl } from '../../store/settings.store'
import { addCountries, resetCountries, setMode } from '../../store/user.store'

const countries = Object.entries(isoCodeToCountryMapping)
const countryIsoCodes = Object.keys(isoCodeToCountryMapping)
const maxSelectedCountriesCount = countryIsoCodes.length

export function ModulePage() {
  const dispatch = useAppDispatch()
  const mode = useAppSelector((state) => state.user.mode)
  const [openSection, setOpenSection] = useState(true)
  const [openCountries, setOpenCountries] = useState(mode === 'international')

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    dispatch(setMode(event.target.value as TCountryMode))
    dispatch(setBackUrl(null))
    dispatch(removeAllEntities())
  }

  return (
    <Box sx={{ p: 4 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 700,
            color: 'grey.800',
            cursor: 'pointer',
          }}
          onClick={() => setOpenSection(!openSection)}
        >
          Modul International
        </Typography>
        <IconButton onClick={() => setOpenSection(!openSection)}>
          <ExpandMoreIcon />
        </IconButton>
      </Box>
      {openSection && (
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 3 }}>
            <FormLabel id="mode-setting">
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: 700,
                  color: 'grey.800',
                }}
              >
                Sucheinstellungen
              </Typography>
            </FormLabel>
            <FormControl>
              <RadioGroup
                aria-labelledby="mode-setting"
                name="mode"
                value={mode}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="national"
                  control={<Radio />}
                  label={<AtLabel />}
                />
                <FormControlLabel
                  value="international"
                  control={<Radio />}
                  label={<IntLabel />}
                />
              </RadioGroup>
            </FormControl>
            {mode === 'international' && (
              <Box
                component={'span'}
                sx={{
                  mt: 3,
                  py: 1.5,
                  px: 2,
                  border: '1px solid blue',
                  borderColor: 'info.main',
                  borderRadius: '5px',
                  backgroundColor: 'info.light',
                  fontSize: '0.75rem',
                  alignSelf: 'flex-start',
                }}
              >
                <strong>Hinweis:</strong> Die Suche in den Ländern Österreich
                und Deutschland ist standardmäßig aktiviert
              </Box>
            )}
          </Box>
          {mode === 'international' && (
            <>
              <Box mt={3}>
                <Typography
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 700,
                    color: 'grey.800',
                  }}
                >
                  Länderauswahl
                </Typography>
              </Box>
              <Box sx={{ mt: 2, width: '100%' }}>
                <CountrySelect />
              </Box>
              <Box
                sx={{
                  mt: 4,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 700,
                    color: 'primary.main',
                  }}
                >
                  Ausgewählte und verfügbare Länder
                </Typography>
                <Button
                  sx={{
                    border: 0,
                    color: 'primary.main',
                    cursor: 'pointer',
                    fontSize: '0.75rem',
                    fontWeight: 700,
                    textTransform: 'none',
                  }}
                  onClick={() => setOpenCountries(!openCountries)}
                >
                  {openCountries ? 'Weniger' : 'Mehr'} anzeigen{' '}
                  <ExpandMoreIcon
                    sx={{ transform: openCountries ? 'rotate(180deg)' : '' }}
                  />
                </Button>
              </Box>
              {openCountries && (
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container mt={0} spacing={2}>
                    <Grid item pt={0} md={6} lg={3}>
                      <AllCountriesCheckbox />
                    </Grid>
                  </Grid>
                  <Grid container mt={0} spacing={2}>
                    <Grid item md={6} lg={3}>
                      <CountryList page={0} />
                    </Grid>
                    <Grid item md={6} lg={3}>
                      <CountryList page={1} />
                    </Grid>
                    <Grid item md={6} lg={3}>
                      <CountryList page={2} />
                    </Grid>
                    <Grid item md={6} lg={3}>
                      <CountryList page={3} />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  )
}

const countriesPerPage = 20

type TCountryListProps = {
  page: number
}

function CountryList({ page }: TCountryListProps) {
  const toggleCountry = useToggleCountry()
  const selectedCountries = useAppSelector(
    (state) => state.user.selectedCountries
  )

  const indexFrom = page * countriesPerPage
  const partialCountries = countries.slice(
    indexFrom,
    indexFrom + countriesPerPage
  )

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    toggleCountry(event.target.value)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      {partialCountries.map((c) => {
        const isSelected = selectedCountries.includes(c[0])
        const checkboxId = `${c[0]}-check`

        return (
          <CountryCheckbox
            key={c[0]}
            isoCode={c[0]}
            forId={checkboxId}
            isSelected={isSelected}
          >
            <Checkbox
              id={checkboxId}
              sx={{ p: 0 }}
              checked={isSelected}
              onChange={handleChange}
              value={c[0]}
              disabled={
                c[0] === 'at' ||
                c[0] === 'de' ||
                (!isSelected &&
                  selectedCountries.length >= maxSelectedCountriesCount)
              }
            />
          </CountryCheckbox>
        )
      })}
    </Box>
  )
}

function CountrySelect() {
  const [value, setValue] = useState<[string, string] | null>(null)
  const selectedCountries = useAppSelector(
    (state) => state.user.selectedCountries
  )
  const addCountry = useToggleCountry()

  function handleChange(
    _event: SyntheticEvent,
    value: [string, string] | null
  ) {
    if (!value) return

    addCountry(value[0])
    setValue(null)
  }

  return (
    <Autocomplete
      id="country-select"
      value={value}
      options={countries}
      blurOnSelect
      disablePortal
      fullWidth
      disabled={selectedCountries.length >= maxSelectedCountriesCount}
      getOptionLabel={(option) => option[1]}
      getOptionDisabled={(option) => selectedCountries.includes(option[0])}
      isOptionEqualToValue={(option, value) => {
        return option[0] === value[0] && option[1] === value[1]
      }}
      onChange={handleChange}
      popupIcon={
        <Box sx={{ color: 'primary.main', fontSize: '0.75rem' }}>
          {selectedCountries.length} von {maxSelectedCountriesCount} Ländern
          ausgewählt
        </Box>
      }
      componentsProps={{
        popupIndicator: { disabled: true, sx: { transform: 'none' } },
      }}
      noOptionsText="Keine Optionen"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Land eintippen und auswählen"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option[0]}.png 2x`}
            src={`https://flagcdn.com/w20/${option[0]}.png`}
            alt=""
          />
          {option[1]} ({getAlpha3ByAlpha2(option[0]).toUpperCase()})
        </Box>
      )}
    />
  )
}

function AllCountriesCheckbox() {
  const dispatch = useAppDispatch()
  const selectedCountries = useAppSelector(
    (state) => state.user.selectedCountries
  )

  const isSelected = selectedCountries.length === maxSelectedCountriesCount

  function handleChange() {
    if (selectedCountries.length < maxSelectedCountriesCount) {
      dispatch(addCountries(countryIsoCodes))
    }

    if (selectedCountries.length === maxSelectedCountriesCount) {
      dispatch(resetCountries())
    }
  }

  return (
    <Box
      component={'label'}
      htmlFor="all-countries-check"
      sx={{
        alignItems: 'center',
        borderRadius: '4px',
        backgroundColor: isSelected ? 'primary.light' : 'white',
        display: 'flex',
        height: '28px',
        gap: '0.5rem',
        px: '0.25rem',
      }}
    >
      <Checkbox
        id="all-countries-check"
        sx={{ p: 0 }}
        checked={isSelected}
        onChange={handleChange}
        value="all"
      />
      <Box component="span" sx={{ display: 'flex' }}>
        <EarthIcon />
      </Box>
      <Box component="span">Alle Länder auswählen</Box>
    </Box>
  )
}

type TCountryCheckboxProps = {
  children: ReactNode
  isoCode: string
  forId: string
  readonly?: boolean
  isSelected?: boolean
}

function CountryCheckbox({
  children,
  isoCode,
  forId,
  readonly = false,
  isSelected = false,
}: TCountryCheckboxProps) {
  const countryName = getCountryByIsoCode(isoCode)

  return (
    <Box
      component={'label'}
      htmlFor={forId}
      sx={{
        alignItems: 'center',
        borderRadius: '4px',
        backgroundColor: readonly
          ? 'grey.200'
          : isSelected
            ? 'primary.light'
            : 'white',
        display: 'flex',
        height: '28px',
        gap: '0.5rem',
        px: '0.25rem',
      }}
    >
      {children}
      <Box component="span" sx={{ display: 'flex' }}>
        <CountryFlag isoCode={isoCode} countryName={countryName} />
      </Box>
      <Box component="span">{countryName}</Box>
    </Box>
  )
}
