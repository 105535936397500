export const INTERACTION_QUERY_FRAGMENT = `
  fragment InteractionGroupFragment on InteractionGroupType {
    bezeichnung
    produkte {
      id
      bezeichnung
    }
    stoffe {
      id
      bezeichnung
    }
  }
`

export const INTERACTION_QUERY_PARTIAL = `
  interaction(identifiers: $entityIdentifiers) {
    id
    gruppe
    schwereGrad
    schwereGradLaie
    istAllgemein
    istSpezifisch
    haeufigkeit
    pharmPlausibilitaet
    interaktionsRichtung
    bezeichnung
    quellenBewertung
    merkmale {
      id
      typ
    }
    beschreibungen {
      typ
      beschreibung
    }
    gruppeLinks {
      ...InteractionGroupFragment
    }
    gruppeRechts {
      ...InteractionGroupFragment
    }
    revisionsDatum
  }
`
