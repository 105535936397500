import { Flex } from '../Layout/Box'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'

export function LoadingScreen() {
  return (
    <Flex justifyContent="center" alignItems="center" height="100%">
      <LoadingSpinner />
    </Flex>
  )
}
