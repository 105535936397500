import styled from '@emotion/styled'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import type { ReactNode } from 'react'
import type { StylingProps } from '../../types/components'
import { HighlightSection } from '../Highlight/SectionHighlight'
import { Content } from '../Layout/Content'
import { Text } from '../Layout/Typography'
import { OffLabel } from '../OffLabel/OffLabel'

export type SectionProps = {
  children: ReactNode
  heading?: string | ReactNode
  headingLogo?: ReactNode
  addOn?: string
  width?: 'large' | 'medium' | 'small'
  id?: string
  offLabel?: boolean
  highlight?: boolean
}

export function Section({
  heading,
  headingLogo,
  addOn,
  children,
  width,
  id,
  offLabel,
  highlight,
  ...props
}: SectionProps & StylingProps) {
  return (
    <Container id={id} {...props}>
      <HighlightSection highlight={highlight}>
        <Box py={4}>
          <Content width={width}>
            <OffLabel pl={2} ml={-2} py={2} my={-2} pr={2} offLabel={offLabel}>
              {heading && (
                <Box mb={0.5}>
                  <Typography
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    variant="h4"
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {heading}
                      {addOn && (
                        <>
                          {' '}
                          <Text fontStyle="italic">{addOn}</Text>
                        </>
                      )}
                    </Box>
                    {headingLogo && <Box>{headingLogo}</Box>}
                  </Typography>
                </Box>
              )}
              {children}
            </OffLabel>
          </Content>
        </Box>
      </HighlightSection>
    </Container>
  )
}

export const Container = styled.section`
  overflow: hidden;

  & + & {
    border-top: 1px solid ${(props) => props.theme.palette.divider};
  }

  &:nth-of-type(even) {
    background: ${(props) => props.theme.palette.grey[50]};
  }
`
