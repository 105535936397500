import {
  SubstanceApplicationRouteOfAdministrationHitsData as ROAHitsData,
  RoutesOfAdministrationTypesEnum,
  SubstanceApplicationCountryHitsData,
} from '@austria-codex/types'
import {
  ROAHitsInt,
  TransformedROAHitsData,
  TransformedROAHitsInt,
} from '../types/common.types'

export function getROAHits(
  applications: SubstanceApplicationCountryHitsData[] | undefined,
  countries: string[],
  prepType: 'mono' | 'kombination',
  domainType: 'human' | 'veterinaer'
): ROAHitsData | null {
  const sections =
    applications?.filter((a) => countries.includes(a.isoCode)) ?? []

  // No section found by given countries
  if (sections.length === 0) {
    return null
  }

  // If only one section found, return the ROA of that section
  if (sections.length === 1) {
    return sections[0][prepType]?.[domainType] ?? null
  }

  // If more than 1 section found,
  // we need to sum up each ROA.
  const result: ROAHitsData = {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    F: 0,
    G: 0,
  }

  let hasAtLeasOneRoaHits = false

  sections.forEach((s) => {
    // Get the routes of administration hits object
    const roaHits = s[prepType]?.[domainType]

    // If we have an object, loop over each key/value
    // and if the value is not 'null' or 0, add it
    // to the right key in the result object.
    if (roaHits) {
      hasAtLeasOneRoaHits = true
      const entries = Object.entries(roaHits) as Array<
        // prettier-ignore
        [keyof ROAHitsData, number | null]
      >
      entries.forEach(([roa, count]) => {
        if (count) {
          if (result[roa] !== null) {
            result[roa] += count
          }
        }
      })
    }
  })

  // We only return the result, if at least
  // one roa hits object was found.
  if (hasAtLeasOneRoaHits) {
    return result
  }

  return null
}

export function transformROAHits({ at, de, int }: ROAHitsInt) {
  const result: TransformedROAHitsInt = {
    A: { at: 0, de: 0, int: 0 },
    B: { at: 0, de: 0, int: 0 },
    C: { at: 0, de: 0, int: 0 },
    D: { at: 0, de: 0, int: 0 },
    E: { at: 0, de: 0, int: 0 },
    F: { at: 0, de: 0, int: 0 },
    G: { at: 0, de: 0, int: 0 },
  }

  const entries = Object.entries(result) as Array<
    [RoutesOfAdministrationTypesEnum, TransformedROAHitsData]
  >

  // Sum up each count for each route of adminstration
  for (const [roa] of entries) {
    if (at) {
      const count = at[roa] ?? 0
      result[roa].at += count
    }

    if (de) {
      const count = de[roa] ?? 0
      result[roa].de += count
    }

    if (int) {
      const count = int[roa] ?? 0
      result[roa].int += count
    }
  }

  // Loop over the results object again, and delete all
  // entries, where all counts are 0 and leave those
  // where at least one entrie has a count > 0.
  for (const [roa] of entries) {
    const r = result[roa]
    if (r.at === 0 && r.de === 0 && r.int === 0) {
      delete result[roa]
    }
  }

  return result
}
