import styled from '@emotion/styled'
import {
  Component,
  createContext,
  PureComponent,
  ReactNode,
  useContext,
} from 'react'
import { Link, useLocation } from 'react-router-dom'

interface AnchorItem {
  name: string
  hash: string
}

interface AnchorMenuContextCallbacks {
  addAnchor: (name: string, key: string, position: number) => void
  removeAnchor: (key: string) => void
}

interface AnchorMenuContextValue extends AnchorMenuContextCallbacks {
  anchors: AnchorItem[]
}

const AnchorMenuContext = createContext<AnchorMenuContextValue | null>(null)

interface AnchorProviderProps {
  children?: ReactNode
}

export class AnchorProvider extends Component<
  AnchorProviderProps,
  AnchorMenuContextValue
> {
  addAnchor = (name: string, hash: string, position: number) => {
    this.setState((state) => {
      const nextAnchors = [...state.anchors]
      nextAnchors[position] = { name, hash }

      return {
        anchors: nextAnchors,
      }
    })
  }

  removeAnchor = (hash: string) => {
    this.setState((state) => ({
      anchors: state.anchors.filter((anchor) => anchor.hash !== hash),
    }))
  }

  state: AnchorMenuContextValue = {
    anchors: [],
    addAnchor: this.addAnchor,
    removeAnchor: this.removeAnchor,
  }

  render() {
    const { children } = this.props

    return (
      <AnchorMenuContext.Provider value={this.state}>
        {children}
      </AnchorMenuContext.Provider>
    )
  }
}

function useAnchorData() {
  const anchorContext = useContext(AnchorMenuContext)
  if (!anchorContext) {
    throw new Error('Anchor context does not exist')
  }

  return anchorContext
}

export function AnchorMenu() {
  const anchorData = useAnchorData()
  const { pathname, search } = useLocation()

  const anchorLinks = anchorData.anchors.filter((anchor) => anchor)

  return anchorLinks.length > 0 ? (
    <AnchorMenuBorder>
      <AnchorMenuItems>
        {anchorLinks.map(({ name, hash }) => (
          <li key={hash}>
            <AnchorMenuItemLink
              to={{
                pathname,
                search,
                hash,
              }}
              onClick={() => {
                document
                  .getElementById(hash)
                  ?.scrollIntoView({ behavior: 'smooth' })
              }}
            >
              {name}
            </AnchorMenuItemLink>
          </li>
        ))}
      </AnchorMenuItems>
    </AnchorMenuBorder>
  ) : null
}

const AnchorMenuBorder = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.palette.grey[200]};
`

const AnchorMenuItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
`

const AnchorMenuItemLink = styled(Link)`
  padding: 10px 10px;
  display: block;
  font-size: ${(props) => props.theme.fontSizes[0]}px;
  line-height: ${20 / 12};
  letter-spacing: 0.05em;
  text-decoration: none;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.common.black};

  ${(props) => props.theme.media(1, props.theme.breakpoints)} {
    font-size: 0.8125rem;
    padding: 10px 30px;
  }

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`

type AnchorProps = {
  name: string
  hash: string
  position: number
}

export function Anchor({ name, hash, position }: AnchorProps) {
  const anchorData = useAnchorData()
  return (
    <AnchorLink
      name={name}
      hash={hash}
      position={position}
      addAnchor={anchorData.addAnchor}
      removeAnchor={anchorData.removeAnchor}
    />
  )
}

class AnchorLink extends PureComponent<
  AnchorProps & AnchorMenuContextCallbacks
> {
  componentDidMount() {
    const { addAnchor, name, hash, position } = this.props
    addAnchor(name, hash, position)
  }

  componentWillUnmount() {
    const { removeAnchor, hash } = this.props
    removeAnchor(hash)
  }

  render() {
    const { hash } = this.props
    return <AnchorHash id={hash} />
  }
}

const AnchorHash = styled.a`
  height: 0;
  width: 0;
  display: block;
`
