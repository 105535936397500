import { createContext, Dispatch } from 'react'
import { WaringLevelAction } from '../components/WaringLevelProvider'

export type WarningLevelContextInterface = {
  levels: string[]
  dispatch: Dispatch<WaringLevelAction>
}

export const WarningLevelContext = createContext<WarningLevelContextInterface>({
  levels: [],
  dispatch: () => {
    console.warn(`Added level to empty context`)
  },
})
