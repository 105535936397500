import { Box } from '@mui/material'
import { roaMatrixRowConfig } from '../../../common/text'
import { transformROAHits } from '../../../helpers/substance.helper'
import { useAppSelector } from '../../../hooks/useStoreHooks'
import type { ROAHitsInt } from '../../../types/common.types'
import { SubstanceApplicationHitsRowInt } from './SubstanceApplicationHitsRowInt'

type Props = {
  hits: ROAHitsInt
  isVeterinary: boolean
  isMonoPreparation: boolean
  otherCountries: string[]
}

export function SubstanceApplicationHitsBlockInt({
  hits,
  isVeterinary,
  isMonoPreparation,
  otherCountries,
}: Props) {
  const filter = useAppSelector((state) => state.filter)
  const noFilter = filter.selectedRoutesOfAdministration.length === 0

  const transformedHits = transformROAHits(hits)

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Box width={48}></Box>
        <Box width={128}></Box>
        <Box width={96} sx={{ textAlign: 'center', fontWeight: 700 }}>
          AUT
        </Box>
        <Box width={96} sx={{ textAlign: 'center', fontWeight: 700 }}>
          DEU
        </Box>
        {otherCountries.length > 0 && (
          <Box width={96} sx={{ textAlign: 'center', fontWeight: 700 }}>
            Weitere Länder
          </Box>
        )}
      </Box>
      <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {roaMatrixRowConfig.map(({ code, title }) => {
          const hitsForCode = transformedHits[code]
          if (!hitsForCode) {
            return null
          }

          if (filter.routesOfAdministration[code] || noFilter) {
            return (
              <SubstanceApplicationHitsRowInt
                key={code}
                hits={hitsForCode}
                title={title}
                routeOfAdministration={code}
                isVeterinary={isVeterinary}
                isMonoPreparation={isMonoPreparation}
                otherCountries={otherCountries}
              />
            )
          }

          return null
        })}
      </Box>
    </>
  )
}
