import { css, Global } from '@emotion/react'

const globalStyle = css`
  * {
    box-sizing: border-box;
  }

  :root {
    font-size: 100%;
    line-height: 1.5;
  }

  #app {
    height: 100%;
  }

  html {
    height: 100%;
    // overflow-y: auto !important;
    scrollbar-gutter: stable;
  }

  body {
    margin: 0;
    font-family: 'PT Sans', sans-serif;
    font-size: 0.875rem;
    color: #424242;
    height: 100%;
    padding-right: 0 !important;
  }
`

export const GlobalStyle = () => <Global styles={globalStyle} />
