import Cookie from 'js-cookie'
import { SettingsAdapterInterface } from '../../common/settings'

const setPreferenceKey = (key: string, value: string) =>
  new Promise<boolean>((resolve) => {
    Cookie.set(key, value.toString(), {
      expires: 90,
      sameSite: 'strict',
      secure: true,
    })
    resolve(true)
  })

const getPreferenceKey = (key: string) =>
  new Promise<string | undefined>((resolve) => {
    resolve(Cookie.get(key))
  })

export const createCookieAdapter = (key: string): SettingsAdapterInterface => {
  return {
    get: () => getPreferenceKey(key),
    set: (value) => setPreferenceKey(key, value as string),
  }
}
