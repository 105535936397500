export const shortInfoHeadings = {
  101: 'Indikationen',
  102: 'Kontraindikationen',
  103: 'Nebenwirkungen',
  104: 'Dosierung',
  105: 'Patientenhinweise',
  106: 'Haltbarkeit und Lagerung',
  107: 'Eigenschaften',
  108: 'Hinweise',
} as const

export const dispensaryMapping = {
  0: 'Nicht verschreibungspflichtig',
  1: 'Verschreibungspflichtig',
  3: 'Suchtgift',
} as const

export const storageMapping = {
  1: 'Kühlschrank',
  2: 'Raumtemperatur',
} as const

export const dispensaryIntMapping = {
  0: 'OTC',
  1: 'RP',
  3: 'SG',
} as const

// These countries are present in the KHIX files (Normal + Extended)
// Sorting is intentional: AT and DE first, then alphabetically
export const isoCodeToCountryMapping = {
  at: 'Österreich',
  de: 'Deutschland',
  eg: 'Ägypten',
  ar: 'Argentinien',
  am: 'Armenien',
  au: 'Australien',
  be: 'Belgien',
  ba: 'Bosnien-Herzegowina',
  br: 'Brasilien',
  bg: 'Bulgarien',
  cl: 'Chile',
  cn: 'China',
  cr: 'Costa Rica',
  dk: 'Dänemark',
  do: 'Dominikanische Republik',
  ec: 'Ecuador',
  ee: 'Estland',
  fo: 'Färöer',
  fj: 'Fidschi',
  fi: 'Finnland',
  fr: 'Frankreich',
  gi: 'Gibraltar',
  gr: 'Griechenland',
  gb: 'Großbritannien',
  hk: 'Hongkong',
  in: 'Indien',
  ie: 'Irland',
  is: 'Island',
  il: 'Israel',
  it: 'Italien',
  jp: 'Japan',
  jo: 'Jordanien',
  ca: 'Kanada',
  co: 'Kolumbien',
  hr: 'Kroatien',
  cu: 'Kuba',
  lv: 'Lettland',
  li: 'Liechtenstein',
  lt: 'Litauen',
  lu: 'Luxemburg',
  my: 'Malaysia',
  mt: 'Malta',
  mu: 'Mauritius',
  mk: 'Nordmazedonien',
  mx: 'Mexiko',
  mc: 'Monaco',
  me: 'Montenegro',
  nz: 'Neuseeland',
  nl: 'Niederlande',
  kp: 'Nordkorea',
  no: 'Norwegen',
  pk: 'Pakistan',
  pa: 'Panama',
  pe: 'Peru',
  ph: 'Philippinen',
  pl: 'Polen',
  pt: 'Portugal',
  ro: 'Rumänien',
  ru: 'Russland',
  se: 'Schweden',
  ch: 'Schweiz',
  rs: 'Serbien',
  sg: 'Singapur',
  sk: 'Slowakei',
  si: 'Slowenien',
  es: 'Spanien',
  lk: 'Sri Lanka',
  za: 'Südafrika',
  kr: 'Südkorea',
  tw: 'Taiwan',
  th: 'Thailand',
  cz: 'Tschechien',
  tr: 'Türkei',
  ua: 'Ukraine',
  hu: 'Ungarn',
  uy: 'Uruguay',
  ae: 'Vereinigte Arabische Emirate',
  us: 'USA',
  vn: 'Vietnam',
  cy: 'Zypern',
} as const

export const alpha2ToAlpha3 = {
  af: 'afg',
  al: 'alb',
  dz: 'dza',
  as: 'asm',
  ad: 'and',
  ao: 'ago',
  ai: 'aia',
  aq: 'ata',
  ag: 'atg',
  ar: 'arg',
  am: 'arm',
  aw: 'abw',
  au: 'aus',
  at: 'aut',
  az: 'aze',
  bs: 'bhs',
  bh: 'bhr',
  bd: 'bgd',
  bb: 'brb',
  by: 'blr',
  be: 'bel',
  bz: 'blz',
  bj: 'ben',
  bm: 'bmu',
  bt: 'btn',
  bo: 'bol',
  ba: 'bih',
  bw: 'bwa',
  bv: 'bvt',
  br: 'bra',
  io: 'iot',
  bn: 'brn',
  bg: 'bgr',
  bf: 'bfa',
  bi: 'bdi',
  kh: 'khm',
  cm: 'cmr',
  ca: 'can',
  cv: 'cpv',
  ky: 'cym',
  cf: 'caf',
  td: 'tcd',
  cl: 'chl',
  cn: 'chn',
  cx: 'cxr',
  cc: 'cck',
  co: 'col',
  km: 'com',
  cg: 'cog',
  cd: 'cod',
  ck: 'cok',
  cr: 'cri',
  ci: 'civ',
  hr: 'hrv',
  cu: 'cub',
  cy: 'cyp',
  cz: 'cze',
  dk: 'dnk',
  dj: 'dji',
  dm: 'dma',
  do: 'dom',
  ec: 'ecu',
  eg: 'egy',
  sv: 'slv',
  gq: 'gnq',
  er: 'eri',
  ee: 'est',
  et: 'eth',
  fk: 'flk',
  fo: 'fro',
  fj: 'fji',
  fi: 'fin',
  fr: 'fra',
  gf: 'guf',
  pf: 'pyf',
  tf: 'atf',
  ga: 'gab',
  gm: 'gmb',
  ge: 'geo',
  de: 'deu',
  gh: 'gha',
  gi: 'gib',
  gr: 'grc',
  gl: 'grl',
  gd: 'grd',
  gp: 'glp',
  gu: 'gum',
  gt: 'gtm',
  gn: 'gin',
  gw: 'gnb',
  gy: 'guy',
  ht: 'hti',
  hm: 'hmd',
  va: 'vat',
  hn: 'hnd',
  hk: 'hkg',
  hu: 'hun',
  is: 'isl',
  in: 'ind',
  id: 'idn',
  ir: 'irn',
  iq: 'irq',
  ie: 'irl',
  il: 'isr',
  it: 'ita',
  jm: 'jam',
  jp: 'jpn',
  jo: 'jor',
  kz: 'kaz',
  ke: 'ken',
  ki: 'kir',
  kp: 'prk',
  kr: 'kor',
  kw: 'kwt',
  kg: 'kgz',
  la: 'lao',
  lv: 'lva',
  lb: 'lbn',
  ls: 'lso',
  lr: 'lbr',
  ly: 'lby',
  li: 'lie',
  lt: 'ltu',
  lu: 'lux',
  mo: 'mac',
  mg: 'mdg',
  mw: 'mwi',
  my: 'mys',
  mv: 'mdv',
  ml: 'mli',
  mt: 'mlt',
  mh: 'mhl',
  mq: 'mtq',
  mr: 'mrt',
  mu: 'mus',
  yt: 'myt',
  mx: 'mex',
  fm: 'fsm',
  md: 'mda',
  mc: 'mco',
  mn: 'mng',
  ms: 'msr',
  ma: 'mar',
  mz: 'moz',
  mm: 'mmr',
  na: 'nam',
  nr: 'nru',
  np: 'npl',
  nl: 'nld',
  nc: 'ncl',
  nz: 'nzl',
  ni: 'nic',
  ne: 'ner',
  ng: 'nga',
  nu: 'niu',
  nf: 'nfk',
  mp: 'mnp',
  mk: 'mkd',
  no: 'nor',
  om: 'omn',
  pk: 'pak',
  pw: 'plw',
  ps: 'pse',
  pa: 'pan',
  pg: 'png',
  py: 'pry',
  pe: 'per',
  ph: 'phl',
  pn: 'pcn',
  pl: 'pol',
  pt: 'prt',
  pr: 'pri',
  qa: 'qat',
  re: 'reu',
  ro: 'rou',
  ru: 'rus',
  rw: 'rwa',
  sh: 'shn',
  kn: 'kna',
  lc: 'lca',
  pm: 'spm',
  vc: 'vct',
  ws: 'wsm',
  sm: 'smr',
  st: 'stp',
  sa: 'sau',
  sn: 'sen',
  sc: 'syc',
  sl: 'sle',
  sg: 'sgp',
  sk: 'svk',
  si: 'svn',
  sb: 'slb',
  so: 'som',
  za: 'zaf',
  gs: 'sgs',
  es: 'esp',
  lk: 'lka',
  sd: 'sdn',
  sr: 'sur',
  sj: 'sjm',
  sz: 'swz',
  se: 'swe',
  ch: 'che',
  sy: 'syr',
  tw: 'twn',
  tj: 'tjk',
  tz: 'tza',
  th: 'tha',
  tl: 'tls',
  tg: 'tgo',
  tk: 'tkl',
  to: 'ton',
  tt: 'tto',
  tn: 'tun',
  tr: 'tur',
  tm: 'tkm',
  tc: 'tca',
  tv: 'tuv',
  ug: 'uga',
  ua: 'ukr',
  ae: 'are',
  gb: 'gbr',
  us: 'usa',
  um: 'umi',
  uy: 'ury',
  uz: 'uzb',
  vu: 'vut',
  ve: 'ven',
  vn: 'vnm',
  vg: 'vgb',
  vi: 'vir',
  wf: 'wlf',
  eh: 'esh',
  ye: 'yem',
  zm: 'zmb',
  zw: 'zwe',
  ax: 'ala',
  bq: 'bes',
  cw: 'cuw',
  gg: 'ggy',
  im: 'imn',
  je: 'jey',
  me: 'mne',
  bl: 'blm',
  mf: 'maf',
  rs: 'srb',
  sx: 'sxm',
  ss: 'ssd',
  xk: 'xkx',
} as const
