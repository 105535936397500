import { ProductData } from '@austria-codex/types'
import { List, ListItem } from '../../components/Layout/List'
import { Text } from '../../components/Layout/Typography'
import { Section } from '../../components/Section/Section'
import { useProductData } from '../../contexts/entity'

export function StorageSection() {
  const product = useProductData()

  return hasStorageInformation(product) ? (
    <Section width="small" heading="Lagerung">
      <List>
        {hasExtendedStorageInformation(product) ? (
          <>
            {product.lagerung?.haltbarkeit && (
              <ListItem>
                Haltbarkeit: {product.lagerung.haltbarkeit.dauerWert ?? 'k.A.'}{' '}
                {product.lagerung.haltbarkeit.dauerEinheit?.beschreibung ??
                  'k.A.'}
              </ListItem>
            )}
            {product.lagerung?.lagerungsbedingung && (
              <ListItem>
                Lagerungsbedingung:{' '}
                {product.lagerung.lagerungsbedingung.tempMin}
                {' bis '}
                {product.lagerung.lagerungsbedingung.tempMax}{' '}
                {product.lagerung.lagerungsbedingung.tempEinheit.bezeichnung}
              </ListItem>
            )}
          </>
        ) : (
          product.lagerung?.kennzeichen?.map((indicator) => (
            <ListItem key={indicator.bezeichnung}>
              {(indicator.kategorie === 1 || indicator.kategorie === 3) && (
                <Text fontWeight="bold">{indicator.bezeichnung}: </Text>
              )}
              {indicator.beschreibung}
            </ListItem>
          ))
        )}
      </List>
    </Section>
  ) : null
}

export const hasStorageInformation = (product: ProductData): boolean =>
  (product.lagerung?.kennzeichen && product.lagerung?.kennzeichen.length > 0) ||
  hasExtendedStorageInformation(product)

export const hasExtendedStorageInformation = (product: ProductData): boolean =>
  Boolean(product.lagerung?.haltbarkeit) ||
  Boolean(product.lagerung?.lagerungsbedingung)
