import { List, ListItem } from '../../../components/Layout/List'
import { Section } from '../../../components/Section/Section'
import { useProductIntData } from '../../../contexts/entity'
import { getDispensaryPolicy } from '../../../helpers/product-international.helper'

export function IntDispensarySection() {
  const product = useProductIntData()

  // Only show section if DE product
  if (product.isoCode !== 'de') {
    return null
  }

  if (product.abgabeBestimmung === undefined) {
    return null
  }

  const abgabeBestimmung = getDispensaryPolicy(product.abgabeBestimmung)

  return (
    <Section width="small" heading="Verschreibung und Abgabe">
      <List>
        <ListItem>{abgabeBestimmung}</ListItem>
      </List>
    </Section>
  )
}
