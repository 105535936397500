import { Html } from '../../components/Html/Html'
import { DocumentationSection } from '../../components/Section/DocumentationSection'
import { useProductData } from '../../contexts/entity'

export function FieldOfApplicationSection() {
  const product = useProductData()

  return product.dokumentation?.schnellhilfe?.anwendungsgebiete ? (
    <DocumentationSection
      inherit={product.dokumentation.schnellhilfe.anwendungsgebiete.vererbt}
      width="small"
      heading="Anwendungsgebiete"
    >
      <Html
        content={product.dokumentation.schnellhilfe.anwendungsgebiete.text}
      />
    </DocumentationSection>
  ) : null
}
