import { CompositionIntType, type Eintrag } from '@austria-codex/types'
import { isNullOrUndefined } from '@austria-codex/utilities'
import { css } from '@emotion/react'
import { Text } from '../../../../components/Layout/Typography'
import { CellBorder, TableCell } from '../../../../components/Table/TableCell'
import { TableRow } from '../../../../components/Table/TableRow'
import { Numeral } from '../../../../components/Utility/Numeral'
import { IntCompositionTitleBlock } from './IntCompositionTitleBlock'

type TProps = {
  entry: Eintrag
  onClick?: () => void
}

export function IntCompositionSubstanceRow({ entry, onClick }: TProps) {
  return (
    <TableRow display={['flex', 'table-row']} flexWrap={['wrap', 'nowrap']}>
      <TableCell width={'auto'}>
        {/* todo: [1, 'auto'] */}
        <CellBorder pr={3} indent>
          <IntCompositionTitleBlock
            bullet={
              entry.eintragsTyp !== CompositionIntType.ErklaerenderText &&
              isNullOrUndefined(entry.bezLaufnummer)
            }
            entry={entry}
            onClick={onClick}
          >
            <Text
              color={onClick ? 'primary.main' : 'inherit'}
              role={onClick ? 'button' : undefined}
              cursor={onClick ? 'pointer' : 'inherit'}
              dangerouslySetInnerHTML={{ __html: entry.bezeichnung }}
            />
          </IntCompositionTitleBlock>
        </CellBorder>
      </TableCell>
      <TableCell
        width={120}
        css={css({ whiteSpace: 'nowrap', textAlign: 'right' })}
      >
        <CellBorder pr={[1, 3]} pl={[40, 0]}>
          {entry.einheit && entry.menge && (
            <Numeral input={entry.menge} format="0,0[.][0000]" />
          )}
        </CellBorder>
      </TableCell>
      <TableCell width={80}>
        <CellBorder>{entry.einheit ?? ''}</CellBorder>
      </TableCell>
    </TableRow>
  )
}
