import { occurrenceDescription } from '@austria-codex/types'
import { Box } from '@mui/material'
import { OffLabel } from '../../components/OffLabel/OffLabel'
import { Section } from '../../components/Section/Section'
import {
  ValueCard,
  ValueCardDescription,
  ValueCardKey,
  ValueCardValue,
} from '../../components/ValueCard/ValueCard'
import { useProductData } from '../../contexts/entity'

export function DivisibilitySection() {
  const product = useProductData()

  if (!product.teilbarkeit) {
    return null
  }

  // If maxTeilbarkeit is 1 we dont need to show this section.
  if (product.teilbarkeit.maxTeilbarkeit <= 1) {
    return null
  }

  return (
    <Section
      width="small"
      heading="Teilbarkeit"
      id={`divisibility:${product.id}`}
    >
      <Box sx={{ display: 'flex' }}>
        <ValueCard width={1 / 3}>
          <ValueCardValue>{product.teilbarkeit?.maxTeilbarkeit}</ValueCardValue>
          <ValueCardKey>max. Teilbarkeit</ValueCardKey>
        </ValueCard>
        <ValueCard width={1 / 3}>
          <ValueCardValue>
            {occurrenceDescription[product.teilbarkeit.gleicheDosen]}
          </ValueCardValue>
          <ValueCardKey>Gleiche Dosen</ValueCardKey>
          {product.teilbarkeit?.hinweisGleicheDosen && (
            <ValueCardDescription>
              {product.teilbarkeit.hinweisGleicheDosen}
            </ValueCardDescription>
          )}
        </ValueCard>
        <ValueCard width={1 / 3}>
          <ValueCardValue>
            {occurrenceDescription[product.teilbarkeit.erleichterteEinnahme]}
          </ValueCardValue>
          <ValueCardKey>Erleichterte Einnahme</ValueCardKey>
          {product.teilbarkeit?.hinweisErleichterteEinnahme && (
            <ValueCardDescription>
              {product.teilbarkeit.hinweisErleichterteEinnahme}
            </ValueCardDescription>
          )}
        </ValueCard>
      </Box>
      {product.teilbarkeit?.hinweisTeilbarkeit && (
        <Box>{product.teilbarkeit.hinweisTeilbarkeit}</Box>
      )}
      {product.teilbarkeit?.hinweisOhneBruchrille && (
        <Box sx={{ mt: 3 }}>
          <OffLabel offLabel m={-2} py={2} pl={2} pr={5}>
            {product.teilbarkeit.hinweisOhneBruchrille}
          </OffLabel>
        </Box>
      )}
    </Section>
  )
}
