import { AlignmentType, ParagraphChildType } from '../types'
import { TextElement } from './TextElement'

type ParagraphProps = {
  children: ParagraphChildType[]
  alignment?: AlignmentType
}

export function Paragraph({ children, alignment }: ParagraphProps) {
  return (
    <p>
      {children.map((child, index) => (
        <TextElement
          key={index}
          child={child}
          variant="span"
          alignment={alignment}
        />
      ))}
    </p>
  )
}
