export function compareString(
  left: string | number,
  right: string | number
): number {
  if (left == null) {
    left = '_'
  }

  if (right == null) {
    right = '_'
  }

  if (typeof left === 'string' || typeof right === 'string') {
    return String(left).localeCompare(String(right), 'de', {
      sensitivity: 'base',
    })
  }

  return left - right
}
